import UserTypes from "@/models/Types/UserTypes";

class UserTypeProvider {

    constructor() {
        if (this.instance) {
            return this.instance;
        }
        this.instance = this;
    }

    getUserType() {
        const userObject = JSON.parse(localStorage.getItem('ibisOnlinebestellungUserObject'));
        const customer_type = userObject?.customer_type;
        switch (customer_type) {
            case 'Fachhandel':
                return UserTypes.RETAILER;
            case 'Bauhaus':
                return UserTypes.BAUHAUS;
            default:
                return UserTypes.XXXL_GROUP;
        }
    }

}

export default new UserTypeProvider();