<template>
    <div class="product-images">
        <div v-if="mainImage" class="main-image">
            <img :src="mainImage" />
        </div>
    </div>
</template>

<script>
export default {
    inject: ["$firebase"],

    props: {
        currentProduct: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            mainImage: "",
        };
    },
    mounted() {
        this.fetchProductImages(this.currentProduct.product_id);
    },
    watch: {
        currentProduct: {
            handler() {
                this.fetchProductImages(this.currentProduct.product_id);
            },
            deep: true,
        },
    },

    methods: {
        async fetchProductImages(productId) {
            const storageRef = this.$firebase
                .storage()
                .ref(`ProductImages/${productId}`);
            try {
                const result = await storageRef.listAll();
                const urls = await Promise.all(
                    result.items.map((item) => item.getDownloadURL())
                );
                if (urls.length > 0) {
                    this.mainImage = urls[0]; // Set the first image as the main image
                }
                else{
                    this.mainImage = null; // Set a placeholder image
                }
            } catch (error) {
                console.error("Failed to fetch images", error);
            }
        },


    },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.product-images {
    .main-image {
        width: 100%; // Adjust as necessary
        height: auto; // Adjust as necessary
        display: flex;
        justify-content: center;

        img {
            width: 10vw; // Ensure this matches your design requirements
            height: 10vw; // Maintain aspect ratio
            object-fit: contain;
            max-width: 100px;
            max-height: 100px;

            /*  @media (max-width: $tablet) {
                height: 35vw;
                width: 35vw;
            }

            @media (max-width: $mobile) {
                height: 50vw;
                width: 50vw;
            } */
        }
    }
}
</style>