<template>
  <div class="downloadCategoryWrapper" id="downloadCategoryWrapper">
    <div class="topLine" v-if="userMultiplikator <= 1 && isRetailer">
      <div class="search-bar">
        <input
          type="text"
          v-model="searchText"
          placeholder="Suche nach Dateien..."
        />
      </div>
      <div class="changeTopic">
        <span
          style="margin-right: 1rem"
          @click="this.$router.push('/downloads/preislistenkonfigurator')"
          v-if="this.$route.path === '/downloads/preislisten' && !isLoading"
          ><span style="transform: rotate(180deg); display: inline-block"
            >➜</span
          >
          Preislisten-Konfigurator</span
        >
        <span
          @click="handleQuickNav('/downloads/weitere_downloads')"
          v-if="this.$route.path === '/downloads/preislisten' && !isLoading"
          >Weitere Downloads <span>➜</span></span
        >
        <span
          @click="handleQuickNav('/downloads/preislisten')"
          v-if="this.$route.path !== '/downloads/preislisten' && !isLoading"
          ><span style="transform: rotate(180deg); display: inline-block"
            >➜</span
          >
          Preislisten</span
        >
      </div>
    </div>
    <div v-if="userMultiplikator <= 1 && isRetailer">
      <div class="files-grid" v-if="files.length && !isLoading">
        <FileItem v-for="(file, index) in files" :key="index" :file="file" />
      </div>
      <div>
        <div class="spinnerWrapper" v-if="isLoading">
          <IbisSpinner v-if="isLoading"></IbisSpinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileItem from "../shared/FileItem.vue";
import IbisSpinner from "../shared/IbisSpinner.vue";
import priceListsFileNames from "../../models/FileNameMappings/priceListsFileNames.js";
import otherContentFileNames from "../../models/FileNameMappings/otherContentFileNames.js";
import UserTypeProvider from "@/utility/UserTypeProvider";
import UserTypes from "@/models/Types/UserTypes";

export default {
  inject: ["$firebase"],
  components: {
    FileItem,
    IbisSpinner,
  },
  computed: {
    userMultiplikator() {
      return JSON.parse(localStorage.getItem("ibisOnlinebestellungUserObject")).multiplikator;
    },
    isRetailer() {
      return UserTypeProvider.getUserType() === UserTypes.RETAILER;
    },
  },
  data() {
    return {
      allFiles: [],
      files: [],
      isLoading: true,
      searchText: "",
      priceListsFileNames: priceListsFileNames,
      otherContentFileNames: otherContentFileNames,
    };
  },
  watch: {
    // Watch for changes in route
    $route: {
      immediate: true,
      handler() {
        this.loadFiles();
      },
    },
  },
  async created() {
    this.isLoading = true;
    try {
      await this.loadFiles();
    } catch (error) {
      console.error("Error while fetching files:", error);
    } finally {
      document
        .getElementById("downloadCategoryWrapper")
        .classList.add("fade-in-bottom");
      this.isLoading = false;
    }
  },

  methods: {
    async loadFiles() {
      this.isLoading = true;
      if (this.userMultiplikator <= 1) {
        try {
          const storageRef = this.$firebase.storage().ref();
          const filesRef =
            this.$route.path === "/downloads/preislisten"
              ? storageRef.child("DownloadableContent/Pricelists/")
              : storageRef.child("DownloadableContent/OtherContent/");
          const result = await filesRef.listAll();
          const filePromises = result.items.map(async (item) => {
            const url = await item.getDownloadURL();
            return { name: item.name.split(".")[0], url };
          });
          this.files = await Promise.all(filePromises);
          this.allFiles = [...this.files];
        } catch (error) {
          console.error("Error while fetching files:", error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    handleQuickNav(route) {
      this.$router.push(route);
      // Remove the file processing from here as it will be handled by the watcher
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.downloadCategoryWrapper {
  padding: 0 10rem;
  margin: auto;
  max-width: 1200px;
  @media (max-width: $mobile) {
    padding: 0;
  }
  @media (min-width: $mobile) and (max-width: $tablet) {
    padding: 0 4rem;
  }
  .spinnerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  .topLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
    @media (max-width: $mobile) {
      flex-flow: column-reverse;
      gap: 1rem;
    }
    .search-bar {
      display: flex;
      border-radius: 50px;
      background-color: $ibis-grey-middle;
      padding: 4px;
      width: 40%;
      @media (max-width: $mobile) {
        flex-flow: column-reverse;
        width: 95%;
      }
      input {
        width: 100%;
        @media (max-width: $mobile) {
          width: auto;
        }
        height: 2.5em;
        border-radius: 50px;
        border: none;
        padding-left: 1rem;
        font-size: $font-size-big;

        &:focus {
          outline: none;
        }
      }
    }

    .changeTopic {
      @media (max-width: $mobile) {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      span {
        cursor: pointer;
        transition: 0.1s;
        &:hover {
          color: $ibis-red;
        }
      }
    }
  }
  .files-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 2rem;
    margin-bottom: 5rem;
    @media (max-width: $mobile) {
      padding: 0 1rem;
    }
  }
}
</style>
