<template>
  <div class="passepartoutInputWrapper">
    <div class="mainHeadlineWrapper">
      <h2 class="headline">Passepartout {{ isSecondPassepartout ? '2' : '' }}
      </h2>
    </div>
    <label class="checkboxContainer" v-if="!isSecondPassepartout"
      style="margin-bottom: 2rem; margin-top: 1rem">kein Passepartout /
      kundeneigenes Passepartout
      <input id="withBridgeCheckbox" type="checkbox"
        v-model="withoutPassepartout" @input="onWithPPInput()" />
      <span class="checkmark"></span>
    </label>
    <div class="passepartoutSearch" v-if="!withoutPassepartout">
      <ProductSearchbar :filter="passepartoutSearchFilter"
        :customOnClick="selectPassepartout"></ProductSearchbar>
    </div>
    <div v-if="currentProduct && !withoutPassepartout"
      class="workshopOptionDetails">
      <div class="content">
        <NonConfigurableFreeSpaceDummy :roundToTwo="roundToTwo"
          :productProp="currentProduct"></NonConfigurableFreeSpaceDummy>
        <TopCutAndFrameOptions :updatePrice="updatePrice"
          :heightValidation="heightValidation"
          :widthValidation="widthValidation" :selectAll="selectAll"
          :productProp="currentProduct"></TopCutAndFrameOptions>
        <PassepartoutOptions style="margin-top: 1.5em"
          :updatePrice="updatePrice"
          :innerHeightValidation="innerHeightValidation"
          :innerWidthValidation="innerWidthValidation" :selectAll="selectAll"
          :syncConfiguration="syncConfiguration"
          :onNoCutOutInput="onNoCutOutInput" :productProp="currentProduct" :isSecondPassepartout="isSecondPassepartout">
        </PassepartoutOptions>
        <ProductRestrictions :productProp="currentProduct">
        </ProductRestrictions>
        <!--  <PositionPrice
          :roundToTwo="roundToTwo"
          :productProp="currentProduct"
        ></PositionPrice> -->
      </div>
      <div class="sizeUnitWrapper">
        <div>cm</div>
        <div v-show="!currentProduct.passepartout_no_cut_out"
          style="margin-top: 3.2rem">
          cm
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductSearchbar from "@/components/configurator/shared/ProductSearchbar.vue";
import NonConfigurableFreeSpaceDummy from "@/components/configurator/shared/Information/NonConfigurableFreeSpaceDummy.vue";
//import PositionPrice from "@/components/configurator/shared/Information/PositionPrice.vue";
import FamilyTypes from "@/models/Types/FamilyTypes";
import ProductOptionsToSort from "@/models/Types/ProductOptionsToSort";
import ProductTypes from "@/models/Types/ProductTypes";
import TopCutAndFrameOptions from "@/components/configurator/shared/Options/TopCutAndFrameOptions.vue";
import PassepartoutOptions from "@/components/configurator/shared/Options/PassepartoutOptions.vue";
import ProductRestrictions from "@/components/configurator/shared/Information/ProductRestrictions.vue";
export default {
  props: [
    "roundToTwo",
    "updatePrice",
    "heightValidation",
    "widthValidation",
    "innerWidthValidation",
    "innerHeightValidation",
    "selectAll",
    "syncConfiguration",
    "onNoCutOutInput",
    "checkProductSizeLimits",
    "isSecondPassepartout",
  ],
  components: {
    ProductSearchbar,
    NonConfigurableFreeSpaceDummy,
    TopCutAndFrameOptions,
    PassepartoutOptions,
    ProductRestrictions,
    //PositionPrice,
  },
  data() {
    return {
      productTypes: ProductTypes,
      familyTypes: FamilyTypes,
    };
  },
  computed: {
    withoutPassepartout: {
      get() {
        return this.currentWorkshopWork.no_passepartout;
      },
      set(value) {
        this.$store.commit("setWorkshopPassepartoutStatus", value);
      },
    },
    currentWorkshopWork() {
      return this.$store.getters.currentWorkshopWork;
    },
    currentProduct() {
      return !this.isSecondPassepartout ? this.$store.getters.currentWorkshopWork.passepartout : this.$store.getters.currentWorkshopWork.second_passepartout;
    },
    currentFrame() {
      return this.$store.getters.currentWorkshopWork.frame;
    },
  },
  methods: {
    passepartoutSearchFilter(results) {
      if (this.currentFrame) {
        const filteredFamilies = results
          .map((family) => {
            // First, filter the products within each family.
            const filteredProducts = family.products.filter(
              (e) => e.sub_category === ProductOptionsToSort.PASSEPARTOUT_CUT
            );
            const doubleFilteredProducts = filteredProducts.filter((e) =>
              this.checkProductSizeLimits(e)
            );
            // Return the family object with the filtered products,
            // or null if there are no products left after filtering.
            return doubleFilteredProducts.length > 0
              ? { ...family, products: doubleFilteredProducts }
              : null;
          })
          // Then, filter out any null entries (i.e., families with zero products).
          .filter((family) => family !== null);
        return filteredFamilies;
      }
    },
    selectPassepartout(result) {
      if (this.isSecondPassepartout) {
        this.$store.commit("setWorkshopSecondPassepartout", result.products[0]);
      } else {
        this.$store.commit("setWorkshopPassepartout", result.products[0]);
      }
    },
    onWithPPInput() {
      this.withoutPassepartout = !this.withoutPassepartout;
      if (this.withoutPassepartout) {
        this.$store.commit("setWorkshopPassepartout", undefined);
        this.$store.commit("setWorkshopSecondPassepartout", undefined);
      }
      this.syncConfiguration();
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../../../assets/sass/style.scss";

.passepartoutInputWrapper {
  .mainHeadlineWrapper {
    .headline {
      text-align: left;
    }
  }
}

.sizeUnitWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 12.8rem;
  min-width: 1.5vw;
  margin-left: 0.5rem;

  span {
    height: fit-content;
  }
}

.checkboxContainer {
  z-index: 1;
  display: flex;
  position: relative;
  padding-left: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 0;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background-color: $ibis-grey-middle;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked~.checkmark {
    background-color: $ibis-red;
    transition: 0.15s;

    &:after {
      display: block;
    }
  }

  .checkmark:after {
    left: 6.5px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
</style>
