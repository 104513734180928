<template>
  <div class="glasInputWrapper">
    <div class="mainHeadlineWrapper">
      <h2 class="headline">Glas {{ isSecondGlas ? '2' : null }}</h2>
      <div class="infoIconWrapper">
        <ToolTip tipText="<strong>IbiS artglass ®claryl UV70:</strong>
          beidseitig
          interferenzoptisch entspiegeltes ca. 2mm starkes Weißglas mit 70%
          UV-Schutz. Durch die Entspiegelung bleiben die Farbtöne Ihres Bildes
          unverfälscht.
          <br>
          <br>
          <strong>IbiS artglass ®claryl UV92:</strong> beidsetig
          interferenzoptisch entspiegeltes
          ca. 2mm starkes Weißglas mit 92% UV-Schutz. Durch die Entspiegelung
          bleiben die Farbtöne Ihres Bildes unverfälscht. Der erhöhte UV-Schutz
          verhindert ein frühzeitiges Verblassen der Farben Ihres Bildes.
          <br>
          <br>

          <strong>Mattglas 2mm:</strong> einseitig geätztes, reflexarmes Glas in
          ca.
          2mm Stärke,
          das Lichtspiegelungen verhindert, Ihrem Bild jedoch die Schärfe nimmt.
          <br>
          <br>

          <strong>Floatglas UV99:</strong> Fensterglas mit 99% UV-Schutz ist ein
          nicht
          entspiegeltes ca. 2mm starkes Floatglas mit einer speziellen einseitig
          aufgebrachten UV-Schutz-Beschichtung. Die beschichtete Seite muss dem
          Bild zugewandt eingebaut werden.
          <br>
          <br>

          <strong>artglass ®claryl UV99:</strong> interferenzoptisch
          entspiegeltes ca. 2mm
          starkes Bilderglas mit einer speziellen einseitig aufgebrachten
          UV-Schutz-Beschichtung. Die beschichtete Seite muss dem Bild zugewandt
          eingebaut werden

          <br>
          <br>


          <strong>OptiumMuseumAcrylic:</strong> 3mm starkes, interferenzoptisch
          entspiegeltes
          Plexiglas mit 99% UV-Schutz. Es ist sowohl kratzresistent, als auch
          antistatisch. Zudem ist es einfach zu reinigen und nur halb so schwer
          wie vergleichbares Glas. OptiumMuseumAcrylic ist die mit Abstand
          hochwertigste Möglichkeit Kunstwerke zu rahmen."></ToolTip>
      </div>
    </div>
    <IbisSpinner v-if="isLoading"></IbisSpinner>
    <div v-else>
      <ProductSelect :products="finalFilteredProducts" v-show="!isSecondGlas"
        @changeProduct="changeGlas" :type="workshopProductSelectionTypes.GLAS"
        :isSecondGlas="isSecondGlas" ref="glasSelect">
      </ProductSelect>
      <ProductSelect :products="finalFilteredProducts" v-show="isSecondGlas"
        @changeProduct="changeGlas" :type="workshopProductSelectionTypes.GLAS"
        :isSecondGlas="isSecondGlas" ref="secondGlasSelect">
      </ProductSelect>
    </div>
    <div class="glasDetails">
      <div v-if="currentProduct" class="workshopOptionDetails">
        <div class="content">
          <NonConfigurableFreeSpaceDummy :roundToTwo="roundToTwo"
            :productProp="currentProduct"></NonConfigurableFreeSpaceDummy>
          <TopCutAndFrameOptions :updatePrice="updatePrice"
            :heightValidation="heightValidation"
            :widthValidation="widthValidation" :selectAll="selectAll"
            :productProp="currentProduct"></TopCutAndFrameOptions>

          <SecondGlasPictureSizeOptions v-if="isSecondGlas"
            :productProp="currentProduct"
            :innerSecondGlasHeightValidation="innerSecondGlasHeightValidation"
            :innerSecondGlasWidthValidation="innerSecondGlasWidthValidation"
            :selectAll="selectAll"></SecondGlasPictureSizeOptions>

          <ProductRestrictions :productProp="currentProduct">
          </ProductRestrictions>
          <!--  <PositionPrice
            :roundToTwo="roundToTwo"
            :productProp="currentProduct"
          ></PositionPrice> -->
        </div>
        <div class="sizeUnitWrapper">
          <div>cm</div>
          <div v-if="isSecondGlas"
            v-show="!currentProduct.passepartout_no_cut_out"
            style="margin-top: 2.6rem">
            cm
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NonConfigurableFreeSpaceDummy from "@/components/configurator/shared/Information/NonConfigurableFreeSpaceDummy.vue";
//import PositionPrice from "@/components/configurator/shared/Information/PositionPrice.vue";
import ProductRestrictions from "@/components/configurator/shared/Information/ProductRestrictions.vue";
import TopCutAndFrameOptions from "@/components/configurator/shared/Options/TopCutAndFrameOptions.vue";
import IbisSpinner from "@/components/shared/IbisSpinner.vue";
import ProductFactory from "@/utility/ProductFactory";
import ProductSelect from "../../shared/ProductSelect";
import FamilyTypes from "@/models/Types/FamilyTypes";
import ProductTypes from "@/models/Types/ProductTypes";
import WorkshopProductSelectionTypes from "@/models/Types/WorkshopProductSelectionTypes";
import WorkshopModes from "@/models/Types/WorkshopModes";
import Measurements from "@/models/Types/Measurements";
import SecondGlasPictureSizeOptions from "@/components/configurator/shared/Options/SecondGlasPictureSizeOptions.vue";
import UserTypeProvider from "@/utility/UserTypeProvider";
import UserTypes from "@/models/Types/UserTypes";
import ToolTip from "@/components/shared/ToolTip.vue";
export default {
  inject: ["$firebase"],
  props: [
    "availableProducts",
    "checkProductSizeLimits",
    "roundToTwo",
    "updatePrice",
    "heightValidation",
    "widthValidation",
    "selectAll",
    "isSecondGlas",
    "innerSecondGlasHeightValidation",
    "innerSecondGlasWidthValidation",
  ],
  components: {
    IbisSpinner,
    ProductSelect,
    NonConfigurableFreeSpaceDummy,
    TopCutAndFrameOptions,
    SecondGlasPictureSizeOptions,
    ProductRestrictions,
    ToolTip,
    //PositionPrice,
  },

  data() {
    return {
      isLoading: true,
      filteredProducts: [],
      allProducts: [],
      productTypes: ProductTypes,
      familyTypes: FamilyTypes,
      workshopProductSelectionTypes: WorkshopProductSelectionTypes,
    };
  },
  computed: {
    currentProduct: {
      get() {
        return !this.isSecondGlas ? this.$store.getters.currentWorkshopWork.glas : this.$store.getters.currentWorkshopWork.second_glas;
      },
      set(value) {
        !this.isSecondGlas ? this.$store.commit("switchWorkshopGlas", value) : this.$store.commit("switchWorkshopSecondGlas", value);
      },
    },
    currentWorkshopWorkFrameWidth() {
      if (this.$store.getters.currentWorkshopWork.frame) {
        return this.$store.getters.currentWorkshopWork.frame.width;
      }
      return undefined;
    },
    currentWorkshopWorkFrameHeight() {
      if (this.$store.getters.currentWorkshopWork.frame) {
        return this.$store.getters.currentWorkshopWork.frame.height;
      }
      return undefined;
    },
    currentFrame() {
      return this.$store.getters.currentWorkshopWork.frame;
    },
    currentWorkshopWork() {
      return this.$store.getters.currentWorkshopWork;
    },
    currentWorkshopMode() {
      return this.$store.getters.currentWorkshopMode;
    },
    finalFilteredProducts() {
      const userType = UserTypeProvider.getUserType();
      return this.filteredProducts.filter((e) => {
        let noPrice;

        if (userType === UserTypes.RETAILER) {
          noPrice = !e.price && !e.qm_price;
        } else {
          noPrice = !e.price;
        }
        return !noPrice;
      });
    }
  },

  watch: {
    currentWorkshopWorkFrameWidth() {
      this.filterAvailableProducts();
    },
    currentWorkshopWorkFrameHeight() {
      this.filterAvailableProducts();
    },
    currentWorkshopWork: {
      handler: async function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (
            this.currentWorkshopMode === WorkshopModes.FRAMING ||
            this.currentWorkshopMode === WorkshopModes.FRAME_WITH_EQUIPMENT
          ) {
            if (!this.currentProduct) {
              await this.initializeGlasses();
            }
          }
        }
      },
      deep: true,
    },
    "currentWorkshopWork.double_glas": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal && !this.currentWorkshopWork.second_glas) {
          this.setDefaultSecondGlas(this.filteredProducts[0]);
          if (this.currentWorkshopWork.double_glas) {
            this.$refs.secondGlasSelect.presetSecondGlas(this.filteredProducts[0]);
          }
        }
      },
      deep: true,
    },
  },

  async mounted() {
    await this.initializeGlasses();
  },

  methods: {
    changeGlas(product) {
      this.currentProduct = product;
    },
    setDefaultGlas(glas) {
      if (this.currentWorkshopWork.glas) {
        return;
      }
      this.$store.commit("setDefaultGlas", glas);
    },
    setDefaultSecondGlas(glas) {
      this.$store.commit("setDefaultSecondGlas", glas);
    },
    async initializeGlasses() {
      this.filteredProducts = [];
      this.allProducts = [];
      const db = this.$firebase.firestore();
      const productsRef = db.collection("products");
      const productsPromises = this.availableProducts.map(async (e) => {
        const productRef = await productsRef.where("parent_id", "==", e).get();
        productRef.forEach(async (doc) => {
          const product = await ProductFactory.fromJson(doc.data());
          this.allProducts.push(product);
        });
      });
      await Promise.all(productsPromises);
      this.allProducts.forEach((e) => {
        e.selected_measurement_type = Measurements.AUSSENMASS;
      });
      this.filteredProducts = [...this.allProducts];
      this.filterAvailableProducts();

      if (!this.currentProduct) {
        this.setDefaultGlas(this.filteredProducts[0]);
        this.$nextTick(() => {
          if (this.$refs.glasSelect) {
            this.$refs.glasSelect.presetGlas(this.filteredProducts[0]);
          }
        });
      }
      this.isLoading = false;
    },
    filterAvailableProducts() {
      if (this.currentFrame) {
        // Assuming checkProductSizeLimits only checks for size limits and does not affect the article_id comparison
        this.filteredProducts = this.allProducts.filter((e) => {
          return this.checkProductSizeLimits(e);
        });
        // Check if any item in the filteredProducts has the same article_id as the currentProduct
        const hasCurrentProduct = this.filteredProducts.some(
          (e) => e.article_id === this.currentProduct?.article_id
        );

        // If the currentProduct is not in the filteredProducts, reset the currentProduct
        if (!hasCurrentProduct) {
          this.currentProduct = "";
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../../../assets/sass/style.scss";

.glasInputWrapper {
  .mainHeadlineWrapper {
    width: 60vw;
    display: flex;
    align-items: center;
    gap: .5rem;

    @media (max-width: $mobile) {
      width: 95vw;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;

    .headline {
      text-align: left;
    }
  }
}

.sizeUnitWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 12.8rem;
  min-width: 1.5vw;
  margin-left: 0.5rem;

  span {
    height: fit-content;
  }
}
</style>
