<template>
  <div class="downloadOptionsWrapper" id="downloadOptionsWrapper">
    <div
      class="downloadsOption"
      @click="this.$router.push('/downloads/preislistenkonfigurator')"
      v-if="userMultiplikator <= 1 && isRetailer"
    >
      <img src="../../assets/icons/pricelist_configurator.svg" />
      <span>Preislisten-Konfigurator</span>
    </div>
    <div
      class="downloadsOption"
      @click="this.$router.push('/downloads/preislisten')"
      v-if="userMultiplikator <= 1 && isRetailer"
    >
      <img src="../../assets/icons/euro.svg" />
      <span>Preislisten</span>
    </div>
    <div
      class="downloadsOption"
      @click="this.$router.push('/downloads/weitere_downloads')"
      v-if="userMultiplikator <= 1 && isRetailer"
    >
      <img src="../../assets/icons/download.svg" />
      <span>Weitere Downloads</span>
    </div>
  </div>
</template>
<script>
import UserTypes from '@/models/Types/UserTypes';
import UserTypeProvider from '@/utility/UserTypeProvider';

export default {
  mounted() {
    document
      .getElementById("downloadOptionsWrapper")
      .classList.add("fade-in-bottom");
  },
  computed: {
    userMultiplikator() {
      return JSON.parse(localStorage.getItem("ibisOnlinebestellungUserObject")).multiplikator;
    },
    isRetailer() {
      return UserTypeProvider.getUserType() === UserTypes.RETAILER;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.downloadOptionsWrapper {
  display: flex;
  width: 100%;
  height: 80vh;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: $tablet) {
    flex-flow: column;
    gap: 2rem;
    margin-top: 2rem;
    height: auto;
  }
  .downloadsOption {
    height: 20rem;
    width: 20rem;
    background-color: $ibis-grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    border-radius: 25px;
    cursor: pointer;

    img {
      max-width: 8rem;
      max-height: 8rem;
    }
    span {
      font-size: $font-size-bigger;
      font-weight: bold;
    }
  }
}
</style>
