<template>
  <div class="discountCodesWrapper">
    <h2>Gutschein-Codes</h2>
    <h3>Aktiv</h3>
    <div class="codes">
      <CodeList
        :list="activeCodes"
        :isLoading="isActiveCodesLoading"
        :isActiveList="true"
        @listChange="fetchCodes()"
      ></CodeList>
    </div>
    <h3>Inaktiv</h3>
    <div class="codes">
      <CodeList
        :list="inActiveCodes"
        :isLoading="isInActiveCodesLoading"
        :isActiveList="false"
        @listChange="fetchCodes()"
      ></CodeList>
    </div>
    <div class="codeForm" v-if="showCodeForm">
      <CodeForm @buttonSubmit="onButtonSubmit"></CodeForm>
    </div>
    <div class="addCodeWrapper">
      <IbisButton
        v-if="!showCodeForm"
        buttonClass="primary"
        buttonText="Code erstellen +"
        @click="handleOnAddCodeClick"
      ></IbisButton>
    </div>
  </div>
</template>
<script>
import BackendHandler from "@/utility/BackendHandler";
import IbisButton from "../../shared/IbisButton.vue";
import CodeForm from "./CodeForm.vue";
import CodeList from "./CodeList.vue";
export default {
  components: { IbisButton, CodeForm, CodeList },

  data() {
    return {
      showCodeForm: false,
      isActiveCodesLoading: false,
      isInActiveCodesLoading: false,
      activeCodes: [],
      inActiveCodes: [],
    };
  },
  mounted() {
    this.fetchCodes();
  },
  methods: {
    handleOnAddCodeClick() {
      this.showCodeForm = true;
    },
    onButtonSubmit() {
      this.showCodeForm = false;
      this.fetchActiveDiscountCodes();
    },
    async fetchActiveDiscountCodes() {
      this.isActiveCodesLoading = true;
      try {
        const response = await BackendHandler.getAllActiveDiscountCodes();
        this.activeCodes = response.data;
      } catch (e) {
        console.log(e);
        this.isActiveCodesLoading = false;
        this.activeCodes = [];
      }
      this.isActiveCodesLoading = false;
    },
    async fetchInactiveDiscountCodes() {
      this.isInActiveCodesLoading = true;
      try {
        const response = await BackendHandler.getAllInactiveDiscountCodes();
        this.inActiveCodes = response.data;
      } catch (e) {
        console.log(e);
        this.isInActiveCodesLoading = false;
        this.inActiveCodes = [];
      }
      this.isInActiveCodesLoading = false;
    },
    fetchCodes() {
      this.fetchActiveDiscountCodes();
      this.fetchInactiveDiscountCodes();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/sass/style.scss";

.discountCodesWrapper {
  text-align: start;
  margin-top: 5rem;
  margin-bottom: 3rem;
  padding: 2% 5% 5% 5%;
  @media (max-width: $mobile) {
    padding: 0;
    padding-bottom: 2rem;
  }
  background-color: $ibis-grey;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 1500px;
  h2 {
    margin-bottom: 0;
    @media (max-width: $mobile) {
      margin-left: 5%;
    }
  }
  h3 {
    margin: 0;
    @media (max-width: $mobile) {
      margin-left: 5%;
    }
  }
  .codes {
    border-radius: 25px;
    width: 100%;
    border-radius: 25px;
    background-color: white;
    height: 15rem;
    box-shadow: 0px 8px 37px rgba(64, 64, 64, 0.1);
    padding: 2rem;
    box-sizing: border-box;
    @media (max-width: $mobile) {
      padding: 0.5rem;
    }
  }

  .addCodeWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
</style>
