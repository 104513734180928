<template>
  <div class="footer">
    <div class="curveHolder">
      <div class="curve"></div>
    </div>
    <div class="topLine">
      <img :src="userLogo" />
    </div>
    <div class="footer-content">
      <div class="legal">
        <span @click="navigateFromFooter('/impressum')">Impressum</span>
        <span @click="navigateFromFooter('/datenschutz')">Datenschutz</span>
        <span @click="openAGBs()"
          v-if="isUserAuthenticated && isRetailer">AGB</span>
      </div>
      <div class="configurator">
        <span @click="navigateFromFooter('/konfigurator')"
          v-if="isUserAuthenticated">Produktkonfigurator</span>
        <span @click="navigateFromFooter('/downloads/preislistenkonfigurator')"
          v-if="isUserAuthenticated">Preislistenkonfigurator</span>
      </div>
      <div class="contact">
        <span @click="navigateFromFooter('/downloads')"
          v-if="isUserAuthenticated && isRetailer">Downloads</span>
        <span
          @click="navigateFromFooter('/ansprechpartner')">Ansprechpartner</span>
        <span @click="goToPublicWebsite()">Zu unserer Website</span>
      </div>
      <div class="profile">
        <span @click="navigateFromFooter('/profil')" style="text-align: start"
          v-if="isUserAuthenticated">Meine Daten / Bestellungen</span>
      </div>
    </div>
    <div class="bottomLine">
      <span>MADE BY</span>
      <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 5000 2000" style="enable-background: new 0 0 5000 2000"
        xml:space="preserve" @click="redirectToBerger()">
        <g>
          <path :fill="'currentColor'" class="st0"
            d="M2094.6,1033.9c-21.3-5.7-43.3,6.9-49,28.3c-6.6,24.5-17.7,47-33.2,66.8c-14.9,19.2-33.2,35.1-54.4,47.3
		c-21.2,12.2-44.1,20.1-68.1,23.5c-24.9,3.5-49.9,1.9-74.5-4.7c-24.5-6.6-47-17.7-66.8-33.2c-19.2-14.9-35.1-33.2-47.3-54.4
		c-12.2-21.2-20.1-44.1-23.5-68.1c-3.5-24.9-1.9-49.9,4.7-74.5c6.6-24.5,17.7-47,33.2-66.8c14.9-19.2,33.2-35.1,54.4-47.3
		s44.1-20.1,68.1-23.5c24.9-3.5,49.9-1.9,74.5,4.7c24.5,6.6,47,17.7,66.8,33.2c8.5,6.7,16.4,14,23.6,21.9l-158.7,91.8
		c-19.1,11.1-25.7,35.5-14.6,54.7c11.1,19.1,35.5,25.7,54.7,14.6l197-114c19.1-11.1,25.6-35.5,14.6-54.6
		c-17.4-30.1-40.1-56.2-67.4-77.5c-28.3-22.1-60.4-38-95.3-47.4c-34.9-9.4-70.7-11.6-106.2-6.6c-34.3,4.8-67,16-97.1,33.4
		c-30.1,17.4-56.2,40.1-77.5,67.4c-22.1,28.3-38,60.4-47.4,95.3c-9.4,34.9-11.6,70.7-6.6,106.2c4.8,34.3,16,67,33.4,97.1
		c17.4,30.1,40.1,56.2,67.4,77.5c28.3,22.1,60.4,38,95.3,47.4c22.9,6.1,46.1,9.2,69.4,9.2c12.3,0,24.6-0.9,36.8-2.6
		c34.3-4.8,67-16,97.1-33.4c30.1-17.4,56.2-40.1,77.5-67.4c22.1-28.3,38-60.4,47.4-95.3C2128.6,1061.5,2116,1039.6,2094.6,1033.9z" />
          <path :fill="'currentColor'" class="st0"
            d="M3558.6,1033.9c-21.3-5.7-43.3,6.9-49,28.3c-6.6,24.5-17.7,47-33.2,66.8c-14.9,19.2-33.2,35.1-54.4,47.3
		c-21.2,12.2-44.1,20.1-68.1,23.5c-24.9,3.5-49.9,1.9-74.5-4.7c-24.5-6.6-47-17.7-66.8-33.2c-19.2-14.9-35.1-33.2-47.3-54.4
		c-12.2-21.2-20.1-44.1-23.5-68.1c-3.5-24.9-1.9-49.9,4.7-74.5c6.6-24.5,17.7-47,33.2-66.8c14.9-19.2,33.2-35.1,54.4-47.3
		c21.2-12.2,44.1-20.1,68.1-23.5c24.9-3.5,49.9-1.9,74.5,4.7c24.5,6.6,47,17.7,66.8,33.2c8.5,6.7,16.4,14,23.6,21.9l-158.7,91.8
		c-19.1,11.1-25.7,35.5-14.6,54.7c11.1,19.1,35.5,25.7,54.7,14.6l197-114c19.1-11.1,25.6-35.5,14.6-54.6
		c-17.4-30.1-40.1-56.2-67.4-77.5c-28.3-22.1-60.4-38-95.3-47.4c-34.9-9.4-70.7-11.6-106.2-6.6c-34.3,4.8-67,16-97.1,33.4
		c-30.1,17.4-56.2,40.1-77.5,67.4c-22.1,28.3-38,60.4-47.4,95.3c-9.4,34.9-11.6,70.7-6.6,106.2c4.8,34.3,16,67,33.4,97.1
		c17.4,30.1,40.1,56.2,67.4,77.5c28.3,22.1,60.4,38,95.3,47.4c22.9,6.1,46.1,9.2,69.4,9.2c12.3,0,24.6-0.9,36.8-2.6
		c34.3-4.8,67-16,97.1-33.4c30.1-17.4,56.2-40.1,77.5-67.4c22.1-28.3,38-60.4,47.4-95.3C3592.6,1061.5,3580,1039.6,3558.6,1033.9z" />
          <path :fill="'currentColor'" class="st0"
            d="M1357,765.7c0.5-0.5,1.1-1.1,1.6-1.6c4.9-4.9,9.6-10.1,14.1-15.5c4.4-5.4,8.6-11,12.5-16.8
		c3.9-5.8,7.6-11.8,10.9-18c3.4-6.2,6.5-12.6,9.2-19.1c2.8-6.5,5.2-13.3,7.3-20.1c2.1-6.9,3.9-13.9,5.4-20.9
		c1.5-7.1,2.6-14.4,3.3-21.7c0.7-7.3,1.1-14.8,1.1-22.2s-0.4-14.9-1.1-22.2c-0.7-7.2-1.8-14.5-3.3-21.6c-1.4-7-3.2-14.1-5.4-20.9
		c-2.1-6.8-4.6-13.5-7.3-20.1c-2.7-6.5-5.8-12.9-9.2-19.1c-3.3-6.1-7-12.2-10.9-18c-3.9-5.8-8.1-11.4-12.5-16.8
		c-4.4-5.4-9.1-10.6-14.1-15.5c-4.9-4.9-10.1-9.6-15.5-14.1c-5.4-4.4-11-8.6-16.8-12.5c-5.8-3.9-11.8-7.6-18-10.9
		c-6.2-3.4-12.6-6.5-19.1-9.2c-6.5-2.8-13.3-5.2-20.1-7.3c-6.9-2.1-13.9-3.9-20.9-5.4c-7.1-1.5-14.4-2.6-21.7-3.3
		c-14.7-1.5-29.8-1.5-44.5,0c-7.2,0.7-14.5,1.8-21.6,3.3c-7,1.4-14.1,3.2-20.9,5.4c-6.8,2.1-13.5,4.6-20.1,7.3
		c-6.5,2.7-12.9,5.8-19.1,9.2c-6.1,3.3-12.2,7-18,10.9c-5.8,3.9-11.4,8.1-16.8,12.5c-5.4,4.4-10.6,9.1-15.5,14.1
		c-4.9,4.9-9.6,10.1-14.1,15.5c-4.4,5.4-8.6,11-12.5,16.8c-3.9,5.8-7.6,11.8-10.9,18c-3.4,6.2-6.5,12.6-9.2,19.1
		c-2.8,6.5-5.2,13.3-7.3,20.1c-2.1,6.9-3.9,13.9-5.4,20.9c-1.5,7.1-2.6,14.4-3.3,21.7c-0.7,7.3-1.1,14.8-1.1,22.2v415.9h0.3
		c3,66.9,30.5,129.4,78.2,177.1c50.6,50.6,117.9,78.5,189.5,78.5s138.9-27.9,189.5-78.5c50.6-50.6,78.5-117.9,78.5-189.5
		c0-71.6-27.9-138.9-78.5-189.5C1418.7,798.7,1389.2,779,1357,765.7z M1067.2,595.8c0.5-4.6,1.2-9.2,2.1-13.7
		c0.9-4.4,2-8.9,3.4-13.2c1.3-4.3,2.9-8.5,4.6-12.7c1.7-4.1,3.7-8.1,5.8-12c2.1-3.9,4.4-7.7,6.9-11.4c2.5-3.7,5.1-7.2,8-10.6
		c2.8-3.4,5.8-6.7,8.9-9.8c3.1-3.1,6.4-6.1,9.8-8.9c3.4-2.8,7-5.5,10.6-8c3.7-2.5,7.5-4.8,11.4-6.9c3.9-2.1,8-4.1,12.1-5.8
		c4.1-1.7,8.4-3.3,12.6-4.6c4.3-1.3,8.8-2.5,13.2-3.4c4.5-0.9,9.1-1.6,13.7-2.1c9.3-0.9,19-0.9,28.3,0c4.6,0.5,9.2,1.2,13.7,2.1
		c4.4,0.9,8.9,2,13.2,3.4c4.3,1.3,8.5,2.9,12.7,4.6c4.1,1.7,8.1,3.7,12,5.8c3.9,2.1,7.7,4.4,11.4,6.9c3.7,2.5,7.2,5.1,10.6,8
		c3.4,2.8,6.7,5.8,9.8,8.9c3.1,3.1,6.1,6.4,8.9,9.8c2.8,3.4,5.5,7,8,10.6c2.5,3.7,4.8,7.5,6.9,11.4c2.1,3.9,4.1,8,5.8,12.1
		c1.7,4.1,3.3,8.4,4.6,12.6c1.3,4.3,2.5,8.8,3.4,13.2c0.9,4.5,1.6,9.1,2.1,13.7c0.5,4.7,0.7,9.4,0.7,14.2s-0.2,9.5-0.7,14.2
		c-0.5,4.6-1.2,9.2-2.1,13.7c-0.9,4.4-2,8.9-3.4,13.2c-1.3,4.3-2.9,8.5-4.6,12.7c-1.7,4.1-3.7,8.1-5.8,12c-2.1,3.9-4.4,7.7-6.9,11.4
		c-2.5,3.7-5.1,7.2-8,10.6c-2.8,3.4-5.8,6.7-8.9,9.8c-3.1,3.1-6.4,6.1-9.8,8.9c-3.4,2.8-7,5.5-10.6,8c-3.7,2.5-7.5,4.8-11.4,6.9
		c-3.9,2.1-8,4.1-12.1,5.8c-4.1,1.7-8.4,3.3-12.6,4.6c-4.3,1.3-8.8,2.5-13.2,3.4c-4.5,0.9-9.1,1.6-13.7,2.1l0.1,0.6
		c-57.3,7.6-110.4,33.4-152.2,74.7V610C1066.5,605.3,1066.7,600.5,1067.2,595.8z M1254.5,1201.5c-103.7,0-188-84.3-188-188
		c0-103.7,84.3-188,188-188s188,84.3,188,188C1442.5,1117.2,1358.2,1201.5,1254.5,1201.5z" />
          <path :fill="'currentColor'" class="st0" d="M2960.6,1181.1c-17-17-36-31.5-56.6-43.3c7.4-5.7,14.5-11.9,21.2-18.6c20-20,35.7-43.3,46.7-69.3
		c11.4-26.9,17.1-55.5,17.1-84.9s-5.8-58-17.1-84.9c-11-26-26.7-49.3-46.7-69.3c-6.9-6.9-14.3-13.3-22-19.2
		c20.9-11.8,40.1-26.5,57.4-43.8c15.6-15.6,15.6-40.9,0-56.6s-40.9-15.6-56.6,0c-18,18-38.9,31.9-62.2,41.3
		c-22.5,9.1-46.3,13.8-70.8,13.8c-2.7,0-5.4,0.3-7.9,0.8c-26.7,0.9-52.5,6.6-77,17c-26,11-49.3,26.7-69.3,46.7
		c-20,20-35.7,43.3-46.7,69.3c-11.4,26.9-17.1,55.5-17.1,84.9s5.8,58,17.1,84.9c11,26,26.7,49.3,46.7,69.3
		c20,20,43.3,35.7,69.3,46.7c26.9,11.4,55.5,17.1,84.9,17.1c3,0,6-0.1,9-0.2c22.2,1,43.8,5.9,64.2,14.5c22.4,9.5,42.5,23,59.8,40.3
		s30.8,37.4,40.3,59.8c9.8,23.2,14.8,47.8,14.8,73.2s-5,50-14.8,73.2c-9.5,22.4-23,42.5-40.3,59.8s-37.4,30.8-59.8,40.3
		c-23.2,9.8-47.8,14.8-73.2,14.8c-25.4,0-50-5-73.2-14.8c-22.4-9.5-42.5-23-59.8-40.3s-30.8-37.4-40.3-59.8
		c-9.8-23.2-14.8-47.8-14.8-73.2c0-25.4,5-50,14.8-73.2c8.6-20.3-0.9-43.8-21.3-52.4c-20.3-8.6-43.8,0.9-52.4,21.3
		c-14,33.1-21.1,68.2-21.1,104.3s7.1,71.3,21.1,104.3c13.5,31.9,32.8,60.6,57.4,85.2s53.3,43.9,85.2,57.4
		c33.1,14,68.2,21.1,104.3,21.1s71.3-7.1,104.3-21.1c31.9-13.5,60.6-32.8,85.2-57.4c24.6-24.6,43.9-53.3,57.4-85.2
		c14-33.1,21.1-68.2,21.1-104.3c0-36.2-7.1-71.3-21.1-104.3C3004.5,1234.3,2985.2,1205.7,2960.6,1181.1z M2673.4,1062.6
		c-12.7-12.7-22.6-27.5-29.6-43.9c-7.2-17-10.8-35.1-10.8-53.7s3.6-36.7,10.8-53.7c6.9-16.4,16.9-31.2,29.6-43.9
		c12.7-12.7,27.5-22.6,43.9-29.6c17-7.2,35.1-10.8,53.7-10.8s36.7,3.6,53.7,10.8c16.4,6.9,31.2,16.9,43.9,29.6
		c12.7,12.7,22.6,27.5,29.6,43.9c7.2,17,10.8,35.1,10.8,53.7s-3.6,36.7-10.8,53.7c-6.9,16.4-16.9,31.2-29.6,43.9
		c-12.7,12.7-27.5,22.6-43.9,29.6c-14.4,6.1-29.5,9.6-45.2,10.6c-2.8-0.1-5.6-0.1-8.4-0.1c-1.7,0-3.3,0.1-5,0.3
		c-16.9-0.6-33.3-4.2-48.8-10.7C2700.9,1085.2,2686.1,1075.3,2673.4,1062.6z" />
          <path :fill="'currentColor'" class="st0"
            d="M2466.5,745.6c-71.6,0-138.9,27.9-189.5,78.5c-50.6,50.6-78.5,117.9-78.5,189.5v227.9c0,22.1,17.9,40,40,40
		s40-17.9,40-40v-227.9c0-103.7,84.3-188,188-188c22.1,0,40-17.9,40-40S2488.6,745.6,2466.5,745.6z" />
          <path :fill="'currentColor'" class="st0"
            d="M3930.5,745.6c-71.6,0-138.9,27.9-189.5,78.5c-50.6,50.6-78.5,117.9-78.5,189.5v227.9c0,22.1,17.9,40,40,40
		s40-17.9,40-40v-227.9c0-103.7,84.3-188,188-188c22.1,0,40-17.9,40-40S3952.6,745.6,3930.5,745.6z" />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import AssetProvider from "@/utility/AssetProvider";
import UserTypeProvider from "./utility/UserTypeProvider";
import UserTypes from "./models/Types/UserTypes";
export default {
  inject: ["$firebase"],
  data() {
    return {
      agbDownloadUrl: "",
    };
  },
  mounted() {
    this.fetchAGBDownloadUrl();
  },
  computed: {
    userLogo() {
      return AssetProvider.getLogoForUserType();
    },
    isUserAuthenticated() {
      return localStorage.getItem("ibisOnlinebestellungUserObject") !== null;
    },
    isRetailer() {
      return UserTypeProvider.getUserType() === UserTypes.RETAILER;
    },
  },
  methods: {
    navigateFromFooter(path) {
      this.$router.push(path);
    },
    redirectToBerger() {
      window.open("https://berger-studios.de/", "_blank");
    },
    goToPublicWebsite() {
      window.open("https://www.ibis-rahmen.de/", "_blank");
    },
    async fetchAGBDownloadUrl() {
      try {
        const storage = this.$firebase.storage(); // Get the Firebase storage
        const storageRef = storage.ref(
          "Public/Zu guter Letzt - Bestellhinweise, AGB, Impressum.pdf"
        ); // Adjust the path to your AGB file
        this.agbDownloadUrl = await storageRef.getDownloadURL();
      } catch (error) {
        console.error("Error fetching AGB download URL:", error);
      }
    },
    openAGBs() {
      if (this.agbDownloadUrl) {
        window.open(this.agbDownloadUrl, "_blank");
      }
    },
    showToastMessage(toastMessage, type, inputId) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
      if (type === this.toastTypes.ERROR && inputId) {
        document.getElementById(inputId).classList.add("invalid");
        setTimeout(() => {
          document.getElementById(inputId).classList.remove("invalid");
        }, 3000);
      }
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 5000);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./assets/sass/style.scss";

.footer {
  width: 100vw;
  position: relative;
  overflow: hidden;
  background-color: $ibis-grey; // Set the footer background color here
}

.curveHolder {
  width: 100%;
  height: 50px;
  background-color: $ibis-grey;
  position: relative;

  .curve {
    position: absolute;
    background: white;
    width: 100%;
    height: 50px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }
}

.topLine {
  img {
    width: 5rem;
    margin-top: 2rem;
  }
}

.footer-content {
  height: 10rem; // Adjust the height here to make sure the total height including the SVG remains 10rem
  background-color: transparent;
  padding-left: 8rem;
  padding-top: 3rem;
  padding-right: 8rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: $mobile) {
    padding-left: 2rem;
    padding-right: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  @media (min-width: $mobile) and (max-width: $tablet) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .legal,
  .configurator,
  .profile,
  .contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;

    @media (max-width: $mobile) {
      gap: 1rem;
    }
  }

  span {
    cursor: pointer;
    font-weight: bold;
    transition: 0.3s;
    text-align: left;

    &:hover {
      color: $ibis-red;
    }
  }
}

.bottomLine {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 10px;

  svg {
    width: 5rem;
    cursor: pointer;
    transition: 0.15s;

    &:hover {
      fill: $ibis-red;
      color: $ibis-red;
    }
  }
}
</style>
