<template>
  <label
    class="checkboxContainer"
    v-if="
      currentProduct.familyType !== familyTypes.PASSEPARTOUT &&
      currentProduct.familyType !== familyTypes.GLAS &&
      currentProduct.familyType !== familyTypes.STRETCHER_FRAME &&
      currentProduct.productType === productTypes.CUT &&
      !currentProduct.is_aluminium &&
      !currentProduct.is_back_wall
    "
    style="margin-top: 2em; margin-bottom: 1em"
    >Gerader Schnitt
    <input
      type="checkbox"
      v-model="currentProduct.is_straight_cut"
      @input="syncConfiguration()"
    />
    <span class="checkmark"></span>
  </label>
  <label
    class="checkboxContainer"
    v-if="
      currentProduct.familyType === familyTypes.STRETCHER_FRAME &&
      currentProduct.selected_denomination_type !== denominations.LEGS &&
      (currentProduct.width >= 80 || currentProduct.height >= 80)
    "
    style="margin-bottom: 1rem; margin-top: 2rem"
    >Mit Steg
    <input
      id="withBridgeCheckbox"
      type="checkbox"
      v-model="currentProduct.sf_with_bridge"
      @input="onWithBridgeInput()"
    />
    <span class="checkmark"></span>
  </label>
  <label
    class="checkboxContainer"
    v-if="
      currentProduct.familyType === familyTypes.STRETCHER_FRAME &&
      currentProduct.selected_denomination_type !== denominations.LEGS &&
      currentProduct.width >= 80 &&
      currentProduct.height >= 80
    "
    >Mit Kreuz
    <input
      id="withCrossCheckbox"
      type="checkbox"
      v-model="currentProduct.sf_with_cross"
      @input="onWithCrossInput()"
    />
    <span class="checkmark"></span>
  </label>
</template>
<script>
import FamilyTypes from "@/models/Types/FamilyTypes";
import Denominations from "@/models/Types/Denominations";
import ProductTypes from "@/models/Types/ProductTypes";

export default {
  props: [
    "productProp",
    "syncConfiguration",
    "onWithBridgeInput",
    "onWithCrossInput",
  ],
  data() {
    return {
      familyTypes: FamilyTypes,
      denominations: Denominations,
      productTypes: ProductTypes,
    };
  },
  computed: {
    currentProduct() {
      return !this.productProp
        ? this.$store.getters.currentProduct
        : this.productProp;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../../assets/sass/style.scss";

.checkboxContainer {
  z-index: 1;
  display: flex;
  position: relative;
  padding-left: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background-color: $ibis-grey-middle;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked ~ .checkmark {
    background-color: $ibis-red;
    transition: 0.15s;
    &:after {
      display: block;
    }
  }

  .checkmark:after {
    left: 6.5px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
</style>
