const FamilyTypes = require("@/models/Types/FamilyTypes.js");
const ProductTypes = require("@/models/Types/ProductTypes");
const PoleProduct = require("@/models/PoleProduct");
const FrameProduct = require("@/models/FrameProduct");
const CutProduct = require("@/models/CutProduct");
const NonConfigurableProduct = require("@/models/NonConfigurableProduct");
const axios = require("axios");
import ProductOptionsToSort from "@/models/Types/ProductOptionsToSort";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import UserTypeProvider from "./UserTypeProvider";
import UserTypes from "@/models/Types/UserTypes";

class ProductFactory {
  static async fromJson(data) {
    const price = this.getPrice(data);
    const discount = UserTypeProvider.getUserType() === UserTypes.RETAILER ? parseFloat(data.discount) : 0;
    if (this.getProductType(data) === ProductTypes.POLE) {
      return new PoleProduct(
        data.name,
        data.article_id,
        data.search_params,
        data.workshop_naming,
        data.parent_id,
        data.product_uid,
        this.getFamilyType(data),
        data.category,
        data.sub_category,
        parseFloat(data.selling_unit),
        parseFloat(data.min_quantity_surcharge),
        data.only_full_selling_units,
        price,
        parseFloat(data.quantity),
        data.price_unit,
        data.custom_pole_length,
        discount,
        data.notes,
        data.calculated_selling_unit,
        data.unavailable_regions,
        data.isRemainingStock,
        data.remainingStockDiscount,
        data.productImageUrls,
        data.remainingStockQuantity,
        data.discontinued
      );
    } else if (this.getProductType(data) === ProductTypes.FRAME || data.sub_category === ProductOptionsToSort.CORNER_FRAME) {
      return new FrameProduct(
        data.name,
        data.article_id,
        data.search_params,
        data.workshop_naming,
        data.parent_id,
        data.product_uid,
        this.getFamilyType(data),
        data.category,
        data.sub_category,
        parseFloat(data.selling_unit),
        parseFloat(data.min_quantity_surcharge),
        data.only_full_selling_units,
        price,
        parseFloat(data.quantity),
        data.price_unit,
        discount,
        data.notes,
        parseFloat(data.min_size ? data.min_size.split("x")[0] : undefined), // min_width
        parseFloat(data.min_size ? data.min_size.split("x")[1] : undefined), // min_height
        parseFloat(data.max_size ? data.max_size.split("x")[0] : undefined), // max_width
        parseFloat(data.max_size ? data.max_size.split("x")[1] : undefined), // max_height
        data.width, //for db sync
        data.height, //for db sync
        data.selected_measurement_type,
        data.unavailable_regions,
        data.isRemainingStock,
        data.remainingStockDiscount,
        data.productImageUrls,
        data.remainingStockQuantity,
        data.discontinued,
        data.is_own_frame,
        data.no_hangers,
      );
    } else if (this.getProductType(data) === ProductTypes.CUT) {
      return new CutProduct(
        data.name,
        data.article_id,
        data.search_params,
        data.workshop_naming,
        data.parent_id,
        data.product_uid,
        this.getFamilyType(data),
        data.category,
        data.sub_category,
        parseFloat(data.selling_unit),
        parseFloat(data.min_quantity_surcharge),
        data.only_full_selling_units,
        price,
        parseFloat(data.quantity),
        data.price_unit,
        discount,
        data.notes,
        parseFloat(data.min_size ? data.min_size.split("x")[0] : undefined), // min_width
        parseFloat(data.min_size ? data.min_size.split("x")[1] : undefined), // min_height
        parseFloat(data.max_size ? data.max_size.split("x")[0] : undefined), // max_width
        parseFloat(data.max_size ? data.max_size.split("x")[1] : undefined), // max_height
        data.is_back_wall,
        data.is_aluminium,
        data.is_wood,
        data.with_poly,
        data.mounting_set, // only for alu cuts
        data.sf_decimal_size_price, //only stretcher frames
        data.sf_with_bridge,
        data.sf_with_cross,
        data.qm_price,
        data.max_price,
        data.width, //for db sync
        data.height, //for db sync
        data.selected_measurement_type,
        data.selected_denomination_type,
        data.is_straight_cut,
        data.passepartout_inner_width,
        data.passepartout_inner_height,
        data.passepartout_inner_cut_type,
        data.passepartout_outer_cut_form,
        data.passepartout_inner_cut_form,
        data.passepartout_size_calculation_mode,
        data.passepartout_top_leg_width,
        data.passepartout_left_leg_width,
        data.passepartout_right_leg_width,
        data.passepartout_bottom_leg_width,
        data.passepartout_no_cut_out,
        data.passepartout_number_of_cut_outs,
        data.passepartout_number_of_cut_outs_notes,
        data.second_glas_picture_width,
        data.second_glas_picture_height,
        data.second_glas_top_leg_width,
        data.second_glas_left_leg_width,
        data.second_glas_right_leg_width,
        data.second_glas_bottom_leg_width,
        data.unavailable_regions,
        data.isRemainingStock,
        data.remainingStockDiscount,
        data.productImageUrls,
        data.remainingStockQuantity,
        data.discontinued
      );
    } else if (this.getProductType(data) === ProductTypes.GLAS_BOARD && data.glas_group) {
      const idToken = await firebase.auth().currentUser.getIdToken();
      const response = await axios.post(
        process.env.VUE_APP_BACKEND_BASE_URL + "/getGlasSizeOptions",
        { group: data.glas_group },
        {
          headers: {
            authorization: `Bearer ${idToken}`
          }
        }
      );
      return new NonConfigurableProduct(
        data.name, //wanted to group glas products into one head name
        data.article_id,
        data.search_params,
        data.workshop_naming,
        data.parent_id,
        data.product_uid,
        this.getFamilyType(data),
        data.category,
        data.sub_category,
        parseFloat(data.selling_unit),
        parseFloat(data.min_quantity_surcharge),
        data.only_full_selling_units,
        price,
        parseFloat(data.quantity),
        data.price_unit,
        discount,
        data.notes,
        this.getProductType(data),
        response.data,
        data.glas_group,
        undefined,
        data.isRemainingStock,
        data.remainingStockDiscount,
        data.productImageUrls,
        data.remainingStockQuantity,
        data.discontinued
      );
    } else {
      return new NonConfigurableProduct(
        data.name,
        data.article_id,
        data.search_params,
        data.workshop_naming,
        data.parent_id,
        data.product_uid,
        this.getFamilyType(data),
        data.category,
        data.sub_category,
        parseFloat(data.selling_unit),
        parseFloat(data.min_quantity_surcharge),
        data.only_full_selling_units,
        price,
        parseFloat(data.quantity),
        data.price_unit,
        discount,
        data.notes,
        undefined,
        undefined,
        undefined,
        data.unavailable_regions,
        data.isRemainingStock,
        data.remainingStockDiscount,
        data.productImageUrls,
        data.remainingStockQuantity,
        data.discontinued
      );
    }
  }

  static buildGlasSizeOption(data) {
    const price = this.getPrice(data);
    const discount = UserTypeProvider.getUserType() === UserTypes.RETAILER ? parseFloat(data.discount) : 0;
    return new NonConfigurableProduct(
      data.name, //wanted to group glas products into one head name
      data.article_id,
      data.search_params,
      data.workshop_naming,
      data.parent_id,
      data.product_uid,
      this.getFamilyType(data),
      data.category,
      data.sub_category,
      parseFloat(data.selling_unit),
      parseFloat(data.min_quantity_surcharge),
      data.only_full_selling_units,
      price,
      parseFloat(data.quantity),
      data.price_unit,
      discount,
      data.notes,
      this.getProductType(data),
      undefined,
      data.glas_group,
      data.unavailable_regions,
      data.isRemainingStock,
      data.remainingStockDiscount,
      data.productImageUrls,
      data.remainingStockQuantity,
      data.discontinued
    );
  }

  static getPrice(data) {
    const userType = UserTypeProvider.getUserType();
    if (userType === UserTypes.BAUHAUS && !isNaN(parseFloat(data.price_bauhaus))) {
      return parseFloat(data.price_bauhaus);
    } else if (userType === UserTypes.XXXL_GROUP && !isNaN(parseFloat(data.price_xxxl_group))) {
      return parseFloat(data.price_xxxl_group);
    } else if (userType === UserTypes.RETAILER || (isNaN(parseFloat(data.price_bauhaus)) && isNaN(parseFloat(data.price_xxxl_group)))) {
      return data.price;
    }
  }

  static getFamilyType(data) {
    const type = Object.values(FamilyTypes).find((type) =>
      data.sub_category?.includes(type)
    );
    return type ? type : FamilyTypes.FRAME;
  }

  static getProductType(data) {
    const type = Object.values(ProductTypes).find((type) =>
      data.sub_category?.includes(type)
    );
    return type ? type : undefined;
  }
}

export default ProductFactory;
