const Measurements = require("./Types/Measurements");
const ProductTypes = require("./Types/ProductTypes");
const Denominations = require("./Types/Denominations");
const PasspartoutCutTypes = require("./Types/PassepartoutCutTypes");
const PassepartoutCutForms = require("./Types/PassepartoutCutForms");
const FamilyTypes = require("./Types/FamilyTypes");
const PPSizeCalculationModes = require("./Types/PPSizeCalculationModes");
const SingleProduct = require("./SingleProduct");

class CutProduct extends SingleProduct {
  /* Core attributes */
  name;
  article_id;
  description;
  workshop_naming;
  product_id;
  webshop_id;
  familyType;
  category;
  sub_category;
  selling_unit;
  min_quantity_surcharge;
  only_full_units;
  price;
  quantity = 1;
  discount = 0;

  notes;

  /* default CUT properties */

  min_width;
  min_height;
  max_width;
  max_height;

  is_back_wall;
  is_aluminium;
  is_wood;

  with_poly = false;
  mounting_set;

  sf_decimal_size_price;
  sf_with_bridge = false;
  sf_with_cross = false;

  qm_price;
  max_price;

  /* defaultWidth;
  defaultHeight; */

  width;
  height;

  selected_measurement_type = Measurements.FALZMASS;
  selected_denomination_type = Denominations.FRAMES;
  // number_of_denominations = 1;
  is_straight_cut = false;

  productType = ProductTypes.CUT;

  /* PP properties */

  passepartout_inner_width;
  passepartout_inner_height;

  passepartout_inner_cut_type = PasspartoutCutTypes.DIAGONAL;
  passepartout_outer_cut_form = PassepartoutCutForms.BOX;
  passepartout_inner_cut_form = PassepartoutCutForms.BOX;

  passepartout_size_calculation_mode = PPSizeCalculationModes.LEGS;

  passepartout_top_leg_width;
  passepartout_left_leg_width;
  passepartout_right_leg_width;
  passepartout_bottom_leg_width;

  passepartout_no_cut_out = false;

  isRemainingStock;
  remainingStockDiscount;
  productImageUrls;
  remainingStockQuantity;

  discontinued = false;
  passepartout_number_of_cut_outs = 1;
  passepartout_number_of_cut_outs_notes = ""

  second_glas_picture_width;
  second_glas_picture_height;

  second_glas_top_leg_width;
  second_glas_left_leg_width;
  second_glas_right_leg_width;
  second_glas_bottom_leg_width;

  constructor(
    name,
    article_id,
    description,
    workshop_naming,
    product_id,
    webshop_id,
    familyType,
    category,
    sub_category,
    selling_unit,
    min_quantity_surcharge,
    only_full_units,
    price,
    quantity,
    price_unit,
    discount,
    notes,

    min_height,
    min_width,
    max_height,
    max_width,
    is_back_wall,
    is_aluminium,
    is_wood,
    with_poly,
    mounting_set,
    sf_decimal_size_price,

    sf_with_bridge,
    sf_with_cross,

    qm_price,
    max_price,

    width,
    height,

    selected_measurement_type,
    selected_denomination_type,
    is_straight_cut,

    passepartout_inner_width,
    passepartout_inner_height,

    passepartout_inner_cut_type,
    passepartout_outer_cut_form,
    passepartout_inner_cut_form,

    passepartout_size_calculation_mode,

    passepartout_top_leg_width,
    passepartout_left_leg_width,
    passepartout_right_leg_width,
    passepartout_bottom_leg_width,
    passepartout_no_cut_out,
    passepartout_number_of_cut_outs,
    passepartout_number_of_cut_outs_notes,

    second_glas_picture_width,
    second_glas_picture_height,

    second_glas_top_leg_width,
    second_glas_left_leg_width,
    second_glas_right_leg_width,
    second_glas_bottom_leg_width,

    unavailable_regions,

    isRemainingStock,
    remainingStockDiscount,
    productImageUrls,
    remainingStockQuantity,
    discontinued,

  ) {
    super();
    this.name = name;
    this.article_id = article_id;
    this.description = description;
    this.workshop_naming = workshop_naming;
    this.product_id = product_id;
    this.webshop_id = webshop_id;
    this.familyType = familyType;
    this.category = category;
    this.sub_category = sub_category;
    this.selling_unit = selling_unit;
    this.min_quantity_surcharge = min_quantity_surcharge;
    this.only_full_units = only_full_units === "Ja" ? true : false;
    this.price = price;
    this.price_unit = price_unit;
    this.quantity = this.only_full_units
      ? selling_unit
      : quantity
        ? quantity
        : 1;
    this.discount = discount;
    this.notes = notes;

    this.min_height =
      familyType === FamilyTypes.STRETCHER_FRAME ? 20 : min_height;
    this.min_width =
      familyType === FamilyTypes.STRETCHER_FRAME ? 20 : min_width;

    this.max_height = max_height
      ? max_height
      : familyType === FamilyTypes.PASSEPARTOUT
        ? 150
        : 280;
    this.max_width = max_width
      ? max_width
      : familyType === FamilyTypes.PASSEPARTOUT
        ? 100
        : 280;

    this.is_back_wall = is_back_wall
      ? is_back_wall
      : this.sub_category?.includes("Rückwand");
    this.is_aluminium = is_aluminium
      ? is_aluminium
      : this.category?.includes("Alu") || this.sub_category?.includes("Alu");
    this.is_wood = is_wood
      ? is_wood
      : this.category?.includes("Holz") || this.sub_category?.includes("Holz");

    with_poly ? (this.with_poly = with_poly) : null;

    this.mounting_set = mounting_set;
    this.sf_decimal_size_price = sf_decimal_size_price;

    sf_with_bridge ? (this.sf_with_bridge = sf_with_bridge) : null;
    sf_with_cross ? (this.sf_with_cross = sf_with_cross) : null;

    this.qm_price = qm_price;
    this.max_price = max_price;

    if (!this.min_width && !this.min_height) {
      this.min_width = this.is_aluminium ? 8 : this.is_wood ? 9 : 2;
      this.min_height = this.is_aluminium ? 8 : this.is_wood ? 9 : 2;
    }

    this.defaultWidth = CutProduct.extractWidth(name);
    this.defaultHeight = CutProduct.extractHeight(name);
    /* this.width = this.defaultWidth;
    this.height = this.defaultHeight; */

    this.selected_measurement_type = selected_measurement_type
      ? selected_measurement_type
      : familyType === FamilyTypes.PASSEPARTOUT ||
        familyType === FamilyTypes.GLAS
        ? Measurements.AUSSENMASS
        : familyType === FamilyTypes.STRETCHER_FRAME
          ? Measurements.BILDMASS
          : Measurements.FALZMASS;

    selected_denomination_type
      ? (this.selected_denomination_type = selected_denomination_type)
      : null;

    is_straight_cut ? (this.is_straight_cut = is_straight_cut) : null;

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }

    passepartout_inner_width
      ? (this.passepartout_inner_width = passepartout_inner_width)
      : null;

    passepartout_inner_height
      ? (this.passepartout_inner_height = passepartout_inner_height)
      : null;

    passepartout_inner_cut_type
      ? (this.passepartout_inner_cut_type = passepartout_inner_cut_type)
      : null;

    passepartout_outer_cut_form
      ? (this.passepartout_outer_cut_form = passepartout_outer_cut_form)
      : null;

    passepartout_inner_cut_form
      ? (this.passepartout_inner_cut_form = passepartout_inner_cut_form)
      : null;

    passepartout_size_calculation_mode
      ? (this.passepartout_size_calculation_mode =
        passepartout_size_calculation_mode)
      : null;

    passepartout_top_leg_width
      ? (this.passepartout_top_leg_width = passepartout_top_leg_width)
      : null;

    passepartout_left_leg_width
      ? (this.passepartout_left_leg_width = passepartout_left_leg_width)
      : null;

    passepartout_right_leg_width
      ? (this.passepartout_right_leg_width = passepartout_right_leg_width)
      : null;

    passepartout_bottom_leg_width
      ? (this.passepartout_bottom_leg_width = passepartout_bottom_leg_width)
      : null;

    passepartout_no_cut_out
      ? (this.passepartout_no_cut_out = passepartout_no_cut_out)
      : null;

    second_glas_picture_width ? (this.second_glas_picture_width = second_glas_picture_width) : null;
    second_glas_picture_height ? (this.second_glas_picture_height = second_glas_picture_height) : null;
    second_glas_left_leg_width ? (this.second_glas_left_leg_width = second_glas_left_leg_width) : null;
    second_glas_right_leg_width ? (this.second_glas_right_leg_width = second_glas_right_leg_width) : null;
    second_glas_top_leg_width ? (this.second_glas_top_leg_width = second_glas_top_leg_width) : null;
    second_glas_bottom_leg_width ? (this.second_glas_bottom_leg_width = second_glas_bottom_leg_width) : null;


    this.unavailable_regions = unavailable_regions;

    this.isRemainingStock = isRemainingStock;
    this.remainingStockDiscount = remainingStockDiscount;
    this.productImageUrls = productImageUrls;
    this.remainingStockQuantity = remainingStockQuantity;

    this.discontinued = discontinued === "True" ? true : false;

    this.passepartout_number_of_cut_outs = passepartout_number_of_cut_outs ? passepartout_number_of_cut_outs : 1;
    this.passepartout_number_of_cut_outs_notes = passepartout_number_of_cut_outs_notes ? passepartout_number_of_cut_outs_notes : "";
  }

  static extractWidth(str) {
    const regex = /(\d+(?:[.,]\d+)?)\s*[xX]\s*(\d+(?:[.,]\d+)?)/g;
    let match;
    let lastMatch;

    while ((match = regex.exec(str)) !== null) {
      lastMatch = match;
    }

    if (lastMatch) {
      const width = parseFloat(lastMatch[1].replace(",", "."));
      return isNaN(width) ? null : width;
    } else {
      return null;
    }
  }

  static extractHeight(str) {
    const regex = /(\d+(?:[.,]\d+)?)\s*[xX]\s*(\d+(?:[.,]\d+)?)/g;
    let match;
    let lastMatch;

    while ((match = regex.exec(str)) !== null) {
      lastMatch = match;
    }

    if (lastMatch) {
      const height = parseFloat(lastMatch[2].replace(",", "."));
      return isNaN(height) ? null : height;
    } else {
      return null;
    }
  }

  setWidth(value, invokeReset) {
    value = Math.max(1, value); // Ensure value is at least 1

    let maxW =
      value <= this.height
        ? Math.min(this.max_width, this.max_height) // Choose the smaller one
        : Math.max(this.max_width, this.max_height); // Choose the larger one

    if (value > maxW) {
      if (
        invokeReset &&
        this.selected_denomination_type !== Denominations.LEGS
      ) {
        this.setHeight(this.height, false);
      }
      this.width = maxW;
    } else if (this.min_width && value < this.min_width) {
      this.width = this.min_width;
    } else {
      if (
        this.passepartout_inner_width &&
        this.passepartout_inner_width > value
      ) {
        this.passepartout_inner_width = value - 1;
      }
      if (
        this.second_glas_picture_width &&
        this.second_glas_picture_width > value
      ) {
        this.second_glas_picture_width = value - 1;
      }

      this.width = value;
    }

    if (invokeReset && this.selected_denomination_type !== Denominations.LEGS) {
      this.setHeight(this.height, false);
    }
  }

  setHeight(value, invokeReset) {
    value = Math.max(1, value); // Ensure value is at least 1

    let maxH =
      value <= this.width
        ? Math.min(this.max_height, this.max_width) // Choose the smaller one
        : Math.max(this.max_height, this.max_width); // Choose the larger one

    if (value > maxH) {
      this.height = maxH;
      if (invokeReset) this.setWidth(this.width, false);
    } else if (this.min_height && value < this.min_height) {
      this.height = this.min_height;
    } else {
      if (
        this.passepartout_inner_height &&
        this.passepartout_inner_height > value
      ) {
        this.passepartout_inner_height = value - 1;
      }
      if (
        this.second_glas_picture_height &&
        this.second_glas_picture_height > value
      ) {
        this.second_glas_picture_height = value - 1;
      }

      this.height = value;
    }

    if (invokeReset) this.setWidth(this.width, false);
  }

  setPPInnerWidth(value) {
    if (this.width) {
      value >= this.width
        ? (this.passepartout_inner_width = this.width - 1)
        : (this.passepartout_inner_width = value);
    } else {
      value >= this.max_width
        ? (this.passepartout_inner_width = this.max_width - 2)
        : (this.passepartout_inner_width = value);
    }
  }
  setPPInnerHeight(value) {
    if (this.height) {
      value >= this.height
        ? (this.passepartout_inner_height = this.height - 1)
        : (this.passepartout_inner_height = value);
    } else {
      value >= this.max_height
        ? (this.passepartout_inner_height = this.max_height - 2)
        : (this.passepartout_inner_height = value);
    }
  }

  setSecondGlasPictureWidth(value) {
    if (this.width) {
      value >= this.width
        ? (this.second_glas_picture_width = this.width - 1)
        : (this.second_glas_picture_width = value);
    } else {
      value >= this.max_width
        ? (this.second_glas_picture_width = this.max_width - 2)
        : (this.second_glas_picture_width = value);
    }
  }
  setSecondGlasPictureHeight(value) {
    if (this.height) {
      value >= this.height
        ? (this.second_glas_picture_height = this.height - 1)
        : (this.second_glas_picture_height = value);
    } else {
      value >= this.max_height
        ? (this.second_glas_picture_height = this.max_height - 2)
        : (this.second_glas_picture_height = value);
    }
  }

  /* reset() {
    this.height = undefined;
    this.width = undefined;

    this.quantity = 1;

    this.notes = undefined;

    this.selected_measurement_type = Measurements.FALZMASS;
    this.selected_denomination_type = Denominations.FRAMES;
    //this.number_of_denominations = 1;
    this.is_straight_cut = false;

    this.passepartout_inner_width = undefined;
    this.passepartout_inner_height = undefined;

    this.passepartout_inner_cut_type = PasspartoutCutTypes.DIAGONAL;
    this.passepartout_outer_cut_form = PassepartoutCutForms.BOX;
    this.passepartout_inner_cut_form = PassepartoutCutForms.BOX;

    this.passepartout_top_leg_width = undefined;
    this.passepartout_left_leg_width = undefined;
    this.passepartout_right_leg_width = undefined;
    this.passepartout_bottom_leg_width = undefined;

    this.passepartout_no_cut_out = false;
  } */
}
module.exports = CutProduct;
