const ProductTypes = {
  POLE: "Stange",
  CUT: "Zuschnitt",
  FRAME: "Rahmen",
  ARC: "Bogen",
  INTERCHANGEABLE: "Wechselrahmen",
  NON_CONFIGURABLE: "NON_CONFIGURABLE",
  GLAS_BOARD: "Tafel"
};

module.exports = ProductTypes;
