const WorkshopModes = require("./WorkshopModes");

const AdditionalServices = [
  {
    key: "Heften_Rückseitig",
    value: "Rückseitig Heften",
    price: 8.2,
    pricePerWalkingMeters: true,
    supportedModes: [WorkshopModes.CANVAS_STRETCH],
  },
  {
    key: "Ausmessung_Bild",
    value: "Bild muss ausgemessen werden",
    price: 9.9,
    pricePerWalkingMeters: false,
  }, //preis pro stk
  {
    key: "Ausbau_Bild",
    value: "Bild muss ausgebaut werden",
    price: 6.7,
    pricePerWalkingMeters: true,
  }, //preis pro lfm
  {
    key: "Beschneidung_Bild",
    value: "Bild muss beschnitten werden",
    price: 9.90,
    pricePerWalkingMeters: false,
  }, //preis pro stk
  {
    key: "Einfaerbung_Kanten",
    value: "Kanten einfärben",
    price: 11.30,
    pricePerWalkingMeters: true,
    supportedModes: [WorkshopModes.CANVAS_STRETCH, WorkshopModes.LAMINATION_AND_SURFACE_FINISHING],
  }, //preis pro lfm
  
];
module.exports = AdditionalServices;
