const otherContentFileNames = {
  "artglass_bedeutung_von_uv-schutz_kurzfassung_en":
    "artglass - Bedeutung von UV-Schutz Kurzfassung (Englisch)",
  "artglass_bedeutung_von_uv-schutz_langfassung_en":
    "artglass - Bedeutung von UV-Schutz Langfassung (Englisch)",
  "dreiglas-_und_vierglasstrategie": "©claryl - Dreiglas & Vierglasstrategie",
  fabira_colourmount_passepartouts__zertifikat:
    "Fabira Colourmount Passepartout Zertifikat",
  "flyer_alpha_fine_art_2023-06": "Flyer Alpha FineArt Crescent-IbiS Juni 2023",
  "flyer_kool_tack_2023-06": "Flyer Kool Tack Crescent-IbiS Juni 2023",
  montageanleitung_zubehoer_galerieschiene_d_a:
    "Nielsen Galerieschienen Montageanleitung",
  montageanleitungen_fuer_sicherheits_befestigungssysteme_2019:
    "Nielsen Sicherheitsbefestigungssystem Montageanleitung",
  "preisblatt_._crescent_alpha_fineart_kool_tack_2023-06_1":
    "Preisblatt Crescent Alpha FineArt & Kool Tack Juni 2023",
  "Preisblatt_._Crescent_Good_2023-07": "Preisblatt Crescent Good 2023-07",
  "preisblatt_._fabira_ibis_neuheiten_2022-09":
    "Preisblatt Fabira-IbiS Neuheiten September 2022 (September 2022)",
  "preisblatt_._fabira_ibis_neuheiten_2023-03":
    "Preisblatt Fabira-IbiS Neuheiten M‰rz 2023",
  "preisblatt_._fabira_ibis_neuheiten_mai_2022_2":
    "Preisblatt Fabira-IbiS Neuheiten Mai 2022 (Mai 2022)",
  "preisblatt_._fabira_ibis_neuheiten_oktober_2021_2":
    "Fabira Neuheiten Preisliste Oktober 2021",
  "Preisblatt_OptiumMuseumAcrylic_&_TruLifeAcrylic 2023-08":
    "Preisblatt Optium Museum Acrylic & Trulife Acrylic (August 2023)",
  stas_manuals_complete_web: "Stas Montageanleitung komplett",
  zertifikat_protection_board: "Fabira Protection Board Zertifikat",
};
module.exports = otherContentFileNames;
