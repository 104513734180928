<template>
  <div class="nonConfigurableFreeSpaceDummy">
    <div style="position: relative;">
      <CornerImage
        v-if="currentConfiguratorMode === configuratorModes.WORKSHOP_WORKS"
        :current-product="currentProduct" />
      <span>{{ currentProduct.name }}</span>
      <span
        v-if="currentProduct.familyType !== familyTypes.GLAS && !currentProduct.is_own_frame">
        {{
          currentProduct.min_quantity_surcharge &&
            currentProduct.selling_unit > 1 &&
            currentProduct.quantity < currentProduct.selling_unit ?
            currentProduct.familyType === familyTypes.STRETCHER_FRAME &&
              (!isInteger(currentProduct.height) ||
                !isInteger(currentProduct.width)) && currentProduct.height
              !== undefined && currentProduct.width !== undefined &&
              userType === userTypes.RETAILER ?
              formatPrice(roundToTwo(currentProduct.sf_decimal_size_price +
                currentProduct.min_quantity_surcharge)) :
              formatPrice(roundToTwo(currentProduct.price +
                currentProduct.min_quantity_surcharge)) :
            currentProduct.familyType === familyTypes.STRETCHER_FRAME &&
              (!isInteger(currentProduct.height) ||
                !isInteger(currentProduct.width)) && currentProduct.height
              !== undefined && currentProduct.width !== undefined &&
              userType === userTypes.RETAILER ?
              formatPrice(roundToTwo(currentProduct.sf_decimal_size_price)) :
              formatPrice(roundToTwo(currentProduct.price)) }} € {{
          currentProduct.productType === productTypes.POLE ? "pro Meter" :
            currentProduct.productType === productTypes.NON_CONFIGURABLE ||
              currentProduct.productType === productTypes.FRAME ?
              currentProduct.sub_category === productOptionsToSort.CORNER_FRAME
                ? "pro Lfm." : // <-- This line has been changed
                currentProduct.sub_category === productOptionsToSort.WOOD_FRAME
                  ? "pro Lfm. zzgl. Verleimen" : "pro Stk." : "pro Lfm." }} <span
          style="color: #f22539">
          {{
          currentProduct.isRemainingStock === "True" &&
            currentProduct.remainingStockDiscount
            ? "abzgl. " +
            currentProduct.remainingStockDiscount * 100 +
            "% Restpostenrabatt (ersetzt den Onlinerabatt)"
            : ""
        }}
      </span>

      </span>
      <span v-else-if="currentProduct.is_own_frame">
        0€
      </span>
    </div>
    <div>
      <span v-if="currentProduct.article_id">Art. {{
          currentProduct.article_id }}</span>
      <span v-if="currentProduct.selling_unit">
        VE:
        {{ currentProduct.selling_unit.toFixed(0) }}
        {{
          currentProduct.productType === productTypes.POLE
            ? currentProduct.selling_unit > 1
              ? " Stangen"
              : "Stange"
            : ""
        }}
      </span>
    </div>
  </div>
</template>

<script>
import { isInteger } from "lodash";
import FamilyTypes from "@/models/Types/FamilyTypes";
import ProductTypes from "@/models/Types/ProductTypes";
import ProductOptionsToSort from "@/models/Types/ProductOptionsToSort";
import CornerImage from "@/components/shared/CornerImage.vue";
import ConfiguratorMode from "@/models/Types/ConfiguratorMode";
import UserTypeProvider from "@/utility/UserTypeProvider";
import UserTypes from "@/models/Types/UserTypes";
export default {
  props: ["productProp"],
  components: {
    CornerImage,
  },
  data() {
    return {
      isInteger: isInteger,
      familyTypes: FamilyTypes,
      productTypes: ProductTypes,
      productOptionsToSort: ProductOptionsToSort,
      configuratorModes: ConfiguratorMode,
      userTypes: UserTypes
    };
  },
  computed: {
    configuratorMode() {
      return this.$store.getters.currentConfiguratorMode;
    },
    currentProduct() {
      return !this.productProp
        ? this.$store.getters.currentProduct
        : this.productProp;
    },
    userMultiplikator() {
      return JSON.parse(localStorage.getItem("ibisOnlinebestellungUserObject")).multiplikator;
    },
    currentConfiguratorMode() {
      return this.$store.getters.currentConfiguratorMode;
    },
    userType() {
      return UserTypeProvider.getUserType()
    }
  },
  methods: {
    roundToTwo(num) {
      return +parseFloat(Math.round(num + "e+3") + "e-3");
    },
    formatPrice(value) {
      // Convert the number to a string with two decimal places
      let priceString = (value * this.userMultiplikator).toFixed(2);

      // Swap the dot for a comma for decimal separation
      priceString = priceString.replace(".", ",");

      // Add dots as thousands separators
      return priceString.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../../assets/sass/style.scss";

.nonConfigurableFreeSpaceDummy {
  height: 12rem;

  div {
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  span {
    text-align: end;
    width: 50%;
  }
}
</style>
