const HangingTypes = require("./Types/HangingTypes");
const WorkshopModes = require("./Types/WorkshopModes");
const WorkshopStretchers = require("./Types/WorkshopStretchers");
const WorkshopWork = require("./WorkshopWork");

class WorkshopStretching extends WorkshopWork {
  mode = WorkshopModes.CANVAS_STRETCH;
  stretcher_frame;
  hangings = [];
  picture_value;
  damage;
  services = [];
  quantity = 1;
  notes = "";
  picture_has_to_be_cut_notes = "";
  edges_have_to_be_colored_notes = "";

  constructor(
    stretcher_frame,
    hangings,
    picture_value,
    damage,
    services,
    quantity,
    notes,
    picture_has_to_be_cut_notes,
    edges_have_to_be_colored_notes
  ) {
    super();
    this.stretcher_frame = stretcher_frame
      ? stretcher_frame
      : WorkshopStretchers[0];
    hangings ? (this.hangings = hangings) : this.assignDefaultHanging();
    this.picture_value = picture_value;
    this.damage = damage;
    services ? (this.services = services) : null;
    this.quantity = quantity ? quantity : 1;
    this.notes = notes;
    this.picture_has_to_be_cut_notes = picture_has_to_be_cut_notes
      ? picture_has_to_be_cut_notes
      : "";
    this.edges_have_to_be_colored_notes = edges_have_to_be_colored_notes ? edges_have_to_be_colored_notes : "";
  }
  assignDefaultHanging() {
    HangingTypes.forEach((e) => {
      if (e.key === "DEFAULT") {
        this.hangings.push(e);
      }
    });
  }
}
module.exports = WorkshopStretching;
