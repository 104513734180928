<template>
  <div class="amountInputWrapper">
    <div class="amountWrapper">
      <span v-if="currentProduct.productType === productTypes.GLAS_BOARD"
        >Anzahl Tafeln:</span
      >
      <span v-else>Anzahl:</span>
      <input
        type="number"
        id="quantityInput"
        v-model="currentProduct.quantity"
        @blur="doubleInputValidation()"
      />
    </div>
  </div>
  <div
    v-if="currentProduct.isRemainingStock === 'True'"
    style="
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
    "
  >
    {{
      "(" +
      currentProduct.remainingStockQuantity +
      (this.currentProduct.productType === this.productTypes.POLE
        ? " Meter"
        : this.currentProduct.productType ===
            this.productTypes.NON_CONFIGURABLE ||
          this.currentProduct.productType === this.productTypes.FRAME
        ? this.currentProduct.sub_category ===
          this.productOptionsToSort.WOOD_FRAME || this.currentProduct.sub_category === this.productOptionsToSort.CORNER_FRAME
          ? " Lfm."
          : " Stk."
        : " Lfm.") +
      " verfügbar)"
    }}
  </div>
</template>
<script>
import ProductTypes from "@/models/Types/ProductTypes";
import ProductOptionsToSort from "@/models/Types/ProductOptionsToSort";
export default {
  props: ["doubleInputValidation", "isWorkshopWorkQuantity"],
  data() {
    return {
      productTypes: ProductTypes,
      productOptionsToSort: ProductOptionsToSort,
    };
  },
  computed: {
    currentProduct() {
      return this.isWorkshopWorkQuantity
        ? this.$store.getters.currentWorkshopWork
        : this.$store.getters.currentProduct;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../../assets/sass/style.scss";

.amountInputWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1em;

  .amountWrapper {
    display: flex;
    gap: 0.5em;
    align-items: center;
    justify-content: flex-end;
    input {
      padding: 12px 10px;
      box-shadow: none;
      border: 2px solid $ibis-red;
      border-radius: 50px;
      color: $ibis-black !important;
      font-size: $font-size-big;
      max-width: 4vw;
      @media (max-width: $tablet) {
        max-width: 10vw;
      }
      text-align: center;
    }
    input:focus {
      outline: none;
      border-color: $ibis-red !important;
    }
  }
}
</style>
