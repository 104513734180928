<template>
  <div class="backwallInputWrapper">
    <div class="mainHeadlineWrapper">
      <h2 class="headline">Rückwand</h2>
      <div class="infoIconWrapper">
        <ToolTip tipText="<strong>Protection Board 2,4mm weiß:</strong>
          gefertigt aus hochwertigster, gebleichter und ligninfreier
          Alpha-Zellulose, was für lebenslangen Säureschutz und höchste
          Alterungsbeständigkeit steht und vor äußeren Einflüssen durch
          Schadstoffe schützt.
          <br>
          <br>
          <strong>CorriCor mrw 2,6mm:</strong> beidsetig
          säurefreie und alterungsbeständige Museumsrückwand mit Doppelwelle aus
          100% Alphazellulose.
          <br>
          <br>

          <strong>MDF 3mm:</strong> nicht säurefreie 3mm starke
          Holzfaser-Standardrückwand.
          <br>
          <br>

          <strong>Foamboards:</strong> Kern aus geschäumtem Polystyrol, der für
          hohe Steifigkeit sorgt und gleichzeitig wenig wiegt. FCKW-frei und von
          beiden Seiten druckfest und eben.
          <br>
          <br>

          <strong>Archival Foamboards:</strong> Leichtschaumplatte mit Kern aus
          geschäumtem Polystyrol. Qualität nach höchstem Konservierungsstandard.

          <br>
          <br>


          <strong>KapaMount:</strong> beidseitig weiße Leichtschaumplatte mit
          einer Einlage aus hellgrauem Polyurethan-Hartschaum.
          Überdurchschnittlich steif und sehr formstabil."></ToolTip>
      </div>
    </div>
    <IbisSpinner v-if="isLoading" :updatePrice="updatePrice"></IbisSpinner>
    <!--  <StretchingTypeInput
      v-if="currentWorkshopMode === workshopModes.LAMINATION_FRAMING">
    </StretchingTypeInput> -->
    <div :class="currentWorkshopWork.double_glas
      ? 'disabled'
      : undefined
      ">
      <ProductSelect :products="filteredProducts"
        @changeProduct="changeBackWall"
        :type="workshopProductSelectionTypes.BACK_WALL" ref="backWallSelect">
      </ProductSelect>
    </div>
    <div class="backWallDetails">
      <div v-if="currentProduct" class="workshopOptionDetails">
        <div class="content">
          <NonConfigurableFreeSpaceDummy :roundToTwo="roundToTwo"
            :productProp="currentProduct"></NonConfigurableFreeSpaceDummy>
          <TopCutAndFrameOptions :updatePrice="updatePrice"
            :heightValidation="heightValidation"
            :widthValidation="widthValidation" :selectAll="selectAll"
            :productProp="currentProduct"></TopCutAndFrameOptions>
          <ProductRestrictions :productProp="currentProduct">
          </ProductRestrictions>
          <!-- <PositionPrice
            :roundToTwo="roundToTwo"
            :productProp="currentProduct"
          ></PositionPrice> -->
        </div>
        <div class="sizeUnitWrapper">
          <div>cm</div>
        </div>
      </div>
    </div>
    <div v-if="currentWorkshopMode === workshopModes.FRAMING">
      <label class="checkboxContainer"
        style="margin-bottom: 1rem; margin-top: 2rem">
        Doppelglaseinrahmung
        <input type="checkbox"
          v-model="$store.getters.currentWorkshopWork.double_glas"
          @change="onDoubleGlasChange" />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</template>
<script>
import ToolTip from "@/components/shared/ToolTip.vue";

import NonConfigurableFreeSpaceDummy from "@/components/configurator/shared/Information/NonConfigurableFreeSpaceDummy.vue";
//import PositionPrice from "@/components/configurator/shared/Information/PositionPrice.vue";
import ProductRestrictions from "@/components/configurator/shared/Information/ProductRestrictions.vue";
import TopCutAndFrameOptions from "@/components/configurator/shared/Options/TopCutAndFrameOptions.vue";
import IbisSpinner from "@/components/shared/IbisSpinner.vue";
import WorkshopBackWalls from "@/models/Types/WorkshopBackWalls";
import ProductFactory from "@/utility/ProductFactory";
import ProductSelect from "../../shared/ProductSelect";
import FamilyTypes from "@/models/Types/FamilyTypes";
import ProductTypes from "@/models/Types/ProductTypes";
import WorkshopProductSelectionTypes from "@/models/Types/WorkshopProductSelectionTypes";
import WorkshopModes from "@/models/Types/WorkshopModes";
import Measurements from "@/models/Types/Measurements";
import UserTypeProvider from "@/utility/UserTypeProvider";
import UserTypes from "@/models/Types/UserTypes";
import InstallationTypes from "@/models/Types/InstallationTypes";
import InstallationTypes_BAUHAUS from "@/models/Types/BAUHAUS/InstallationTypes_BAUHAUS";
import InstallationTypes_XXXL from "@/models/Types/XXXL_GROUP/InstallationTypes_XXXL";
//import StretchingTypeInput from "../../lamination/components/StretchingTypeInput.vue";

export default {
  inject: ["$firebase"],
  props: [
    "availableProducts",
    "checkProductSizeLimits",
    "roundToTwo",
    "updatePrice",
    "heightValidation",
    "widthValidation",
    "selectAll",
  ],
  data() {
    return {
      workshopBackWalls: WorkshopBackWalls,
      workshopModes: WorkshopModes,
      filteredProducts: [],
      allProducts: [],
      isLoading: true,
      productTypes: ProductTypes,
      familyTypes: FamilyTypes,
      workshopProductSelectionTypes: WorkshopProductSelectionTypes,
    };
  },
  components: {
    ProductSelect,
    //StretchingTypeInput,
    IbisSpinner,
    NonConfigurableFreeSpaceDummy,
    TopCutAndFrameOptions,
    ProductRestrictions,
    ToolTip,
    //PositionPrice,
  },
  watch: {
    currentWorkshopWorkFrameWidth() {
      this.filterAvailableProducts();
    },
    currentWorkshopWorkFrameHeight() {
      this.filterAvailableProducts();
    },
    currentWorkshopWork: {
      handler: async function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (
            this.currentWorkshopMode === WorkshopModes.FRAMING ||
            this.currentWorkshopMode === WorkshopModes.FRAME_WITH_EQUIPMENT
          ) {
            if (!this.currentProduct) {
              await this.initializeBackwalls();
            }
          }
        }
      },
      deep: true,
    },
    "$store.getters.currentWorkshopWork.double_glas"(newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.$store.getters.currentWorkshopWork.second_glas = undefined;
      }
    },
  },
  computed: {
    currentProduct: {
      get() {
        return this.$store.getters.currentWorkshopWork.back_wall;
      },
      set(newValue) {
        this.$store.commit("switchWorkshopBackWall", newValue);
      },
    },
    currentWorkshopWorkFrameWidth() {
      if (this.$store.getters.currentWorkshopWork.frame) {
        return this.$store.getters.currentWorkshopWork.frame.width;
      }
      return undefined;
    },
    currentWorkshopWorkFrameHeight() {
      if (this.$store.getters.currentWorkshopWork.frame) {
        return this.$store.getters.currentWorkshopWork.frame.height;
      }
      return undefined;
    },
    currentFrame() {
      return this.$store.getters.currentWorkshopWork.frame;
    },
    currentWorkshopWork() {
      return this.$store.getters.currentWorkshopWork;
    },
    currentWorkshopMode() {
      return this.$store.getters.currentWorkshopMode;
    },
  },
  async mounted() {
    await this.initializeBackwalls();
  },
  methods: {
    changeBackWall(value) {
      this.currentProduct = value;
    },
    setDefaultBackWall(back_wall) {
      this.$store.commit("setDefaultBackWall", back_wall);
    },
    async initializeBackwalls() {
      this.filteredProducts = [];
      this.allProducts = [];
      const db = this.$firebase.firestore();
      const productsRef = db.collection("products");
      const productsPromises = this.availableProducts.map(async (e) => {
        const productRef = await productsRef
          .where("product_uid", "==", e)
          .get();
        productRef.forEach(async (doc) => {
          const product = await ProductFactory.fromJson(doc.data());
          this.allProducts.push(product);
        });
      });
      await Promise.all(productsPromises);
      this.allProducts.forEach((e) => {
        e.selected_measurement_type = Measurements.AUSSENMASS;
      });
      this.filteredProducts = [...this.allProducts];
      this.filterAvailableProducts();
      const userType = UserTypeProvider.getUserType();
      this.filteredProducts = this.filteredProducts.filter((e) => {
        let noPrice;

        if (userType === UserTypes.RETAILER) {
          noPrice = !e.price && !e.qm_price;
        } else {
          noPrice = !e.price;
        }
        return !noPrice;
      });
      if (!this.currentProduct) {
        this.setDefaultBackWall(this.filteredProducts[0]);
        this.$nextTick(() => {
          if (this.$refs.backWallSelect) {
            this.$refs.backWallSelect.presetGlas(this.filteredProducts[0]);
          }
        });
      }
      this.isLoading = false;
    },
    filterAvailableProducts() {
      if (this.currentFrame) {
        // Assuming checkProductSizeLimits only checks for size limits and does not affect the article_id comparison
        this.filteredProducts = this.allProducts.filter((e) =>
          this.checkProductSizeLimits(e)
        );

        // Check if any item in the filteredProducts has the same article_id as the currentProduct
        const hasCurrentProduct = this.filteredProducts.some(
          (e) => e.article_id === this.currentProduct?.article_id
        );

        // If the currentProduct is not in the filteredProducts, reset the currentProduct
        if (!hasCurrentProduct) {
          this.currentProduct = "";
        }
      }
    },
    onDoubleGlasChange() {
      const installationTypes = UserTypeProvider.getUserType() === UserTypes.RETAILER
        ? InstallationTypes
        : UserTypeProvider.getUserType() === UserTypes.BAUHAUS
          ? InstallationTypes_BAUHAUS
          : InstallationTypes_XXXL;
      if (this.$store.getters.currentWorkshopWork.double_glas) {
        this.$store.getters.currentWorkshopWork.back_wall = undefined;

        this.$store.getters.currentWorkshopWork.installation = installationTypes.find((e) => e.key === "DOPPEL_GLAS");
      }
      else {
        this.$store.getters.currentWorkshopWork.installation = installationTypes.find((e) => e.key === "E");
        this.$store.getters.currentWorkshopWork.second_glas = undefined;
      }

    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../../../assets/sass/style.scss";

.backwallInputWrapper {
  .mainHeadlineWrapper {
    width: 60vw;
    display: flex;
    align-items: center;
    gap: .5rem;

    @media (max-width: $mobile) {
      width: 95vw;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;

    .headline {
      text-align: left;
    }
  }
}

.sizeUnitWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 12.8rem;
  min-width: 1.5vw;
  margin-left: 0.5rem;

  span {
    height: fit-content;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
