<template>
  <div class="heroSection" id="heroSection">
    <div class="mainCaption">
      <span v-if="isRetailer">IbiS Onlinebestellung: Shop für Fachhändler</span>
      <span v-else>Herzlich Willkommen im Onlinekonfigurator</span>
    </div>
    <div class="subCaption">
      <span>Konfigurieren Sie ihre gewünschten Produkte simpel und
        schnell.</span>
    </div>
    <div class="actionButton">
      <IbisButton buttonClass="primary" buttonText="Bestellen"
        @click="navigateToConfigurator()"></IbisButton>
    </div>
  </div>
</template>
<script>
import UserTypeProvider from "@/utility/UserTypeProvider";
import IbisButton from "../shared/IbisButton.vue";
import UserTypes from "@/models/Types/UserTypes";
export default {
  components: {
    IbisButton,
  },
  mounted() {
    document.getElementById("heroSection").classList.add("scale-in-center");
  },
  computed: {
    isRetailer() {
      return UserTypeProvider.getUserType() === UserTypes.RETAILER;
    },
  },
  methods: {
    navigateToConfigurator() {
      this.$router.push("/konfigurator");
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.heroSection {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 35rem;
  background-image: url("../../assets/images/hero_section.avif");
  background-size: cover;
  background-position: center 20%;

  @media (min-width: $default-desktop) {
    height: 45rem;
  }

  @media (min-width: $big-desktop) {
    height: 55rem;
  }

  @media (min-width: $large-desktop) {
    height: 60rem;
  }

  @media (max-width: $tablet) {
    margin-top: 0;
    background-position: 83% 20%;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

    mix-blend-mode: darken;

    @media (max-width: $tablet) {
      width: 100%;
      background: rgba(0, 0, 0, 0.4); // 50% opacity grey overlay
    }
  }

  .mainCaption {
    position: absolute;
    color: white;
    font-size: $font-size-xlarge;
    top: 25%;
    left: 15%;
    font-weight: bold;
    width: 25rem;
    text-align: left;

    @media (max-width: $mobile) {
      left: 5%;
      top: 10%;
      width: 80vw;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 40vw;
    }
  }

  .subCaption {
    position: absolute;
    color: white;
    top: 45%;
    left: 15%;
    text-align: left;

    @media (max-width: $mobile) {
      left: 5%;
      top: 40%;
      width: 80vw;
    }
  }

  .actionButton {
    position: absolute;
    top: 55%;
    left: 15%;

    @media (max-width: $mobile) {
      left: 5%;
      top: 52%;
    }
  }

  .heroProductSearchWrapper {
    position: absolute;
    bottom: -10px;    z-index: 2;
  }
}
</style>
