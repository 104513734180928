<template>
  <div class="screenCover">
    <div class="cookieDialog" id="cookieDialog">
      <div class="consentText">
        <h1>Cookies</h1>
        <div>
          <span>
            Diese Webplattform verwendet Cookies und Google Analytics für die
            Analyse und Statistik. Wir nutzen Cookies zu unterschiedlichen
            Zwecken, unter anderem zur Analyse von Nutzerverhalten. Durch die
            weitere Nutzung der Website stimmen Sie der Verwendung zu.
          </span>
          <span class="dataPrivacyLink">Datenschutz</span>
        </div>
      </div>
      <div class="consentButtons" v-if="!showConfiguraton">
        <IbisButton
          buttonClass="secondary"
          buttonText="Konfigurieren"
          @click="configureCookies"
        ></IbisButton>
        <IbisButton
          buttonClass="primary"
          buttonText="Akzeptieren"
          @click="acceptCookies"
        ></IbisButton>
      </div>
      <div class="consentButtons" v-if="showConfiguraton">
        <IbisButton
          buttonClass="secondary"
          buttonText="Nur notwendige akzeptieren"
          @click="declineCookies"
        ></IbisButton>
        <IbisButton
          buttonClass="primary"
          buttonText="Alle akzeptieren"
          @click="acceptCookies"
        ></IbisButton>
      </div>
    </div>
  </div>
</template>

<script>
import IbisButton from "./components/shared/IbisButton.vue";
export default {
  mounted() {
    document.getElementById("cookieDialog").classList.add("fade-in-bottom");
  },
  components: {
    IbisButton,
  },
  data() {
    return {
      showConfiguraton: false,
    };
  },

  methods: {
    configureCookies() {
      this.showConfiguraton = true;
    },
    declineCookies() {
      this.$store.dispatch("setCookieConsent", false);
      this.$emit("closeDialog");
    },
    acceptCookies() {
      this.$store.dispatch("setCookieConsent", true);
      this.$emit("closeDialog");
    },
  },
};
</script>
<style scoped lang="scss">
@import "./assets/sass/style.scss";

.screenCover {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($ibis-grey, 0.8); // Semi-transparent overlay
  z-index: 9999; // High z-index to ensure it's on top
  display: flex; // Using flexbox to center the dialog
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically

  .cookieDialog {
    background-color: white;
    box-shadow: 0px 8px 37px rgba(64, 64, 64, 0.1);
    max-width: 50%;
    padding: 20px; // Some padding around the content
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media (max-width: $tablet) {
      max-width: 80%;
      margin-bottom: 10rem;
    }
    .consentText {
      h1 {
        margin: 0;
        margin-bottom: 1.5rem;
      }
      .dataPrivacyLink {
        color: $ibis-red;
        cursor: pointer;
      }
    }

    .consentButtons {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }
}
</style>
