const { default: UserTypeProvider } = require("@/utility/UserTypeProvider");
const SingleProduct = require("./SingleProduct");
const Measurements = require("./Types/Measurements");
const ProductTypes = require("./Types/ProductTypes");
const UserTypes = require("./Types/UserTypes");

class FrameProduct extends SingleProduct {
  name;
  article_id;
  description;
  workshop_naming;
  product_id;
  webshop_id;
  familyType;
  category;
  sub_category;
  selling_unit;
  min_quantity_surcharge;
  only_full_units;
  price;
  quantity = 1;
  discount = 0;

  notes;

  min_width;
  min_height;
  max_width;
  max_height;
  width;
  height;
  selected_measurement_type = Measurements.FALZMASS;

  productType = ProductTypes.FRAME;

  passepartout_top_leg_width;
  passepartout_left_leg_width;
  passepartout_right_leg_width;
  passepartout_bottom_leg_width;

  discontinued = false;
  no_hangers = false;

  constructor(
    name,
    article_id,
    description,
    workshop_naming,
    product_id,
    webshop_id,
    familyType,
    category,
    sub_category,
    selling_unit,
    min_quantity_surcharge,
    only_full_units,
    price,
    quantity,
    price_unit,
    discount,
    notes,

    min_height,
    min_width,
    max_height,
    max_width,

    width,
    height,
    selected_measurement_type,

    unavailable_regions,

    isRemainingStock,
    remainingStockDiscount,
    productImageUrls,
    remainingStockQuantity,
    discontinued,

    is_own_frame,
    no_hangers
  ) {
    super();
    this.name = name;
    this.article_id = article_id;
    this.description = description;
    this.workshop_naming = workshop_naming;
    this.product_id = product_id;
    this.webshop_id = webshop_id;
    this.familyType = familyType;
    this.category = category;
    this.sub_category = sub_category;
    this.selling_unit = selling_unit;
    this.min_quantity_surcharge = min_quantity_surcharge;
    this.only_full_units = only_full_units === "Ja" ? true : false;
    this.price = price;
    this.price_unit = price_unit;
    this.quantity = this.only_full_units
      ? selling_unit
      : quantity
        ? quantity
        : 1;
    this.discount = discount;

    this.notes = notes;

    this.min_height = min_height ? min_height : 15;
    this.min_width = min_width ? min_width : 15;
    this.max_height = max_height ? max_height : 250;
    this.max_width = max_width ? max_width : 175;

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }

    selected_measurement_type
      ? (this.selected_measurement_type = selected_measurement_type)
      : null;

    this.unavailable_regions = unavailable_regions;

    this.isRemainingStock = isRemainingStock;
    this.remainingStockDiscount = remainingStockDiscount;
    this.productImageUrls = productImageUrls;
    this.remainingStockQuantity = remainingStockQuantity;
    this.discontinued = discontinued === "True" ? true : false;

    this.is_own_frame = is_own_frame;
    this.no_hangers = no_hangers !== undefined ? no_hangers : UserTypeProvider.getUserType() === UserTypes.RETAILER ? true : false;

  }
  static extractWidth(str) {
    const regex = /(\d+(?:[.,]\d+)?)\s*[xX]\s*(\d+(?:[.,]\d+)?)/g;
    let match;
    let lastMatch;

    while ((match = regex.exec(str)) !== null) {
      lastMatch = match;
    }

    if (lastMatch) {
      const width = parseFloat(lastMatch[1].replace(",", "."));
      return isNaN(width) ? null : width;
    } else {
      return null;
    }
  }

  static extractHeight(str) {
    const regex = /(\d+(?:[.,]\d+)?)\s*[xX]\s*(\d+(?:[.,]\d+)?)/g;
    let match;
    let lastMatch;

    while ((match = regex.exec(str)) !== null) {
      lastMatch = match;
    }

    if (lastMatch) {
      const height = parseFloat(lastMatch[2].replace(",", "."));
      return isNaN(height) ? null : height;
    } else {
      return null;
    }
  }
  setWidth(value, invokeReset) {
    value = Math.max(1, value); // Ensure value is at least 1

    let maxW =
      value <= this.height
        ? Math.min(this.max_width, this.max_height) // Choose the smaller one
        : Math.max(this.max_width, this.max_height); // Choose the larger one

    if (value > maxW) {
      if (invokeReset) this.setHeight(this.height, false);
      this.width = maxW;
    } else if (this.min_width && value < this.min_width) {
      this.width = this.min_width;
    } else {
      this.width = value;
    }

    if (invokeReset) this.setHeight(this.height, false);
  }

  setHeight(value, invokeReset) {

    value = Math.max(1, value); // Ensure value is at least 1

    let maxH =
      value <= this.width
        ? Math.min(this.max_height, this.max_width) // Choose the smaller one
        : Math.max(this.max_height, this.max_width); // Choose the larger one

    if (value > maxH) {
      this.height = maxH;
      if (invokeReset) this.setWidth(this.width, false);
    } else if (this.min_height && value < this.min_height) {
      this.height = this.min_height;
    } else {
      this.height = value;
    }
    if (invokeReset) this.setWidth(this.width, false);
  }
  /*  reset() {
    this.width = FrameProduct.extractWidth(this.name);
    this.height = FrameProduct.extractHeight(this.name);
    this.selected_measurement_type = Measurements.FALZMASS;
    this.quantity = 1;
    this.notes = undefined;
  } */
}
module.exports = FrameProduct;
