<template>
  <div class="entryPageWrapper">
    <div class="modeSwitch">
      <ConfiguratorModeSwitch></ConfiguratorModeSwitch>
    </div>
    <div class="floatingPrice"
      v-if="currentConfiguratorMode === configuratorModes.WORKSHOP_WORKS">
      <PositionPrice :roundToTwo="roundToTwo" :productProp="currentWorkshopWork"
        text="Gesamtpreis:" style="margin-top: 0"></PositionPrice>
    </div>
    <ConfiguratorMainWrapper
      v-if="currentConfiguratorMode === configuratorModes.PRODUCT" />
    <WorkshopWorksWrapper
      v-if="currentConfiguratorMode === configuratorModes.WORKSHOP_WORKS" />

  </div>
</template>
<script>
import ConfiguratorMainWrapper from "./ConfiguratorMainWrapper.vue";
import ConfiguratorModeSwitch from "../shared/ConfiguratorModeSwitch.vue";
import ConfiguratorMode from "@/models/Types/ConfiguratorMode";
import WorkshopWorksWrapper from "../WorkshopWorks/WorkshopWorksWrapper.vue";
import PositionPrice from "../shared/Information/PositionPrice.vue";
import calculatePrice from "@/utility/PriceCalculator";
//EXAMPLE URL:  http://localhost:8080/?products=10018635,10000042,10000058,10015379

export default {
  data() {
    return {
      isLoading: true,
      configuratorModes: ConfiguratorMode,
    };
  },
  computed: {
    currentConfiguratorMode() {
      return this.$store.getters.currentConfiguratorMode;
    },
    currentWorkshopWork() {
      return this.$store.getters.currentWorkshopWork;
    },
    numericPositionPrice() {
      let originalPrice = calculatePrice(this.currentWorkshopWork);
      // Check if there is a discount code percentage applied
      let discountCodePercentage = 0;
      if (this.$store.getters.currentDiscountCode) {
        discountCodePercentage = this.$store.getters.currentDiscountCode
          .discountPercentage
          ? this.$store.getters.currentDiscountCode.discountPercentage
          : 0;
      }
      let absoluteDiscount = 0;
      if (this.$store.getters.currentDiscountCode) {
        absoluteDiscount = this.$store.getters.currentDiscountCode
          .absoluteDiscount
          ? this.$store.getters.currentDiscountCode.absoluteDiscount
          : 0;
      }
      // If a discount code is applied, calculate the full price without the discount code percentage
      if (discountCodePercentage > 0) {
        originalPrice = originalPrice / (1 - discountCodePercentage);
      } else if (absoluteDiscount > 0) {
        originalPrice = originalPrice + absoluteDiscount;
      } else if (this.currentWorkshopWork.discount) {
        originalPrice = originalPrice / (1 - this.currentWorkshopWork.discount);
      }
      return this.roundToTwo(originalPrice);
    },
  },
  components: {
    ConfiguratorMainWrapper,
    ConfiguratorModeSwitch,
    WorkshopWorksWrapper,
    PositionPrice,
  },
  methods: {
    roundToTwo(num) {
      return +parseFloat(Math.round(num + "e+3") + "e-3");
    },
  }
};
</script>
<style lang="scss">
@import "../../../assets/sass/style.scss";

.floatingPrice {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: white;
  padding: 1rem;
  border-radius: 50px;
  box-shadow: 0px 8px 37px rgba(64, 64, 64, 0.1);
  z-index: 100;
  font-weight: bold;
}
</style>
