<template>
  <div
    class="stockItemDetailsWrapper"
    id="stockItemDetailsWrapper"
    v-show="!currentConfigLoading"
  >
    <div class="stockItemOptionsWrapper">
      <div class="imagesAndOptions">
        <div class="productImagesWrapper">
          <ProductImages v-if="!currentConfigLoading"></ProductImages>
        </div>
        <div class="configuratorWrapper">
          <ProductConfigurator></ProductConfigurator>
        </div>
      </div>
      <div class="selectedProductIDsWrapper">
        <SelectedProductIDs
          @click="$router.push('/konfigurator')"
        ></SelectedProductIDs>
      </div>

      <div class="orderConfirmWrapper"><OrderConfirm /></div>
    </div>
  </div>
  <div v-if="currentConfigLoading" class="loading">
    <IbisSpinner></IbisSpinner>
  </div>
</template>
<script>
import ProductImages from "@/components/shared/ProductImages.vue";
import OrderConfirm from "@/components/configurator/shared/OrderConfirm.vue";
import ProductConfigurator from "@/components/configurator/ProductConfigurator/ProductConfigurator.vue";
import SelectedProductIDs from "@/components/configurator/shared/SelectedProductIDs.vue";
import IbisSpinner from "@/components/shared/IbisSpinner.vue";
import DBSyncer from "@/utility/DBSyncer";

export default {
  inject: ["$firebase"],

  components: {
    ProductImages,
    ProductConfigurator,
    SelectedProductIDs,
    OrderConfirm,
    IbisSpinner,
  },

  computed: {
    firstProduct() {
      return this.$store.getters.selectedProductFamily?.products[0];
    },
  },

  data() {
    return {
      currentConfigLoading: true,
      imageFetchUrls: [],
    };
  },

  mounted() {
    this.$nextTick(() => {
      document
        .getElementById("stockItemDetailsWrapper")
        .classList.add("fade-in-bottom");
      this.fetchCurrentUserConfiguration();
    });
  },

  methods: {
    async fetchCurrentUserConfiguration() {
      this.currentConfigLoading = true;
      await DBSyncer.fetchUserConfiguration();
      this.currentConfigLoading = false;
      this.fetchProductImages();
    },

    async fetchProductImages() {
      if (!this.firstProduct || !this.firstProduct.product_id) return;
      const storageRef = this.$firebase
        .storage()
        .ref(`ProductImages/${this.firstProduct.product_id}`);
      try {
        // List all files in the product's folder to get the images
        const result = await storageRef.listAll();
        const imagePromises = result.items.map((item) => item.getDownloadURL());
        this.imageFetchUrls = await Promise.all(imagePromises);
      } catch (error) {
        console.error("Failed to fetch images", error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.stockItemDetailsWrapper {
  display: flex;
  flex-direction: column;

  .stockItemOptionsWrapper {
    padding: 0rem 5rem;
    box-sizing: border-box;
    width: 100vw;
    @media (max-width: $tablet) {
      padding: 3rem 0;
      align-items: center;
    }
    @media (max-width: $mobile) {
      width: 100vw;
      margin-top: 0;
      padding: 2rem 0;
    }

    background-color: $ibis-grey;
    margin: auto;
    display: flex;
    flex-flow: column;
    padding-top: 40px;
    padding-bottom: 60px;
    margin-bottom: 100px;
    margin-top: 3em;
    max-width: 1500px;
    .imagesAndOptions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @media (max-width: $tablet) {
        flex-direction: column;
        justify-content: unset;
      }
      .productImagesWrapper {
        min-height: 100px;
        min-width: 20%;
      }

      .configuratorWrapper {
        width: 70%;
        @media (max-width: $mobile) {
          width: 100%;
        }
        @media (min-width: $mobile) and (max-width: $tablet) {
          width: 80%;
        }
        max-width: 850px;
      }
    }

    .selectedProductIDsWrapper {
      width: 100%;
      @media (max-width: $mobile) {
        width: 100vw;
      }
    }
    .orderConfirmWrapper {
      width: 100%;
      @media (max-width: $tablet) {
        width: 100%;
        display: flex;
      }
      @media (max-width: $mobile) {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      display: flex;
      justify-content: flex-end;
    }
  }
}
.loading {
  height: 80vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
