<template>
  <div class="mainListWrapper" v-if="this.selectedConfigurations.length > 0">
    <div class="headerWrapper">
      <label class="header">{{
        currentRoute === "/konfigurator"
          ? "Übersicht"
          : "Aktuelle Konfiguration"
      }}</label>
    </div>
    <div
      v-for="(product, index) in this.selectedConfigurations"
      :key="index"
      class="listWrapper"
    >
      <div
        class="productTile"
        @click="selectItem(index, product)"
        :class="{ selected: this.$store.getters.selectedIndex === index }"
      >
        <div class="listTileIDWrapper">
          <span class="listTileID">#{{ index + 1 }}</span>
        </div>
        <div class="productNameWrapper">
          <span class="productName" v-if="!isWorkshopInstance(product)">
            {{
              product.productType === productTypes.GLAS_BOARD
                ? product.name
                : product.description
            }}
          </span>
          <span class="productName" v-else> Werkstattarbeit </span>
        </div>
        <div v-if="!isWorkshopInstance(product)" class="productType">
          <div
            v-if="
              product.selected_denomination_type === denominations.FRAMES ||
              product.productType !== productTypes.CUT
            "
          >
            {{ product.sub_category }}
          </div>
          <div
            v-else-if="
              product.selected_denomination_type === denominations.LEGS ||
              product.productType !== productTypes.CUT
            "
          >
            Schenkel
          </div>
        </div>
        <div v-else class="productType">
          <div>
            {{ product.mode }}
          </div>
        </div>
        <div class="productLength">
          <div
            v-if="
              product.productType !== productTypes.NON_CONFIGURABLE &&
              product.productType !== productTypes.POLE &&
              product.width &&
              product.height && product.selected_denomination_type !== denominations.LEGS
            "
          >
            {{
              product.is_straight_cut
                ? "gerader Schnitt"
                : product.selected_measurement_type
            }}
            {{
              roundToTwo(parseFloat(product.width)).toFixed(2).replace(".", ",")
            }}
            x
            {{
              roundToTwo(parseFloat(product.height))
                .toFixed(2)
                .replace(".", ",")
            }}
            cm ({{ product.quantity }}x)
          </div>
          <div
            v-else-if="
              product.selected_denomination_type === denominations.LEGS &&
              product.width
            "
          >
            {{
              product.is_straight_cut
                ? "gerader Schnitt"
                : product.selected_measurement_type
            }}
            {{
              roundToTwo(parseFloat(product.width)).toFixed(2).replace(".", ",")
            }}
            cm
          </div>
          <div v-else-if="product.productType === productTypes.POLE">
            {{
              roundToTwo(product.length * product.quantity)
                .toFixed(2)
                .replace(".", ",")
            }}
            m
          </div>
          <div
            v-else-if="
              product.productType === productTypes.NON_CONFIGURABLE ||
              product.productType === productTypes.GLAS_BOARD ||
              isWorkshopInstance(product)
            "
          >
            {{ product.quantity }}x
          </div>
        </div>
        <div class="productPrice">{{ calculateProductPrice(product) }} €</div>
        <div class="optionIconsWrapper" v-if="currentRoute !== '/'">
          <div class="optionIcons">
            <img
              src="../../../assets/icons/edit_icon.svg"
              @click="editProductFamily()"
            />
            <!-- <img
              src="../../assets/icons/copy_icon.svg"
              @click="copyProductFamily(index)"
            /> -->
            <img
              src="../../../assets/icons/delete_icon.svg"
              @click.stop="deleteProductFamily(index)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="noProductsSelectedText">
    <span>Keine Produkte ausgewählt</span>
  </div>
</template>
<script>
import ConfiguratorMode from "@/models/Types/ConfiguratorMode";
import Denominations from "@/models/Types/Denominations";
import ProductTypes from "@/models/Types/ProductTypes";
import WorkshopWork from "@/models/WorkshopWork";
import DBSyncer from "@/utility/DBSyncer";
import calculatePrice from "@/utility/PriceCalculator";
export default {
  computed: {
    currentProduct() {
      return this.$store.getters.currentProduct;
    },
    currentProductFamily() {
      return this.$store.getters.selectedProductFamily;
    },
    selectedConfigurations() {
      return this.$store.getters.selectedConfigurations;
    },
    currentGlasOption: {
      get() {
        return this.$store.getters.currentGlasSizeOption;
      },
      set(newValue) {
        this.$store.commit("switchGlasSizeOption", newValue);
      },
    },
    selectedIndex() {
      return this.$store.getters.selectedIndex;
    },
    totalPrice() {
      return this.$store.getters.totalPrice;
    },
    currentRoute() {
      return this.$route.path;
    },
  },
  data() {
    return {
      productTypes: ProductTypes,
      denominations: Denominations,
    };
  },

  methods: {
    selectItem(index, product) {
      if (product instanceof WorkshopWork) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.$store.commit("setWorkshopWork", product);
        this.$store.commit("setWorkshopMode", product.mode);
        this.$store.commit("setSelectedIndex", index);

        this.$store.commit(
          "setConfiguratorMode",
          ConfiguratorMode.WORKSHOP_WORKS
        );

        return;
      } else {
        this.$store.commit("setSelectedProductFamily", index);
        this.$store.commit("setConfiguratorMode", ConfiguratorMode.PRODUCT);
      }
    },
    editProductFamily() {
      window.scrollTo({ top: 200, behavior: "smooth" });
    },
    /*  copyProductFamily(index) {
      if (this.$store.getters.selectedIndex === index) {
        this.$store.commit("copyProductFamily", index);
        this.$store.commit("updateSelectedConfigurations");
      }
    }, */
    deleteProductFamily(index) {
      if (this.$store.getters.selectedIndex === index) {
        this.$store.commit("deleteProductFamily", index);
        this.syncConfiguration();
      }
    },
    calculateProductPrice(product) {
      let originalPrice = calculatePrice(product);
      let discountCodePercentage = 0;
      if (this.$store.getters.currentDiscountCode) {
        discountCodePercentage = this.$store.getters.currentDiscountCode
          .discountPercentage
          ? this.$store.getters.currentDiscountCode.discountPercentage
          : 0;
      }
      let absoluteDiscount = 0;
      if (this.$store.getters.currentDiscountCode) {
        absoluteDiscount = this.$store.getters.currentDiscountCode
          .absoluteDiscount
          ? this.$store.getters.currentDiscountCode.absoluteDiscount
          : 0;
      }
      // If a discount code is applied, calculate the full price without the discount code percentage
      if (discountCodePercentage > 0) {
        originalPrice = originalPrice / (1 - discountCodePercentage);
      } else if (absoluteDiscount > 0) {
        originalPrice = originalPrice + absoluteDiscount;
      } else if (this.currentProduct?.discount) {
        originalPrice = originalPrice / (1 - this.currentProduct.discount);
      }

      // Round to two decimal places
      const roundedPrice = this.roundToTwo(originalPrice);

      // Convert to string and format the price
      let priceString = roundedPrice.toFixed(2).toString();
      priceString = priceString
        .replace(/\./g, "#")
        .replace(/,/g, ".")
        .replace(/#/g, ",");

      // Format the number with a dot for thousands and a comma for decimals
      return priceString.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    roundToTwo(num) {
      return +parseFloat(Math.round(num + "e+3") + "e-3");
    },
    syncConfiguration() {
      DBSyncer.syncConfiguration();
    },
    isWorkshopInstance(product) {
      return product instanceof WorkshopWork;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.mainListWrapper {
  margin-top: 2em;
  .headerWrapper {
    text-align: start;
    margin-bottom: 1em;
    .header {
      font-size: $font-size-bigger;
      @media (max-width: $mobile) {
        padding-left: 1rem;
      }
    }
  }
  .listWrapper {
    .productTile {
      font-size: $font-size-big;
      padding: 10px 15px;
      background-color: white;
      margin-bottom: 1em;
      border-radius: 50px;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      @media (max-width: $mobile) {
        flex-flow: column;
        gap: 0.5rem;
      }
      .listTileIDWrapper {
        margin-left: 1em;
      }
      .productNameWrapper {
        padding: 0.5em;
        width: 30%;
        text-align: left;
        @media (max-width: $mobile) {
          width: 90%;
          text-align: center;
          padding: 0;
        }
        .productName {
          text-align: start;
          @media (max-width: $mobile) {
            width: 90%;
            text-align: center;
          }
        }
      }

      .productType {
        width: 15%;
        text-align: start;
        @media (max-width: $mobile) {
          width: 90%;
          text-align: center;
        }
      }
      .productLength {
        width: 10vw;
        @media (max-width: $mobile) {
          width: 90%;
          text-align: center;
        }
      }
      .productPrice {
        @media (max-width: $mobile) {
          margin-bottom: 1rem;
        }
      }
      .optionIconsWrapper {
        display: flex;
        justify-content: right;
        align-items: center;

        .optionIcons {
          display: flex;
          gap: 8px;
          img {
            height: 25px;
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.selected {
  border: 2px solid $ibis-red;
  padding: 8px 13px !important;
  img {
    opacity: 1 !important;
    cursor: pointer;
  }
}

.noProductsSelectedText {
  margin: 3em;
}
</style>
