<template>
  <div class="hangingInputWrapper">
    <div class="mainHeadlineWrapper">
      <h2 class="headline">Aufhängung</h2>
      <div class="infoIconWrapper" style="margin-top:1.2rem">
        <ToolTip tipText="<strong>Standardaufhängung:</strong>
          Als Standardaufhänger werden Zackenaufhänger verwendet. In der Regel
          wird – je nach Ausrichtung Ihres Bildes – entweder im Hoch- oder
          Querformat ein Zackenaufhänger angebracht.
          <br>
          <br>
          <strong>Seilaufhängung:</strong> Je nach Ausrichtung Ihres Bildes
          erhält die Rahmung im Hoch- oder Querformat eine Seilaufhängung, die
          jeweils rechts und links am Rahmen angebracht wird, wodurch die
          Belastung verteilt wird. Der Rahmen wir direkt am Seil aufgehängt.
          <br>
          <br>

          <strong>Schwerlastaufhängung:</strong> Je größer die Rahmung wird,
          desto schwerer wird sie. Schwerlastaufhänger werden – je nach Format
          Ihres Bildes – entweder im Hoch- oder Querformat rechts und links an
          Ihrem Rahmen angebracht und verteilen dadurch die Belastung. Leider
          ist das Gewicht Ihrer Rahmung im Bestellprozess oftmals nicht
          einwandfrei zu bestimmen, weshalb wir im Zweifel grundsätzlich dazu
          raten Schwerlastaufhänger anzubringen.
          <br>
          <br>"></ToolTip>
      </div>
    </div>
    <template v-for="(type, index) in hangingTypes">
      <label class="checkboxContainer" v-if="checkOptionAvailability(type)"
        :key="type.key" style="margin-bottom: 1rem; margin-top: 2rem">
        {{ type.value }}
        <input :id="`checkbox-${type.key}+${index}`" type="checkbox"
          v-model="type.checked" @change="setSelectedHangings()" />
        <span class="checkmark"></span>
      </label>
    </template>
  </div>
</template>

<script>
import HangingTypes from "@/models/Types/HangingTypes";
import HangingTypes_BAUHAUS from "@/models/Types/BAUHAUS/HangingTypes_BAUHAUS";
import HangingTypes_XXXL from "@/models/Types/XXXL_GROUP/HangingTypes_XXXL";
import DBSyncer from "@/utility/DBSyncer";
import UserTypeProvider from "@/utility/UserTypeProvider";
import UserTypes from "@/models/Types/UserTypes";
import ToolTip from "@/components/shared/ToolTip.vue";
export default {
  components: {
    ToolTip,
  },
  data() {
    return {
      hangingTypes: (UserTypeProvider.getUserType() === UserTypes.RETAILER ? HangingTypes : UserTypeProvider.getUserType() === UserTypes.BAUHAUS ? HangingTypes_BAUHAUS : HangingTypes_XXXL).map((type) => ({ ...type, checked: false })),
    };
  },

  created() {
    this.matchHangingsToWorkshop();
  },
  watch: {
    workshopWorkHangings: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.matchHangingsToWorkshop();
        }
      },
      deep: true,
    },
  },
  methods: {
    matchHangingsToWorkshop() {
      // Assume workshopWorkHangings is an array of hanging type keys
      if (this.workshopWorkHangings) {
        this.hangingTypes.forEach((type) => {
          type.checked = this.workshopWorkHangings.some(
            (e) => e.key === type.key
          );
        });
      }

      /*  // If there are no hangings in the workshop, default to the first one checked
      if (this.hangingTypes.length > 0) {
        this.hangingTypes[0].checked = true;
      } */
      this.setSelectedHangings();
    },
    setSelectedHangings() {
      // Update workshop hangings based on selected types
      this.workshopWorkHangings = this.selectedTypes;
      this.syncConfiguration();
    },
    syncConfiguration() {
      DBSyncer.syncConfiguration();
      this.$store.commit("updatePrice");
    },
    checkOptionAvailability(obj) {
      if (obj.unSupportedModes) {
        return !obj.unSupportedModes.includes(this.currentWorkshopMode);
      }
      return true;
    },
  },
  computed: {
    selectedTypes() {
      return this.hangingTypes.filter((type) => type.checked);
    },
    currentWorkshopMode() {
      return this.$store.getters.currentWorkshopMode;
    },
    workshopWorkHangings: {
      get() {
        return this.$store.getters.currentWorkshopWork.hangings;
      },
      set(value) {
        this.$store.commit("setWorkshopHangings", value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../../../assets/sass/style.scss";

.hangingInputWrapper {
  .mainHeadlineWrapper {
    display: flex;
    align-items: center;
    gap: .5rem;

    @media (max-width: $mobile) {
      width: 95vw;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;

    .headline {
      margin-bottom: 0;

      text-align: left;
    }
  }
}

.checkboxContainer {
  z-index: 1;
  display: flex;
  position: relative;
  padding-left: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 0;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background-color: $ibis-grey-middle;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked~.checkmark {
    background-color: $ibis-red;
    transition: 0.15s;

    &:after {
      display: block;
    }
  }

  .checkmark:after {
    left: 6.5px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
</style>
