import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import router from "../router/index.js";
const store = require("../main.js");

class AuthService {
  async getInstance() {
    if (!AuthService.instance) {
      /* const firebaseConfig = {
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID,
      };

      const app = firebase.initializeApp(firebaseConfig); */
      this.auth = firebase.auth();
      this.db = firebase.firestore();

      this.auth.onAuthStateChanged((user) => {
        this.user = user;
        if (user) {
          store.default.commit(
            "setUserAdminStatus",
            this.getAdminStatusFromLocalStorage()
          );
        } else {
          store.default.commit("setUserAdminStatus", false);
          localStorage.removeItem("ibisOnlinebestellungUserObject");
        }
      });

      AuthService.instance = this;
    }

    return AuthService.instance;
  }

  async login(customerId, password) {
    const docData = await this.getUserDoc(customerId);

    try {
      const credential = await this.auth.signInWithEmailAndPassword(
        docData.emails[0],
        password
      );

      localStorage.setItem(
        "ibisOnlinebestellungUserObject",
        JSON.stringify(docData)
      );
      store.default.commit("setUserAdminStatus", docData.isAdmin);
      this.startSessionTimer();

      return credential;

    } catch (error) {
      if (error.code === "auth/wrong-password") {
        throw new Error(
          "Das eingegebene Passwort oder Kundennummer ist falsch."
        );
      } else if (error.code === "auth/user-not-found") {
        throw new Error("Es existiert kein Konto für diese Kundennummer.");
      } else if (error.code === "auth/user-disabled") {
        throw new Error("Ihr Konto wurde deaktiviert.");
      } else if (error.code === "auth/too-many-requests") {
        throw new Error(
          "Zu viele Login Anfragen, bitte setzen Sie ihr Passwort zurück oder warten Sie 24 Stunden."
        );
      } else {
        console.log(error.message);
        throw new Error("Etwas ist schiefgelaufen.");
      }
    }
  }

  async changePassword(currentPassword, newPassword) {
    if (!this.user) {
      throw new Error("Es ist kein Nutzer eingeloggt.");
    }

    const email = this.user.email;

    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      currentPassword
    );

    try {
      await this.user.reauthenticateWithCredential(credential);
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        throw new Error("Das aktuelle Passwort ist nicht richtig.");
      } else {
        console.log(error.message);
        throw new Error("Etwas ist schiefgelaufen.");
      }
    }

    try {
      // Update the password
      await this.user.updatePassword(newPassword);
    } catch (error) {
      if (error.code === "auth/weak-password") {
        throw new Error("Das ausgewählte Passwort ist zu schwach.");
      } else {
        console.log(error.message);
        throw new Error("Etwas ist schiefgelaufen.");
      }
    }
  }

  async logout() {
    store.default.commit("setUserAdminStatus", false);
    localStorage.removeItem("ibisOnlinebestellungUserObject");
    await this.auth.signOut();
    window.location.reload();
  }

  getUser() {
    return new Promise((resolve, reject) => {
      const unsubscribe = this.auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }

  async forgotPassword(customerId) {
    try {
      const docData = await this.getUserDoc(customerId);
      await this.auth.sendPasswordResetEmail(docData.emails[0]);
    } catch (error) {
      console.error("Error sending password reset email: ", error);
      throw new Error("Error sending password reset email.");
    }
  }

  async getUserDoc(customerId) {
    const docRef = this.db.collection("users").doc(customerId.toString());
    const docSnap = await docRef.get();
    if (!docSnap.exists) {
      throw new Error("Es existiert kein Konto mit dieser Kundennummer.");
    }

    const docData = docSnap.data();
    return docData;
  }

  startSessionTimer() {
    if (this.sessionTimer) clearTimeout(this.sessionTimer);

    this.sessionTimer = setTimeout(() => {
      this.logout();
      router.push("/login");
    }, 30 * 60 * 1000); // 30 minutes
  }

  resetSessionTimer() {
    this.startSessionTimer();
  }

  getAdminStatusFromLocalStorage() {
    const userObject = JSON.parse(
      localStorage.getItem("ibisOnlinebestellungUserObject")
    );
    return userObject ? userObject.isAdmin : false;
  }
}

export default new AuthService();
