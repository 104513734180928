<template>
  <div class="carrierInputWrapper">
    <div class="mainHeadlineWrapper">
      <h2 class="headline">Trägermaterial</h2>
      <div class="infoIconWrapper">
        <ToolTip tipText="Kaschieren und Oberflächenbearbeitungen stellen für
          Originale einen erheblichen Wertverlust dar. Deshalb lehnen wir das
          Kaschieren, Laminieren und Veredeln von Originalen grundsätzlich ab.
          <br>
          <br>
          Insbesondere Digitalausdrucke, die im Injektverfahren auf Foto- oder
          FineArt-Medien produziert wurden, können empfindlich auf Hitze
          reagieren. Für das Verarbeiten dieser Materialien übernehmen wir daher
          keine Gewähr. Im Zweifel stellen Sie uns bitte ein Testmuster zur
          Verfügung.
          <br>
          <br>

          Kaschierungen auf massive Platten können nicht nachträglich
          beschnitten werden. Um exakte und saubere Kanten zu erzielen, wird die
          Platte daher etwas kleiner geschnitten, um das Bild nach dem
          Kaschieren kantenbündig nachschneiden zu können.
          <br>
          <br>

          Ein unsachgemäßer Transport, Lagerung oder Gebrauch können die Güte
          und damit die Weiterverarbeitungsfähigkeit eines Druckes erheblich
          mindern. Deshalb werden Kaschier- und Veredlungsarbeiten generall auf
          das Risiko des Kunden vorgenommen. Wir übernehmen für diese Arbeiten
          keine Haftung (außer bei grober Fahrlässigkeit)."></ToolTip>
      </div>
    </div>
    <StretchingTypeInput :updatePrice="updatePrice"></StretchingTypeInput>
    <IbisSpinner v-if="isLoading"></IbisSpinner>
    <div v-else>
      <ProductSelect :products="filteredProducts" @changeProduct="changeCarrier"
        :type="workshopProductSelectionTypes.CARRIER_MATERIAL"></ProductSelect>
    </div>
    <div class="carrierDetails">
      <div v-if="currentProduct" class="workshopOptionDetails">
        <div class="content">
          <NonConfigurableFreeSpaceDummy :roundToTwo="roundToTwo"
            :productProp="currentProduct"></NonConfigurableFreeSpaceDummy>
          <TopCutAndFrameOptions :updatePrice="updatePrice"
            :heightValidation="heightValidation"
            :widthValidation="widthValidation" :selectAll="selectAll"
            :productProp="currentProduct"></TopCutAndFrameOptions>
          <ProductRestrictions :productProp="currentProduct"
            :isLaminationAndSurfaceFinishing="true"></ProductRestrictions>
          <!-- <PositionPrice
            :roundToTwo="roundToTwo"
            :productProp="currentProduct"
          ></PositionPrice> -->
        </div>
        <div class="sizeUnitWrapper">
          <div>cm</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NonConfigurableFreeSpaceDummy from "@/components/configurator/shared/Information/NonConfigurableFreeSpaceDummy.vue";
//import PositionPrice from "@/components/configurator/shared/Information/PositionPrice.vue";
import ProductRestrictions from "@/components/configurator/shared/Information/ProductRestrictions.vue";
import TopCutAndFrameOptions from "@/components/configurator/shared/Options/TopCutAndFrameOptions.vue";
import IbisSpinner from "@/components/shared/IbisSpinner.vue";
import ProductFactory from "@/utility/ProductFactory";
import ProductSelect from "../../shared/ProductSelect";
import FamilyTypes from "@/models/Types/FamilyTypes";
import ProductTypes from "@/models/Types/ProductTypes";
import WorkshopProductSelectionTypes from "@/models/Types/WorkshopProductSelectionTypes";
import WorkshopModes from "@/models/Types/WorkshopModes";
import Measurements from "@/models/Types/Measurements";
import StretchingTypeInput from "./StretchingTypeInput.vue";
import ToolTip from "@/components/shared/ToolTip.vue";
export default {
  inject: ["$firebase"],
  props: [
    "availableProducts",
    "roundToTwo",
    "updatePrice",
    "heightValidation",
    "widthValidation",
    "selectAll",
    "syncConfiguration",
    "onWithBridgeInput",
    "onWithCrossInput",
    "initTrigger",
  ],
  components: {
    IbisSpinner,
    StretchingTypeInput,
    ProductSelect,
    NonConfigurableFreeSpaceDummy,
    TopCutAndFrameOptions,
    ProductRestrictions,
    ToolTip,
    //PositionPrice,
  },

  data() {
    return {
      isLoading: true,
      filteredProducts: [],
      allProducts: [],
      productTypes: ProductTypes,
      familyTypes: FamilyTypes,
      workshopProductSelectionTypes: WorkshopProductSelectionTypes,
    };
  },
  computed: {
    currentProduct: {
      get() {
        return this.$store.getters.currentWorkshopWork.carrier_material;
      },
      set(value) {
        this.$store.commit("switchWorkshopCarrierMaterial", value);
      },
    },
    currentWorkshopWork() {
      return this.$store.getters.currentWorkshopWork;
    },
    currentWorkshopMode() {
      return this.$store.getters.currentWorkshopMode;
    },
  },
  watch: {
    currentWorkshopWork: {
      handler: async function (newVal, oldVal) {
        if (newVal !== oldVal && !this.isLoading) {
          if (
            this.currentWorkshopMode ===
            WorkshopModes.LAMINATION_AND_SURFACE_FINISHING || this.currentWorkshopMode === WorkshopModes.LAMINATION_FRAMING
          ) {
            if (!this.currentProduct) {
              await this.initializeCarriers();
            }
          }
        }
      },
      deep: true,
    },
    initTrigger: {
      handler: async function () {
        if (this.initTrigger) {
          this.initializeCarriers();
        }
      },
    },
  },
  /*  async created() {
     await this.initializeCarriers();
   }, */
  methods: {
    changeCarrier(product) {
      this.currentProduct = product;
      product.webshop_id === 10011541 ? this.currentWorkshopWork.back_wall = undefined : null;
    },
    async initializeCarriers() {
      this.isLoading = true;
      this.filteredProducts = [];
      this.allProducts = [];
      const db = this.$firebase.firestore();
      const productsRef = db.collection("products");
      const productsPromises = this.availableProducts.map(async (e) => {
        const productRef = await productsRef
          .where("product_uid", "==", e)
          .get();
        productRef.forEach(async (doc) => {
          const product = await ProductFactory.fromJson(doc.data());
          this.allProducts.push(product);
        });
      });
      await Promise.all(productsPromises);
      this.allProducts.forEach((e) => {
        e.selected_measurement_type = Measurements.AUSSENMASS;
      });
      if (!this.currentProduct) {
        this.changeCarrier(this.allProducts[0] ? this.allProducts[0] : undefined);
      }
      this.filteredProducts = [...this.allProducts];
      this.isLoading = false;
      this.$emit("carrierInitialized");
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../../../assets/sass/style.scss";

.carrierInputWrapper {
  .mainHeadlineWrapper {
    width: 60vw;
    display: flex;
    align-items: center;
    gap: .5rem;

    @media (max-width: $mobile) {
      width: 95vw;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;

    .headline {
      text-align: left;
    }
  }
}

.sizeUnitWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 12.8rem;
  min-width: 1.5vw;
  margin-left: 0.5rem;

  span {
    height: fit-content;
  }
}
</style>
