const WorkshopModes = require("../WorkshopModes");

const AdditionalServices = [
    {
        key: "Heften_Rückseitig",
        value: "Rückseitig Heften",
        price: 0,
        pricePerWalkingMeters: true,
        supportedModes: [WorkshopModes.CANVAS_STRETCH],
    },
    {
        key: "Ausmessung_Bild",
        value: "Bild muss ausgemessen werden",
        price: 12.23,
        pricePerWalkingMeters: false,
    }, //preis pro stk
    {
        key: "Ausbau_Bild",
        value: "Bild muss ausgebaut werden",
        price: 8.15,
        pricePerWalkingMeters: true,
    }, //preis pro lfm
    {
        key: "Beschneidung_Bild",
        value: "Bild muss beschnitten werden",
        price: 12.25,
        pricePerWalkingMeters: false,
    }, //preis pro stk
    {
        key: "Einfaerbung_Kanten",
        value: "Kanten einfärben",
        price: 8.78,
        pricePerWalkingMeters: true,
        supportedModes: [WorkshopModes.CANVAS_STRETCH, WorkshopModes.LAMINATION_AND_SURFACE_FINISHING],

    }, //preis pro lfm
    
];
module.exports = AdditionalServices;
