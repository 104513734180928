<template>
  <div class="contactsPage" id="contactsPage">
    <div class="contactsHeader">Ihre Ansprechpartner bei IbiS</div>
    <div class="contactCards">
      <div v-for="(personData, index) in personDataArray" :key="index">
        <div class="contactCard">
          <ContactCard :personData="personData"></ContactCard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PersonDataArray from "../../models/Memojis/IbisMemojis";
import ContactCard from "./ContactCard.vue";
export default {
  components: { ContactCard },
  mounted() {
    document.getElementById("contactsPage").classList.add("fade-in-bottom");
  },
  data() {
    return {
      personDataArray: PersonDataArray,
    };
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";
.contactsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0rem;
  .contactsHeader {
    width: 90%;
    text-align: start;
    font-size: $font-size-xlarge;
  }
  .contactCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem; /* Adjust as needed for space between cards */
    width: 90%;
    margin-top: 3rem;
    @media (max-width: $mobile) {
      grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: $mobile) and (max-width: $tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
  }
}
</style>
