<template>
  <div class="profilePage" id="profilePage">
    <div class="welcomeHeader">
      Willkommen zurück, {{ buildIntroductionName() }}
    </div>
    <!-- <div class="subHeaders">
      <div class="personalDataHeader">Persönliche Daten</div>
      <div class="lastOrdersHeader">Letzte Bestellungen</div>
    </div> -->
    <div class="mainWidgets">
      <div class="personalInformationWrapper">
        <PersonalInformation />
        <div
          class="changePasswordLink"
          @click="this.$router.push('/passwort_aendern')"
        >
          <img src="../../assets/icons/locked-padlock-.svg" />
          <span>Passwort ändern</span>
        </div>
      </div>
      <div class="lastOrdersWrapper">
        <LastOrders />
      </div>
    </div>
  </div>
</template>
<script>
import PersonalInformation from "./PersonalInformation.vue";
import LastOrders from "./LastOrders.vue";

export default {
  components: {
    PersonalInformation,
    LastOrders,
  },
  mounted() {
    document.getElementById("profilePage").classList.add("fade-in-bottom");
  },
  computed: {
    userObject() {
      return JSON.parse(localStorage.getItem("ibisOnlinebestellungUserObject"));
    },
  },
  methods: {
    buildIntroductionName() {
      let fullName = this.userObject.name;
      let salutation = this.userObject.name.split(" ")[0];
      let lastName = fullName.substring(fullName.lastIndexOf(" ") + 1);
      return `${
        salutation === "Herr" ? "Herr" : salutation === "Frau" ? "Frau" : ""
      } ${lastName}`;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.profilePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 5rem;
  @media (max-width: $mobile) {
    padding: 1rem;
  }
  @media (min-width: $mobile) and (max-width: $tablet) {
    padding: 3rem 2rem;
  }
  .welcomeHeader {
    width: 100%;
    text-align: start;
    font-size: $font-size-xlarge;
  }

  /* .subHeaders {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5rem;
    .personalDataHeader {
      width: 50%;
      text-align: start;
      font-size: $font-size-large;
      max-width: 800px;
    }
    .lastOrdersHeader {
      width: 50%;
      text-align: start;
      font-size: $font-size-large;
    }
  } */
  .mainWidgets {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    align-items: flex-start;
    gap: 5rem;
    @media (min-width: $mobile) and (max-width: $tablet) {
      gap: 1rem;
    }
    @media (max-width: $mobile) {
      flex-flow: column;
      gap: 2rem;
      margin-top: 0;
    }
    .personalInformationWrapper {
      width: 50%;
      max-width: 800px;
      @media (max-width: $mobile) {
        width: 100%;
      }
    }
    .lastOrdersWrapper {
      width: 50%;
      max-width: 800px;
      @media (max-width: $mobile) {
        width: 100%;
      }
    }
    .changePasswordLink {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 2em;
      cursor: pointer;
      img {
        width: 1.5rem;
        margin-bottom: 3px;
      }
      span {
        font-size: $font-size-big;
        color: $ibis-red;
      }
    }
  }
}
</style>
