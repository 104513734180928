<template>
  <div class="passepartoutOptionsWrapper">

    <div class="secondRow">
      <div class="innenmassLabel">Bildmaß</div>
      <div class="topNumberInputs">
        <input type="number" :value="currentProduct.second_glas_picture_width"
          @blur="innerSecondGlasWidthValidation(currentProduct)"
          placeholder="Breite" id="innerSecondGlasWidthInput"
          @keydown.enter="this.$refs.innerHeightInput.focus()"
          @click="selectAll" step="0.1" />
        <span>x</span>
        <input type="number" :value="currentProduct.second_glas_picture_height"
          @blur="innerSecondGlasHeightValidation(currentProduct)"
          placeholder="Höhe" id="innerSecondGlaHeightInput"
          ref="innerHeightInput" @click="selectAll" step="0.1" />
      </div>
    </div>

    <div :class="'passepartoutPreviewWrapper' +
          (isWorkshopMode ? ' noWidthConstraint' : '')
          ">
      <SecondGlasPicturePositioningPreview :productProp="productProp" />
    </div>
  </div>
</template>
<script>
import ConfiguratorMode from "@/models/Types/ConfiguratorMode";
import SecondGlasPicturePositioningPreview from "../SecondGlasPicturePositioningPreview.vue";
export default {
  props: [
    "productProp",
    "updatePrice",
    "innerSecondGlasHeightValidation",
    "innerSecondGlasWidthValidation",
    "selectAll",
    "syncConfiguration",
    "onNoCutOutInput",
  ],
  components: {
    SecondGlasPicturePositioningPreview,
  },

  computed: {
    currentProduct() {
      return !this.productProp
        ? this.$store.getters.currentProduct
        : this.productProp;
    },
    isWorkshopMode() {
      return (
        this.$store.getters.currentConfiguratorMode ===
        ConfiguratorMode.WORKSHOP_WORKS
      );
    },
  },

};
</script>
<style scoped lang="scss">
@import "../../../../assets/sass/style.scss";

.passepartoutOptionsWrapper {
  .firstRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5em;

    .outerCutType {
      min-width: 50%;
    }

    .outerCutForm {
      min-width: 35%;
    }
  }

  .secondRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .innenmassLabel {
      font-size: $font-size-bigger;

      @media (max-width: $mobile) {
        font-size: $font-size-big;
      }
    }
  }

  .thirdRow {
    margin-top: 1.5em;
    margin-bottom: 2em;
    display: flex;
    justify-content: space-between;

    .innerCutType {
      min-width: 50%;
    }

    .innerCutForm {
      min-width: 35%;
    }
  }

  .fourthRow {
    display: flex;
    justify-content: flex-start;
  }

  .fifthRow {
    display: flex;
    justify-content: flex-start;
  }

  .sixthRow {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    align-items: center;
    gap: 1rem;
  }
}


.passepartoutPreviewWrapper {
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 33vw;

  @media (max-width: $mobile) {
    max-width: none;
    margin-left: 1.5rem;
  }
}

.checkboxContainer {
  z-index: 1;
  margin-left: 0.5em;
  display: flex;
  position: relative;
  padding-left: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background-color: $ibis-grey-middle;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked~.checkmark {
    background-color: $ibis-red;
    transition: 0.15s;

    &:after {
      display: block;
    }
  }

  .checkmark:after {
    left: 6.5px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

input {
  padding: 12px 10px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  max-width: 4vw;
  text-align: center;

  @media (max-width: $mobile) {
    min-width: 3rem;
  }
}

.topNumberInputs {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  font-size: $font-size-big;
  width: 40%;
  margin-top: 1rem;
}

.justify-end {
  justify-content: flex-end !important;
}

.invalid {
  border: 2px solid $ibis-red;
  color: $ibis-red;
}

.noWidthConstraint {
  max-width: unset;
  margin-left: 3.5vw;
}

select {
  padding: 12px 20px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("../../../../assets/icons/arrow_down.svg");
  background-repeat: no-repeat;
  background-position: calc(95%) center;
  padding-right: 2em;
  min-width: 40%;
}

select::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

select:focus {
  outline: none;
  border-color: $ibis-black;
}

.textFieldWrapper {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;

  .textField {
    border-radius: 25px;
    padding: 20px;
    width: 100%;
    resize: none;
    color: $ibis-black;
    border: 2px solid $ibis-grey-middle;
    //outline: 2px solid $ibis-black;
    outline: none;
    //font-family: "Raleway";

    &:focus {
      border: 2px solid $ibis-black;
    }
  }
}
</style>
