<template>
    <div class="productSelect">
        <select @change="setStretchingType()" v-model="selectedType">
            <option value="" disabled selected>{{
            currentWorkshopWorkStretchingType?.description }}</option>
            <option v-for="(value, key) in stretchingTypes" :value="value"
                :key="key">
                {{ value.description }}

            </option>
        </select>
    </div>
</template>
<script>
import UserTypes from '@/models/Types/UserTypes';
import UserTypeProvider from '@/utility/UserTypeProvider';
import CanvasStretchingTypes from '@/models/Types/CanvasStretchingTypes';
import CanvasStretchingTypes_BAUHAUS from '@/models/Types/BAUHAUS/CanvasStretchingTypes_BAUHAUS';
import CanvasStretchingTypes_XXXL from '@/models/Types/XXXL_GROUP/CanvasStretchingTypes_XXXL';
export default {

    props: [
        "updatePrice",
    ],
    data() {
        return {
            selectedType: "",
            stretchingTypes: []
        };
    },
    computed: {
        currentWorkshopWorkCarrierMaterial() {
            return this.$store.getters.currentWorkshopWork.carrier_material;
        },
        currentWorkshopWorkStretchingType() {
            return this.$store.getters.currentWorkshopWork.stretching_type;
        }
    },
    mounted() {
        this.getStretchingTypes();
    },
    methods: {
        getStretchingTypes() {
            const userType = UserTypeProvider.getUserType();

            switch (userType) {
                case UserTypes.XXXL_GROUP:
                    this.stretchingTypes = CanvasStretchingTypes_XXXL;
                    break;
                case UserTypes.BAUHAUS:
                    this.stretchingTypes = CanvasStretchingTypes_BAUHAUS;
                    break;
                default:
                    this.stretchingTypes = CanvasStretchingTypes;
                    break;
            }

        },
        setStretchingType() {
            this.$store.commit("setCurrentWorkshopWorkStretchingType", this.selectedType);
            this.updatePrice();
        }

    }
};
</script>
<style scoped lang="scss">
@import "../../../../../assets/sass/style.scss";

select {
    padding: 12px 20px;
    box-shadow: none;
    border: 2px solid $ibis-black;
    border-radius: 50px;
    color: $ibis-black !important;
    font-size: $font-size-big;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url("../../../../../assets/icons/arrow_down.svg");
    background-repeat: no-repeat;
    background-position: calc(97%) center;
    padding-right: 2em;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;

    @media (max-width: $mobile) {
        min-width: 100%;
    }
}

select::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
}

select:focus {
    outline: none;
    border-color: $ibis-black;
}
</style>