<template>
  <div class="topCutAndFrameOptions" id="topCutAndFrameOptions">
    <select v-model="currentProduct.selected_measurement_type" v-if="!currentProduct.passepartout_no_cut_out &&
      currentProduct.familyType !== familyTypes.PASSEPARTOUT &&
      currentProduct.familyType !== familyTypes.GLAS &&
      currentProduct.familyType !== familyTypes.STRETCHER_FRAME &&
      !currentProduct.is_back_wall && currentWorkshopMode !== workshopModes.CANVAS_FRAMING
      " @change="handleMeasurementChange">
      <option :value="measurements.FALZMASS" :selected="currentProduct.selected_measurement_type === measurements.FALZMASS
      ">
        {{ measurements.FALZMASS }}
      </option>
      <option :value="measurements.BILDMASS" :selected="currentProduct.selected_measurement_type === measurements.BILDMASS
      ">
        {{ measurements.BILDMASS }}
      </option>
      <option :value="measurements.LICHTMASS" :selected="currentProduct.selected_measurement_type === measurements.LICHTMASS
      " v-if="configuratorMode === configuratorModes.PRODUCT">
        {{ measurements.LICHTMASS }}
      </option>
      <option :value="measurements.AUSSENMASS" :selected="currentProduct.selected_measurement_type === measurements.AUSSENMASS
      " v-if="configuratorMode === configuratorModes.PRODUCT">
        {{ measurements.AUSSENMASS }}
      </option>
    </select>
    <div class="aussenmassLabel" v-else-if="currentProduct.familyType === familyTypes.PASSEPARTOUT ||
      currentProduct.familyType === familyTypes.GLAS ||
      currentProduct.is_back_wall
      ">
      Außenmaß
    </div>
    <div class="aussenmassLabel"
      v-else-if="currentProduct.familyType === familyTypes.STRETCHER_FRAME">
      Bildmaß
    </div>
    <div class="aussenmassLabel"
      v-else-if="currentWorkshopMode === workshopModes.CANVAS_FRAMING">
      Falzmaß
    </div>
    <div class="topNumberInputs">
      <input type="number" :value="currentProduct.width"
        @blur="widthValidation(currentProduct, identifier)" placeholder="Breite"
        :id="'widthInput' + (identifier ? identifier : '')" @click="selectAll"
        @keydown.enter="this.$refs.heightInput.focus()" step="0.1"
        :disabled="!isFrame" />
      <span>x</span>
      <input type="number" :value="currentProduct.height"
        @blur="heightValidation(currentProduct, identifier)" placeholder="Höhe"
        :disabled="currentProduct.selected_denomination_type === denominations.LEGS ||
      !isFrame
      " @keydown.enter="heightValidation(currentProduct)"
        :id="'heightInput' + (identifier ? identifier : '')" ref="heightInput"
        @click="selectAll" step="0.1" />
    </div>
  </div>
  <div class="folding_allowance"
    v-if="currentProduct.selected_measurement_type === measurements.FALZMASS && this.currentConfiguratorMode === configuratorModes.WORKSHOP_WORKS">
    <div class="infoIconWrapper">
      <ToolTip tipText="Die Falzzugabe ist die Differenz zwischen Bildmaß und
        Falzmaß. Wenn Sie im Falzmaß bestellen, sind die anderen Komponenten wie
        bspw. das Glas und die Rückwand exakt um die Falzzugabe kleiner als das
        Falzmaß. Besonders relevant ist die Falzzugabe bei Schattenfugenrahmen,
        weil Sie über die Falzzugabe die Breite der gewünschten Schattenfuge
        festlegen."></ToolTip>
    </div>
    <span>Falzzugabe</span>
    <input type="number" v-model="currentWorkshopWork.folding_allowance"
      @blur="onFoldingAllowanceChange" placeholder="Zugabe"
      :disabled="!isFrame" />
  </div>

  <div
    v-if="!currentProduct.no_hangers && isFrame && currentConfiguratorMode === configuratorModes.PRODUCT && currentProduct.familyType !== familyTypes.PASSEPARTOUT && !currentProduct.is_back_wall"
    style="text-align: start; font-weight: bold; margin-top: 2rem;">
    <label v-if="currentProduct.width > currentProduct.height"> Aufhänger
      Querformat</label>
    <label v-else> Aufhänger Hochformat</label>
  </div>
  <label
    v-if="isFrame && currentConfiguratorMode === configuratorModes.PRODUCT && currentProduct.familyType !== familyTypes.PASSEPARTOUT && !currentProduct.is_back_wall"
    class="checkboxContainer" style="margin-top: 2rem;">Ohne
    Aufhänger
    <input type="checkbox" v-model="currentProduct.no_hangers" />
    <span class="checkmark"></span> </label>

</template>
<script>
import ProductTypes from "@/models/Types/ProductTypes";
import FamilyTypes from "@/models/Types/FamilyTypes";
import Measurements from "@/models/Types/Measurements";
import Denominations from "@/models/Types/Denominations";
import ProductOptionsToSort from "@/models/Types/ProductOptionsToSort";
import ConfiguratorMode from "@/models/Types/ConfiguratorMode";
import WorkshopModes from "@/models/Types/WorkshopModes";
import ToolTip from "@/components/shared/ToolTip.vue";
export default {
  components: {
    ToolTip,
  },
  props: ["productProp", "updatePrice", "widthValidation", "heightValidation", "isFrameInput", "identifier"],
  data() {
    return {
      familyTypes: FamilyTypes,
      productTypes: ProductTypes,
      measurements: Measurements,
      denominations: Denominations,
      configuratorModes: ConfiguratorMode,
      workshopModes: WorkshopModes,
    };
  },
  computed: {
    configuratorMode() {
      return this.$store.getters.currentConfiguratorMode;
    },
    currentWorkshopWork() {
      return this.$store.getters.currentWorkshopWork;
    },
    currentWorkshopMode() {
      return this.$store.getters.currentWorkshopMode;
    },
    currentProduct() {
      return !this.productProp
        ? this.$store.getters.currentProduct
        : this.productProp;
    },
    currentConfiguratorMode() {
      return this.$store.getters.currentConfiguratorMode;
    },
    isFrame() {
      if (this.productProp) {
        if (this.currentWorkshopMode === WorkshopModes.CANVAS_FRAMING && !this.isFrameInput) {
          if (!this.currentWorkshopWork?.frame?.width || !this.currentWorkshopWork?.frame?.height) {
            return false;
          } else {
            return true;
          }
        }

        if (
          this.productProp.sub_category === ProductOptionsToSort.ALU_CUT ||
          this.productProp.sub_category === ProductOptionsToSort.WOOD_FRAME || this.productProp.sub_category === ProductOptionsToSort.CORNER_FRAME
        ) {
          return this.configuratorMode === ConfiguratorMode.WORKSHOP_WORKS
            ? true
            : false;
        }
        if (
          this.productProp.sub_category === ProductOptionsToSort.STRETCHER_CUT
        ) {
          return this.configuratorMode === ConfiguratorMode.WORKSHOP_WORKS
            ? true
            : false;
        }
        if (
          this.productProp.sub_category === ProductOptionsToSort.BACK_WALL_CUT
        ) {
          return this.currentWorkshopMode ===
            WorkshopModes.LAMINATION_AND_SURFACE_FINISHING
            ? true
            : false;
        }
      }
      return this.configuratorMode === ConfiguratorMode.WORKSHOP_WORKS
        ? false
        : true;
    },
  },
  methods: {
    handleMeasurementChange() {
      this.$emit("measurementChange");
      this.updatePrice();
    },
    onFoldingAllowanceChange() {

      if (this.currentWorkshopWork.folding_allowance < 0.2) {
        this.currentWorkshopWork.folding_allowance = 0.2;
      }

      //this.currentWorkshopWork.frame.folding_allowance = this.currentWorkshopWork.folding_allowance;
      this.handleMeasurementChange();
      this.updatePrice();
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../../assets/sass/style.scss";

.topCutAndFrameOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .aussenmassLabel {
    font-size: $font-size-bigger;

    @media (max-width: $mobile) {
      font-size: $font-size-big;
    }
  }

  .topNumberInputs {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    font-size: $font-size-big;
    width: 40%;
  }

}

.folding_allowance {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  span {
    font-weight: bold;
  }
}

select {
  padding: 12px 20px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("../../../../assets/icons/arrow_down.svg");
  background-repeat: no-repeat;
  background-position: calc(95%) center;
  padding-right: 2em;
  min-width: 40%;
}

select::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

select:focus {
  outline: none;
  border-color: $ibis-black;
}

input {
  padding: 12px 10px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  max-width: 4vw;
  text-align: center;

  @media (max-width: $mobile) {
    min-width: 3rem;
  }
}

.justify-end {
  justify-content: flex-end !important;
}

.invalid {
  border: 2px solid $ibis-red;
  color: $ibis-red;
}
</style>
