const InstallationTypes = [
  { key: "E", value: "Einbau Staubdicht", price: 22.82 },
  { key: "EE3308", value: "Einbau Standard", price: 17.72 }, //preis pro lfm
  { key: "EKR33081", value: "Einbau Keilrahmen", price: 10.70 }, //preis pro lfm
  {
    key: "DOPPEL_GLAS",
    value: "Einbau Doppelglaseinrahmung",
    price: 48.20,
  },
];
module.exports = InstallationTypes;
