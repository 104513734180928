<template>
  <div class="productRestrictions">
    <div>
      <span v-if="currentProduct.min_width && currentProduct.min_height">{{
        "Minimalmaß: " +
        currentProduct.min_width +
        "x" +
        currentProduct.min_height +
        " cm"
      }}</span>
    </div>
    <div>
      <span v-if="currentProduct.max_width && currentProduct.max_height">{{
        "Maximalmaß: " +
        (!isLaminationAndSurfaceFinishing
          ? currentProduct.max_height
          : laminationLowerMaxSize) +
        "x" +
        (!isLaminationAndSurfaceFinishing
          ? currentProduct.max_width
          : laminationHigherMaxSize) +
        " cm"
      }}</span>
    </div>
  </div>
</template>
<script>
import {
  LaminationLowerMaxSize,
  LaminationHigherMaxSize,
} from "@/models/Types/WorkshopSurfaceFinishings";
export default {
  props: ["productProp", "isLaminationAndSurfaceFinishing"],
  data() {
    return {
      laminationLowerMaxSize: LaminationLowerMaxSize,
      laminationHigherMaxSize: LaminationHigherMaxSize,
    };
  },
  computed: {
    currentProduct() {
      return !this.productProp
        ? this.$store.getters.currentProduct
        : this.productProp;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../../assets/sass/style.scss";
.productRestrictions {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    font-weight: bold;
    margin-top: 1rem;
    display: block;
    width: 100%;
  }
}
</style>
