const ProductCategories = {
  ALU: "Alu",
  GLAS: "Glas/Spiegel",
  TRADE_GOODS: "Handelswaren",
  WOOD: "Holz",
  STRETCHER_FRAME: "Keilrahmen",
  PASSEPARTOUT: "Passepartout",
  BACK_WALL: "Rückwand",
  MISCELLANEOUS: "Sonstiges",
  INTERCHANGABLE: "Wechselrahmen",
};

module.exports = ProductCategories;
