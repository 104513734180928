<template>
  <li class="file-item" @click="openInNewTab(file.url)">
    <a :href="file.url" @click.prevent download>{{ file.name }}</a>
  </li>
</template>

<script>
export default {
  props: ["file"],
  methods: {
    openInNewTab(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.file-item {
  list-style: none;
  text-align: start;
  background-color: $ibis-grey;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0px 5px 10px rgba(64, 64, 64, 0.2);
  min-width: 0;
  cursor: pointer;
  a {
    color: $ibis-black;
    text-decoration: none;
    transition: 0.15s;
    display: inline-block;
    max-width: 80%;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  &:hover {
    a {
      color: $ibis-red;
    }
  }
}
</style>
