<template>
  <button type="button" :class="buttonClass" :disabled="buttonDisabled">
    <span v-if="!isLoading">{{ buttonText }}</span>
    <Spinner v-else :color="spinnerColor" />
  </button>
</template>

<script>
import Spinner from "../shared/IbisSpinner.vue";

export default {
  components: { Spinner },
  props: ["buttonClass", "buttonText", "isLoading", "buttonDisabled"],
  computed: {
    spinnerColor() {
      return this.buttonClass === "primary" ? "white" : "#f22539";
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/sass/style.scss";

button {
  padding: 0.7em 1.2em;
  font-weight: bold;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.15s;
  &:disabled{
    opacity: 0.6;
    cursor: default;
  }
}

.primary {
  color: white;
  background-color: $ibis-red;
  font-size: $font-size-big;
  border-radius: 25px;
}

.secondary {
  color: $ibis-red;
  border: 2px solid $ibis-red;
  border-radius: 25px;
  background-color: white;
}
</style>
