const WorkshopModes = require("./Types/WorkshopModes");
const WorkshopWork = require("./WorkshopWork");
const UserTypes = require("./Types/UserTypes");
const CanvasStretchingTypes_BAUHAUS = require("./Types/BAUHAUS/CanvasStretchingTypes_BAUHAUS");
const CanvasStretchingTypes_XXXL = require("./Types/XXXL_GROUP/CanvasStretchingTypes_XXXL");
const CanvasStretchingTypes = require("./Types/CanvasStretchingTypes");
const { default: UserTypeProvider } = require("@/utility/UserTypeProvider");

class WorkshopLamination extends WorkshopWork {
  mode = WorkshopModes.LAMINATION_AND_SURFACE_FINISHING;
  carrier_material;
  hangings = [];
  surface_finishing;
  picture_value;
  damage;
  services = [];
  quantity = 1;
  notes = "";
  stretching_type;
  picture_has_to_be_cut_notes = "";
  edges_have_to_be_colored_notes = "";

  constructor(
    carrier_material,
    hangings,
    surface_finishing,
    picture_value,
    damage,
    services,
    quantity,
    notes,
    stretching_type,
    picture_has_to_be_cut_notes,
    edges_have_to_be_colored_notes,

  ) {
    super();
    this.carrier_material = carrier_material;
    this.hangings = hangings;
    this.surface_finishing = surface_finishing;
    this.picture_value = picture_value;
    this.damage = damage;
    services ? (this.services = services) : null;
    this.quantity = quantity ? quantity : 1;
    this.notes = notes;
    stretching_type ? this.stretching_type = stretching_type : this.assignDefaultStretchingType();
    this.picture_has_to_be_cut_notes = picture_has_to_be_cut_notes
      ? picture_has_to_be_cut_notes
      : "";
    this.edges_have_to_be_colored_notes = edges_have_to_be_colored_notes ? edges_have_to_be_colored_notes : "";
  }
  assignDefaultStretchingType() {
    const userType = UserTypeProvider.getUserType();
    switch (userType) {
      case UserTypes.XXXL_GROUP:
        this.stretching_type = CanvasStretchingTypes_XXXL.find(e => e.key === "DEFAULT");
        break;
      case UserTypes.BAUHAUS:
        this.stretching_type = CanvasStretchingTypes_BAUHAUS.find(e => e.key === "DEFAULT");
        break;
      default:
        this.stretching_type = CanvasStretchingTypes.find(e => e.key === "DEFAULT");
        break;
    }
  }
}
module.exports = WorkshopLamination;
