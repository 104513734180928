<template>
  <div class="adminNewsBanner">
    <div class="headline">
      <h2>News-Banner</h2>
    </div>
    <div class="userInput">
      <input v-model="newMessage" placeholder="News eingeben" required />
      <IbisButton
        buttonClass="primary"
        buttonText="Aktualisieren"
        @click="updateNewsBanner"
        :isLoading="isUpdateNewsBannerLoading"
      ></IbisButton>
    </div>
    <div class="linkInstructions">
      Links in folgendem Format angeben: https://www.beispiel.de(Text der für
      den Link angezeigt wird)
    </div>
    <div class="status">
      <div class="currentStatus">
        Status: <span>{{ isBannerVisible ? "Sichtbar" : "Unsichtbar" }}</span>
      </div>
      <div class="toggleButton">
        <IbisButton
          buttonClass="secondary"
          buttonText="Sichtbarkeit umschalten"
          @click="toggleBannerVisibility"
          :isLoading="isUpdateNewsBannerVisibilityLoading"
        ></IbisButton>
      </div>
    </div>
  </div>
</template>

<script>
import IbisButton from "@/components/shared/IbisButton.vue";
import ToastTypes from "@/models/Types/ToastTypes";
import BackendHandler from "@/utility/BackendHandler";

export default {
  name: "AdminNewsBanner",
  inject: ["$firebase"],

  data() {
    return {
      newMessage: "",
      isUpdateNewsBannerLoading: false,
      isUpdateNewsBannerVisibilityLoading: false,
      isBannerVisible: false, // Add this line
    };
  },
  mounted() {
    this.fetchCurrentNewsBanner();
    this.fetchCurrentNewsBannerVisibility();
  },
  methods: {
    showToastMessage(toastMessage, type) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
    },
    async updateNewsBanner() {
      if (this.newMessage.trim()) {
        try {
          this.isUpdateNewsBannerLoading = true;

          await BackendHandler.updateNewsBanner(this.newMessage);
          this.showToastMessage(
            "Der News-Banner wurde erfolgreich aktualisiert.",
            ToastTypes.SUCCESS
          );
        } catch (e) {
          this.showToastMessage(
            "Beim aktualisieren des News-Banners ist etwas schiefgelaufen.",
            ToastTypes.ERROR
          );
        } finally {
          this.isUpdateNewsBannerLoading = false;
        }
      }
    },
    async toggleBannerVisibility() {
      try {
        this.isUpdateNewsBannerVisibilityLoading = true; // Reuse the loading state or create a new one for this action
        const response = await BackendHandler.toggleNewsBannerVisibility(); // Assume this method exists and toggles the visibility
        this.isBannerVisible = response.data.isVisible; // Update based on the response from your backend
        this.showToastMessage(
          `Der News-Banner wurde erfolgreich ${
            this.isBannerVisible ? "aktiviert" : "deaktiviert"
          }.`,
          ToastTypes.SUCCESS
        );
      } catch (e) {
        console.log(e);
        this.showToastMessage(
          "Beim Ändern der Sichtbarkeit des News-Banners ist etwas schiefgelaufen.",
          ToastTypes.ERROR
        );
      } finally {
        this.isUpdateNewsBannerVisibilityLoading = false;
      }
    },
    async fetchCurrentNewsBanner() {
      try {
        const snapshot = await this.$firebase
          .database()
          .ref("currentNewsBanner")
          .once("value");
        const currentNewsBanner = snapshot.val();
        if (currentNewsBanner !== null) {
          // Check if the currentNewsBanner exists
          this.newMessage = currentNewsBanner; // Assuming the value is directly the message
        } else {
          console.log("No current news banner message found.");
        }
      } catch (e) {
        console.error("Error fetching current news banner:", e);
        this.showToastMessage(
          "Beim Laden des aktuellen News-Banners ist ein Fehler aufgetreten.",
          ToastTypes.ERROR
        );
      }
    },
    async fetchCurrentNewsBannerVisibility() {
      try {
        const snapshot = await this.$firebase
          .database()
          .ref("showNewsBanner")
          .once("value");
        const showNewsBanner = snapshot.val();
        if (showNewsBanner !== null) {
          // Check if the currentNewsBanner exists
          this.isBannerVisible = showNewsBanner; // Assuming the value is directly the message
        } else {
          console.log("No current news banner visibility prop found.");
        }
      } catch (e) {
        console.error("Error fetching current news banner visibility:", e);
        this.showToastMessage(
          "Beim Laden des aktuellen Sichtbarkeitsstatus des News-Banners ist ein Fehler aufgetreten.",
          ToastTypes.ERROR
        );
      }
    },
  },
  components: { IbisButton },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/sass/style.scss";

.adminNewsBanner {
  /* Styles for your admin panel */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: $mobile) {
    padding: 5%;
  }
  margin-bottom: 3rem;
  .headline {
    text-align: start;
    width: 100%;
  }
  .userInput {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 1rem;
    }
    input {
      padding: 12px 10px;
      box-shadow: none;
      border: 2px solid $ibis-black;
      border-radius: 50px;
      color: $ibis-black !important;
      font-size: $font-size-big;
      text-align: center;
      width: 85%;
      box-sizing: border-box;
      @media (max-width: $mobile) {
        min-width: 3rem;
        width: 100%;
      }
    }
  }
  .linkInstructions{
    text-align: start;
    width: 100%;
    margin-top: 1rem;
  }
  .status {
    display: flex;
    width: 100%;
    align-items: center;
    font-weight: bold;
    gap: 2rem;
    margin: 2rem 0;
    span {
      color: $ibis-red;
    }
  }
}
</style>
