<template>
  <div class="registrationScreenWrapper">
    <div class="leftHalf" id="leftHalf">
      <div class="registrationForm">
        <img src="../../assets/images/IbiS_logo.png" v-if="!isDesktop && !isTablet" />

        <span class="headline"> Registrieren </span>

        <div class="nameInputWrapper">
          <select name="Anrede" v-model="salutation">
            <option value="Herr" selected>Herr</option>
            <option value="Frau">Frau</option>
          </select>
          <input
            type="text"
            placeholder="Vor- & Nachname"
            v-model="name"
            id="nameInput"
            @input="validateName()"
          />
          <span>*</span>
        </div>
        <div class="emailInputWrapper">
          <input
            type="text"
            placeholder="E-Mail"
            v-model="email"
            id="emailInput"
            @input="validateEmail()"
          />
          <span>*</span>
        </div>
        <div class="companyNameInputWrapper">
          <input
            type="text"
            placeholder="Firma"
            v-model="companyName"
            id="companyNameInput"
            @input="validateCompanyName()"
          />
          <span>*</span>
        </div>
        <input
          type="text"
          placeholder="Telefon"
          v-model="phone"
          id="phoneInput"
          @input="onlyNumbersPhone()"
        />
        <input
          type="text"
          placeholder="Kundenummer"
          v-model="customerId"
          id="customerIdInput"
          @input="onlyNumbersCustomerId()"
        />
        <input
          type="text"
          placeholder="Straße und Hausnummer"
          v-model="address"
          id="addressInput"
        />
        <div class="zipAndLocationInputs">
          <input
            type="text"
            placeholder="PLZ"
            v-model="zip"
            id="zipInput"
            @input="onlyNumbersZIP()"
          />
          <input
            type="text"
            placeholder="Ort"
            v-model="addressPlace"
            id="locationInput"
          />
        </div>
        <input
          type="text"
          placeholder="Land"
          v-model="country"
          id="countryInput"
        />

        <div class="registrationButtonWrapper">
          <IbisButton
            buttonClass="primary"
            buttonText="Registrierung beantragen"
            :isLoading="isLoading"
            @click="registerUser()"
          ></IbisButton>
        </div>

        <div class="existingAccountWrapper">
          <span>Bereits registriert? </span>
          <span class="loginLink" @click="this.$router.push('/login')"
            >Login</span
          >
        </div>
      </div>
    </div>
    <div class="rightHalf" id="rightHalf" v-if="isDesktop">
      <img src="../../assets/images/IbiS_logo.png" />
      <span>Fachhändlershop</span>
    </div>
  </div>
</template>
<script>
import IbisButton from "../shared/IbisButton.vue";
import BackendHandler from "@/utility/BackendHandler";
import ToastTypes from "@/models/Types/ToastTypes";
export default {
  components: {
    IbisButton,
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isTablet() {
      return this.$store.getters.isTablet;
    },
    isDesktop() {
      return this.$store.getters.isDesktop;
    },
  },
  data() {
    return {
      salutation: "Herr",
      name: "",
      familyName: "",
      email: "",
      phone: undefined,
      customerId: undefined,
      address: "",
      zip: "",
      addressPlace: "",
      country: "",
      companyName: "",
      showToast: false,
      toastTypes: ToastTypes,
      isLoading: false,
    };
  },
  mounted() {
    document.getElementById("leftHalf").classList.add("fade-in-bottom");
    this.isDesktop
      ? document.getElementById("rightHalf").classList.add("fade-in-bottom")
      : null;
  },
  methods: {
    showToastMessage(toastMessage, type, inputId) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
      if (type === this.toastTypes.ERROR && inputId) {
        document.getElementById(inputId).classList.add("invalid");
        setTimeout(() => {
          document.getElementById(inputId).classList.remove("invalid");
        }, 3000);
      }
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 5000);
    },
    validateForm() {
      let isValid = true;
      if (!this.name) {
        this.showToastMessage(
          "Name darf nicht leer sein.",
          this.toastTypes.ERROR,
          "nameInput"
        );
        isValid = false;
      } else if (!/^[\p{L}]+\s[\p{L}]+$/u.test(this.name)) {
        if (!this.showToast) {
          this.showToastMessage(
            "Bitte geben sie Vor- und Nachnamen an.",
            this.toastTypes.ERROR,
            "nameInput"
          );
          isValid = false;
        }
      } else {
        document.getElementById("nameInput").classList.remove("invalid");
      }

      if (!this.email) {
        if (!this.showToast) {
          this.showToastMessage(
            "E-Mail darf nicht leer sein.",
            this.toastTypes.ERROR,
            "emailInput"
          );
        }

        isValid = false;
      } else if (!/\S+@\S+\.\S+/.test(this.email)) {
        if (!this.showToast) {
          this.showToastMessage(
            "E-Mail ist ungültig",
            this.toastTypes.ERROR,
            "emailInput"
          );
        }

        isValid = false;
      } else {
        document.getElementById("emailInput").classList.remove("invalid");
      }

      if (!this.companyName) {
        if (!this.showToast) {
          this.showToastMessage(
            "Firma darf nicht leer sein.",
            this.toastTypes.ERROR,
            "companyNameInput"
          );
        }
        isValid = false;
      } else {
        document.getElementById("companyNameInput").classList.remove("invalid");
      }

      return isValid;
    },

    validateName() {
      if (!this.name || !/^[\p{L}]+\s[\p{L}]+$/u.test(this.name)) {
        document.getElementById("nameInput").classList.add("invalid");
      } else {
        document.getElementById("nameInput").classList.remove("invalid");
      }
    },

    validateEmail() {
      if (!this.email) {
        document.getElementById("emailInput").classList.add("invalid");
      } else if (!/\S+@\S+\.\S+/.test(this.email)) {
        document.getElementById("emailInput").classList.add("invalid");
      } else {
        document.getElementById("emailInput").classList.remove("invalid");
      }
    },

    validateCompanyName() {
      if (!this.companyName) {
        document.getElementById("companyNameInput").classList.add("invalid");
      } else {
        document.getElementById("companyNameInput").classList.remove("invalid");
      }
    },

    async registerUser() {
      this.isLoading = true;
      if (this.validateForm()) {
        try {
          await BackendHandler.requestRegistration(
            this.salutation + " " + this.name,
            this.email,
            this.phone,
            this.customerId,
            this.address,
            this.zip,
            this.addressPlace,
            this.country,
            this.companyName
          );
          this.isLoading = false;
          if (!this.showToast) {
            this.showToastMessage(
              "Ihr Antrag auf Registrierung wurde übermittelt. Wir melden uns in Kürze.",
              this.toastTypes.SUCCESS
            );
          }
          this.$router.push("/");
        } catch (e) {
          this.isLoading = false;
          if (!this.showToast) {
            this.showToastMessage(e.message, this.toastTypes.ERROR);
          }
        }
      }
      this.isLoading = false;
    },
    onlyNumbersPhone() {
      this.phone = this.phone.replace(/\D/g, "");
    },
    onlyNumbersCustomerId() {
      this.customerId = this.customerId.replace(/\D/g, "");
    },
    onlyNumbersZIP() {
      this.zip = this.zip.replace(/\D/g, "");
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.registrationScreenWrapper {
  width: 100vw;
  display: flex;
  align-items: center;
  padding-top: 3rem;
  @media (max-width: $mobile) {
    padding-top: 0;
  }
  .leftHalf {
    padding-left: 8em;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $mobile) {
      padding-left: 0em;
      display: block;
      width: 100%;
      padding: 2rem;
      padding-top: 0;
    }
    @media (min-width: $mobile) and (max-width: $tablet) {
      display: flex;
      width: 100%;
      padding: 2rem;
    }
    .registrationForm {
      width: 60%;
      max-width: 400px;
      box-shadow: 0px 8px 37px rgba(64, 64, 64, 0.1);
      border-radius: 25px;
      background-color: white;
      border: solid 2px $ibis-grey;
      padding: 2rem 4rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5em;

      @media (max-width: $mobile) {
        padding-left: 0em;
        width: 100%;
        box-shadow: none;
        border: none;
        padding: 0;
        max-width: none;
      }

      @media (min-width: $mobile) and (max-width: $tablet) {
        padding-left: 0em;
        border: none;
        width: 100%;
        max-width: none;
        padding: 0;
        max-width: 50vw;
        box-shadow: none;
      }

      img {
        width: 10rem;
        margin-top: 3rem;
        margin-bottom: 1rem;
      }
      .headline {
        font-size: $font-size-xlarge;
        font-weight: bold;
      }
      .nameInputWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        position: relative;
        select {
          padding: 12px 20px;
          box-shadow: none;
          border: 2px solid $ibis-black;
          border-radius: 50px;
          color: $ibis-black !important;
          font-size: $font-size-big;
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          background-image: url("../../assets/icons/arrow_down.svg");
          background-repeat: no-repeat;
          background-position: calc(95%) center;
          padding-right: 2em;
          min-width: 32%;
        }

        select::-webkit-scrollbar {
          width: 0.5em;
          height: 0.5em;
        }
        select:focus {
          outline: none;
          border-color: $ibis-black;
        }

        span {
          position: absolute;
          font-size: $font-size-xlarge;
          right: -10px;
          top: -5px;
          color: $ibis-red;
        }
      }
      .emailInputWrapper {
        position: relative;
        width: 100%;
        span {
          position: absolute;
          font-size: $font-size-xlarge;
          right: -10px;
          top: -5px;
          color: $ibis-red;
        }
      }
      .companyNameInputWrapper {
        position: relative;
        width: 100%;
        span {
          position: absolute;
          font-size: $font-size-xlarge;
          right: -10px;
          top: -5px;
          color: $ibis-red;
        }
      }
      .zipAndLocationInputs {
        display: flex;
        justify-content: space-between;

        #zipInput {
          width: 35%;
        }
        #locationInput {
          width: 60%;
        }
      }
      input {
        width: 100%;
        text-align: start;
        font-size: $font-size-big;
        padding-left: 1.5em;
        box-sizing: border-box;
      }
      .registrationButtonWrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 1em;
        margin-bottom: 1em;
      }
      .existingAccountWrapper {
        display: flex;
        flex-direction: row;
        gap: 0.5em;
        .loginLink {
          color: $ibis-red;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .rightHalf {
    padding-right: 8em;
    width: 50%;
    height: 100%;
    display: flex;
    gap: 2em;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      max-width: 20rem;
    }
    span {
      font-size: $font-size-large;
      font-weight: bold;
    }
  }
}
input {
  padding: 12px 10px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  //font-size: $font-size-big;
  text-align: center;
}

.invalid {
  border: 2px solid $ibis-red;
  color: $ibis-red;
}
</style>
