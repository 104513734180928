<template>
  <div class="codeListWrapper">
    <div class="columns">
      <div style="width: 20%">Code</div>
      <div style="width: 25%">von</div>
      <div style="width: 25%">bis</div>
      <div style="width: 20%">Rabatt</div>
      <div style="width: 20%">Einmalig</div>
    </div>
    <div class="scrollWrapper">
      <div class="listWrapper" v-if="!isLoading">
        <div v-for="(code, index) in list" :key="index">
          <div class="columnLine">
            <div style="width: 20%">{{ code.code }}</div>
            <div style="width: 25%">
              {{ formatToGermanDateTime(code.validFrom) }}
            </div>
            <div style="width: 25%">
              {{ formatToGermanDateTime(code.validUntil) }}
            </div>
            <div style="width: 20%">
              {{
                code.discountPercentage
                  ? code.discountPercentage * 100 + "%"
                  : code.absoluteDiscount + "€"
              }}
            </div>
            <div
              style="
                width: 20%;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              {{
                code.singleUse
                  ? "Ja (Gesamt)"
                  : code.singleUsePerUser
                  ? "Ja (Nutzer)"
                  : "Nein"
              }}
              <div
                v-if="isActiveList"
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="onDisableClick(code.code)"
                >
                  <path
                    d="M2 5C2 4.05719 2 3.58579 2.29289 3.29289C2.58579 3 3.05719 3 4 3H20C20.9428 3 21.4142 3 21.7071 3.29289C22 3.58579 22 4.05719 22 5C22 5.94281 22 6.41421 21.7071 6.70711C21.4142 7 20.9428 7 20 7H4C3.05719 7 2.58579 7 2.29289 6.70711C2 6.41421 2 5.94281 2 5Z"
                  />
                  <path
                    d="M20.0689 8.49993C20.2101 8.49999 20.3551 8.50005 20.5 8.49805V12.9999C20.5 16.7711 20.5 18.6568 19.3284 19.8283C18.1569 20.9999 16.2712 20.9999 12.5 20.9999H11.5C7.72876 20.9999 5.84315 20.9999 4.67157 19.8283C3.5 18.6568 3.5 16.7711 3.5 12.9999V8.49805C3.64488 8.50005 3.78999 8.49999 3.93114 8.49993L11.25 8.49992L11.25 15.0454L9.55748 13.1648C9.28038 12.8569 8.80617 12.832 8.49828 13.1091C8.1904 13.3862 8.16544 13.8604 8.44254 14.1683L11.4425 17.5016C11.5848 17.6596 11.7874 17.7499 12 17.7499C12.2126 17.7499 12.4152 17.6596 12.5575 17.5016L15.5575 14.1683C15.8346 13.8604 15.8096 13.3862 15.5017 13.1091C15.1938 12.832 14.7196 12.8569 14.4425 13.1648L12.75 15.0454L12.75 8.49992L20.0689 8.49993Z"
                  />
                </svg>
              </div>
              <div
                v-else
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  class="restoreIcon"
                  v-if="checkIfDateInFuture(code.validUntil)"
                  @click="onRestoreClick(code.code)"
                >
                  <path
                    d="M4.52185 7H7C7.55229 7 8 7.44772 8 8C8 8.55229 7.55228 9 7 9H3C1.89543 9 1 8.10457 1 7V3C1 2.44772 1.44772 2 2 2C2.55228 2 3 2.44772 3 3V5.6754C4.26953 3.8688 6.06062 2.47676 8.14852 1.69631C10.6633 0.756291 13.435 0.768419 15.9415 1.73041C18.448 2.69239 20.5161 4.53782 21.7562 6.91897C22.9963 9.30013 23.3228 12.0526 22.6741 14.6578C22.0254 17.263 20.4464 19.541 18.2345 21.0626C16.0226 22.5842 13.3306 23.2444 10.6657 22.9188C8.00083 22.5931 5.54702 21.3041 3.76664 19.2946C2.20818 17.5356 1.25993 15.3309 1.04625 13.0078C0.995657 12.4579 1.45216 12.0088 2.00445 12.0084C2.55673 12.0079 3.00351 12.4566 3.06526 13.0055C3.27138 14.8374 4.03712 16.5706 5.27027 17.9625C6.7255 19.605 8.73118 20.6586 10.9094 20.9247C13.0876 21.1909 15.288 20.6513 17.0959 19.4075C18.9039 18.1638 20.1945 16.3018 20.7247 14.1724C21.2549 12.043 20.9881 9.79319 19.9745 7.8469C18.9608 5.90061 17.2704 4.3922 15.2217 3.6059C13.173 2.8196 10.9074 2.80968 8.8519 3.57803C7.11008 4.22911 5.62099 5.40094 4.57993 6.92229C4.56156 6.94914 4.54217 6.97505 4.52185 7Z"
                  />
                </svg>
                <svg
                  viewBox="0 0 25 25"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="onDeleteClick(code.code)"
                >
                  <g clip-path="url(#clip0_1_13)">
                    <path
                      d="M12.0271 0H12.027C5.38467 0 0 5.38467 0 12.027V12.0271C0 18.6694 5.38467 24.0541 12.027 24.0541H12.0271C18.6694 24.0541 24.0541 18.6694 24.0541 12.0271V12.027C24.0541 5.38467 18.6694 0 12.0271 0Z"
                    />
                    <path
                      d="M8.78786 7L17.7271 15.9392L15.9392 17.7271L7 8.78786L8.78786 7Z"
                      fill="#F5F5F5"
                    />
                    <path
                      d="M15.9392 7L7 15.9392L8.78786 17.7271L17.7271 8.78786L15.9392 7Z"
                      fill="#F5F5F5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_13">
                      <rect width="25" height="25" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="spinnerWrapper" v-else>
        <IbisSpinner></IbisSpinner>
      </div>
    </div>
  </div>
</template>
<script>
import IbisSpinner from "@/components/shared/IbisSpinner.vue";
import BackendHandler from "@/utility/BackendHandler";

export default {
  props: ["list", "isLoading", "isActiveList"],
  methods: {
    formatToGermanDateTime(dateObj) {
      // Check if the input is a Firebase Timestamp object
      if (dateObj && dateObj._seconds !== undefined) {
        // Convert Firebase Timestamp to JavaScript Date object
        const date = new Date(
          dateObj._seconds * 1000 + dateObj._nanoseconds / 1000000
        );
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        return `${day}.${month}.${year} ${hours}:${minutes}`;
      } else {
        // Handle other date formats or invalid input
        return "Invalid Date";
      }
    },
    async onDisableClick(code) {
      await BackendHandler.disableDiscountCode(code);
      this.$emit("listChange");
    },
    async onRestoreClick(code) {
      await BackendHandler.reactivateDiscountCode(code);
      this.$emit("listChange");
    },
    async onDeleteClick(code) {
      await BackendHandler.deleteDiscountCode(code);
      this.$emit("listChange");
    },
    checkIfDateInFuture(dateObj) {
      if (dateObj && dateObj._seconds !== undefined) {
        // Convert Firebase Timestamp to JavaScript Date object
        const date = new Date(
          dateObj._seconds * 1000 + dateObj._nanoseconds / 1000000
        );
        const now = new Date();
        return date > now;
      } else {
        // Handle other date formats or invalid input
        return false;
      }
    },
  },
  components: { IbisSpinner },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/sass/style.scss";

.codeListWrapper {
  height: 100%;
  .columns {
    display: flex;
    div {
      font-weight: bold;
    }
  }
  .scrollWrapper {
    overflow: hidden;
    height: 100%;
    overflow-y: scroll;

    .listWrapper {
      .columnLine {
        display: flex;
        margin-top: 1rem;
        @media (max-width: $mobile) {
          font-size: 12px;
        }
        .restoreIcon {
          margin-right: 0.5rem;
          @media (max-width: $mobile) {
            margin-right: 0.5rem;
          }
        }
        svg {
          height: 1rem;
          width: 1rem;
          fill: $ibis-black; // default fill color
          margin-right: 1.5rem;
          @media (max-width: $mobile) {
            margin-right: 0.5rem;
          }
          &:hover {
            fill: $ibis-red; // change fill color on hover
            transition: fill 0.15s; // apply transition to the fill property
            cursor: pointer;
          }
        }
      }
    }
  }
  .spinnerWrapper {
    margin-top: 2rem;
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
