<template>
  <div id="heroProductSearch">
    <HeroProductSearch />
    <SelectedConfigurationsPreview></SelectedConfigurationsPreview>
  </div>
</template>

<script>
import SelectedConfigurationsPreview from "./SelectedConfigurationsPreview.vue";
import HeroProductSearch from "./HeroProductSearch.vue";
export default {
  components: {
    HeroProductSearch,
    SelectedConfigurationsPreview,
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";


</style>
