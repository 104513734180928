<template>
  <div class="surfaceFinishingInputWrapper">
    <div class="mainHeadlineWrapper">
      <h2 class="headline">Oberflächenveredelung</h2>
    </div>
    <label class="checkboxContainer"
      style="margin-bottom: 1rem; margin-top: 2rem">
      Keine Oberflächenveredelung
      <input id="checkbox-none" type="radio" v-model="selectedSurfaceFinishing"
        :value="undefined" @change="setWorkshopSurfaceFinishing" />
      <span class="checkmark"></span>
    </label>
    <label class="checkboxContainer"
      v-for="(type, index) in surfaceFinishingTypes" :key="type.key"
      style="margin-bottom: 1rem; margin-top: 2rem">
      {{ type.value }}
      <input :id="`checkbox-${index}`" type="radio"
        v-model="selectedSurfaceFinishing" :value="type"
        @change="setWorkshopSurfaceFinishing" />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
import { WorkshopSurfaceFinishings } from "@/models/Types/WorkshopSurfaceFinishings";
import { WorkshopSurfaceFinishings_BAUHAUS } from "@/models/Types/BAUHAUS/WorkshopSurfaceFinishings_BAUHAUS"
import { WorkshopSurfaceFinishings_XXXL } from "@/models/Types/XXXL_GROUP/WorkshopSurfaceFinishings_XXXL"
import UserTypeProvider from "@/utility/UserTypeProvider";
import UserTypes from "@/models/Types/UserTypes";
export default {
  props: ["syncConfiguration"],
  
  data() {
    return {
      // Access the first element of the imported array which contains the surface finishings
      surfaceFinishingTypes: UserTypeProvider.getUserType() === UserTypes.RETAILER ? WorkshopSurfaceFinishings : UserTypeProvider.getUserType() === UserTypes.BAUHAUS ? WorkshopSurfaceFinishings_BAUHAUS : WorkshopSurfaceFinishings_XXXL,
      selectedSurfaceFinishing: undefined,
    };
  },
  created() {
    this.matchSurfaceFinishingToWorkshop();
  },
  watch: {
    workshopWorksurfaceFinishings: {
      handler(newVal) {
        if (newVal !== this.selectedSurfaceFinishing) {
          this.matchSurfaceFinishingToWorkshop();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    matchSurfaceFinishingToWorkshop() {
      const storedFinishing = this.workshopWorksurfaceFinishings;
      // Only proceed if storedFinishing is not undefined
      if (storedFinishing) {
        // Find the matching finishing in the surfaceFinishingTypes array
        const matchingFinishing = this.surfaceFinishingTypes.find(
          (type) => type.key === storedFinishing.key
        );
        this.selectedSurfaceFinishing = matchingFinishing || undefined;
      } else {
        // If storedFinishing is undefined, no surface finishing is selected
        this.selectedSurfaceFinishing = undefined;
      }
    },
    setWorkshopSurfaceFinishing() {
      // Update the workshop surface finishings
      this.workshopWorksurfaceFinishings = this.selectedSurfaceFinishing;
      // Call syncConfiguration to handle offline sync
      this.syncConfiguration();
      this.$emit("surfaceFinishingSelected")
    },
  },
  computed: {
    workshopWorksurfaceFinishings: {
      get() {
        // Get the current value from the store
        return this.$store.getters.currentWorkshopWork.surface_finishing;
      },
      set(value) {
        // Commit the new value to the store
        this.$store.commit("setWorkshopSurfaceFinishing", value);
      },
    },
  },
};
</script>

<style lang="scss">
@import "../../../../../assets/sass/style.scss";

.surfaceFinishingInputWrapper {
  .mainHeadlineWrapper {
    @media (max-width: $mobile) {
      width: 95vw;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;

    .headline {
      margin-bottom: 0;

      text-align: left;
    }
  }
}

.checkboxContainer {
  z-index: 1;
  display: flex;
  position: relative;
  padding-left: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 0;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background-color: $ibis-grey-middle;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked~.checkmark {
    background-color: $ibis-red;
    transition: 0.15s;

    &:after {
      display: block;
    }
  }

  .checkmark:after {
    left: 6.5px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
</style>
