<template>
    <div class="manualServicesInputWrapper">
        <div class="manualButton" v-if="!isInputOpen" @click="toggleInput()">
            <span>zusätzliche DL hinzufügen </span> <span
                style="font-size: 2rem; margin-bottom: 0.3rem;">+</span>
        </div>
        <div class="manualInput" v-if="isInputOpen">
            <div class="manualInputs">
                <input class="position" type="text" placeholder="Komponente"
                    v-model="positionDescription" />
                <input class="price" type="number" placeholder="Preis"
                    v-model.number="price" />
            </div>
            <span class="confirm" :class="{ disabled: !isInputValid }"
                @click="handleAddService()">
                <img src="../../../../../assets/icons/success_icon_contact.svg">
            </span>
            <span class="abort"><img src="../../../../../assets/icons/close.svg"
                    @click="toggleInput()"></span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isInputOpen: false,
            positionDescription: "",
            price: undefined
        }
    },
    computed: {
        isInputValid() {
            return this.positionDescription.length > 0 && this.price > 0;
        }
    },
    methods: {
        toggleInput() {
            this.isInputOpen = !this.isInputOpen;
            if (!this.isInputOpen) {
                this.resetInputs();
            }
        },
        handleAddService() {
            if (this.isInputValid) {
                this.addService();
                this.toggleInput();
                this.resetInputs();
            }
        },
        addService() {
            const payload = {
                key: this.positionDescription,
                value: this.positionDescription,
                price: this.price,
                isManual: true,
                checked: true,
            };
            this.$emit("addService", payload);
        },
        resetInputs() {
            this.positionDescription = "";
            this.price = undefined;
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../../../../assets/sass/style.scss";

.manualServicesInputWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    gap: 1rem;
    width: 100%;
    cursor: pointer;

    .manualButton {
        span {
            color: $ibis-red
        }
    }

    .manualInput {
        display: flex;
        align-self: center;
        gap: 1rem;
        width: 100%;

        .manualInputs {
            display: flex;
            gap: 0.5rem;
            width: 80%;

            .position {
                width: 70%;
            }

            .price {
                width: 30%;
            }

            input {
                padding: 0.75rem;
                border-radius: 25px;
                border: 1px solid $ibis-grey-middle;
            }
        }

        span {
            display: flex;
            align-items: center;
        }

        .confirm {
            img {
                cursor: pointer;
                height: 1.5rem;
            }
        }

        .confirm.disabled img {
            cursor: not-allowed;
            opacity: 0.5;
        }

        .abort {
            img {
                cursor: pointer;
                height: 1rem;
            }
        }
    }
}
</style>
