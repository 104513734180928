<template>
  <div class="news-banner" v-if="isVisible">
    <!-- Update this part to use v-html for rendering processed message -->
    <p v-html="processedMessage"></p>
    <button @click="closeBanner" class="close-btn">
      <img src="@/assets/icons/close_news.svg" alt="Close" />
    </button>
  </div>
</template>

<script>
export default {
  inject: ["$firebase"],
  name: "NewsBanner",

  data() {
    return {
      isVisible: false, // This will now be controlled by the real-time db value
      currentMessage: "", // Initialize with the prop value
    };
  },
  computed: {
    processedMessage() {
    // This regex matches URLs followed by optional custom text in parentheses
    const urlRegex = /(\bhttps?:\/\/[^\s]+?)\(([^)]+)\)/g;
    return this.currentMessage.replace(urlRegex, (match, url, customText) => {
      // Use the custom text as the link text, and add inline style for color
      return `<a href="${url}" target="_blank" style="color: #f22539;">${customText}</a>`;
    });
  },
  },
  created() {
    this.listenToCurrentNewsBanner();
    this.listenToShowNewsBanner(); // Listen to the showNewsBanner value
  },
  beforeUnmount() {
    // Unsubscribe from the real-time updates to prevent memory leaks
    const dbRef = this.$firebase.database().ref();
    dbRef
      .child("currentNewsBanner")
      .off("value", this.currentNewsBannerListener);
    dbRef.child("showNewsBanner").off("value", this.showNewsBannerListener);
  },
  methods: {
    listenToCurrentNewsBanner() {
      this.currentNewsBannerListener = (snapshot) => {
        const newsBanner = snapshot.val();
        if (newsBanner !== null) {
          // Check if the newsBanner is not null
          this.currentMessage = newsBanner; // Directly use the string value
        }
      };

      this.$firebase
        .database()
        .ref("currentNewsBanner")
        .on("value", this.currentNewsBannerListener, (error) => {
          console.error(
            "Error listening to current news banner updates:",
            error
          );
        });
    },
    listenToShowNewsBanner() {
      this.showNewsBannerListener = (snapshot) => {
        const showBanner = snapshot.val();
        this.isVisible = showBanner; // Directly control visibility with the real-time db value
      };

      this.$firebase
        .database()
        .ref("showNewsBanner")
        .on("value", this.showNewsBannerListener, (error) => {
          console.error("Error listening to show news banner updates:", error);
        });
    },
    closeBanner() {
      this.isVisible = false;
      // Optionally, update the real-time database to reflect this change
      // this.$firebase.database().ref("showNewsBanner").set(false);
    },
  },
};
</script>

<style scoped lang="scss">
/* Your styles remain unchanged */
@import "./assets/sass/style.scss";

.news-banner {
  background-color: white;
  color: $ibis-red;
  border-top: 1px solid $ibis-red;
  border-bottom: 1px solid $ibis-red;
  padding: 0.25rem 4rem;
  text-align: center;
  min-height: 3.3rem;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.close-btn {
  position: absolute;
  right: 2.2rem;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  img {
    height: 1rem;
  }
}
</style>
