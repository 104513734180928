const WorkshopSurfaceFinishings = [
  { key: "LAM101", value: "Laminat Matt" },
  //{ key: "LAM103", value: "Laminat Leinen fein" },
  { key: "LAM104", value: "Laminat Leinen grob" },
  { key: "LAM105", value: "Kristall" },
];

const SurfaceFinishingPrices = "100x150:12,120x195:14";

const LaminationLowerMaxSize = 124;
const LaminationHigherMaxSize = 190;

const SurfaceFinishingLowerMaxSize = 124;
const SurfaceFinishingHigherMaxSize = 195;

module.exports = {
  WorkshopSurfaceFinishings,
  SurfaceFinishingPrices,
  LaminationLowerMaxSize,
  LaminationHigherMaxSize,
  SurfaceFinishingLowerMaxSize,
  SurfaceFinishingHigherMaxSize,
};
