<template>
  <div class="filterWrapper">
    <select v-model="selectedCategory">
      <option value="">Alle Produktarten</option>
      <!-- Option to show all stocks -->
      <option
        v-for="(value, key) in categoriesInStock"
        :value="value"
        :key="key"
      >
        {{ value }}
      </option>
    </select>
  </div>
  <div class="stockList" id="stockList">
    <!-- Select element for categories -->

    <!-- Display filtered stock items -->
    <div v-for="item in filteredStock" :key="item.parent_id">
      <StockItem :item="item"></StockItem>
    </div>
  </div>
</template>

<script>
import StockItem from "./StockItem.vue";
import ProductCategories from "@/models/Types/ProductCategories";
export default {
  props: ["stock"],
  components: {
    StockItem,
  },
  data() {
    return {
      selectedCategory: "", // Local state for selected category
      productCategories: ProductCategories,
    };
  },
  computed: {
    filteredStock() {
      // When no category is selected, return the full stock list
      if (!this.selectedCategory) return this.stock;
      // Filter stock based on the selected category
      return this.stock.filter((item) => {
        return item.products[0].category === this.selectedCategory;
      });
    },
    categoriesInStock() {
      const categories = new Set(); // Using a Set to ensure uniqueness
      this.stock.forEach((item) => {
        // Assuming each item has a 'products' array and each product has a 'category'
        if (item.products && item.products.length) {
          categories.add(item.products[0].category);
        }
      });
      return Array.from(categories); // Converting back to an array for easier usage in the template
    },
  },
  mounted() {
    document.getElementById("stockList").classList.add("fade-in-bottom");
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.filterWrapper {
  padding: 3rem 10rem 0;
  box-sizing: border-box;
  @media (max-width: $tablet) {
    padding: 3rem 5rem 0;
  }
  @media (max-width: $mobile) {
    padding: 1rem 1rem 0;
  }
  width: 100%;
  display: flex;
}
.stockList {
  padding: 1rem 10rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 2rem;
  margin-bottom: 5rem;
  @media (max-width: $tablet) {
    padding: 1rem 5rem;
  }
  @media (max-width: $mobile) {
    padding: 0 1rem;
    grid-template-columns: repeat(1, 1fr);
  }
}
select {
  padding: 12px 20px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("../../../assets/icons/arrow_down.svg");
  background-repeat: no-repeat;
  background-position: calc(95%) center;
  padding-right: 2em;
  min-width: 15%;
  @media (max-width: $tablet) {
    min-width: 25%;
  }
  @media (max-width: $mobile) {
    min-width: 50%;
  }
}

select::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}
select:focus {
  outline: none;
  border-color: $ibis-black;
}
</style>
