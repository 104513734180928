<template>
  <div class="manageProductsWrapper">
    <div class="customerFileInputWrapper">
      <AdminFileInput
        :iconPath="iconPath"
        customText="Produkte"
        @file-selected="handleFileSelected"
      ></AdminFileInput>
    </div>
    <div class="initProcessButtonWrapper">
      <IbisButton
        buttonClass="primary"
        buttonText="Produkte aktualisieren"
        :buttonDisabled="!selectedFile || isProcessing"
        @click="initProductsRefresh()"
      ></IbisButton>
      <IbisSpinner v-if="isProcessing" class="adminSpinner"></IbisSpinner>
    </div>
  </div>
</template>
<script>
import IbisButton from "../../shared/IbisButton.vue";
import AdminFileInput from "./AdminFileInput.vue";
import customerIcon from "../../../assets/icons/admin_frame_2.svg";
import BackendHandler from "@/utility/BackendHandler";
import IbisSpinner from "../../shared/IbisSpinner.vue";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import ToastTypes from "@/models/Types/ToastTypes";
export default {
  components: { IbisButton, AdminFileInput, IbisSpinner },
  data() {
    return {
      iconPath: customerIcon,
      selectedFile: undefined,
      isProcessing: false,
      isRefreshRunning: false,
    };
  },
  async mounted() {
    const spinnerRef = firebase.database().ref("/productsUpdateRunning");

    spinnerRef.on("value", (snapshot) => {
      const value = snapshot.val();
      this.isProcessing = value;
      this.isRefreshRunning = value;
    });
  },
  methods: {
    handleFileSelected(file) {
      this.selectedFile = file;
    },
    showToastMessage(toastMessage, type) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
    },
    async initProductsRefresh() {
      if (this.selectedFile) {
        /* const formData = new FormData();
        formData.append("file", this.selectedFile); */

        const reader = new FileReader();
        reader.readAsDataURL(this.selectedFile);
        reader.onload = async () => {
          const base64String = reader.result.split(",")[1];

          this.isProcessing = true;
          try {
            const payload = {
              file: base64String,
            };
            await BackendHandler.initProductsRefresh(payload);
            this.showToastMessage(
              "Die Aktualisierung wurde eingeleitet, die Änderungen sollten in wenigen Minuten live sein.",
              ToastTypes.SUCCESS
            );
            if (!this.isRefreshRunning) {
              this.isProcessing = false;
            }
          } catch (error) {
            console.log(error);
            this.showToastMessage(
              "Bei der Aktualisierung ist etwas schiefgelaufen, bitte kontaktieren sie den Entwickler.",
              ToastTypes.ERROR
            );
            this.isProcessing = false;
          }
        };
        reader.onerror = (error) => {
          console.error("Error reading file:", error);
        };
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.manageProductsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 2rem;

  .initProcessButtonWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .adminSpinner {
      position: absolute;
      right: 10%;
    }
  }
}
</style>
