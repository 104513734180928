<template>
  <div class="heroSectionWrapper">
    <HeroSection />
  </div>
  <div class="pageBodyWrapper" id="pageBodyWrapper"><MainBody /></div>
</template>
<script>
import HeroSection from "./HeroSection.vue";
import MainBody from "./MainBody.vue";
export default {
  components: {
    HeroSection,
    MainBody,
  },
  mounted() {
    document.getElementById("pageBodyWrapper").classList.add("fade-in-bottom");
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.pageBodyWrapper {
  min-height: 60vh;
  position: relative;
}
</style>
