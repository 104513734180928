<template>
  <div class="framingWrapper">
    <FrameInput :roundToTwo="roundToTwo" :updatePrice="updatePrice"
      :heightValidation="heightValidation" :widthValidation="widthValidation"
      :selectAll="selectAll" @measurementChange="adjustOtherComponentsSize()">
    </FrameInput>
    <StretcherInput :availableProducts="workshopStretchers"
      :roundToTwo="roundToTwo" :updatePrice="updatePrice"
      :heightValidation="stretcherHeightValidation"
      :widthValidation="stretcherWidthValidation" :selectAll="selectAll"
      :syncConfiguration="syncConfiguration"
      :onWithBridgeInput="onWithBridgeInput"
      :onWithCrossInput="onWithCrossInput" @changeProduct="strecherChanged">
    </StretcherInput>

    <div class="radioOptions">
      <HangingInput class="checkboxCollection"></HangingInput>
      <InstallationInput class="checkboxCollection"
        v-if="isInstallationOptionAvailable"
        :syncConfiguration="syncConfiguration"></InstallationInput>
    </div>
    <div class="pictureValue"
      v-if="currentWorkshopMode !== workshopModes.FRAME_WITH_EQUIPMENT">
      <PictureValueInput></PictureValueInput>
    </div>
    <div class="additionalServices"
      v-if="currentWorkshopMode !== workshopModes.FRAME_WITH_EQUIPMENT">
      <AdditionalServices></AdditionalServices>
    </div>
    <div class="amount">
      <AmountInput :isWorkshopWorkQuantity="true"
        :doubleInputValidation="doubleInputValidation"></AmountInput>
    </div>
    <div class="notes">
      <ProductNotes :isWorkshopNotes="true"></ProductNotes>
    </div>
    <div class="totalPrice">
      <PositionPrice :roundToTwo="roundToTwo" :productProp="currentWorkshopWork"
        text="Gesamtpreis:" style="margin-top: 0"></PositionPrice>
    </div>
    <div class="addToOrderButtonWrapper">
      <IbisButton buttonClass="primary" buttonText="Zur Bestellung hinzufügen +"
        :buttonDisabled="!isCanvasFramingValid" @click="addProductToOrder()">
      </IbisButton>
    </div>
  </div>
</template>
<script>
import WorkshopStretchers from "@/models/Types/WorkshopStretchers";
import FrameInput from "./components/FrameInput.vue";
import StretcherInput from "..//stretching/components/StretcherInput.vue";
import HangingInput from "./components/HangingInput.vue";
import InstallationInput from "./components/InstallationInput.vue";
import ToastTypes from "@/models/Types/ToastTypes";
import ProductOptionsToSort from "@/models/Types/ProductOptionsToSort";
import WorkshopModes from "@/models/Types/WorkshopModes";
import PictureValueInput from "../shared/PictureValueInput.vue";
import IbisButton from "@/components/shared/IbisButton.vue";
import { isInteger, isNumber } from "lodash";
import PositionPrice from "../../shared/Information/PositionPrice.vue";
import DBSyncer from "@/utility/DBSyncer";
import AmountInput from "../../shared/Options/AmountInput.vue";
import ProductNotes from "../../shared/ProductNotes.vue";
import AdditionalServices from "../shared/AdditionalServices.vue";
import Measurements from "@/models/Types/Measurements";
export default {
  data() {
    return {
      workshopModes: WorkshopModes,
      workshopStretchers: WorkshopStretchers,
    };
  },

  components: {
    FrameInput,
    StretcherInput,
    HangingInput,
    InstallationInput,
    PictureValueInput,
    IbisButton,
    PositionPrice,
    AmountInput,
    ProductNotes,
    AdditionalServices,
  },
  computed: {
    selectedIndex() {
      return this.$store.getters.selectedIndex;
    },
    totalPrice() {
      return this.$store.getters.totalPrice;
    },
    currentWorkshopWork() {
      return this.$store.getters.currentWorkshopWork;
    },
    currentWorkshopMode() {
      return this.$store.getters.currentWorkshopMode;
    },
    currentWorkshopWorkFrameWidth() {
      if (this.currentWorkshopWork) {
        if (this.$store.getters.currentWorkshopWork.frame) {
          return this.$store.getters.currentWorkshopWork.frame.width;
        }
      }
      return undefined;
    },
    currentWorkshopWorkFrameHeight() {
      if (this.currentWorkshopWork) {
        if (this.$store.getters.currentWorkshopWork.frame) {
          return this.$store.getters.currentWorkshopWork.frame.height;
        }
      }
      return undefined;
    },
    currentWorkshopWorkStretcherFrameWidth() {
      if (this.currentWorkshopWork) {
        if (this.$store.getters.currentWorkshopWork.stretcher_frame) {
          return this.$store.getters.currentWorkshopWork.stretcher_frame.width;
        }
      }
      return undefined;
    },
    currentWorkshopWorkStretcherFrameHeight() {
      if (this.currentWorkshopWork) {
        if (this.$store.getters.currentWorkshopWork.stretcher_frame) {
          return this.$store.getters.currentWorkshopWork.stretcher_frame.height;
        }
      }
      return undefined;
    },
    isInstallationOptionAvailable() {
      return this.currentWorkshopMode === WorkshopModes.FRAMING || this.currentWorkshopMode === WorkshopModes.CANVAS_FRAMING;
    },
    isStretcherHanging() {
      if (this.currentWorkshopWork.installation) {
        return this.currentWorkshopWork.installation.key === "EKR33081";
      }
      return false;
    },
    selectedConfigurations() {
      return this.$store.getters.selectedConfigurations;
    },
    isCanvasFramingValid() {
      const userObject = JSON.parse(
        localStorage.getItem("ibisOnlinebestellungUserObject")
      );
      let notesValid = true;
      if (userObject.customer_type !== "Fachhandel" || !userObject.customer_type) {
        notesValid = this.currentWorkshopWork?.notes?.length > 0;
      }

      return (
        isNumber(this.currentWorkshopWorkFrameHeight) &&
        isNumber(this.currentWorkshopWorkFrameWidth) &&
        isInteger(this.currentWorkshopWork.picture_value) && isNumber(this.currentWorkshopWorkStretcherFrameHeight) && isNumber(this.currentWorkshopWorkStretcherFrameWidth) && notesValid
      );
    },
  },
  methods: {
    roundToTwo(num) {
      return +parseFloat(Math.round(num + "e+3") + "e-3");
    },
    heightValidation(product, identifier) {
      let value = this.allowOneDecimal(
        document.getElementById("heightInput" + (identifier ? identifier : '')).value.toString()
      );
      if (product && !value) {
        product.setHeight(1, true);
      } else {
        product.setHeight(value, true);
        if (
          product.sub_category === ProductOptionsToSort.WOOD_FRAME ||
          product.sub_category === ProductOptionsToSort.ALU_CUT
        ) {
          this.adjustOtherComponentsHeight(product.height);
          this.adjustOtherComponentsWidth(product.width);
        }
      }
      this.$forceUpdate();

      if (
        parseFloat(value) + product.width >
        product.max_height + product.max_width
      ) {
        this.showToastMessage(
          "Das Maximalmaß wurde überschritten.",
          ToastTypes.ERROR,
          "heightInput"
        );
      }
      product.passepartout_use_custom_leg_width = false;
      if (
        this.currentWorkshopWork.stretcher_frame.width >= 80 && this.currentWorkshopWork.stretcher_frame.height >= 80
      ) {
        this.currentWorkshopWork.stretcher_frame.sf_with_cross = true;
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge = false;
      }
      else if (
        this.currentWorkshopWork.stretcher_frame.width >= 80 ||
        this.currentWorkshopWork.stretcher_frame.height >= 80
      ) {
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge = true;
        this.currentWorkshopWork.stretcher_frame.sf_with_cross = false;
      } else {
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge = false;
        this.currentWorkshopWork.stretcher_frame.sf_with_cross = false;
      }
      this.updatePrice();
    },
    widthValidation(product, identifier) {
      let value = this.allowOneDecimal(
        document.getElementById("widthInput" + (identifier ? identifier : '')).value.toString()
      );

      if (product && !value) {
        product.setWidth(1, true);
      } else {
        product.setWidth(value, true);

        if (
          product.sub_category === ProductOptionsToSort.WOOD_FRAME ||
          product.sub_category === ProductOptionsToSort.ALU_CUT
        ) {
          this.adjustOtherComponentsWidth(product.width);
          this.adjustOtherComponentsHeight(product.height);
        }
      }
      this.$forceUpdate();
      if (
        product.height + parseFloat(value) >
        product.max_height + product.max_width
      ) {
        this.showToastMessage(
          "Das Maximalmaß wurde überschritten.",
          ToastTypes.ERROR,
          "widthInput"
        );
      }
      product.passepartout_use_custom_leg_width = false;
      if (
        this.currentWorkshopWork.stretcher_frame.width >= 80 && this.currentWorkshopWork.stretcher_frame.height >= 80
      ) {
        this.currentWorkshopWork.stretcher_frame.sf_with_cross = true;
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge = false;
      }
      else if (
        this.currentWorkshopWork.stretcher_frame.width >= 80 ||
        this.currentWorkshopWork.stretcher_frame.height >= 80
      ) {
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge = true;
        this.currentWorkshopWork.stretcher_frame.sf_with_cross = false;
      } else {
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge = false;
        this.currentWorkshopWork.stretcher_frame.sf_with_cross = false;
      }
      this.updatePrice();
    },
    stretcherWidthValidation(product, identifier) {
      let frameWidth = this.currentWorkshopWorkFrameWidth;
      if (frameWidth !== undefined) {
        let value = this.allowOneDecimal(
          document.getElementById("widthInput" + (identifier ? identifier : '')).value.toString()
        );
        if (value > frameWidth - 0.4) {
          product.setWidth(frameWidth - 0.4, true);
          document.getElementById("widthInput" + (identifier ? identifier : '')).value = product.width;
          this.showToastMessage("Die Falzzugabe kann bei Einrahmung einer Leinwand 4mm nicht unterschreiten.", ToastTypes.ERROR);
        }
        else {
          this.currentWorkshopWork.folding_allowance = this.roundToTwo(frameWidth - value);
        }
      }
      this.widthValidation(product, identifier);
      this.updatePrice();
    },

    stretcherHeightValidation(product, identifier) {

      let frameHeight = this.currentWorkshopWorkFrameHeight;
      if (frameHeight !== undefined) {
        let value = this.allowOneDecimal(
          document.getElementById("heightInput" + (identifier ? identifier : '')).value.toString()
        );
        if (value > frameHeight - 0.4) {
          product.setHeight(frameHeight - 0.4, true);
          document.getElementById("heightInput" + (identifier ? identifier : '')).value = product.height;

          this.showToastMessage("Die Falzzugabe kann bei Einrahmung einer Leinwand 4mm nicht unterschreiten.", ToastTypes.ERROR);
        }
        else {
          this.currentWorkshopWork.folding_allowance = this.roundToTwo(frameHeight - value);
        }
      }
      this.heightValidation(product, identifier);
      this.updatePrice();
    },

    updatePrice() {
      this.$store.commit("updatePrice");
    },
    syncConfiguration() {
      this.updatePrice()
      DBSyncer.syncConfiguration();
    },
    allowOneDecimal(numStr) {
      const [wholeNum, decimalNum = ""] = numStr.split(".");

      if (decimalNum.length >= 1) {
        const roundedNum = Number.parseFloat(
          `${wholeNum}.${decimalNum}`
        ).toFixed(1);
        return roundedNum;
      } else {
        return parseFloat(numStr);
      }
    },
    showToastMessage(toastMessage, type, inputId) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
      if (type === ToastTypes.ERROR && inputId) {
        document.getElementById(inputId).classList.add("invalid");
        setTimeout(() => {
          document.getElementById(inputId).classList.remove("invalid");
        }, 3000);
      }
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 5000);
    },
    selectAll(event) {
      event.target.select();
    },
    onNoCutOutInput(product) {
      product.passepartout_no_cut_out = !product.passepartout_no_cut_out;
      this.updatePrice();
    },
    checkProductSizeLimits(product) {
      if (
        this.currentWorkshopWork.frame.height &&
        this.currentWorkshopWork.frame.width
      ) {
        const { width, height } = this.currentWorkshopWork.frame;
        // Check for max values
        const [lowerValueMax, higherValueMax] =
          width >= height ? [height, width] : [width, height];
        const [lowerLimitMax, higherLimitMax] =
          product.max_width >= product.max_height
            ? [product.max_height, product.max_width]
            : [product.max_width, product.max_height];

        // Check for min values
        const [lowerValueMin, higherValueMin] =
          width <= height ? [height, width] : [width, height];
        const [lowerLimitMin, higherLimitMin] =
          product.min_width <= product.min_height
            ? [product.min_height, product.min_width]
            : [product.min_width, product.min_height];

        return (
          lowerLimitMax >= lowerValueMax &&
          higherLimitMax >= higherValueMax &&
          lowerLimitMin <= lowerValueMin &&
          higherLimitMin <= higherValueMin
        );
      } else {
        return true;
      }
    },
    adjustOtherComponentsSize() {
      this.adjustOtherComponentsHeight(this.currentWorkshopWorkFrameHeight);
      this.adjustOtherComponentsWidth(this.currentWorkshopWorkFrameWidth);
    },
    adjustOtherComponentsHeight(value) {

      if (this.currentWorkshopWork.stretcher_frame) {
        if (this.checkProductSizeLimits(this.currentWorkshopWork.stretcher_frame)) {
          this.currentWorkshopWork.stretcher_frame.setHeight(
            this.getValueForMeasurementType(value),
            true
          );

        } else {
          this.showToastMessage(
            "Der Keilrahmen  unterstützt das neue Maß nicht.",
            ToastTypes.ERROR
          );
          this.$store.commit("switchWorkshopStretcherFrame", "");
        }
      }
    },
    adjustOtherComponentsWidth(value) {
      if (this.currentWorkshopWork.stretcher_frame) {
        if (this.checkProductSizeLimits(this.currentWorkshopWork.stretcher_frame)) {

          this.currentWorkshopWork.stretcher_frame.setWidth(
            this.getValueForMeasurementType(value),
            true
          );

        } else {
          this.showToastMessage(
            "Der Keilrahmen unterstützt das neue Maß nicht.",
            ToastTypes.ERROR
          );
          this.$store.commit("switchWorkshopStretcherFrame", "");
        }
      }
    },
    doubleInputValidation() {
      this.validateAboveZeroAndFullNumbers();
      this.validateQuantityInput();
    },
    validateAboveZeroAndFullNumbers() {
      if (
        this.currentWorkshopWork.quantity < 1 &&
        this.currentWorkshopWork.quantity !== ""
      ) {
        this.currentWorkshopWork.quantity = 1;
      }
      this.currentWorkshopWork.quantity = this.currentWorkshopWork.quantity
        ? this.currentWorkshopWork.quantity.toFixed(0)
        : 1;
      this.updatePrice();
    },
    validateQuantityInput() {
      if (
        this.currentWorkshopWork.quantity < 1 &&
        this.currentWorkshopWork.quantity !== ""
      ) {
        this.currentWorkshopWork.quantity = 1;
      }
      this.updatePrice();
    },
    addProductToOrder() {
      this.$store.commit("addWorkshopWork");
      this.$store.commit("updateSelectedConfigurations");

      this.showToastMessage(
        "Einrahmung zur Bestellung hinzugefügt.",
        ToastTypes.SUCCESS
      );
      this.syncConfiguration();
      this.$store.commit("initializeWorkshopWork", true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    getValueForMeasurementType(value) {
      let finalValue = 0;
      this.currentWorkshopWork.frame.selected_measurement_type ===
        Measurements.FALZMASS
        ? (finalValue = value - this.currentWorkshopWork.folding_allowance)
        : (finalValue = value);
      return finalValue;
    },
    onWithBridgeInput() {
      this.currentWorkshopWork.stretcher_frame.sf_with_bridge =
        !this.currentWorkshopWork.stretcher_frame.sf_with_bridge;

      if (
        (this.currentWorkshopWork.stretcher_frame.width <= 80 &&
          this.currentWorkshopWork.stretcher_frame.height <= 80) ||
        this.currentWorkshopWork.stretcher_frame.sf_with_cross
      ) {
        this.currentWorkshopWork.stretcher_frame.sf_with_cross = false;
      }
      this.updatePrice();
    },
    onWithCrossInput() {
      this.currentWorkshopWork.stretcher_frame.sf_with_cross =
        !this.currentWorkshopWork.stretcher_frame.sf_with_cross;

      if (
        this.currentWorkshopWork.stretcher_frame.width <= 80 ||
        this.currentWorkshopWork.stretcher_frame.height <= 80 ||
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge
      ) {
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge = false;
      }
      this.updatePrice();
    },
    strecherChanged() {
      this.adjustOtherComponentsSize()
    }
  },
};
</script>
<style scoped lang="scss">
@import "../../../../assets/sass/style.scss";

.framingWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .radioOptions {
    display: flex;

    @media (max-width: $tablet) {
      flex-direction: column;
    }
  }

  .addToOrderButtonWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .checkboxCollection {
    width: 50%;
    transition: 0.3s;

    @media (max-width: $tablet) {
      width: 100%;
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
