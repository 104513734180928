<template>
  <div class="textFieldWrapper">
    <textarea class="textField" placeholder="Kommission / Anmerkungen" cols="30"
      rows="5" v-model="currentProduct.notes" :style="'border: 2px solid ' + (!isRetailer ? '#f22539' : '#404040')

        "></textarea>
  </div>
</template>
<script>
import UserTypes from "@/models/Types/UserTypes";
import UserTypeProvider from "@/utility/UserTypeProvider";

export default {
  props: ["isWorkshopNotes"],
  computed: {
    currentProduct() {
      return this.isWorkshopNotes
        ? this.$store.getters.currentWorkshopWork
        : this.$store.getters.currentProduct;
    },
    isRetailer() {
      return UserTypeProvider.getUserType() === UserTypes.RETAILER;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.textFieldWrapper {
  margin-top: 2em;
  display: flex;
  justify-content: flex-start;

  .textField {
    border-radius: 25px;
    padding: 20px;
    width: 100%;
    resize: none;
    color: $ibis-black;
    border: 2px solid $ibis-grey-middle;
    //outline: 2px solid $ibis-black;
    outline: none;
    //font-family: "Raleway";

    &:focus {
      border: 2px solid $ibis-black;
    }
  }
}
</style>
