<template>
  <div class="manageCustomersWrapper">
    <div class="customerFileInputWrapper">
      <AdminFileInput
        :iconPath="iconPath"
        customText="Kunden"
        @file-selected="handleFileSelected"
      ></AdminFileInput>
    </div>
    <div class="initProcessButtonWrapper">
      <IbisButton
        buttonClass="primary"
        buttonText="Kunden aktualisieren"
        :buttonDisabled="!selectedFile || isProcessing"
        @click="initCustomerRefresh()"
      ></IbisButton>
      <IbisSpinner v-if="isProcessing" class="adminSpinner"></IbisSpinner>
    </div>
    <div class="initProcessButtonWrapper">
      <IbisButton
        buttonClass="secondary"
        buttonText="Kunden exportieren"
        :buttonIcon="exportIcon"
        :buttonDisabled="isCustomerExportLoading"
        @click="initCustomerExport()"
      ></IbisButton>
      <IbisSpinner
        v-if="isCustomerExportLoading"
        class="adminSpinner"
      ></IbisSpinner>
    </div>
  </div>
</template>
<script>
import IbisButton from "../../shared/IbisButton.vue";
import AdminFileInput from "./AdminFileInput.vue";
import customerIcon from "../../../assets/icons/admin_customer.svg";
import BackendHandler from "@/utility/BackendHandler";
import IbisSpinner from "../../shared/IbisSpinner.vue";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import ToastTypes from "@/models/Types/ToastTypes";
export default {
  components: { IbisButton, AdminFileInput, IbisSpinner },
  data() {
    return {
      iconPath: customerIcon,
      selectedFile: undefined,
      isProcessing: false,
      isRefreshRunning: false,
      isCustomerExportLoading: false,
    };
  },
  async mounted() {
    const spinnerRef = firebase.database().ref("/customersUpdateRunning");

    spinnerRef.on("value", (snapshot) => {
      const value = snapshot.val();
      this.isProcessing = value;
      this.isRefreshRunning = value;
    });
  },
  methods: {
    showToastMessage(toastMessage, type) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
    },
    handleFileSelected(file) {
      this.selectedFile = file;
    },

    async initCustomerRefresh() {
      if (this.selectedFile) {
        const reader = new FileReader();
        reader.readAsDataURL(this.selectedFile);
        reader.onload = async () => {
          const base64String = reader.result.split(",")[1];
          this.isProcessing = true;
          try {
            const payload = {
              file: base64String,
            };

            await BackendHandler.initCustomerRefresh(payload);
            this.showToastMessage(
              "Die Aktualisierung wurde eingeleitet, die Änderungen sollten in wenigen Minuten live sein.",
              ToastTypes.SUCCESS
            );
            if (!this.isRefreshRunning) {
              this.isProcessing = false;
            }
          } catch (error) {
            console.log(error);
            this.showToastMessage(
              "Bei der Aktualisierung ist etwas schiefgelaufen, bitte kontaktieren sie den Entwickler.",
              ToastTypes.ERROR
            );
            this.isProcessing = false;
          }
        };
        reader.onerror = (error) => {
          console.error("Error reading file:", error);
        };
      }
    },
    async initCustomerExport() {
      try {
        this.isCustomerExportLoading = true;

        const response = await BackendHandler.exportCustomersAsExcel();

        if (response && response.data) {
          const binaryData = atob(response.data.base64); // Decode base64
          const bytes = new Uint8Array(binaryData.length);

          for (let i = 0; i < binaryData.length; i++) {
            bytes[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([bytes.buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);

          // Create and trigger a download link
          const link = document.createElement("a");
          link.href = url;

          const date = new Date();
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
          const year = date.getFullYear();

          const formattedDate = `${day}.${month}.${year}`;

          link.download = `Export_Kundenliste_${formattedDate}.xlsx`; // Name of the file to be downloaded
          link.click();

          // Cleanup
          window.URL.revokeObjectURL(url);
        }

        this.isCustomerExportLoading = false;
      } catch (e) {
        this.isCustomerExportLoading = false;
        console.error(e);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.manageCustomersWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 2rem;

  .initProcessButtonWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .adminSpinner {
    position: absolute;
    right: 10%;
  }
}
</style>
