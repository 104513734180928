const InstallationTypes = [
  { key: "E", value: "Einbau Staubdicht", price: 11.5 },
  { key: "EE3308", value: "Einbau Standard", price: 8.8 }, //preis pro lfm
  { key: "EKR33081", value: "Einbau Keilrahmen", price: 6.75 }, //preis pro lfm
  {
    key: "DOPPEL_GLAS",
    value: "Einbau Doppelglaseinrahmung",
    price: 22.80,
  },
];
module.exports = InstallationTypes;
