<template>
  <div class="impressumPage" id="impressumPage">
    <div class="impressumHeader">Impressum</div>
    <div class="locationsWrapper">
      <div class="company">
        <h3>IbiS GmbH & Co. KG</h3>
        <div class="textBlock">
          <span>Goldbeckstraße 10 69493</span>
          <span>Hirschberg an der Bergstraße</span>
        </div>
        <div class="textBlock">
          <span>Telefon: 06201 / 25 901-3</span>
          <span>Telefax: 06201 / 25 901-57</span>
        </div>
        <div class="textBlock">
          <span>E-Mail: info-hirschberg@ibis-online.de</span>
        </div>
        <div class="textBlock">
          <span>KG Sitz Hirschberg</span>
          <span>Registerger. Weinheim HR A 0887 </span>
          <span>IbiS-Polzer Beteiligungsges.mbH</span>
          <span>Sitz Hallerndorf</span>
          <span>AG Bamberg HR B 4033</span>
        </div>
        <div class="textBlock">
          <span>Geschäftsführung: </span>
          <span>Helmut Hauck</span>
          <span>Thomas Polzer</span>
          <span>David Werner</span>
        </div>
        <div class="textBlock">
          <span>UST.ID DE 161 355 425</span>
        </div>
      </div>
      <div class="company">
        <h3>IbiS-Polzer GmbH & Co. KG</h3>
        <div class="textBlock">
          <span>Carl-Kreul-Straße 1</span>
          <span>91352 Hallerndorf</span>
        </div>
        <div class="textBlock">
          <span>Telefon: 09545 / 94 80 340</span>
          <span>Telefax: 09545 / 94 80 344</span>
        </div>
        <div class="textBlock">
          <span>E-Mail: info-hallerndorf@ibis-online.de</span>
        </div>
        <div class="textBlock">
          <span>KG Sitz Hallerndorf</span>
          <span>AG Bamberg HR A 4747</span>
          <span>IbiS-Polzer Beteiligungsges.mbH</span>
          <span>Sitz Hallerndorf </span>
          <span>AG Bamberg HR B 4033</span>
        </div>
        <div class="textBlock">
          <span>Geschäftsführung: </span>
          <span>Helmut Hauck</span>
          <span>Thomas Polzer</span>
          <span>David Werner</span>
        </div>
        <div class="textBlock">
          <span>UST.ID DE 812 913 088</span>
        </div>
      </div>
      <div class="company">
        <h3>IbiS Manufaktur GmbH & Co. KG</h3>
        <div class="textBlock">
          <span>Riedinger Str. 12-14</span>
          <span>45141 Essen</span>
        </div>
        <div class="textBlock">
          <span>Telefon: 0201 / 453 295 40</span>
          <span>Telefax: 0201 / 36 80 677</span>
        </div>
        <div class="textBlock">
          <span>E-Mail: info-essen@ibis-online.de</span>
        </div>
        <div class="textBlock">
          <span>KG Sitz Essen</span>
          <span>Essen HR A 8113</span>
          <span>IbiS-Polzer Beteiligungsges.mbH</span>
          <span>Sitz Hallerndorf</span>
          <span>AG Bamberg HR B 4033</span>
        </div>
        <div class="textBlock">
          <span>Geschäftsführung: </span>
          <span>Helmut Hauck</span>
          <span>Thomas Polzer</span>
          <span>David Werner</span>
        </div>
        <div class="textBlock">
          <span>UST.ID DE 231 139 703</span>
        </div>
      </div>
      <div class="company">
        <h3>IbiS-Kempa GmbH & Co. KG</h3>
        <div class="textBlock">
          <span>Motzener Straße 9</span>
          <span>12277 Berlin</span>
        </div>
        <div class="textBlock">
          <span>Telefon: 030 / 72 12 045</span>
          <span>Telefax: 030 / 72 12 046</span>
        </div>
        <div class="textBlock">
          <span>E-Mail: info-berlin@ibis-online.de</span>
        </div>
        <div class="textBlock">
          <span>KG Sitz Berlin</span>
          <span>Registerger. NEU</span>
          <span>IbiS-Polzer Beteiligungsges.mbH</span>
          <span>Sitz Hallerndorf</span>
          <span>AG Bamberg HR B 4033</span>
        </div>
        <div class="textBlock">
          <span>Geschäftsführung: </span>
          <span>Helmut Hauck</span>
          <span>Thomas Polzer</span>
          <span>David Werner</span>
        </div>
        <div class="textBlock">
          <span>UST.ID DE 250 642 817</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    document.getElementById("impressumPage").classList.add("fade-in-bottom");
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";
.impressumPage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4rem 4rem;

  @media (max-width: $mobile) {
    padding: 1rem 2rem;
  }

  .impressumHeader {
    width: 90%;
    text-align: start;
    font-size: $font-size-xlarge;
  }

  .locationsWrapper {
    margin-top: 3rem;
    @media (max-width: $mobile) {
      margin-top: 0;
    }
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 3rem;
    column-gap: 10rem;
    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column;
    }
    .company {
      h3 {
        text-align: start;
      }
      .textBlock {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        span {
          text-align: start;
        }
      }
    }
  }
}
</style>
