<template>
  <div class="framingWrapper">
    <FrameInput :roundToTwo="roundToTwo" :updatePrice="updatePrice"
      :heightValidation="heightValidation" :widthValidation="widthValidation"
      :selectAll="selectAll" @measurementChange="adjustOtherComponentsSize()">
    </FrameInput>
    <PassepartoutInput :class="!currentWorkshopWorkFrameWidth || !currentWorkshopWorkFrameHeight
      ? 'disabled'
      : undefined
      " style="transition: 0.3s" :roundToTwo="roundToTwo"
      :updatePrice="updatePrice" :heightValidation="heightValidation"
      :widthValidation="widthValidation"
      :innerHeightValidation="innerHeightValidation"
      :innerWidthValidation="innerWidthValidation" :selectAll="selectAll"
      :syncConfiguration="syncConfiguration" :onNoCutOutInput="onNoCutOutInput"
      :checkProductSizeLimits="checkProductSizeLimits"></PassepartoutInput>
    <label v-if="!currentWorkshopWork.no_passepartout" class="checkboxContainer"
      style="margin-top: 1rem;">Zweites Passepartout hinzufügen
      <input type="checkbox"
        v-model="currentWorkshopWork.double_passepartout" />
      <span class="checkmark"></span> </label>
    <PassepartoutInput v-if="currentWorkshopWork?.double_passepartout" :class="!currentWorkshopWorkFrameWidth || !currentWorkshopWorkFrameHeight
      ? 'disabled'
      : undefined
      " style="transition: 0.3s" :roundToTwo="roundToTwo"
      :updatePrice="updatePrice" :heightValidation="heightValidation"
      :widthValidation="widthValidation"
      :innerHeightValidation="innerHeightValidation"
      :innerWidthValidation="innerWidthValidation" :selectAll="selectAll"
      :syncConfiguration="syncConfiguration" :onNoCutOutInput="onNoCutOutInput"
      :checkProductSizeLimits="checkProductSizeLimits"
      :isSecondPassepartout="true"></PassepartoutInput>
    <GlasInput :class="!currentWorkshopWorkFrameWidth ||
      !currentWorkshopWorkFrameHeight ||
      isStretcherHanging
      ? 'disabled'
      : undefined
      " style="transition: 0.3s" :availableProducts="workshopGlasses"
      :checkProductSizeLimits="checkProductSizeLimits" :roundToTwo="roundToTwo"
      :updatePrice="updatePrice" :heightValidation="heightValidation"
      :widthValidation="widthValidation" :selectAll="selectAll"></GlasInput>
    <BackWallInput :class="!currentWorkshopWorkFrameWidth ||
      !currentWorkshopWorkFrameHeight ||
      isStretcherHanging
      ? 'disabled'
      : undefined
      " style="transition: 0.3s" :availableProducts="workshopBackWalls"
      :checkProductSizeLimits="checkProductSizeLimits" :roundToTwo="roundToTwo"
      :updatePrice="updatePrice" :heightValidation="heightValidation"
      :widthValidation="widthValidation" :selectAll="selectAll"></BackWallInput>
    <GlasInput v-show="currentWorkshopWork.double_glas" :class="!currentWorkshopWorkFrameWidth ||
      !currentWorkshopWorkFrameHeight ||
      isStretcherHanging
      ? 'disabled'
      : undefined
      " style="transition: 0.3s" :availableProducts="workshopGlasses"
      :checkProductSizeLimits="checkProductSizeLimits" :roundToTwo="roundToTwo"
      :updatePrice="updatePrice" :heightValidation="heightValidation"
      :widthValidation="widthValidation" :selectAll="selectAll"
      :isSecondGlas="true"
      :innerSecondGlasWidthValidation="innerSecondGlasWidthValidation"
      :innerSecondGlasHeightValidation="innerSecondGlasHeightValidation">
    </GlasInput>
    <div class="radioOptions">
      <HangingInput class="checkboxCollection"></HangingInput>
      <InstallationInput class="checkboxCollection"
        v-if="isInstallationOptionAvailable"
        :syncConfiguration="syncConfiguration"></InstallationInput>
    </div>
    <div class="pictureValue"
      v-if="currentWorkshopMode !== workshopModes.FRAME_WITH_EQUIPMENT">
      <PictureValueInput></PictureValueInput>
    </div>
    <div class="additionalServices">
      <AdditionalServices></AdditionalServices>
    </div>
    <div class="amount">
      <AmountInput :isWorkshopWorkQuantity="true"
        :doubleInputValidation="doubleInputValidation"></AmountInput>
    </div>
    <div class="notes">
      <ProductNotes :isWorkshopNotes="true"></ProductNotes>
    </div>
    <div class="totalPrice">
      <PositionPrice :roundToTwo="roundToTwo" :productProp="currentWorkshopWork"
        text="Gesamtpreis:" style="margin-top: 0"></PositionPrice>
    </div>
    <div class="addToOrderButtonWrapper">
      <IbisButton buttonClass="primary" buttonText="Zur Bestellung hinzufügen +"
        :buttonDisabled="!isFramingValid" @click="addProductToOrder()">
      </IbisButton>
    </div>
  </div>
</template>
<script>
import FrameInput from "./components/FrameInput.vue";
import PassepartoutInput from "./components/PassepartoutInput.vue";
import GlasInput from "./components/GlasInput.vue";
import BackWallInput from "./components/BackWallInput.vue";
import HangingInput from "./components/HangingInput.vue";
import InstallationInput from "./components/InstallationInput.vue";
import ToastTypes from "@/models/Types/ToastTypes";
import ProductOptionsToSort from "@/models/Types/ProductOptionsToSort";
import WorkshopGlasses from "@/models/Types/WorkshopGlasses";
import WorkshopBackWalls from "@/models/Types/WorkshopBackWalls";
import WorkshopModes from "@/models/Types/WorkshopModes";
import PictureValueInput from "../shared/PictureValueInput.vue";
import IbisButton from "@/components/shared/IbisButton.vue";
import { isInteger } from "lodash";
import PositionPrice from "../../shared/Information/PositionPrice.vue";
import DBSyncer from "@/utility/DBSyncer";
import AmountInput from "../../shared/Options/AmountInput.vue";
import ProductNotes from "../../shared/ProductNotes.vue";
import AdditionalServices from "../shared/AdditionalServices.vue";
import Measurements from "@/models/Types/Measurements";
export default {
  data() {
    return {
      workshopGlasses: WorkshopGlasses,
      workshopBackWalls: WorkshopBackWalls,
      workshopModes: WorkshopModes,
    };
  },

  components: {
    FrameInput,
    PassepartoutInput,
    GlasInput,
    BackWallInput,
    HangingInput,
    InstallationInput,
    PictureValueInput,
    IbisButton,
    PositionPrice,
    AmountInput,
    ProductNotes,
    AdditionalServices,
  },
  computed: {
    selectedIndex() {
      return this.$store.getters.selectedIndex;
    },
    totalPrice() {
      return this.$store.getters.totalPrice;
    },
    currentWorkshopWork() {
      return this.$store.getters.currentWorkshopWork;
    },
    currentWorkshopMode() {
      return this.$store.getters.currentWorkshopMode;
    },
    currentWorkshopWorkFrameWidth() {
      if (this.currentWorkshopWork) {
        if (this.$store.getters.currentWorkshopWork.frame) {
          return this.$store.getters.currentWorkshopWork.frame.width;
        }
      }
      return undefined;
    },
    currentWorkshopWorkFrameHeight() {
      if (this.currentWorkshopWork) {
        if (this.$store.getters.currentWorkshopWork.frame) {
          return this.$store.getters.currentWorkshopWork.frame.height;
        }
      }
      return undefined;
    },
    isInstallationOptionAvailable() {
      return this.currentWorkshopMode === WorkshopModes.FRAMING;
    },
    isStretcherHanging() {

      if (this.currentWorkshopWork.installation) {
        return this.currentWorkshopWork.installation.key === "EKR33081";
      }

      return false;
    },
    selectedConfigurations() {
      return this.$store.getters.selectedConfigurations;
    },
    isFramingValid() {
      const userObject = JSON.parse(
        localStorage.getItem("ibisOnlinebestellungUserObject")
      );
      let notesValid = true;
      if (userObject.customer_type !== "Fachhandel" || !userObject.customer_type) {
        notesValid = this.currentWorkshopWork?.notes?.length > 0;
      }
      if (this.currentWorkshopWork.passepartout) {
        if (
          !this.currentWorkshopWork.passepartout.passepartout_no_cut_out &&
          (!this.currentWorkshopWork.passepartout.passepartout_inner_width ||
            !this.currentWorkshopWork.passepartout.passepartout_inner_height)
        ) {
          return false;
        }
      }
      if (this.currentWorkshopWork.second_passepartout) {
        if (
          !this.currentWorkshopWork.second_passepartout.passepartout_no_cut_out &&
          (!this.currentWorkshopWork.second_passepartout.passepartout_inner_width ||
            !this.currentWorkshopWork.second_passepartout.passepartout_inner_height)
        ) {
          return false;
        }
      }
      if (this.currentWorkshopMode === WorkshopModes.FRAME_WITH_EQUIPMENT) {
        return (
          this.currentWorkshopWorkFrameHeight &&
          this.currentWorkshopWorkFrameWidth && notesValid
        );
      } else {
        if (this.currentWorkshopWork.double_glas && this.currentWorkshopMode === this.workshopModes.FRAMING) {
          if (
            !this.currentWorkshopWork.second_glas.second_glas_picture_width ||
            !this.currentWorkshopWork.second_glas.second_glas_picture_height
          ) {
            return false;
          }
        }
        return (
          this.currentWorkshopWorkFrameHeight &&
          this.currentWorkshopWorkFrameWidth &&
          isInteger(this.currentWorkshopWork.picture_value) && notesValid
        );
      }
    },
  },
  methods: {
    roundToTwo(num) {
      return +parseFloat(Math.round(num + "e+3") + "e-3");
    },
    heightValidation(product) {
      let value = this.allowOneDecimal(
        document.getElementById("heightInput").value.toString()
      );

      if (product && !value) {
        product.setHeight(1, true);
        if (
          product.sub_category === ProductOptionsToSort.WOOD_FRAME ||
          product.sub_category === ProductOptionsToSort.CORNER_FRAME ||
          product.sub_category === ProductOptionsToSort.ALU_CUT
        ) {
          if (this.currentWorkshopWork.passepartout) {
            this.currentWorkshopWork.passepartout.setHeight(1, true);
          }
        }
      } else {
        product.setHeight(value, true);
        if (
          product.sub_category === ProductOptionsToSort.WOOD_FRAME ||
          product.sub_category === ProductOptionsToSort.CORNER_FRAME ||
          product.sub_category === ProductOptionsToSort.ALU_CUT
        ) {
          this.adjustOtherComponentsHeight(product.height);
          this.adjustOtherComponentsWidth(product.width);
        }
      }
      this.$forceUpdate();

      if (
        parseFloat(value) + product.width >
        product.max_height + product.max_width
      ) {
        this.showToastMessage(
          "Das Maximalmaß wurde überschritten.",
          ToastTypes.ERROR,
          "heightInput"
        );
      }
      product.passepartout_use_custom_leg_width = false;

      this.updatePrice();
    },
    widthValidation(product) {
      let value = this.allowOneDecimal(
        document.getElementById("widthInput").value.toString()
      );

      if (product && !value) {
        product.setWidth(1, true);
        if (
          product.sub_category === ProductOptionsToSort.WOOD_FRAME ||
          product.sub_category === ProductOptionsToSort.CORNER_FRAME ||
          product.sub_category === ProductOptionsToSort.ALU_CUT
        ) {
          if (this.currentWorkshopWork.passepartout) {
            this.currentWorkshopWork.passepartout.setWidth(1, true);
          }
        }
      } else {
        product.setWidth(value, true);

        if (
          product.sub_category === ProductOptionsToSort.WOOD_FRAME ||
          product.sub_category === ProductOptionsToSort.CORNER_FRAME ||
          product.sub_category === ProductOptionsToSort.ALU_CUT
        ) {
          this.adjustOtherComponentsWidth(product.width);
          this.adjustOtherComponentsHeight(product.height);
        }
      }
      this.$forceUpdate();
      if (
        product.height + parseFloat(value) >
        product.max_height + product.max_width
      ) {
        this.showToastMessage(
          "Das Maximalmaß wurde überschritten.",
          ToastTypes.ERROR,
          "widthInput"
        );
      }
      product.passepartout_use_custom_leg_width = false;

      this.updatePrice();
    },
    innerWidthValidation(product, isSecondPassepartout) {
      let value = this.allowOneDecimal(
        document.getElementById(!isSecondPassepartout ? "innerWidthInput" : 'innerWidthInput2').value.toString()
      );
      if (product && !value) {
        product.setPPInnerWidth(1);
      } else {
        product.setPPInnerWidth(value);
      }
      this.$forceUpdate();

      product.passepartout_use_custom_leg_width = false;
      this.syncConfiguration();
    },

    innerHeightValidation(product, isSecondPassepartout) {
      let value = this.allowOneDecimal(
        document.getElementById(!isSecondPassepartout ? "innerHeightInput" : "innerHeightInput2").value.toString()
      );

      if (product && !value) {
        product.setPPInnerHeight(1);
      } else {
        product.setPPInnerHeight(value);
      }
      this.$forceUpdate();

      product.passepartout_use_custom_leg_width = false;
      this.syncConfiguration();
    },

    innerSecondGlasWidthValidation(product) {
      let value = this.allowOneDecimal(
        document.getElementById("innerSecondGlasWidthInput").value.toString()
      );

      if (product && !value) {
        product.setSecondGlasPictureWidth(1);
      } else {
        product.setSecondGlasPictureWidth(value);
      }
      this.$forceUpdate();

      this.syncConfiguration();
    },
    innerSecondGlasHeightValidation(product) {
      let value = this.allowOneDecimal(
        document.getElementById("innerSecondGlaHeightInput").value.toString()
      );

      if (product && !value) {
        product.setSecondGlasPictureHeight(1);
      } else {
        product.setSecondGlasPictureHeight(value);
      }
      this.$forceUpdate();

      this.syncConfiguration();
    },

    updatePrice() {
      this.$store.commit("updatePrice");
    },
    syncConfiguration() {
      this.updatePrice()
      DBSyncer.syncConfiguration();
    },
    allowOneDecimal(numStr) {
      const [wholeNum, decimalNum = ""] = numStr.split(".");

      if (decimalNum.length >= 1) {
        const roundedNum = Number.parseFloat(
          `${wholeNum}.${decimalNum}`
        ).toFixed(1);
        return roundedNum;
      } else {
        return parseFloat(numStr);
      }
    },
    showToastMessage(toastMessage, type, inputId) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
      if (type === ToastTypes.ERROR && inputId) {
        document.getElementById(inputId).classList.add("invalid");
        setTimeout(() => {
          document.getElementById(inputId).classList.remove("invalid");
        }, 3000);
      }
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 5000);
    },
    selectAll(event) {
      event.target.select();
    },
    onNoCutOutInput(product) {
      product.passepartout_no_cut_out = !product.passepartout_no_cut_out;
      this.updatePrice();
    },
    checkProductSizeLimits(product) {
      if (
        this.currentWorkshopWork.frame.height &&
        this.currentWorkshopWork.frame.width
      ) {
        const { width, height } = this.currentWorkshopWork.frame;
        // Check for max values
        const [lowerValueMax, higherValueMax] =
          width >= height ? [height, width] : [width, height];
        const [lowerLimitMax, higherLimitMax] =
          product.max_width >= product.max_height
            ? [product.max_height, product.max_width]
            : [product.max_width, product.max_height];

        // Check for min values
        const [lowerValueMin, higherValueMin] =
          width <= height ? [height, width] : [width, height];
        const [lowerLimitMin, higherLimitMin] =
          product.min_width <= product.min_height
            ? [product.min_height, product.min_width]
            : [product.min_width, product.min_height];

        return (
          lowerLimitMax >= lowerValueMax &&
          higherLimitMax >= higherValueMax &&
          lowerLimitMin <= lowerValueMin &&
          higherLimitMin <= higherValueMin
        );
      } else {
        return true;
      }
    },
    adjustOtherComponentsSize() {
      this.adjustOtherComponentsHeight(this.currentWorkshopWorkFrameHeight);
      this.adjustOtherComponentsWidth(this.currentWorkshopWorkFrameWidth);
    },
    adjustOtherComponentsHeight(value) {
      if (this.currentWorkshopWork.passepartout) {
        if (
          this.checkProductSizeLimits(this.currentWorkshopWork.passepartout)
        ) {
          this.currentWorkshopWork.passepartout.setHeight(
            this.getValueForMeasurementType(value),
            true
          );
        } else {
          this.showToastMessage(
            "Das Passepartout unterstützt das neue Maß nicht.",
            ToastTypes.ERROR
          );
          this.$store.commit("setWorkshopPassepartout", undefined);
        }
      }
      if (this.currentWorkshopWork.glas) {
        if (this.checkProductSizeLimits(this.currentWorkshopWork.glas)) {
          this.currentWorkshopWork.glas.setHeight(
            this.getValueForMeasurementType(value),
            true
          );
        } else {
          this.showToastMessage(
            "Das Glas unterstützt das neue Maß nicht.",
            ToastTypes.ERROR
          );
          this.$store.commit("switchWorkshopGlas", "");
        }
      }
      if (this.currentWorkshopWork.back_wall) {
        if (this.checkProductSizeLimits(this.currentWorkshopWork.back_wall)) {
          this.currentWorkshopWork.back_wall.setHeight(
            this.getValueForMeasurementType(value),
            true
          );
        } else {
          this.showToastMessage(
            "Die Rückwand unterstützt das neue Maß nicht.",
            ToastTypes.ERROR
          );
          this.$store.commit("switchWorkshopBackWall", "");
        }
      }
    },
    adjustOtherComponentsWidth(value) {
      if (this.currentWorkshopWork.passepartout) {
        if (
          this.checkProductSizeLimits(this.currentWorkshopWork.passepartout)
        ) {
          this.currentWorkshopWork.passepartout.setWidth(
            this.getValueForMeasurementType(value),
            true
          );
        } else {
          this.showToastMessage(
            "Das Passepartout unterstützt das neue Maß nicht.",
            ToastTypes.ERROR
          );
          this.$store.commit("setWorkshopPassepartout", undefined);
        }
      }
      if (this.currentWorkshopWork.glas) {
        if (this.checkProductSizeLimits(this.currentWorkshopWork.glas)) {
          this.currentWorkshopWork.glas.setWidth(
            this.getValueForMeasurementType(value),
            true
          );
        } else {
          this.showToastMessage(
            "Das Glas unterstützt das neue Maß nicht.",
            ToastTypes.ERROR
          );
          this.$store.commit("switchWorkshopGlas", "");
        }
      }
      if (this.currentWorkshopWork.back_wall) {
        if (this.checkProductSizeLimits(this.currentWorkshopWork.back_wall)) {
          this.currentWorkshopWork.back_wall.setWidth(
            this.getValueForMeasurementType(value),
            true
          );
        } else {
          this.showToastMessage(
            "Die Rückwand unterstützt das neue Maß nicht.",
            ToastTypes.ERROR
          );
          this.$store.commit("switchWorkshopBackWall", "");
        }
      }
    },
    doubleInputValidation() {
      this.validateAboveZeroAndFullNumbers();
      this.validateQuantityInput();
    },
    validateAboveZeroAndFullNumbers() {
      if (
        this.currentWorkshopWork.quantity < 1 &&
        this.currentWorkshopWork.quantity !== ""
      ) {
        this.currentWorkshopWork.quantity = 1;
      }
      this.currentWorkshopWork.quantity = this.currentWorkshopWork.quantity
        ? this.currentWorkshopWork.quantity.toFixed(0)
        : 1;
      this.updatePrice();
    },
    validateQuantityInput() {
      if (
        this.currentWorkshopWork.quantity < 1 &&
        this.currentWorkshopWork.quantity !== ""
      ) {
        this.currentWorkshopWork.quantity = 1;
      }
      this.updatePrice();
    },
    addProductToOrder() {
      this.$store.commit("addWorkshopWork");
      this.$store.commit("updateSelectedConfigurations");

      this.showToastMessage(
        "Einrahmung zur Bestellung hinzugefügt.",
        ToastTypes.SUCCESS
      );
      this.syncConfiguration();
      this.$store.commit("initializeWorkshopWork", true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    getValueForMeasurementType(value) {
      let finalValue;
      this.currentWorkshopWork.frame.selected_measurement_type ===
        Measurements.FALZMASS
        ? (finalValue = value - this.currentWorkshopWork.folding_allowance)
        : (finalValue = value);
      return finalValue;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../../assets/sass/style.scss";

.framingWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .radioOptions {
    display: flex;

    @media (max-width: $tablet) {
      flex-direction: column;
    }
  }

  .addToOrderButtonWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .checkboxCollection {
    width: 50%;
    transition: 0.3s;

    @media (max-width: $tablet) {
      width: 100%;
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
