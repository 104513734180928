import ProductFactory from "@/utility/ProductFactory.js";
import ProductFamily from "@/models/productFamily.js";
const axios = require("axios");
const store = require("../main.js");
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import UserTypeProvider from "./UserTypeProvider.js";
import UserTypes from "@/models/Types/UserTypes.js";
//const ProductTypes = require("@/models/Types/ProductTypes.js");
let instance = null;
class BackendHandler {
  constructor() {
    if (!instance) {
      instance = this;
    }

    return instance;
  }

  async initializeProducts(productIds) {
    const idToken = await firebase.auth().currentUser.getIdToken();

    for (const productId of productIds) {
      let productFamily = new ProductFamily(productId);
      const response = await axios.get(
        process.env.VUE_APP_BACKEND_BASE_URL + `/productfamily/${productId}`,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      for (const product of response.data) {
        productFamily.addProduct(ProductFactory.fromJson(product));
      }

      store.default.commit("addProductFamily", productFamily);
    }
    store.default.commit("setSelectedProductFamily", 0);
  }
  async getProductFamilyById(parent_id) {
    try {
      let productFamily = new ProductFamily(parent_id);
      const response = await axios.get(
        process.env.VUE_APP_BACKEND_BASE_URL + `/productfamily/${parent_id}`,
        {}
      );
      for (const product of response.data) {
        productFamily.addProduct(await ProductFactory.fromJson(product));
      }
      return productFamily;
    } catch (e) {
      console.log(e);
    }
  }
  async searchProducts(term, fetchProductSiblings) {
    let productFamilies = [];
    let uniqueFamilyDescriptions = [];
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      const response = await axios.get(
        process.env.VUE_APP_BACKEND_BASE_URL + `/search/${term}`,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
            fetchProductSiblings: fetchProductSiblings,
          },
        }
      );

      let uniqueGlasOptions = [];
      console.log(response.data);
      
      await (async () => {
        for (const product of response.data) {
          try {
            if (
              !productFamilies.find((e) => e.parent_id === product.parent_id)
            ) {
              let productFamily = new ProductFamily(product.parent_id);
              let filtered = response.data.filter(
                (e) => e.parent_id === product.parent_id
              );
              let uniqueProductTypes = [];
              let productPromises = filtered.map((e) => {
                if (
                  !uniqueGlasOptions.includes(e.search_params) &&
                  e.glas_group
                ) {
                  uniqueGlasOptions.push(e.search_params);
                  return ProductFactory.fromJson(e);
                } else if (!e.glas_group) {
                  return ProductFactory.fromJson(e);
                } else {
                  return Promise.resolve();
                }
              });
              // Filter out undefined values from the products array
              let products = (await Promise.all(productPromises)).filter(
                (product) => product !== undefined && (UserTypeProvider.getUserType() === UserTypes.RETAILER || product.price !== 0)
              );

              for (const product of products) {
                if (!uniqueProductTypes.includes(product.productType)) {
                  productFamily.addProduct(product);
                  uniqueProductTypes.push(product.productType);
                }
              }
              productFamily.sortProducts();
              if (
                productFamily.products[0] &&
                !uniqueFamilyDescriptions.includes(
                  productFamily.products[0].description
                )
              ) {
                productFamilies.push(productFamily);
                uniqueFamilyDescriptions.push(
                  productFamily.products[0].description
                );
              }
              //console.log(productFamily);
            }
          } catch (e) {
            console.log(e);
          }
        }
      })();

      const rawData = localStorage.getItem("ibisOnlinebestellungUserObject");
      if (rawData) {
        // eslint-disable-next-line
        const { currentConfiguration, ...rest } = JSON.parse(rawData);
        const userInfo = rest;

        // Assuming productFamilies is an array of objects and each object has an "options" property that is an array
        const availableProductFamilies = productFamilies.filter((family) => {
          // Assuming the first product option is what's needed. Modify this line if the structure is different.
          const firstProduct = family.products ? family.products[0] : null;

          if (firstProduct && firstProduct.unavailable_regions) {
            // Convert both the unavailable_regions array and the ibis_customer_id to strings and check if the latter is included in the former
            return !firstProduct.unavailable_regions.toString().includes(
              userInfo.ibis_customer_id.toString()[0]
            ); // Get first character of ibis_customer_id, convert to string and check if it's included
          } else {
            // If there's no unavailable_regions field, assume the product is available
            return true;
          }
        });
        productFamilies = availableProductFamilies;
      }

      return productFamilies;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async requestRegistration(
    name,
    email,
    phone,
    customerId,
    address,
    zip,
    addressPlace,
    country,
    companyName
  ) {

    const payload = {
      name: name,
      email: email,
      phone: phone,
      customerId: customerId,
      address: address,
      zip: zip,
      address_place: addressPlace,
      country: country,
      companyName: companyName,
    };

    try {
      await axios.post(
        process.env.VUE_APP_BACKEND_BASE_URL + "/requestRegistration",
        payload,
      );
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Senden des Registrierungsantrages ist etwas schiefgelaufen. Bitte versuchen Sie es nochmal."
      );
    }
  }
  async updateUserInformation(payload) {
    const idToken = await firebase.auth().currentUser.getIdToken();

    try {
      await axios.post(
        process.env.VUE_APP_BACKEND_BASE_URL + "/updateUserInformation",
        payload,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Aktualisieren ihrer Daten ist etwas schiefgelaufen. Bitte versuchen Sie es nochmal."
      );
    }
  }

  async placeOrder(payload) {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      await axios.post(
        process.env.VUE_APP_BACKEND_BASE_URL + "/placeOrder",
        payload,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Bestellvorgang ist etwas schiefgelaufen. Bitte versuchen Sie es nochmal."
      );
    }
  } async createCostEstimate(payload) {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      await axios.post(
        process.env.VUE_APP_BACKEND_BASE_URL + "/createCostEstimate",
        payload,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Bestellvorgang ist etwas schiefgelaufen. Bitte versuchen Sie es nochmal."
      );
    }
  }
  async initCustomerRefresh(payload) {
    const idToken = await firebase.auth().currentUser.getIdToken();

    try {
      await axios.post(
        process.env.VUE_APP_ADMIN_BASE_URL + "/customersRefresh",
        payload,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Aktualisieren ist etwas schiefgelaufen. Bitte versuchen Sie es nochmal."
      );
    }
  }
  async initProductsRefresh(payload) {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      await axios.post(
        process.env.VUE_APP_ADMIN_BASE_URL + "/productsRefresh",
        payload,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Aktualisieren ist etwas schiefgelaufen. Bitte versuchen Sie es nochmal."
      );
    }
  }
  async saveCurrentUserConfiguration(payload) {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      await axios.post(
        process.env.VUE_APP_BACKEND_BASE_URL + "/saveCurrentUserConfiguration",
        payload,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Synchronisieren der Konfiguration ist etwas schiefgelaufen."
      );
    }
  }
  async fetchCurrentUserConfiguration(ibis_customer_id) {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      const response = await axios.get(
        process.env.VUE_APP_BACKEND_BASE_URL +
        "/getCurrentUserConfiguration/" +
        `${ibis_customer_id}`,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      return response;
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Ermitteln der aktuellen Konfiguration ist etwas schiefgelaufen."
      );
    }
  }
  async exportPricelistAsPDF(payload) {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      const response = await axios.post(
        process.env.VUE_APP_BACKEND_BASE_URL + "/createPricelistPDF",
        payload,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      return response;
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Erstellen der PDF Preisliste ist etwas schiefgelaufen."
      );
    }
  }
  async exportPricelistAsExcel(payload) {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      const response = await axios.post(
        process.env.VUE_APP_BACKEND_BASE_URL + "/createPricelistExcel",
        payload,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      return response;
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Erstellen der PDF Preisliste ist etwas schiefgelaufen."
      );
    }
  }
  async getTurnover() {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      const response = await axios.get(
        process.env.VUE_APP_ADMIN_BASE_URL + "/turnover",
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      return response;
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Ermitteln des Gesamtumsatzes ist etwas schiefgelaufen."
      );
    }
  }
  async getRemainingStock() {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      const response = await axios.get(
        process.env.VUE_APP_BACKEND_BASE_URL + "/getRemainingStock",
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      return response;
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Ermitteln des Restbestandes ist etwas schiefgelaufen."
      );
    }
  }
  async exportCustomersAsExcel() {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      const response = await axios.get(
        process.env.VUE_APP_ADMIN_BASE_URL + "/exportCustomers",
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      return response;
    } catch (e) {
      console.log(e.message);
      throw new Error("Beim Export der Kunden ist etwas schiefgelaufen.");
    }
  }
  async createDiscountCode(payload) {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      await axios.post(
        process.env.VUE_APP_ADMIN_BASE_URL + "/discountCodes/create",
        payload,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Erstellen eines Gutscheincodes ist etwas schiefgelaufen. Bitte versuchen Sie es nochmal."
      );
    }
  }
  async getAllActiveDiscountCodes() {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      const response = await axios.get(
        process.env.VUE_APP_ADMIN_BASE_URL + "/discountCodes/active",
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      return response;
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Ermitteln der aktiven Gutschein-Codes ist etwas schiefgelaufen."
      );
    }
  }
  async getAllInactiveDiscountCodes() {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      const response = await axios.get(
        process.env.VUE_APP_ADMIN_BASE_URL + "/discountCodes/inactive",
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      return response;
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Ermitteln der inaktiven Gutschein-Codes ist etwas schiefgelaufen."
      );
    }
  }
  async disableDiscountCode(code) {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      await axios.post(
        process.env.VUE_APP_ADMIN_BASE_URL + "/discountCodes/disable/" + code,
        {},
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Deaktivieren eines Gutscheincodes ist etwas schiefgelaufen. Bitte versuchen Sie es nochmal."
      );
    }
  }
  async reactivateDiscountCode(code) {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      await axios.post(
        process.env.VUE_APP_ADMIN_BASE_URL +
        "/discountCodes/reactivate/" +
        code,
        {},
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Reaktivieren eines Gutscheincodes ist etwas schiefgelaufen. Bitte versuchen Sie es nochmal."
      );
    }
  }
  async deleteDiscountCode(code) {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      await axios.delete(
        process.env.VUE_APP_ADMIN_BASE_URL + "/discountCodes/" + code,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim Löschen eines Gutscheincodes ist etwas schiefgelaufen. Bitte versuchen Sie es nochmal."
      );
    }
  }
  async checkDiscountCode(code) {
    const ibis_customer_id = JSON.parse(
      localStorage.getItem("ibisOnlinebestellungUserObject")
    ).ibis_customer_id;
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      const response = await axios.post(
        process.env.VUE_APP_BACKEND_BASE_URL + "/checkDiscountCode/" + code,
        { ibis_customer_id: ibis_customer_id },
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      return response;
    } catch (e) {
      console.log(e);
      throw new Error(e.response.data.message);
    }
  }
  async updateNewsBanner(news) {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      await axios.post(
        process.env.VUE_APP_ADMIN_BASE_URL + "/newsBanner",
        { news: news },
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim aktualisieren des News-Banners ist etwas schiefgelaufen. Bitte versuchen Sie es nochmal."
      );
    }
  }
  async toggleNewsBannerVisibility() {
    const idToken = await firebase.auth().currentUser.getIdToken();
    try {
      const response = await axios.post(
        process.env.VUE_APP_ADMIN_BASE_URL + "/newsBanner/toggleVisibility",
        {},
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      return response;
    } catch (e) {
      console.log(e.message);
      throw new Error(
        "Beim aktualisieren des News-Banners ist etwas schiefgelaufen. Bitte versuchen Sie es nochmal."
      );
    }
  }
  async updateCustomerGeolocationVisibility(ibis_customer_id) {
    const idToken = await firebase.auth().currentUser.getIdToken();

    try {
      const response = await axios.post(
        process.env.VUE_APP_BACKEND_BASE_URL +
        "/updateCustomerGeolocationVisibility/" +
        `${ibis_customer_id}`,
        {},
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      return response;
    } catch (e) {
      console.log(e);
      throw new Error(e.response.data.error);
    }
  }
  async getCustomerGeolocationVisibility(ibis_customer_id) {
    const idToken = await firebase.auth().currentUser.getIdToken();

    try {
      const response = await axios.get(
        process.env.VUE_APP_BACKEND_BASE_URL +
        "/getCustomerGeolocationVisibility/" +
        `${ibis_customer_id}`,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      return response;
    } catch (e) {
      console.log(e);
      throw new Error(e.response.data.error);
    }
  }
}

export default new BackendHandler();
