<template>
  <div class="main" v-if="this.$store.getters.selectedProductFamily">
    <div class="optionListWrapper">
      <div class="optionList">
        <ul>
          <li v-for="(option, index) in this.$store.getters.selectedProductFamily
      .products" :key="index">
            <input type="radio" :id="'option' + (index + 1)" :name="'options'"
              :value="option.sub_category" @click="changeProduct(index)"
              v-bind:checked="index ===
    this.$store.getters.selectedProductFamily.selectedIndex
    " />
            <label :for="'option' + (index + 1)">{{
    option.sub_category
  }}</label>
          </li>
        </ul>
      </div>
      <div class="categoryOptionsWrapper">
        <ProductOptions></ProductOptions>
      </div>
    </div>
  </div>
  <!-- <div class="clearForm" v-if="isClearFormAvailable">
    <IbisButton buttonClass="secondary" buttonText="Formular Leeren"
      @click="clearForm"></IbisButton>
  </div> -->

</template>

<script>
import ProductOptions from "./ProductOptions.vue";
//import IbisButton from "@/components/shared/IbisButton.vue";
export default {
  components: {
    ProductOptions,
    //IbisButton,
  },

  computed: {
    isClearFormAvailable() {
      return this.$store.getters.selectedProductFamily?.products?.length > 1;
    },
  },

  methods: {
    changeProduct(index) {
      this.$store.commit("switchProduct", index);
      this.$nextTick(() => {
        const inputs = document.querySelectorAll("input");
        for (let i = 0; i < inputs.length; i++) {
          if (
            !inputs[i].disabled &&
            !inputs[i].readOnly &&
            (inputs[i].id.includes("width") ||
              inputs[i].id.includes("quantity"))
          ) {
            inputs[i].focus();
            break;
          }
        }
      });
    },
    clearForm() {
      this.$store.commit("clearForm");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.main {
  min-height: 216px;
  background: #ffffff;
  box-shadow: 0px 8px 37px rgba(64, 64, 64, 0.1);
  border-radius: 25px;
  margin-top: 30px;
  padding: 0 25px;
  max-width: 800px;

  @media (max-width: $mobile) {
    padding: 0 1rem;
  }

  display: grid;

  .optionListWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $mobile) {
      flex-direction: column;
    }

    .optionList {
      @media (max-width: $mobile) {
        width: 100%;
      }

      ul {
        @media (max-width: $mobile) {
          margin-top: 2rem;
        }

        list-style-type: none;
        display: flex;
        flex-flow: column;
        gap: 15px;
        padding: 0;

        li {
          display: flex;
          font-weight: 700;
          align-items: center;

          label {
            margin-top: 3px;
            font-size: $font-size-big;
          }

          input[type="radio"] {
            appearance: none;
            background: $ibis-grey-middle;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            margin-right: 10px;
          }

          input[type="radio"]:not(:checked)+label {
            color: $ibis-grey-middle;
            transition: 0.15s;
          }

          input[type="radio"]:checked {
            background-color: $ibis-red;
            border-color: $ibis-red;
            transition: 0.15s;
          }
        }
      }
    }

    .categoryOptionsWrapper {
      width: 65%;

      @media (max-width: $mobile) {
        width: 100%;
      }

      padding-top: 2em;
      padding-bottom: 2em;
    }
  }
}

.clearForm {
  width: 100%;
  max-width: 850px;
  display: flex;
  justify-content: flex-end;
  padding: 25px 0;
  box-sizing: border-box;
}
</style>
