<template>
  <div class="stretcherInputWrapper">
    <div class="mainHeadlineWrapper">
      <h2 class="headline">Keilrahmen</h2>
    </div>
    <IbisSpinner v-if="isLoading"></IbisSpinner>
    <div v-else>
      <ProductSelect :products="filteredProducts"
        @changeProduct="changeStretcher"
        :type="workshopProductSelectionTypes.STRETCHER_FRAME"
        ref="stretcherSelect"></ProductSelect>
    </div>
    <!--  <div v-if="currentWorkshopMode === workshopModes.CANVAS_FRAMING">
      <label class="checkboxContainer"
        style="margin-bottom: 1rem; margin-top: 2rem">
        Bild ist bereits aufgespannt
        <input type="checkbox" v-model="alreadyClamped"
          @change="setClampedStatus" />
        <span class="checkmark"></span>
      </label>
    </div> -->
    <div class="stretcherDetails">
      <div v-if="currentProduct" class="workshopOptionDetails">
        <div class="content">
          <NonConfigurableFreeSpaceDummy :roundToTwo="roundToTwo"
            :productProp="currentProduct">
          </NonConfigurableFreeSpaceDummy>
          <TopCutAndFrameOptions :updatePrice="updatePrice"
            :heightValidation="heightValidation"
            :widthValidation="widthValidation" :selectAll="selectAll"
            :productProp="currentProduct" identifier="stretcher">
          </TopCutAndFrameOptions>
          <CheckboxOptions :productProp="currentProduct"
            :syncConfiguration="syncConfiguration"
            :onWithBridgeInput="onWithBridgeInput"
            :onWithCrossInput="onWithCrossInput"></CheckboxOptions>
          <ProductRestrictions :productProp="currentProduct">
          </ProductRestrictions>
          <!-- <PositionPrice
            :roundToTwo="roundToTwo"
            :productProp="currentProduct"
          ></PositionPrice> -->
        </div>
        <div class="sizeUnitWrapper">
          <div>cm</div>
        </div>
      </div>

    </div>
    <HangingInput v-if="currentWorkshopMode !== workshopModes.CANVAS_FRAMING">
    </HangingInput>
  </div>
</template>
<script>
import NonConfigurableFreeSpaceDummy from "@/components/configurator/shared/Information/NonConfigurableFreeSpaceDummy.vue";
//import PositionPrice from "@/components/configurator/shared/Information/PositionPrice.vue";
import ProductRestrictions from "@/components/configurator/shared/Information/ProductRestrictions.vue";
import TopCutAndFrameOptions from "@/components/configurator/shared/Options/TopCutAndFrameOptions.vue";
import IbisSpinner from "@/components/shared/IbisSpinner.vue";
import ProductFactory from "@/utility/ProductFactory";
import ProductSelect from "../../shared/ProductSelect";
import FamilyTypes from "@/models/Types/FamilyTypes";
import ProductTypes from "@/models/Types/ProductTypes";
import WorkshopProductSelectionTypes from "@/models/Types/WorkshopProductSelectionTypes";
import CheckboxOptions from "@/components/configurator/shared/Options/CheckboxOptions.vue";
import HangingInput from "../../framing/components/HangingInput.vue";
import SingleProduct from "@/models/SingleProduct";
import WorkshopModes from "@/models/Types/WorkshopModes";
import CutProduct from "@/models/CutProduct";
export default {
  inject: ["$firebase"],
  props: [
    "availableProducts",
    "roundToTwo",
    "updatePrice",
    "heightValidation",
    "widthValidation",
    "selectAll",
    "syncConfiguration",
    "onWithBridgeInput",
    "onWithCrossInput",
  ],
  components: {
    IbisSpinner,
    ProductSelect,
    NonConfigurableFreeSpaceDummy,
    TopCutAndFrameOptions,
    ProductRestrictions,
    // PositionPrice,
    CheckboxOptions,
    HangingInput,
  },

  data() {
    return {
      isLoading: true,
      filteredProducts: [],
      allProducts: [],
      productTypes: ProductTypes,
      familyTypes: FamilyTypes,
      workshopProductSelectionTypes: WorkshopProductSelectionTypes,
      workshopModes: WorkshopModes,
      alreadyClamped: false,
    };
  },
  computed: {
    currentProduct: {
      get() {
        return this.$store.getters.currentWorkshopWork.stretcher_frame;
      },
      set(value) {
        this.$store.commit("switchWorkshopStretcherFrame", value);
      },
    },
    currentWorkshopWork() {
      return this.$store.getters.currentWorkshopWork;
    },
    currentWorkshopMode() {
      return this.$store.getters.currentWorkshopMode;
    },
  },
  watch: {
    currentWorkshopWork: {
      handler: async function (newVal, oldVal) {
        if (newVal !== oldVal && !this.isLoading) {
          if (this.currentWorkshopMode === WorkshopModes.CANVAS_STRETCH || this.currentWorkshopMode === WorkshopModes.CANVAS_FRAMING) {
            if (!(this.currentProduct instanceof SingleProduct)) {
              await this.initializeStretchers();
            }
          }
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.initializeStretchers();
  },
  methods: {
    async initializeStretchers() {
      this.isLoading = true;

      this.filteredProducts = [];
      this.allProducts = [];
      const db = this.$firebase.firestore();
      const productsRef = db.collection("products");
      const productsPromises = this.availableProducts.map(async (e) => {
        const productRef = await productsRef
          .where("article_id", "==", e.key)
          .get();
        await productRef.forEach(async (doc) => {
          const product = await ProductFactory.fromJson(doc.data());
          this.allProducts.push(product);

        })
        if (e.key === "NONE") {
          const product = new CutProduct("Bild bereits aufgespannt");
          product.description = "Bild bereits aufgespannt";
          product.price = 0;
          product.sf_decimal_size_price = 0;
          product.familyType = FamilyTypes.STRETCHER_FRAME
          this.allProducts.push(product);
        }

      });
      await Promise.all(productsPromises);
      this.filteredProducts = [...this.allProducts];

      if (!(this.currentProduct instanceof SingleProduct)) {
        this.changeStretcher(this.filteredProducts[0]);
        this.$nextTick(() => {
          if (this.$refs.stretcherSelect) {
            this.$refs.stretcherSelect.presetGlas(this.filteredProducts[0]);
          }
        });
      }

      this.isLoading = false;
    },
    changeStretcher(product) {
      this.$emit("changeProduct", product);
      this.currentProduct = product;
    },
    setClampedStatus() {
      this.$store.commit("setWorkshopStretcherClampedStatus", this.alreadyClamped);
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../../../assets/sass/style.scss";

.stretcherInputWrapper {
  .mainHeadlineWrapper {
    width: 60vw;

    @media (max-width: $mobile) {
      width: 95vw;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;

    .headline {
      text-align: left;
    }
  }
}

.sizeUnitWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 12.8rem;
  min-width: 1.5vw;
  margin-left: 0.5rem;

  span {
    height: fit-content;
  }
}
</style>
