<template>
  <div class="adminPanelPage" id="adminPanelPage">
    <div class="adminPanelHeader">Admin-Bereich</div>
    <div class="adminTools">
      <ManageCustomers></ManageCustomers>
      <ManageProducts></ManageProducts>
    </div>
    <div class="dicountCodes">
      <DiscountCodes></DiscountCodes>
    </div>
    <div class="newsBanner">
      <EditNewsBanner></EditNewsBanner>
    </div>
    <div class="totalTurnover">
      <h2>Gesamtumsatz durch Konfigurator (ausgenommen Testbestellungen)</h2>
      <div class="turnoverLoadingSpinner" v-if="isTurnoverLoading">
        <IbisSpinner></IbisSpinner>
      </div>
      <div v-if="!isTurnoverLoading">
        <h3>Umsatz insgesamt (Fachhandel): {{ totalTurnover }}</h3>
        <div v-for="yearData in sortedTurnover.turnoverByYear"
          :key="yearData.year">
          <h3>Umsatz {{ yearData.year }} :</h3>
          <div v-for="cityData in yearData.cities" :key="cityData[0]">
            {{ cityData[0] }}: {{
        formatPriceWithSeparator(cityData[1].toFixed(2)) }}
          </div>
        </div>
        <h3>Umsatz ingesamt (Nicht Fachhandel): </h3>
        <div v-for="yearData in sortedTurnover.nonFachhandelTurnoverByYear"
          :key="yearData.year">
          <h3>Umsatz {{ yearData.year }} :</h3>
          {{ sortedTurnover.totalNonFachhandelTurnover }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManageCustomers from "./components/ManageCustomers.vue";
import ManageProducts from "./components/ManageProducts.vue";
import IbisSpinner from "../shared/IbisSpinner.vue";
import BackendHandler from "@/utility/BackendHandler";
import DiscountCodes from "./components/DiscountCodes.vue";
import EditNewsBanner from "./components/EditNewsBanner.vue";

export default {
  mounted() {
    document.getElementById("adminPanelPage").classList.add("fade-in-bottom");
    this.getTurnover();
  },
  components: {
    ManageCustomers,
    ManageProducts,
    IbisSpinner,
    DiscountCodes,
    EditNewsBanner,
  },
  data() {
    return {
      totalTurnover: 0,
      sortedTurnover: [],
      isTurnoverLoading: false,
    };
  },
  methods: {
    async getTurnover() {
      this.isTurnoverLoading = true;
      const response = await BackendHandler.getTurnover();
      this.totalTurnover = response.data.totalTurnover;
      this.sortedTurnover = response.data;
      this.isTurnoverLoading = false;
    },

    formatPriceWithSeparator(value) {
      let formattedValue = value.toString().replace(".", ",");
      formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return formattedValue;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.adminPanelPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0rem;

  .adminPanelHeader {
    width: 70%;
    text-align: start;
    font-size: $font-size-xlarge;
    margin-bottom: 5rem;

    @media (max-width: $mobile) {
      margin-bottom: 3rem;
    }
  }

  .adminTools {
    display: flex;
    align-items: flex-start;
    gap: 10rem;

    @media (max-width: $mobile) {
      gap: 4rem;
      flex-flow: column;
    }
  }

  .dicountCodes {
    width: 70%;

    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  .newsBanner {
    width: 70%;

    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  .totalTurnover {
    margin-top: 1rem;

    .turnoverLoadingSpinner {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
  }
}
</style>
