<template>
  <div class="heroProductSearch">
    <div class="gemIcon">
      <img src="../../assets/icons/gem-duotone.svg" draggable="false" />
    </div>
    <div class="mainSearchCaption">Produktsuche</div>
    <div class="subSearchCaption">
      Suchen Sie nach dem gewünschten Produkt um den Bestellprozess zu starten.
    </div>
    <div class="heroSearchBar"><ProductSearchbar /></div>
  </div>
</template>
<script>
import ProductSearchbar from "../configurator/shared/ProductSearchbar.vue";
export default {
  components: {
    ProductSearchbar,
  },
  computed: {
    selectedConfigurationsLength() {
      return this.$store.getters.selectedConfigurations.length;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.heroProductSearch {
  position: absolute;
  width: 60vw;
  background-color: $ibis-grey;
  border-radius: 25px;
  top: -12vh;
  left: 50%;
  transform: translate(-50%);
  padding: 0 5% 5% 5%;
  max-width: 800px;
  @media (max-width: $mobile) {
    width: 90vw;
    top: -16vh;
  }
  .gemIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    width: 100%;

    img {
      height: 100%;
      width: 5%;
      @media (max-width: $mobile) {
        margin-bottom: 1rem;
        width: 2rem;
      }
    }
  }
  .mainSearchCaption {
    text-align: left;
    font-size: $font-size-xlarge;
    font-weight: bold;
    margin-bottom: 1em;
  }
  .subSearchCaption {
    text-align: left;
    margin-bottom: 3em;
  }
}
</style>
