<template>
  <div class="stretchingWrapper">
    <StretcherInput :availableProducts="workshopStretchers"
      :roundToTwo="roundToTwo" :updatePrice="updatePrice"
      :heightValidation="heightValidation" :widthValidation="widthValidation"
      :selectAll="selectAll" :syncConfiguration="syncConfiguration"
      :onWithBridgeInput="onWithBridgeInput"
      :onWithCrossInput="onWithCrossInput"></StretcherInput>
    <PictureValueInput></PictureValueInput>
    <div class="amount">
      <AmountInput :isWorkshopWorkQuantity="true"
        :doubleInputValidation="doubleInputValidation"></AmountInput>
    </div>
    <div class="additionalServices">
      <AdditionalServices></AdditionalServices>
    </div>
    <div class="notes">
      <ProductNotes :isWorkshopNotes="true"></ProductNotes>
    </div>
    <div class="totalPrice" v-if="currentWorkshopWork.stretcher_frame">
      <PositionPrice :roundToTwo="roundToTwo" :productProp="currentWorkshopWork"
        text="Gesamtpreis:" style="margin-top: 0"></PositionPrice>
    </div>
    <div class="addToOrderButtonWrapper">
      <IbisButton buttonClass="primary" buttonText="Zur Bestellung hinzufügen +"
        :buttonDisabled="!isStretchingValid" @click="addProductToOrder()">
      </IbisButton>
    </div>
  </div>
</template>
<script>
import StretcherInput from "./components/StretcherInput.vue";
import WorkshopStretchers from "@/models/Types/WorkshopStretchers";
import DBSyncer from "@/utility/DBSyncer";
import ToastTypes from "@/models/Types/ToastTypes";
import AmountInput from "../../shared/Options/AmountInput.vue";
import IbisButton from "@/components/shared/IbisButton.vue";
import ProductNotes from "../../shared/ProductNotes.vue";
import PositionPrice from "../../shared/Information/PositionPrice.vue";
import PictureValueInput from "../shared/PictureValueInput.vue";
import AdditionalServices from "../shared/AdditionalServices.vue";
export default {
  data() {
    return { workshopStretchers: WorkshopStretchers };
  },
  components: {
    StretcherInput,
    IbisButton,
    AmountInput,
    ProductNotes,
    PositionPrice,
    PictureValueInput,
    AdditionalServices,
  },
  computed: {
    currentWorkshopWork() {
      return this.$store.getters.currentWorkshopWork;
    },
    isStretchingValid() {
      if (this.currentWorkshopWork.stretcher_frame) {
        const userObject = JSON.parse(
          localStorage.getItem("ibisOnlinebestellungUserObject")
        );
        let notesValid = true;
        if (userObject.customer_type !== "Fachhandel" || !userObject.customer_type) {
          notesValid = this.currentWorkshopWork?.notes?.length > 0;
        }

        const edgesHaveToBeColored = this.currentWorkshopWork?.services?.some(
          (service) => service.key === "Einfaerbung_Kanten"
        );
        if (edgesHaveToBeColored) {
          if (this.currentWorkshopWork?.edges_have_to_be_colored_notes?.length < 5) {
            return false
          }
        }
        return (
          this.currentWorkshopWork.stretcher_frame.width &&
          this.currentWorkshopWork.stretcher_frame.height &&
          this.currentWorkshopWork.picture_value > 0 && notesValid
        );
      } else {
        return false;
      }
    },
  },
  methods: {
    roundToTwo(num) {
      return +parseFloat(Math.round(num + "e+3") + "e-3");
    },
    updatePrice() {
      this.$store.commit("updatePrice");
    },
    syncConfiguration() {
      this.updatePrice()
      DBSyncer.syncConfiguration();
    },
    heightValidation(product, identifier) {
      let value = this.allowOneDecimal(
        document.getElementById("heightInput" + (identifier ? identifier : '')).value.toString()
      );
      if (product && !value) {
        product.setHeight(1, true);
      } else {
        product.setHeight(value, true);
      }
      this.$forceUpdate();

      if (
        parseFloat(value) + product.width >
        product.max_height + product.max_width
      ) {
        this.showToastMessage(
          "Das Maximalmaß wurde überschritten.",
          ToastTypes.ERROR,
          "heightInput"
        );
      }
      if (
        this.currentWorkshopWork.stretcher_frame.width >= 80 && this.currentWorkshopWork.stretcher_frame.height >= 80
      ) {
        this.currentWorkshopWork.stretcher_frame.sf_with_cross = true;
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge = false;
      }
      else if (
        this.currentWorkshopWork.stretcher_frame.width >= 80 ||
        this.currentWorkshopWork.stretcher_frame.height >= 80
      ) {
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge = true;
        this.currentWorkshopWork.stretcher_frame.sf_with_cross = false;
      } else {
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge = false;
        this.currentWorkshopWork.stretcher_frame.sf_with_cross = false;
      }
      this.updatePrice();
    },
    widthValidation(product, identifier) {
      let value = this.allowOneDecimal(
        document.getElementById("widthInput" + (identifier ? identifier : '')).value.toString()
      );

      if (product && !value) {
        product.setWidth(1, true);
      } else {
        product.setWidth(value, true);
      }
      this.$forceUpdate();
      if (
        product.height + parseFloat(value) >
        product.max_height + product.max_width
      ) {
        this.showToastMessage(
          "Das Maximalmaß wurde überschritten.",
          ToastTypes.ERROR,
          "widthInput"
        );
      }
      if (
        this.currentWorkshopWork.stretcher_frame.width >= 80 && this.currentWorkshopWork.stretcher_frame.height >= 80
      ) {
        this.currentWorkshopWork.stretcher_frame.sf_with_cross = true;
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge = false;
      }
      else if (
        this.currentWorkshopWork.stretcher_frame.width >= 80 ||
        this.currentWorkshopWork.stretcher_frame.height >= 80
      ) {
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge = true;
        this.currentWorkshopWork.stretcher_frame.sf_with_cross = false;
      } else {
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge = false;
        this.currentWorkshopWork.stretcher_frame.sf_with_cross = false;
      }
      this.updatePrice();
    },
    selectAll(event) {
      event.target.select();
    },
    onWithBridgeInput() {
      this.currentWorkshopWork.stretcher_frame.sf_with_bridge =
        !this.currentWorkshopWork.stretcher_frame.sf_with_bridge;

      if (
        (this.currentWorkshopWork.stretcher_frame.width <= 80 &&
          this.currentWorkshopWork.stretcher_frame.height <= 80) ||
        this.currentWorkshopWork.stretcher_frame.sf_with_cross
      ) {
        this.currentWorkshopWork.stretcher_frame.sf_with_cross = false;
      }
      this.updatePrice();
    },
    onWithCrossInput() {
      this.currentWorkshopWork.stretcher_frame.sf_with_cross =
        !this.currentWorkshopWork.stretcher_frame.sf_with_cross;

      if (
        this.currentWorkshopWork.stretcher_frame.width <= 80 ||
        this.currentWorkshopWork.stretcher_frame.height <= 80 ||
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge
      ) {
        this.currentWorkshopWork.stretcher_frame.sf_with_bridge = false;
      }
      this.updatePrice();
    },
    allowOneDecimal(numStr) {
      const [wholeNum, decimalNum = ""] = numStr.split(".");

      if (decimalNum.length >= 1) {
        const roundedNum = Number.parseFloat(
          `${wholeNum}.${decimalNum}`
        ).toFixed(1);
        return roundedNum;
      } else {
        return parseFloat(numStr);
      }
    },
    showToastMessage(toastMessage, type, inputId) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
      if (type === ToastTypes.ERROR && inputId) {
        document.getElementById(inputId).classList.add("invalid");
        setTimeout(() => {
          document.getElementById(inputId).classList.remove("invalid");
        }, 3000);
      }
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 5000);
    },
    doubleInputValidation() {
      this.validateAboveZeroAndFullNumbers();
      this.validateQuantityInput();
    },
    validateAboveZeroAndFullNumbers() {
      if (
        this.currentWorkshopWork.quantity < 1 &&
        this.currentWorkshopWork.quantity !== ""
      ) {
        this.currentWorkshopWork.quantity = 1;
      }
      this.currentWorkshopWork.quantity = this.currentWorkshopWork.quantity
        ? this.currentWorkshopWork.quantity.toFixed(0)
        : 1;
      this.updatePrice();
    },
    validateQuantityInput() {
      if (
        this.currentWorkshopWork.quantity < 1 &&
        this.currentWorkshopWork.quantity !== ""
      ) {
        this.currentWorkshopWork.quantity = 1;
      }
      this.updatePrice();
    },
    addProductToOrder() {
      this.$store.commit("addWorkshopWork");
      this.$store.commit("updateSelectedConfigurations");

      this.showToastMessage(
        "Leinwandaufspannung zur Bestellung hinzugefügt.",
        ToastTypes.SUCCESS
      );
      this.syncConfiguration();
      this.$store.commit("initializeWorkshopWork", true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
<style scoped lang="scss">
.stretchingWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .radioOptions {
    display: flex;
  }

  .addToOrderButtonWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
