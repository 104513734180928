<template>
  <div class="modeSwitchWrapper">
    <div class="modeSwitch">
      <div
        :class="[
          'option',
          configuratorMode === configuratorModes.PRODUCT
            ? 'selectedMode'
            : 'unselectedMode',
        ]"
        style="border-top-left-radius: 15px"
        @click="configuratorMode = configuratorModes.PRODUCT"
      >
        {{ configuratorModes.PRODUCT }}
      </div>
      <div
        :class="[
          'option',
          configuratorMode === configuratorModes.WORKSHOP_WORKS
            ? 'selectedMode'
            : 'unselectedMode',
        ]"
        style="border-top-right-radius: 15px"
        @click="configuratorMode = configuratorModes.WORKSHOP_WORKS"
      >
        {{ configuratorModes.WORKSHOP_WORKS }}
      </div>
    </div>
  </div>
</template>
<script>
import ConfiguratorMode from "@/models/Types/ConfiguratorMode";
export default {
  data() {
    return {
      configuratorModes: ConfiguratorMode,
    };
  },
  computed: {
    configuratorMode: {
      get() {
        return this.$store.getters.currentConfiguratorMode;
      },
      set(value) {
        this.$store.commit("setConfiguratorMode", value);
      },
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.modeSwitchWrapper {
  margin-top: 2rem;
  @media (max-width: $tablet) {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  margin-bottom: -2rem;
  display: flex;
  justify-content: center;
  .modeSwitch {
    display: flex;
    width: 70vw;
    @media (max-width: $tablet) {
      width: 100vw;
    }
    justify-content: space-between;
    .option {
      width: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.5rem;
      font-size: $font-size-bigger;
      cursor: pointer;
      transition: all 0.1s ease-in-out; // Add transition for smooth effect
    }

    .selectedMode {
      color: $ibis-red;
      border-top: 2px solid $ibis-red;
      font-weight: bold;
      transform: translateY(-3px); // Slightly raise the selected option
    }
    .unselectedMode {
      border-top: 2px solid $ibis-black;
      opacity: 0.6;
      transform: translateY(3px); // Slightly lower the unselected option
    }
  }
}
</style>
