<template>
  <div
    :class="!imagesAvailable ? 'mainWrapper' : 'imageItemOptionsWrapper'"
    id="mainWrapper"
    v-show="!currentConfigLoading"
  >
    <div class="topIconWrapper">
      <img class="topIcon" src="../../../assets/icons/gem-duotone.svg" />
    </div>
    <div class="mainHeadlineWrapper">
      <h1 class="headline">Produktkonfigurator</h1>
    </div>
    <div class="searchBarWrapper" v-if="!currentConfigLoading">
      <ProductSearchbar />
    </div>
    <div class="imagesAndOptions">
      <div class="productImages" v-if="imagesAvailable">
        <ProductImages></ProductImages>
      </div>
      <div class="optionsWrapper" v-if="!currentConfigLoading">
        <ProductConfigurator />
      </div>
    </div>
    <div class="selectedProductIDsWrapper" v-if="!currentConfigLoading">
      <SelectedProductIDs />
    </div>

    <div class="orderConfirmWrapper" v-if="!currentConfigLoading">
      <OrderConfirm />
    </div>
  </div>
  <div class="fetchingSpinner" v-show="currentConfigLoading">
    <IbisSpinner></IbisSpinner>
  </div>
</template>
<script>
import ProductConfigurator from "./ProductConfigurator.vue";
import ProductSearchbar from "../shared/ProductSearchbar.vue";
import SelectedProductIDs from "../shared/SelectedProductIDs.vue";
import OrderConfirm from "../shared/OrderConfirm.vue";
import IbisSpinner from "@/components/shared/IbisSpinner.vue";
import DBSyncer from "@/utility/DBSyncer";
import ProductImages from "@/components/shared/ProductImages.vue";

export default {
  inject: ["$firebase"],
  components: {
    ProductConfigurator,
    SelectedProductIDs,
    ProductSearchbar,
    OrderConfirm,
    IbisSpinner,
    ProductImages,
  },

  data() {
    return {
      currentConfigLoading: true,
      imagesAvailable: false, // Data property to track if images are available
    };
  },

  computed: {
    currentProduct() {
      return this.$store.getters.selectedProductFamily?.products[0];
    },
  },

  watch: {
    // Watch for changes in currentProduct to re-check if images are available
    currentProduct: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.checkImagesAvailable(newValue.product_id);
        }
      },
    },
  },

  methods: {
    async checkImagesAvailable(productId) {
      if (!productId) return;
      const storageRef = this.$firebase
        .storage()
        .ref(`ProductImages/${productId}`);
      try {
        const result = await storageRef.listAll();
        this.imagesAvailable = result.items.length > 0;
      } catch (error) {
        console.error("Error checking for images", error);
        this.imagesAvailable = false;
      }
    },

    async fetchCurrentUserConfiguration() {
      this.currentConfigLoading = true;
      await DBSyncer.fetchUserConfiguration();
      this.currentConfigLoading = false;
    },
  },

  mounted() {
    document.getElementById("mainWrapper").classList.add("fade-in-bottom");
    this.fetchCurrentUserConfiguration();
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.mainWrapper {
  width: 70vw;
  @media (max-width: $mobile) {
    width: 100vw;
    margin-top: 0;
  }
  @media (min-width: $mobile) and (max-width: $tablet) {
    width: 90vw;
  }
  background-color: $ibis-grey;
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 60px;
  margin-bottom: 100px;
  margin-top: 3em;
  max-width: 1100px;
  .topIconWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15%;
    margin-bottom: 25px;

    .topIcon {
      height: 50px;
    }
  }
  .mainHeadlineWrapper {
    width: 60vw;

    @media (max-width: $mobile) {
      width: 95vw;
    }
    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }
    max-width: 850px;
    .headline {
      text-align: left;
      @media (max-width: $mobile) {
        text-align: center;
      }
    }
  }

  .selectedProductIDsWrapper {
    width: 60vw;
    @media (max-width: $mobile) {
      width: 100vw;
    }
    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;
  }
  .searchBarWrapper {
    width: 60vw;
    @media (max-width: $mobile) {
      width: 95vw;
    }
    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }
    max-width: 850px;

    margin-top: 30px;
  }
  /* .currentProductInformationWrapper {
    width: 60vw;
    margin-top: 30px;
  } */

  .optionsWrapper {
    width: 60vw;
    @media (max-width: $mobile) {
      width: 100vw;
    }
    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }
    max-width: 850px;
  }
  .overviewWrapper {
    width: 60vw;
    @media (max-width: $mobile) {
      width: 100vw;
    }
    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }
    max-width: 850px;
  }
  .orderConfirmWrapper {
    width: 60vw;
    @media (max-width: $mobile) {
      width: 100vw;
      display: flex;
      justify-content: center;
    }
    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }
    max-width: 850px;

    display: flex;
    justify-content: flex-end;
  }
}
.imageItemOptionsWrapper {
  padding: 0rem 5rem;
  box-sizing: border-box;
  width: 100vw;
  @media (max-width: $tablet) {
    padding: 3rem 0;
    align-items: center;
  }
  @media (max-width: $mobile) {
    width: 100vw;
    margin-top: 0;
    padding: 2rem 0;
  }
  .topIconWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15%;
    margin-bottom: 25px;

    .topIcon {
      height: 50px;
    }
  }
  display: flex;
  justify-content: center;
  .searchBarWrapper {
    width: 60vw;
    @media (max-width: $mobile) {
      width: 95vw;
    }
    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }
    max-width: 850px;

    margin-top: 30px;
  }
  background-color: $ibis-grey;
  margin: auto;
  display: flex;
  flex-flow: column;
  padding-top: 40px;
  padding-bottom: 60px;
  margin-bottom: 100px;
  margin-top: 3em;
  max-width: 1500px;
  .imagesAndOptions {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $tablet) {
      flex-direction: column;
      justify-content: unset;
    }
    .productImagesWrapper {
      min-height: 100px;
      min-width: 20%;
    }

    .configuratorWrapper {
      width: 70%;
      @media (max-width: $mobile) {
        width: 100%;
      }
      @media (min-width: $mobile) and (max-width: $tablet) {
        width: 80%;
      }
      max-width: 850px;
    }
  }

  .selectedProductIDsWrapper {
    width: 100%;
    @media (max-width: $mobile) {
      width: 100vw;
    }
  }
  .orderConfirmWrapper {
    width: 100%;
    @media (max-width: $tablet) {
      width: 100%;
      display: flex;
    }
    @media (max-width: $mobile) {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    display: flex;
    justify-content: flex-end;
  }
}
.fetchingSpinner {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.optionsWrapper {
    width: 60vw;
    @media (max-width: $mobile) {
      width: 100vw;
    }
    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }
    max-width: 850px;
  }
</style>
