<template>
  <div class="bottomCutOptions">
    <div
      class="numberOfEdgesInputWrapper"
      v-show="
        currentProduct.familyType !== familyTypes.PASSEPARTOUT &&
        currentProduct.familyType !== familyTypes.GLAS && !currentProduct.is_back_wall
      "
    >
      <select
        v-model="this.$store.getters.currentProduct.selected_denomination_type"
        @change="onDenominationTypeChange()"
      >
        <option
          :value="denominations.FRAMES"
          :selected="
            currentProduct.selected_denomination_type === denominations.FRAMES
          "
        >
          {{ denominations.FRAMES }}
        </option>
        <option
          :value="denominations.LEGS"
          :selected="
            currentProduct.selected_denomination_type === denominations.LEGS
          "
        >
          {{ denominations.LEGS }}
        </option>
      </select>
    </div>
    <label class="checkboxContainer" v-if="currentProduct.is_aluminium"
      >Mit Poly
      <input
        id="withPolyCheckbox"
        type="checkbox"
        v-model="this.$store.getters.currentProduct.with_poly"
        @input="onWithPolyInput()"
      />
      <span class="checkmark"></span>
    </label>
  </div>
</template>
<script>
import FamilyTypes from "@/models/Types/FamilyTypes";
import Denominations from "@/models/Types/Denominations";

export default {
  props: ["onDenominationTypeChange"],
  data() {
    return {
      familyTypes: FamilyTypes,
      denominations: Denominations,
    };
  },
  computed: {
    currentProduct() {
      return this.$store.getters.currentProduct;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../../assets/sass/style.scss";
.bottomCutOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .numberOfEdgesInputWrapper {
    display: flex;
    align-items: center;
    gap: 0.5em;

    select {
      min-width: 65%;
    }
  }
}
input {
  padding: 12px 10px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  max-width: 4vw;
  text-align: center;
  @media (max-width: $mobile) {
    min-width: 3rem;
  }
}

.checkboxContainer {
  z-index: 1;
  margin-left: 0.5em;
  display: flex;
  position: relative;
  padding-left: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background-color: $ibis-grey-middle;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked ~ .checkmark {
    background-color: $ibis-red;
    transition: 0.15s;
    &:after {
      display: block;
    }
  }

  .checkmark:after {
    left: 6.5px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

select {
  padding: 12px 20px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("../../../../assets/icons/arrow_down.svg");
  background-repeat: no-repeat;
  background-position: calc(95%) center;
  padding-right: 2em;
  min-width: 40%;
}

select::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}
select:focus {
  outline: none;
  border-color: $ibis-black;
}

input {
  padding: 12px 10px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  max-width: 4vw;
  text-align: center;
  @media (max-width: $mobile) {
    min-width: 3rem;
  }
}
.justify-end {
  justify-content: flex-end !important;
}
.invalid {
  border: 2px solid $ibis-red;
  color: $ibis-red;
}
</style>
