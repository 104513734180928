<template>
  <div class="tooltip-container">
    <slot></slot>
    <div><img src="../../assets/icons/question-mark.svg" alt="" /></div>
    <div class="tooltip-text" v-html="tipText"></div>
  </div>
</template>

<script>
export default {
  props: {
    tipText: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const tooltip = this.$el.querySelector('.tooltip-text');
    const container = this.$el;

    container.addEventListener('mouseover', () => {
      const tooltipRect = tooltip.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      // Reset any previous positioning classes
      tooltip.classList.remove('tooltip-left', 'tooltip-right');

      if (tooltipRect.right > viewportWidth) {
        // If it overflows to the right, align it to the right of the container
        tooltip.classList.add('tooltip-right');
      } else if (tooltipRect.left < 0) {
        // If it overflows to the left, align it to the left of the container
        tooltip.classList.add('tooltip-left');
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/style.scss";

.tooltip-container {
  position: relative;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  color: $ibis-red;
  margin-left: 0.5rem;
  cursor: pointer;

  &:hover .tooltip-text {
    display: block;
    opacity: 1;
  }
}

.tooltip-text {
  display: none;

  @media (min-width: $tablet) {
    width: 600px;
  }

  @media (max-width: $tablet) {
    width: 440px;
  }

  @media (max-width: $mobile) {
    width: 240px;
  }

  background-color: white;
  box-shadow: 0px 8px 37px rgba(64, 64, 64, 0.1);
  padding: 1rem;
  color: $ibis-black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  font-size: $font-size-big;
}

.tooltip-container:hover .tooltip-text {
  display: block;
  opacity: 1;
}

.tooltip-container .tooltip-text.tooltip-right {
  left: auto;
  right: 0;
  transform: translateX(0%);
}

.tooltip-container .tooltip-text.tooltip-left {
  left: 0;
  right: auto;
  transform: translateX(0);
}
</style>
