const CanvasStretchingTypes = [
    {
        key: "DEFAULT",
        description: "Standardkaschierung",
        pricing: "80x100:13.47,100x150:13.47,150x300:13.47",
        supportsSurfaceFinishings: true,

    },
    {
        key: "CLOTH",
        description: "Tuch- bzw. Stoff-/Seidenbildkaschierung",
        pricing: "90x120:30.42,200x200:30.42",
        supportedProducts: [10011541, 10008546, 10008545, 10013756],
        supportsSurfaceFinishings: true,

    },
    {
        key: "GOBELIN",
        description: "Gobelin / Stickbild aufspannen",
        pricing: "90x120:46.37,200x200:46.37",
        supportedProducts: [10011541],
        supportsSurfaceFinishings: false,
    }
]

module.exports = CanvasStretchingTypes;