<template>
  <div class="header">
    <div class="personalDataHeader">Persönliche Daten</div>
  </div>
  <div class="personalInformation">
    <div class="subHeader">Kundennummer</div>
    <div class="informationContent">{{ userObject.ibis_customer_id }}</div>
    <div class="subHeader">
      <span>Name</span><img src="../../assets/icons/pencil.svg"
        v-show="!showEditName && !anyEditFormActive"
        @click="showEditName = true" />
    </div>
    <div class="informationContent">{{ userObject.name }}</div>
    <div class="editInput" v-show="!userObject.name || showEditName">
      <div class="customNameInput">
        <select name="Anrede" v-model="salutation">
          <option value="Herr" selected>Herr</option>
          <option value="Frau">Frau</option>
        </select>
        <input type="text" placeholder="Name" id="nameInput"
          v-model="nameInput" />
      </div>
      <div class="inputActionButtonsWrapper">
        <IbisButton buttonClass="primary" buttonText="Speichern"
          :isLoading="isLoading" :buttonDisabled="!isValidName"
          @click="saveNameInformation"></IbisButton>
        <IbisButton buttonClass="secondary" buttonText="Abbrechen"
          @click="showEditName = false"></IbisButton>
      </div>
    </div>

    <div class="subHeader">
      <span v-if="userObject.emails <= 1">E-Mail</span>
      <span v-else>E-Mails</span>
    </div>
    <div class="informationContent" style="
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 1rem;
      ">
      <div v-for="(email, index) in userObject.emails" :key="email">
        <div style="
            display: flex;
            align-items: center;
            gap: 0.5rem;
            height: 1.5rem;
          ">
          {{ email }}
          <img src="../../assets/icons/trash.svg"
            v-if="index > 0 && showDeleteEmailIndex === undefined"
            @click="showDeleteEmailIndex = index" />

          <div v-if="showDeleteEmailIndex === index" class="confirmEmailDelete">
            <img src="../../assets/icons/success_icon_contact.svg"
              @click="deleteEmailInformation(index)" />
            <img style="width: 1rem" src="../../assets/icons/close.svg"
              @click="showDeleteEmailIndex = undefined" />
            <IbisSpinner v-if="isDeleteEmailLoading"></IbisSpinner>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!showAddEmail && !anyEditFormActive" class="addContent"
      @click="showAddEmail = true">
      <img src="../../assets/icons/plus.svg" />
      Hinzufügen
    </div>
    <div class="editInput" v-show="showAddEmail" style="margin-top: 1rem">
      <input type="text" placeholder="E-Mail" id="emailInput"
        v-model="emailInput" />
      <div class="inputActionButtonsWrapper">
        <IbisButton buttonClass="primary" buttonText="Hinzufügen"
          :isLoading="isLoading" :buttonDisabled="!isValidEmail"
          @click="addEmailInformation"></IbisButton>
        <IbisButton buttonClass="secondary" buttonText="Abbrechen"
          @click="showAddEmail = false"></IbisButton>
      </div>
    </div>

    <div class="subHeader">
      <span>Firma</span><img src="../../assets/icons/pencil.svg"
        v-show="!showEditCompanyName && !anyEditFormActive"
        @click="showEditCompanyName = true" />
    </div>
    <div class="informationContent">
      {{ userObject.company_name }}
    </div>

    <div class="editInput"
      v-show="!userObject.company_name || showEditCompanyName">
      <input type="text" placeholder="Firma" id="companyNameInput"
        v-model="companyNameInput" />
      <div class="inputActionButtonsWrapper">
        <IbisButton buttonClass="primary" buttonText="Speichern"
          :isLoading="isLoading" :buttonDisabled="!companyNameInput"
          @click="saveCompanyNameInformation"></IbisButton>
        <IbisButton buttonClass="secondary" buttonText="Abbrechen"
          @click="showEditCompanyName = false"></IbisButton>
      </div>
    </div>

    <div class="subHeader">
      <span>Telefon</span>
      <img src="../../assets/icons/pencil.svg"
        v-show="!showEditPhone && !anyEditFormActive"
        @click="showEditPhone = true" />
    </div>
    <div class="informationContent" v-show="userObject.phone && !showEditPhone">
      {{ userObject.phone }}
    </div>
    <div v-show="!showEditPhone && !userObject.phone && !anyEditFormActive"
      class="addContent" @click="showEditPhone = true">
      <img src="../../assets/icons/plus.svg" />
      Hinzufügen
    </div>
    <div class="editInput" v-show="showEditPhone">
      <input type="text" placeholder="Telefon" id="zipInput"
        v-model="phoneInput" @input="onlyNumbersPhone()" />
      <div class="inputActionButtonsWrapper">
        <IbisButton buttonClass="primary" buttonText="Speichern"
          :isLoading="isLoading" :buttonDisabled="!phoneInput"
          @click="savePhoneInformation"></IbisButton>
        <IbisButton buttonClass="secondary" buttonText="Abbrechen"
          @click="showEditPhone = false"></IbisButton>
      </div>
    </div>

    <div class="subHeader">
      <span>Website</span>
      <img src="../../assets/icons/pencil.svg"
        v-show="!showEditWebsite && !anyEditFormActive"
        @click="showEditWebsite = true" />
    </div>
    <div class="informationContent"
      v-show="userObject.website && !showEditWebsite">
      {{ userObject.website }}
    </div>
    <div v-show="!showEditWebsite && !userObject.website && !anyEditFormActive"
      class="addContent" @click="showEditWebsite = true">
      <img src="../../assets/icons/plus.svg" />
      Hinzufügen
    </div>
    <div class="editInput" v-show="showEditWebsite">
      <input type="text" placeholder="Website" id="zipInput"
        v-model="websiteInput" />
      <div class="inputActionButtonsWrapper">
        <IbisButton buttonClass="primary" buttonText="Speichern"
          :isLoading="isLoading" :buttonDisabled="!websiteInput"
          @click="saveWebsiteInformation"></IbisButton>
        <IbisButton buttonClass="secondary" buttonText="Abbrechen"
          @click="showEditWebsite = false"></IbisButton>
      </div>
    </div>

    <div class="subHeader">
      <span>Adresse</span>
      <img src="../../assets/icons/pencil.svg"
        v-show="!showEditAddress && !anyEditFormActive"
        @click="showEditAddress = true" />
    </div>
    <div class="informationContent"
      v-show="userObject.address && userObject.ZIP && !showEditAddress">
      {{
      userObject.address +
      ", " +
      userObject.ZIP +
      " " +
      userObject.address_place +
      (userObject.country ? ", " + userObject.country : "")
    }}
    </div>
    <div v-show="!showEditAddress &&
      (!userObject.address || !userObject.ZIP) &&
      !anyEditFormActive
      " class="addContent" @click="showEditAddress = true">
      <img src="../../assets/icons/plus.svg" />
      Hinzufügen
    </div>
    <div class="editInput" v-show="showEditAddress">
      <input type="text" placeholder="Straße und Hausnummer" id="addressInput"
        v-model="addressInput" />

      <div class="customZipAndLocationInputs">
        <input type="text" placeholder="PLZ" id="zipInput" v-model="zipInput"
          @input="onlyNumbersZIP()" />
        <input type="text" placeholder="Ort" v-model="addressPlaceInput"
          id="locationInput" />
      </div>
      <input type="text" placeholder="Land" id="countryInput"
        v-model="countryInput" />
      <div class="inputActionButtonsWrapper">
        <IbisButton buttonClass="primary" buttonText="Speichern"
          :isLoading="isLoading" :buttonDisabled="!isAddressFormValid"
          @click="saveAddressInformation"></IbisButton>
        <IbisButton buttonClass="secondary" buttonText="Abbrechen"
          @click="showEditAddress = false"></IbisButton>
      </div>
    </div>
    <div class="geoVisibility" v-if="isRetailer">
      <div class="subHeader">
        <span>Sichtbarkeit im Fachhandelsverzeichnis</span>
        <ToolTip
          tipText="Um auf der Karte angezeigt zu werden, müssen Sie in den letzten 3 Monaten mindestens eine Onlinebestellung aufgegeben haben.">
        </ToolTip>
      </div>
      <div class="status">
        <div class="currentStatus">
          Status:
          <span v-if="!isGeoLocationLoading">{{
      geoVisibilityStatus ? "Sichtbar" : "Unsichtbar"
    }}</span>
          <span v-else>
            <IbisSpinner></IbisSpinner>
          </span>
        </div>
        <div class="toggleButton">
          <IbisButton buttonClass="secondary"
            buttonText="Sichtbarkeit umschalten"
            @click="toggleGeoLocationVisibility"
            :isLoading="isUpdateGeoLocationLoading"></IbisButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BackendHandler from "@/utility/BackendHandler";
import IbisButton from "../shared/IbisButton.vue";
import ToastTypes from "@/models/Types/ToastTypes";
import IbisSpinner from "../shared/IbisSpinner.vue";
import cloneDeep from "lodash/cloneDeep";
import ToolTip from "../shared/ToolTip.vue";
import UserTypeProvider from "@/utility/UserTypeProvider";
import UserTypes from "@/models/Types/UserTypes";
export default {
  components: {
    IbisButton,
    IbisSpinner,
    ToolTip
  },
  computed: {
    isRetailer() {
      return UserTypeProvider.getUserType() === UserTypes.RETAILER;
    },
    userObject() {
      return JSON.parse(localStorage.getItem("ibisOnlinebestellungUserObject"));
    },
    isAddressFormValid() {
      return this.addressInput && this.zipInput && this.countryInput;
    },
    isValidName() {
      const regex = /^[\p{L}]+\s[\p{L}]+$/u;
      return regex.test(this.nameInput);
    },
    isValidEmail() {
      const regex = /^\S+@\S+\.\S+$/;
      return regex.test(this.emailInput);
    },
    anyEditFormActive() {
      return (
        this.showEditName ||
        this.showAddEmail ||
        this.showEditCompanyName ||
        this.showEditPhone ||
        this.showEditAddress
      );
    },
  },
  data() {
    return {
      salutation: undefined,
      showEditName: false,
      showAddEmail: false,
      showEditCompanyName: false,
      showEditAddress: false,
      showEditPhone: false,
      showEditWebsite: false,
      isLoading: false,
      nameInput: "",
      emailInput: "",
      companyNameInput: "",
      addressInput: "",
      phoneInput: "",
      websiteInput: "",
      zipInput: "",
      addressPlaceInput: "",
      countryInput: "",
      toastTypes: ToastTypes,
      showDeleteEmailIndex: undefined,
      geoVisibilityStatus: false,
      isDeleteEmailLoading: false,
      isGeoLocationLoading: true,
      isUpdateGeoLocationLoading: false,
    };
  },
  async mounted() {
    await this.getGeoLocationVisibilityStatus();
    let splittedName = this.userObject.name.split(" ");
    this.salutation = splittedName[0];
    this.nameInput = splittedName[1] + " " + splittedName[2];

    this.emailInput = "";
    this.companyNameInput = this.userObject.company_name;

    this.addressInput = this.userObject.address;
    this.zipInput = this.userObject.ZIP;
    this.addressPlaceInput = this.userObject.address_place;
    this.countryInput = this.userObject.country;
    this.phoneInput = this.userObject.phone;
    this.websiteInput = this.userObject.website;
  },
  methods: {
    showToastMessage(toastMessage, type, inputId) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
      if (type === this.toastTypes.ERROR && inputId) {
        document.getElementById(inputId).classList.add("invalid");
        setTimeout(() => {
          document.getElementById(inputId).classList.remove("invalid");
        }, 3000);
      }
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 5000);
    },
    async saveNameInformation() {
      let payload = {
        customerId: this.userObject.ibis_customer_id,
        fieldsToUpdate: {
          name: this.salutation + " " + this.nameInput,
        },
      };

      this.isLoading = true;
      try {
        await BackendHandler.updateUserInformation(payload);
        let currentUserObject = this.userObject;

        currentUserObject.name = this.nameInput;

        localStorage.setItem(
          "ibisOnlinebestellungUserObject",
          JSON.stringify(currentUserObject)
        );

        this.isLoading = false;
        if (!this.showToast) {
          this.showToastMessage(
            "Ihre Daten wurden aktualisiert.",
            this.toastTypes.SUCCESS
          );
        }
        this.showEditName = false;
      } catch (e) {
        this.isLoading = false;
        if (!this.showToast) {
          this.showToastMessage(e.message, this.toastTypes.ERROR);
        }
      }
      this.isLoading = false;
    },
    async addEmailInformation() {
      if (this.userObject.emails.includes(this.emailInput)) {
        this.showToastMessage(
          "Diese Email-Adresse ist bereits angegeben.",
          this.toastTypes.ERROR
        );
        return;
      }
      let tempMails = cloneDeep(this.userObject.emails);
      tempMails.push(this.emailInput);
      await this.editEmailInformation(tempMails);
      this.userObject.emails.push(this.emailInput);
      this.saveUserObject();
      this.$forceUpdate();
    },
    async deleteEmailInformation(index) {
      this.isDeleteEmailLoading = true;
      let tempMails = cloneDeep(this.userObject.emails);

      if (index > 0 && index < this.userObject.emails.length) {
        tempMails.splice(index, 1);
      }

      await this.editEmailInformation(tempMails);
      this.userObject.emails.splice(index, 1);
      this.saveUserObject();
      this.$forceUpdate();
      this.isDeleteEmailLoading = false;
    },
    async editEmailInformation(newMails) {
      let payload = {
        customerId: this.userObject.ibis_customer_id,
        fieldsToUpdate: {
          emails: newMails,
        },
      };

      this.isLoading = true;
      try {
        await BackendHandler.updateUserInformation(payload);
        this.saveUserObject();
        this.isLoading = false;
        if (!this.showToast) {
          this.showToastMessage(
            "E-Mail Adressen aktualisiert.",
            this.toastTypes.SUCCESS
          );
        }
        this.showAddEmail = false;
      } catch (e) {
        this.isLoading = false;
        if (!this.showToast) {
          this.showToastMessage(e.message, this.toastTypes.ERROR);
        }
      }
      this.isLoading = false;
    },
    async saveCompanyNameInformation() {
      let payload = {
        customerId: this.userObject.ibis_customer_id,
        fieldsToUpdate: {
          company_name: this.companyNameInput,
        },
      };

      this.isLoading = true;
      try {
        await BackendHandler.updateUserInformation(payload);
        let currentUserObject = this.userObject;

        currentUserObject.company_name = this.companyNameInput;

        localStorage.setItem(
          "ibisOnlinebestellungUserObject",
          JSON.stringify(currentUserObject)
        );

        this.isLoading = false;
        if (!this.showToast) {
          this.showToastMessage(
            "Ihre Daten wurden aktualisiert.",
            this.toastTypes.SUCCESS
          );
        }
        this.showEditCompanyName = false;
      } catch (e) {
        this.isLoading = false;
        if (!this.showToast) {
          this.showToastMessage(e.message, this.toastTypes.ERROR);
        }
      }
      this.isLoading = false;
    },
    async saveAddressInformation() {
      let payload = {
        customerId: this.userObject.ibis_customer_id,
        fieldsToUpdate: {
          address: this.addressInput,
          ZIP: this.zipInput,
          address_place: this.addressPlaceInput,
          country: this.countryInput,
        },
      };

      this.isLoading = true;
      try {
        await BackendHandler.updateUserInformation(payload);
        let currentUserObject = this.userObject;

        currentUserObject.address = this.addressInput;
        currentUserObject.ZIP = this.zipInput;
        currentUserObject.address_place = this.addressPlaceInput;
        currentUserObject.country = this.countryInput;

        localStorage.setItem(
          "ibisOnlinebestellungUserObject",
          JSON.stringify(currentUserObject)
        );

        this.isLoading = false;
        if (!this.showToast) {
          this.showToastMessage(
            "Ihre Daten wurden aktualisiert.",
            this.toastTypes.SUCCESS
          );
        }
        this.showEditAddress = false;
      } catch (e) {
        this.isLoading = false;
        if (!this.showToast) {
          console.log(e);
          this.showToastMessage(e.message, this.toastTypes.ERROR);
        }
      }
      this.isLoading = false;
    },
    async savePhoneInformation() {
      let payload = {
        customerId: this.userObject.ibis_customer_id,
        fieldsToUpdate: {
          phone: this.phoneInput,
        },
      };

      this.isLoading = true;
      try {
        await BackendHandler.updateUserInformation(payload);
        let currentUserObject = this.userObject;

        currentUserObject.phone = this.phoneInput;

        localStorage.setItem(
          "ibisOnlinebestellungUserObject",
          JSON.stringify(currentUserObject)
        );

        this.isLoading = false;
        if (!this.showToast) {
          this.showToastMessage(
            "Ihre Daten wurden aktualisiert.",
            this.toastTypes.SUCCESS
          );
        }
        this.showEditPhone = false;
      } catch (e) {
        this.isLoading = false;
        if (!this.showToast) {
          this.showToastMessage(e.message, this.toastTypes.ERROR);
        }
      }
      this.isLoading = false;
    },
    async saveWebsiteInformation() {
      let payload = {
        customerId: this.userObject.ibis_customer_id,
        fieldsToUpdate: {
          website: this.websiteInput,
        },
      };

      this.isLoading = true;
      try {
        await BackendHandler.updateUserInformation(payload);
        let currentUserObject = this.userObject;

        currentUserObject.website = this.websiteInput;

        localStorage.setItem(
          "ibisOnlinebestellungUserObject",
          JSON.stringify(currentUserObject)
        );

        this.isLoading = false;
        if (!this.showToast) {
          this.showToastMessage(
            "Ihre Daten wurden aktualisiert.",
            this.toastTypes.SUCCESS
          );
        }
        this.showEditWebsite = false;
      } catch (e) {
        this.isLoading = false;
        if (!this.showToast) {
          this.showToastMessage(e.message, this.toastTypes.ERROR);
        }
      }
      this.isLoading = false;
    },
    onlyNumbersPhone() {
      this.phoneInput = this.phoneInput.replace(/\D/g, "");
    },
    onlyNumbersZIP() {
      this.zipInput = this.zipInput.replace(/\D/g, "");
    },
    saveUserObject() {
      let currentUserObject = this.userObject;

      localStorage.setItem(
        "ibisOnlinebestellungUserObject",
        JSON.stringify(currentUserObject)
      );
    },
    async toggleGeoLocationVisibility() {
      this.isUpdateGeoLocationLoading = true;
      try {
        await BackendHandler.updateCustomerGeolocationVisibility(
          this.userObject.ibis_customer_id
        );
        // On success, display a success toast.
        this.showToastMessage(
          "Die Sichtbarkeit wurde erfolgreich umgeschaltet.",
          this.toastTypes.SUCCESS
        );
        await this.getGeoLocationVisibilityStatus(); // Refresh the status after updating.
      } catch (error) {
        console.log(error);
        // On error, display an error toast with the error message.
        this.showToastMessage(error, this.toastTypes.ERROR);
      } finally {
        this.isUpdateGeoLocationLoading = false;
      }
    },

    async getGeoLocationVisibilityStatus() {
      this.isGeoLocationLoading = true;
      try {
        const response = await BackendHandler.getCustomerGeolocationVisibility(
          this.userObject.ibis_customer_id
        );
        this.geoVisibilityStatus = response.data.displayOnMap;
      } catch (error) {
        // On error, display an error toast with the error message.
        this.showToastMessage(error.message, this.toastTypes.ERROR);
      } finally {
        this.isGeoLocationLoading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.header {
  margin-top: 2rem;
  width: 100%;
  margin-bottom: 1rem;

  .personalDataHeader {
    text-align: start;
    font-size: $font-size-large;
  }
}

.personalInformation {
  padding: 2rem 4rem;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 8px 37px rgba(64, 64, 64, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: $tablet) {
    padding: 2rem 2rem;
  }

  .subHeader {
    font-size: $font-size-bigger;
    font-weight: bold;
    text-align: start;
    margin-bottom: 1rem;

    img {
      width: 1rem;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .informationContent {
    font-size: $font-size-big;
    text-align: start;
    margin-bottom: 2rem;

    img {
      cursor: pointer;
      width: 1.5rem;
    }

    .confirmEmailDelete {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }

  .addContent {
    color: $ibis-red;
    display: flex;
    width: 100%;
    gap: 0.5rem;
    padding: 0.5rem 0;
    margin-bottom: 1.5rem;
    cursor: pointer;

    img {
      width: 1rem;
    }
  }

  .editInput {
    margin-bottom: 1.5rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: $tablet) {
      width: 100%;
    }

    .inputActionButtonsWrapper {
      display: flex;
      justify-content: space-between;
    }

    .customNameInput {
      display: flex;
      gap: 1rem;
    }

    .customZipAndLocationInputs {
      display: flex;
      justify-content: space-between;

      #zipInput {
        width: 20%;
      }

      #locationInput {
        width: 50%;
      }
    }
  }

  .geoVisibility {
    padding: 2rem 0;

    .subHeader {
      position: relative;


    }

    .status {
      display: flex;
      width: 100%;
      align-items: center;
      font-weight: bold;
      gap: 2rem;
      margin: 2rem 0;

      .currentStatus {
        display: flex;
        gap: 0.5rem;

        span {
          color: $ibis-red;
        }
      }
    }
  }
}

input {
  padding: 12px 20px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  //font-size: $font-size-big;
  text-align: start;
}

select {
  padding: 12px 20px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("../../assets/icons/arrow_down.svg");
  background-repeat: no-repeat;
  background-position: calc(95%) center;
  padding-right: 2em;
  min-width: 32%;
}

select::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

select:focus {
  outline: none;
  border-color: $ibis-black;
}
</style>
