const WorkshopModes = require("../WorkshopModes");

const HangingTypes = [
  { key: "DEFAULT", value: "Standardaufhängung", price: 0 },
  {
    key: "AS", value: "Seilaufhängung anbringen", price: 20.60,
    unSupportedModes: [WorkshopModes.LAMINATION_AND_SURFACE_FINISHING],
  }, //preis pro stk
  {
    key: "719001",
    value: "Schwerlastaufhängung anbringen",
    price: 9.00,
    unSupportedModes: [WorkshopModes.CANVAS_STRETCH, WorkshopModes.LAMINATION_AND_SURFACE_FINISHING],
  }, //preis pro stk
];
module.exports = HangingTypes;
