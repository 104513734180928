<template>
  <div class="summaryWrapper"
    v-if="this.$store.getters.selectedConfigurations.length > 0">
    <div class="detailsWrapper">
      <div class="headline">
        <div class="headlineText">
          <span>GESAMTPREIS</span>
          <span>(Onlinerabatt bereits abgezogen)</span>
        </div>
      </div>
      <div class="priceWrapper">
        <span>{{ formattedTotalPrice }} €</span>
      </div>
    </div>
    <div class="notesTextFieldWrapper">
      <textarea class="textField" placeholder="Kommission / Anmerkungen"
        cols="30" rows="5" v-model="orderNotes"></textarea>
    </div>
    <div class="clerkWrapper" v-if="!isRetailer">
      <input type="text" placeholder="Sachbearbeiter" v-model="clerk" />
    </div>
    <div class="discountCodeWrapper">
      <input type="text" placeholder="Gutscheincode" v-model="discountCode" />
      <div class="applyButtonWrapper">
        <IbisButton buttonClass="primary" buttonText="Anwenden"
          :isLoading="isDiscountCodeCheckLoading"
          :buttonDisabled="!isApplyDiscountValid"
          @click="checkAndApplyDiscountCode"></IbisButton>
      </div>
    </div>

    <label class="checkboxContainer"><span>Wir akzeptieren die</span><span
        class="agbLink" @click="openAGBs()">AGB (Allgemeinen
        Geschäftsbedingungen)</span>
      <input id="withPolyCheckbox" type="checkbox" v-model="agbConfirmed"
        @input="agbConfirmed = !agbConfirmed" />
      <span class="checkmark"></span>
    </label>
    <div class="buttonOptionsWrapper">
      <IbisButton buttonClass="primary" buttonText="BESTELLUNG AUFGEBEN"
        @click="placeOrder()" :disabled="!agbConfirmed"
        :isLoading="isOrderLoading">
      </IbisButton>
      <IbisButton buttonClass="secondary"
        buttonText="KOSTENVORANSCHLAG ERSTELLEN" @click="placeOrder(true)"
        :isLoading="isCostEstimateLoading" />
    </div>
  </div>
</template>
<script>
import ConfiguratorMode from "@/models/Types/ConfiguratorMode";
import ToastTypes from "@/models/Types/ToastTypes";
import WorkshopModes from "@/models/Types/WorkshopModes";
import WorkshopFraming from "@/models/WorkshopFraming";
import BackendHandler from "@/utility/BackendHandler";
import DBSyncer from "@/utility/DBSyncer";
import calculatePrice from "@/utility/PriceCalculator";
import IbisButton from "../../shared/IbisButton.vue";
import UserTypeProvider from "@/utility/UserTypeProvider";
import UserTypes from "@/models/Types/UserTypes";
export default {
  inject: ["$firebase"],
  components: {
    IbisButton,
  },
  computed: {
    selectedConfigurations() {
      return this.$store.getters.selectedConfigurations;
    },
    currentProductFamily() {
      return this.$store.getters.selectedProductFamily;
    },
    currentGlasOption() {
      return this.$store.getters.currentGlasSizeOption;
    },
    selectedIndex() {
      return this.$store.getters.selectedIndex;
    },
    formattedTotalPrice() {
      const price = this.roundToTwo(this.$store.getters.totalPrice).toFixed(2);
      return this.formatPriceWithSeparator(price);
    },

    // Unformatted price for payload
    unformattedTotalPrice() {
      return this.roundToTwo(this.$store.getters.totalPrice);
    },
    userInfo() {
      const rawData = localStorage.getItem("ibisOnlinebestellungUserObject");
      if (!rawData) return null; // handle case where there's no userObject in localStorage

      // eslint-disable-next-line
      const { currentConfiguration, ...rest } = JSON.parse(rawData);
      return rest;
    },
    isApplyDiscountValid() {
      const absoluteDiscount =
        this.$store.getters.currentDiscountCode?.absoluteDiscount ?? 0;
      const totalPrice = this.$store.getters.totalPrice;
      // Check if the discount code is not already applied and if an absolute discount does not exceed total price
      return (
        this.discountCode &&
        !this.$store.getters.currentDiscountCode?.code &&
        absoluteDiscount <= totalPrice
      );
    },
    isRetailer() {
      return UserTypeProvider.getUserType() === UserTypes.RETAILER;
    },
  },
  data() {
    return {
      isOrderLoading: false,
      isCostEstimateLoading: false,
      orderNotes: "",
      toastTypes: ToastTypes,
      agbConfirmed: false,
      agbDownloadUrl: "",
      discountCode: "",
      clerk: "",
      isDiscountCodeCheckLoading: false,
      discountCodeObj: {},
    };
  },
  mounted() {
    this.fetchAGBDownloadUrl();
  },
  methods: {
    async placeOrder(isCostEstimate = false) {
      try {
        if (isCostEstimate) {
          this.isCostEstimateLoading = true;
        } else {
          this.isOrderLoading = true;
        }
        this.$store.commit("setOrderProcessing", true);

        for (const product of this.selectedConfigurations) {
          product.calculated_price = this.roundToTwo(calculatePrice(product));
        }
        const payload = {
          products: this.selectedConfigurations,
          totalPrice: this.unformattedTotalPrice,
          orderNotes: this.orderNotes,
          userInfo: this.userInfo,
          timestamp: Date.now(),
          discountCode: this.discountCodeObj,
          clerk: this.clerk,
        };

        if (isCostEstimate) {
          await BackendHandler.createCostEstimate(payload);
        } else {
          await BackendHandler.placeOrder(payload);
        }
        if (isCostEstimate) {
          this.showToastMessage(
            "Ihr Kostenvoranschlag wurde erstellt und ihnen zugesendet.",
            ToastTypes.SUCCESS
          );
        } else {
          this.showToastMessage(
            "Ihre Bestellung wurde übermittelt.",
            ToastTypes.SUCCESS
          );
        }
        if (isCostEstimate) {
          this.isCostEstimateLoading = false;
        } else {
          this.isOrderLoading = false;
          this.$store.commit("setLastCurrentSessionOrder", payload);
        }
          this.$store.commit("resetSelectedConfigurations");
          this.$store.commit("setCurrentDiscountCode", {});
          this.$store.commit("setWorkshopMode", WorkshopModes.FRAMING)
          this.$store.commit("setWorkshopWork", new WorkshopFraming());
          this.$store.commit("setConfiguratorMode", ConfiguratorMode.PRODUCT);
        //this.$store.commit("initializeWorkshopWork");
        this.syncConfiguration();
        this.$store.commit("setOrderProcessing", false);

        if (!isCostEstimate) {
          this.$router.push("/danke");
        }
      } catch (e) {
        if (isCostEstimate) {
          this.isCostEstimateLoading = false;
        } else {
          this.isOrderLoading = false;
          this.$store.commit("setOrderProcessing", false);
        }
        this.$store.commit("setOrderProcessing", false);

        this.showToastMessage(
          "Beim Bestellvorgang ist ein Fehler aufgetreten.",
          ToastTypes.ERROR
        );
      }

      if (isCostEstimate) {
        this.isCostEstimateLoading = false;
      } else {
        this.isOrderLoading = false;
      }
      this.$store.commit("setOrderProcessing", false);

    },
    showToastMessage(toastMessage, type, inputId) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
      if (type === this.toastTypes.ERROR && inputId) {
        document.getElementById(inputId).classList.add("invalid");
        setTimeout(() => {
          document.getElementById(inputId).classList.remove("invalid");
        }, 3000);
      }
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 5000);
    },
    async fetchAGBDownloadUrl() {
      try {
        const storage = this.$firebase.storage(); // Get the Firebase storage
        const storageRef = storage.ref(
          "Public/Zu guter Letzt - Bestellhinweise, AGB, Impressum.pdf"
        ); // Adjust the path to your AGB file
        this.agbDownloadUrl = await storageRef.getDownloadURL();
      } catch (error) {
        console.error("Error fetching AGB download URL:", error);
        this.showToastMessage("Fehler beim Laden der AGB", ToastTypes.ERROR);
      }
    },
    openAGBs() {
      if (this.agbDownloadUrl) {
        window.open(this.agbDownloadUrl, "_blank");
      } else {
        this.showToastMessage(
          "AGB konnte nicht geladen werden.",
          ToastTypes.ERROR
        );
      }
    },
    roundToTwo(num) {
      return +parseFloat(Math.round(num + "e+3") + "e-3");
    },
    syncConfiguration() {
      DBSyncer.syncConfiguration();
    },
    formatPriceWithSeparator(value) {
      // First, replace the existing dot with a comma
      let formattedValue = value.toString().replace(".", ",");

      // Then, replace the thousands separator with a dot
      formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      return formattedValue;
    },
    async checkAndApplyDiscountCode() {
      try {
        this.isDiscountCodeCheckLoading = true;
        // Assuming you have a method to fetch discount details, else you can insert that logic here
        const response = await BackendHandler.checkDiscountCode(
          this.discountCode
        );

        // Simulate fetching the absolute discount and checking against total price
        const absoluteDiscount = response.data.absoluteDiscount ?? 0;
        const totalPrice = this.$store.getters.totalPrice;

        if (!response.data.isValid) {
          this.showToastMessage(
            "Ungültiger Gutscheincode.",
            this.toastTypes.ERROR
          );
        } else if (absoluteDiscount > totalPrice) {
          // Show error toast if absolute discount exceeds total price
          this.showToastMessage(
            "Gutscheincode kann nicht angewendet werden, da der Gesamtbetrag kleiner ist als der Gutscheinwert",
            this.toastTypes.ERROR
          );
        } else {
          // Apply discount code if valid and does not exceed total price
          this.showToastMessage(
            "Gutscheincode erfolgreich angewendet.",
            this.toastTypes.SUCCESS
          );
          this.$store.commit("setCurrentDiscountCode", response.data);
          this.discountCodeObj = response.data;
          // Additional logic to apply the discount
        }
      } catch (error) {
        console.error("Error checking discount code:", error);
        this.showToastMessage(error.message, this.toastTypes.ERROR);
      } finally {
        this.isDiscountCodeCheckLoading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.summaryWrapper {
  width: 25vw;

  @media (max-width: $tablet) {
    width: auto;
  }

  background: white;
  border-radius: 40px;
  margin-top: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-flow: column;
  max-width: 350px;
  padding: 0 20px 20px 20px;

  .detailsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2em;

    .headline {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10vw;
      width: 60%;

      .headlineText {
        display: flex;
        flex-direction: column;
      }
    }

    .priceWrapper {
      padding: 5px 25px;
      border-radius: 40px;
      border: solid 2px $ibis-red;

      span {
        color: $ibis-red;
      }
    }

    .amountWrapper {
      width: 15%;
      text-align: start;
    }
  }

  .notesTextFieldWrapper {
    margin-top: 1rem;
    display: flex;
    justify-content: center;

    .textField {
      border-radius: 25px;
      padding: 20px;
      width: 100%;
      resize: none;
      color: $ibis-black;
      border: 2px solid $ibis-grey-middle;
      //outline: 2px solid $ibis-black;
      outline: none;
      //font-family: "Raleway";

      &:focus {
        border: 2px solid $ibis-black;
      }
    }
  }

  .discountCodeWrapper {
    margin-top: 1rem;
    width: 100%;

    input {
      padding: 12px 10px;
      box-shadow: none;
      border: 2px solid $ibis-grey-middle;
      border-radius: 50px;
      color: $ibis-black !important;
      font-size: $font-size-big;
      text-align: center;
      width: 100%;
      box-sizing: border-box;

      @media (max-width: $mobile) {
        min-width: 3rem;
      }
    }

    .applyButtonWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
    }
  }

  .clerkWrapper {
    margin-top: 1rem;
    width: 100%;

    input {
      padding: 12px 10px;
      box-shadow: none;
      border: 2px solid $ibis-grey-middle;
      border-radius: 50px;
      color: $ibis-black !important;
      font-size: $font-size-big;
      text-align: center;
      width: 100%;
      box-sizing: border-box;

      @media (max-width: $mobile) {
        min-width: 3rem;
      }
    }

  }

  .checkboxContainer {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
    z-index: 1;
    display: flex;
    position: relative;
    padding-left: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
    }

    span {
      text-align: start;
      display: inline;
      font-size: 14px;
    }

    .agbLink {
      color: $ibis-red;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .checkmark {
      position: absolute;
      top: 20%;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 50px;
      background-color: $ibis-grey-middle;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }

    input:checked~.checkmark {
      background-color: $ibis-red;
      transition: 0.15s;

      &:after {
        display: block;
      }
    }

    .checkmark:after {
      left: 6.5px;
      top: 4px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .buttonOptionsWrapper {
    margin-top: 1rem;
    display: flex;
    flex-flow: column;
    gap: 7px;
  }
}
</style>
