<template>
  <div class="loginScreenWrapper">
    <div class="leftHalf" id="leftHalf">
      <div class="loginForm">
        <span class="headline"> Passwort Ändern </span>

        <input type="password" placeholder="Aktuelles Passwort"
          v-model="currentPassword" id="currentPasswordInput"
          @input="validateCurrentPassword()" />
        <input type="password" placeholder="Neues Passwort"
          v-model="newPassword" id="newPasswordInput"
          @input="validateNewPassword()" />
        <input type="password" placeholder="Neues Passwort wiederholen"
          v-model="newPasswordRepeat" id="newPasswordRepeatInput"
          @input="validateNewPasswordRepeat()" />

        <div class="loginButtonWrapper">
          <IbisButton buttonClass="primary" buttonText="Passwort Ändern"
            :isLoading="isLoading" @click="changePassword()"></IbisButton>
        </div>
      </div>
    </div>
    <div class="rightHalf" id="rightHalf" v-if="isDesktop">
      <img :src="userLogo" />
      <span>Fachhändlershop</span>
    </div>
  </div>
</template>
<script>
import IbisButton from "../shared/IbisButton.vue";
import AuthService from "@/utility/AuthService";
import ToastTypes from "@/models/Types/ToastTypes";
import AssetProvider from "@/utility/AssetProvider";
export default {
  components: {
    IbisButton,
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isTablet() {
      return this.$store.getters.isTablet;
    },
    isDesktop() {
      return this.$store.getters.isDesktop;
    },
    userLogo() {
      return AssetProvider.getLogoForUserType()
    }
  },
  mounted() {
    document.getElementById("leftHalf").classList.add("fade-in-bottom");
    this.isDesktop
      ? document.getElementById("rightHalf").classList.add("fade-in-bottom")
      : null;
  },
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
      showToast: false,
      toastTypes: ToastTypes,
      isLoading: false,
    };
  },

  methods: {
    showToastMessage(toastMessage, type, inputId) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
      if (type === this.toastTypes.ERROR && inputId) {
        document.getElementById(inputId).classList.add("invalid");
        setTimeout(() => {
          document.getElementById(inputId).classList.remove("invalid");
        }, 3000);
      }
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 5000);
    },
    validateForm() {
      let isValid = true;
      if (!this.currentPassword) {
        if (!this.showToast) {
          this.showToastMessage(
            "Aktuelles Passwort darf nicht leer sein.",
            this.toastTypes.ERROR,
            "currentPasswordInput"
          );
          isValid = false;
        }
      } else {
        document
          .getElementById("currentPasswordInput")
          .classList.remove("invalid");
      }

      if (!this.newPassword) {
        if (!this.showToast) {
          this.showToastMessage(
            "Neues Passwort darf nicht leer sein.",
            this.toastTypes.ERROR,
            "newPasswordInput"
          );
        }
        isValid = false;
      } else if (this.newPassword.length < 8) {
        if (!this.showToast) {
          this.showToastMessage(
            "Neues Passwort muss mindestens 8 Zeichen lang sein.",
            this.toastTypes.ERROR,
            "newPasswordInput"
          );
        }
        isValid = false;
      } else {
        document.getElementById("newPasswordInput").classList.remove("invalid");
      }
      if (this.newPassword !== this.newPasswordRepeat) {
        if (!this.showToast) {
          this.showToastMessage(
            "Passwörter stimmen nicht miteinander ein.",
            this.toastTypes.ERROR,
            "newPasswordRepeatInput"
          );
        }
        isValid = false;
      } else {
        document
          .getElementById("newPasswordRepeatInput")
          .classList.remove("invalid");
      }

      return isValid;
    },

    validateCurrentPassword() {
      if (!this.currentPassword) {
        document
          .getElementById("currentPasswordInput")
          .classList.add("invalid");
      } else {
        document
          .getElementById("currentPasswordInput")
          .classList.remove("invalid");
      }
    },
    validateNewPassword() {
      if (!this.newPassword || this.newPassword.length < 8) {
        document.getElementById("newPasswordInput").classList.add("invalid");
      } else {
        document.getElementById("newPasswordInput").classList.remove("invalid");
      }
    },
    validateNewPasswordRepeat() {
      if (!this.newPasswordRepeat) {
        document
          .getElementById("newPasswordRepeatInput")
          .classList.add("invalid");
      } else {
        document
          .getElementById("newPasswordRepeatInput")
          .classList.remove("invalid");
      }
    },
    async changePassword() {
      this.isLoading = true;
      if (this.validateForm()) {
        try {
          await AuthService.changePassword(
            this.currentPassword,
            this.newPassword
          );
          this.isLoading = false;
          if (!this.showToast) {
            this.showToastMessage(
              "Ihr Passwort wurde geändert.",
              this.toastTypes.SUCCESS
            );
          }
          this.$router.push("/");
        } catch (e) {
          this.isLoading = false;
          if (!this.showToast) {
            this.showToastMessage(e.message, this.toastTypes.ERROR);
          }
        }
      }
      this.isLoading = false;
    },
  },
};
</script>

<!-- keep the style as it is -->
<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.loginScreenWrapper {
  width: 100vw;
  height: 90vh;
  display: flex;
  align-items: center;

  @media (max-width: $tablet) {
    height: 70vh;
  }

  .leftHalf {
    padding-left: 8em;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $mobile) {
      padding-left: 0em;
      display: block;
      width: 100%;
      padding: 2rem;
      margin-top: 5rem;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      display: flex;
      width: 100%;
      padding: 2rem;
    }

    .loginForm {
      width: 60%;
      max-width: 300px;
      box-shadow: 0px 8px 37px rgba(64, 64, 64, 0.1);
      border-radius: 25px;
      background-color: white;
      border: solid 2px $ibis-grey;
      padding: 3rem 4rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2em;

      @media (max-width: $mobile) {
        padding-left: 0em;
        width: 100%;
        box-shadow: none;
        border: none;
        padding: 0;
        max-width: none;
      }

      @media (min-width: $mobile) and (max-width: $tablet) {
        padding-left: 0em;
        border: none;
        width: 100%;
        max-width: none;
        padding: 0;
        max-width: 50vw;
        box-shadow: none;
      }

      .headline {
        font-size: $font-size-xlarge;
        font-weight: bold;
        margin-bottom: 0.5em;
      }

      input {
        width: 100%;
        text-align: start;
        font-size: $font-size-big;
        padding-left: 1.5em;
        box-sizing: border-box;
      }

      .loginButtonWrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 1em;
        margin-bottom: 1em;
      }

      .noAccountWrapper {
        display: flex;
        flex-direction: row;
        gap: 0.5em;

        .registerLink {
          color: $ibis-red;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .rightHalf {
    padding-right: 8em;
    width: 50%;
    height: 100%;
    display: flex;
    gap: 2em;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      max-width: 20rem;
    }

    span {
      font-size: $font-size-large;
      font-weight: bold;
    }
  }
}

input {
  padding: 12px 10px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  //font-size: $font-size-big;
  text-align: center;
}

input:disabled {
  opacity: 0.5;
}

input:focus {
  outline: none;
  border-color: $ibis-black;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.invalid {
  border: 2px solid $ibis-red;
  color: $ibis-red;
}
</style>
