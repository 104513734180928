/* const ProductFactory = require("@/utility/ProductFactory");

class Product {
  name;
  description;
  product_id;
  // max_height;
  // max_width;
  webshop_id;
  familyType;
  // productType;
  category;
  sub_category;
  selling_unit;
  min_quantity_surcharge;
  only_full_units;
  // qm_price;
  // custom_cut_price;
  price;
  quantity;

  static fromJson(data) {
    return ProductFactory.build(data);
  }
}

module.exports = Product;
 */
class SingleProduct {}
module.exports = SingleProduct;
