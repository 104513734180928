import ProductFactory from "@/utility/ProductFactory";
const ProductOptionsToSort = require("@/models/Types/ProductOptionsToSort");
//const store = require("../main.js");

class ProductFamily {
  selectedIndex = 0;
  parent_id = undefined;
  products = [];
  //only for remaining stocks
  remainingStockQuantity;

  //only for grouped glasses
  glas_size_options = [];

  constructor(parent_id, selectedIndex) {
    this.parent_id = parent_id;
    this.selectedIndex = selectedIndex ? selectedIndex : 0;
  }

  setSelectedIndex(value) {
    this.selectedIndex = value;
  }
  addProduct(product, fromOfflineSync) {
    if (product.glas_size_options) {
      for (const option of product.glas_size_options) {
        let product = ProductFactory.buildGlasSizeOption(option);
        this.glas_size_options.push(product);
      }
      /* store.default.commit(
        "setCurrentGlasSizeOption",
        this.glas_size_options[0]
      ); */
      !fromOfflineSync ? (product = this.glas_size_options[0]) : null;
      //store.default.commit("switchGlasSizeOption", this.glas_size_options[0]);
    }
    this.products.push(product);
  }
  sortProducts() {
    const customOrder = [
      ProductOptionsToSort.ARC,
      ProductOptionsToSort.WOOD_CUT,
      ProductOptionsToSort.ALU_CUT,
      ProductOptionsToSort.WOOD_FRAME,
      ProductOptionsToSort.CORNER_FRAME,
      ProductOptionsToSort.CUT,
      ProductOptionsToSort.POLE,
    ];
    this.products = this.products.slice().sort((a, b) => {
      return (
        customOrder.indexOf(a.sub_category) -
        customOrder.indexOf(b.sub_category)
      );
    });
  }
}
export default ProductFamily;
