<template>
  <div class="workshopConfigurationWrapper">
    <FramingWrapper v-if="currentWorkshopMode === workshopModes.FRAMING ||
      currentWorkshopMode === workshopModes.FRAME_WITH_EQUIPMENT
      "></FramingWrapper>
    <CanvasFramingWrapper
      v-if="currentWorkshopMode === workshopModes.CANVAS_FRAMING">
    </CanvasFramingWrapper>
    <StretchingWrapper
      v-if="currentWorkshopMode === workshopModes.CANVAS_STRETCH">
    </StretchingWrapper>
    <LaminationWrapper v-if="currentWorkshopMode === workshopModes.LAMINATION_AND_SURFACE_FINISHING
      "></LaminationWrapper>
    <LaminationFraming
      v-if="currentWorkshopMode === workshopModes.LAMINATION_FRAMING">
    </LaminationFraming>
  </div>
</template>
<script>
import WorkshopModes from "@/models/Types/WorkshopModes";
import FramingWrapper from "./framing/FramingWrapper.vue";
import CanvasFramingWrapper from "./framing/CanvasFramingWrapper.vue";
import StretchingWrapper from "./stretching/StretchingWrapper.vue";
import LaminationWrapper from "./lamination/LaminationWrapper.vue";
import LaminationFraming from "./framing/LaminationFramingWrapper.vue"

export default {
  data() {
    return {
      workshopModes: WorkshopModes,
    };
  },
  components: { FramingWrapper, CanvasFramingWrapper, StretchingWrapper, LaminationWrapper, LaminationFraming },
  computed: {
    currentWorkshopMode() {
      return this.$store.getters.currentWorkshopMode;
    },
  },
};
</script>
<style scoped lang="scss">
.workshopConfigurationWrapper {
  @import "../../../assets/sass/style.scss";

  width: 60vw;
  max-width: 850px;

  @media (max-width: $tablet) {
    width: 80vw;
  }

  @media (max-width: $mobile) {
    width: 95vw;
  }
}
</style>
