<template>
  <div class="mainWrapper" id="mainWrapper" v-show="!currentConfigLoading">
    <div class="topIconWrapper">
      <img class="topIcon" src="../../../assets/icons/gem-duotone.svg" />
    </div>
    <div class="mainHeadlineWrapper">
      <h1 class="headline">Werkstattarbeiten</h1>
    </div>
    <div class="workshopMode">
      <select v-model="workshopMode" @change="initializeWorkshopWork()">
        <!-- Option to show all stocks -->
        <option v-for="(value, key) in workshopModes" :value="value" :key="key">
          {{ value }}
        </option>
      </select>
    </div>
    <div class="workshopConfiguration">
      <WorkshopConfiguration></WorkshopConfiguration>
    </div>
    <div class="clearForm">
      <IbisButton buttonClass="secondary" buttonText="Formular Leeren"
        @click="clearForm"></IbisButton>
    </div>
    <div class="selectedProductIDsWrapper">
      <SelectedProductIDs />
    </div>

    <div class="orderConfirmWrapper">
      <OrderConfirm />
    </div>
  </div>

  <div class="fetchingSpinner" v-show="currentConfigLoading">
    <IbisSpinner></IbisSpinner>
  </div>
</template>
<script>
import SelectedProductIDs from "../shared/SelectedProductIDs.vue";
import OrderConfirm from "../shared/OrderConfirm.vue";
import IbisSpinner from "../../shared/IbisSpinner.vue";
import IbisButton from "../../shared/IbisButton.vue";
import WorkshopModes from "@/models/Types/WorkshopModes";
import WorkshopConfiguration from "./WorkshopConfiguration.vue";
import DBSyncer from "@/utility/DBSyncer";
export default {
  components: {
    SelectedProductIDs,
    OrderConfirm,
    IbisSpinner,
    WorkshopConfiguration,
    IbisButton,
  },

  mounted() {
    document.getElementById("mainWrapper").classList.add("fade-in-bottom");
    this.fetchCurrentUserConfiguration();
  },
  data() {
    return {
      currentConfigLoading: true,
      workshopModes: WorkshopModes,
    };
  },

  computed: {
    workshopMode: {
      get() {
        return this.$store.getters.currentWorkshopMode;
      },
      set(value) {
        this.$store.commit("setWorkshopMode", value);
      },
    },

  },

  methods: {
    async fetchCurrentUserConfiguration() {
      this.currentConfigLoading = true;

      await DBSyncer.fetchUserConfiguration();

      this.currentConfigLoading = false;
    },
    initializeWorkshopWork() {
      this.$store.commit("initializeWorkshopWork");
    },
    clearForm() {
      this.$store.commit("clearForm");
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.mainWrapper {
  width: 70vw;

  @media (max-width: $mobile) {
    width: 100vw;
    margin-top: 0;
  }

  @media (min-width: $mobile) and (max-width: $tablet) {
    width: 90vw;
  }

  background-color: $ibis-grey;
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 60px;
  margin-bottom: 100px;
  margin-top: 3em;
  max-width: 1100px;

  .topIconWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15%;
    margin-bottom: 25px;

    .topIcon {
      height: 50px;
    }
  }

  .mainHeadlineWrapper {
    width: 60vw;

    @media (max-width: $mobile) {
      width: 95vw;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;

    .headline {
      text-align: left;

      @media (max-width: $mobile) {
        text-align: center;
      }
    }
  }

  .workshopMode {
    width: 60vw;

    @media (max-width: $tablet) {
      width: 80vw;
    }

    @media (max-width: $mobile) {
      width: 95vw;
    }

    max-width: 850px;
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;
  }

  .workshopConfiguration {
    margin-top: 1rem;
  }

  .selectedProductIDsWrapper {
    margin-top: 1rem;
    width: 60vw;

    @media (max-width: $mobile) {
      width: 100vw;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;
  }

  .searchBarWrapper {
    width: 60vw;

    @media (max-width: $mobile) {
      width: 95vw;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;

    margin-top: 30px;
  }

  /* .currentProductInformationWrapper {
      width: 60vw;
      margin-top: 30px;
    } */

  .optionsWrapper {
    width: 60vw;

    @media (max-width: $mobile) {
      width: 100vw;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;
  }

  .overviewWrapper {
    width: 60vw;

    @media (max-width: $mobile) {
      width: 100vw;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;
  }

  .orderConfirmWrapper {
    width: 60vw;

    @media (max-width: $mobile) {
      width: 100vw;
      display: flex;
      justify-content: center;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;

    display: flex;
    justify-content: flex-end;
  }
}

.fetchingSpinner {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

select {
  padding: 12px 20px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("../../../assets/icons/arrow_down.svg");
  background-repeat: no-repeat;
  background-position: calc(95%) center;
  padding-right: 2em;
  min-width: 50%;

  @media (max-width: $mobile) {
    min-width: 50%;
  }
}

select::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

select:focus {
  outline: none;
  border-color: $ibis-black;
}

.clearForm {
  width: 60vw;
  max-width: 850px;
  display: flex;
  justify-content: flex-end;
  padding: 25px 0;
  box-sizing: border-box;
}
</style>
