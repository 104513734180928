<template>
  <div class="product-images">
    <div v-if="mainImage" class="main-image">
      <img :src="mainImage" />
    </div>
    <div class="thumbnails">
      <div
        v-for="(image, index) in imageLinks"
        :key="index"
        @click="setMainImage(image)"
      >
        <img :src="image" class="thumbnail" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["$firebase"],

  computed: {
    currentProduct() {
      return this.$store.getters.selectedProductFamily?.products[0];
    },
  },

  data() {
    return {
      mainImage: "",
      imageLinks: [],
    };
  },

  watch: {
    currentProduct: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          await this.fetchProductImages(newVal.product_id);
        }
      },
    },
  },

  methods: {
    async fetchProductImages(productId) {
      const storageRef = this.$firebase
        .storage()
        .ref(`ProductImages/${productId}`);
      try {
        const result = await storageRef.listAll();
        const urls = await Promise.all(
          result.items.map((item) => item.getDownloadURL())
        );
        this.imageLinks = urls;
        if (urls.length > 0) {
          this.mainImage = urls[0]; // Set the first image as the main image
        }
      } catch (error) {
        console.error("Failed to fetch images", error);
      }
    },

    setMainImage(image) {
      this.mainImage = image; // Update the main image
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.product-images {
  .main-image {
    width: 100%; // Adjust as necessary
    height: auto; // Adjust as necessary
    display: flex;
    justify-content: center;
    img {
      width: 25vw; // Ensure this matches your design requirements
      height: 25vw; // Maintain aspect ratio
      object-fit: contain;
      max-width: 400px;
      max-height: 400px;
      @media (max-width: $tablet) {
        height: 35vw;
        width: 35vw;
      }
      @media (max-width: $mobile) {
        height: 50vw;
        width: 50vw;
      }
    }
  }
  .thumbnails {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-top: 1rem;
    gap: 2rem;
    max-width: 400px;
    @media (max-width: $tablet) {
      margin-top: 2rem;
    }
    .thumbnail {
      border-radius: 15px;
      height: 100px; // Maintain aspect ratio
      margin: 5px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
