<template>
  <div class="stockItem" @click="goToItemDetails(item.parent_id, item)">
    <div class="itemImageWrapper">
      <img
        v-if="firstImageUrl"
        :src="firstImageUrl"
        class="itemImage"
        @error="imageError"
      />
    </div>
    <div class="productName">
      {{ firstProduct.article_id }} {{ firstProduct.name }}
    </div>
  </div>
</template>

<script>
import DBSyncer from "@/utility/DBSyncer";
export default {
  props: ["item"],
  inject: ["$firebase"],
  data() {
    return {
      firstImageUrl: "",
      imageFetchError: false,
    };
  },
  computed: {
    firstProduct() {
      return this.item.products[0];
    },
    currentProductFamily() {
      return this.$store.getters.selectedProductFamily;
    },
    selectedConfigurations() {
      return this.$store.getters.selectedConfigurations;
    },
    currentGlasOption: {
      get() {
        return this.$store.getters.currentGlasSizeOption;
      },
      set(newValue) {
        this.$store.commit("switchGlasSizeOption", newValue);
      },
    },
  },
  mounted() {
    this.fetchFirstImage();
  },
  methods: {
    async fetchFirstImage() {
      if (!this.firstProduct || !this.firstProduct.product_id) return;
      const storageRef = this.$firebase
        .storage()
        .ref(`ProductImages/${this.firstProduct.product_id}`);
      try {
        // List all files in the product's folder to get the first image
        const result = await storageRef.listAll();
        if (result.items.length > 0) {
          const firstImageRef = result.items[0]; // Get the first file reference
          this.firstImageUrl = await firstImageRef.getDownloadURL();
        }
      } catch (error) {
        console.error("Failed to fetch image", error);
        this.imageFetchError = true;
      }
    },
    imageError() {
      this.imageFetchError = true;
    },
    goToItemDetails(id, productFamily) {
      this.$store.commit("addSelectedProductFamily", productFamily);
      this.syncConfiguration();
      this.$router.push({
        name: "RestbestandDetails",
        params: { id: id },
      });
    },
    syncConfiguration() {
      DBSyncer.syncConfiguration();
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.stockItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  list-style: none;
  text-align: start;
  background-color: $ibis-grey;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0px 5px 10px rgba(64, 64, 64, 0.2);
  min-width: 0;
  cursor: pointer;
  .productName {
    color: $ibis-black;
    text-decoration: none;
    transition: 0.15s;
    display: inline-block;
    max-width: 80%;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  &:hover {
    .productName {
      color: $ibis-red;
    }
  }
  .itemImageWrapper {
    min-width: 50px;
    min-height: 50px;
    .itemImage {
      width: 50px; // or your desired size
      height: 50px; // ensure this matches your design requirements
      border-radius: 10px;
      object-fit: cover;
    }
  }
}
</style>
