<template>
  <div class="header">
    <div class="lastOrdersHeader">Letzte Bestellungen</div>
  </div>
  <div class="lastOrders">
    <IbisSpinner v-show="isLoading" />
    <div v-show="!isLoading" class="fileList" id="fileList">
      <div v-for="(file, index) in files" :key="index">
        <span class="fileTimeStamp">{{ file.timeCreated.date }}</span>
        <FileItem :file="file" />
      </div>
    </div>
  </div>
</template>

<script>
import FileItem from "../shared/FileItem.vue";
import IbisSpinner from "../shared/IbisSpinner.vue";

export default {
  inject: ["$firebase"],
  components: {
    FileItem,
    IbisSpinner,
  },
  data() {
    return {
      files: [],
      isLoading: true,
    };
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem("ibisOnlinebestellungUserObject"));
    },
  },
  async created() {
    this.isLoading = true;
    try {
      const storageRef = this.$firebase.storage().ref();
      // List files in the folder corresponding to the ibis_customer_id
      const customerFilesRef = storageRef.child(
        `OrderDocuments/${this.userInfo.ibis_customer_id}`
      );
      const result = await customerFilesRef.listAll();
      const filePromises = result.items.map(async (item) => {
        const url = await item.getDownloadURL();
        const metadata = await item.getMetadata(); // Get metadata of the file
        const timeCreated = new Date(metadata.timeCreated); // Convert ISO 8601 string to Date
        const dateOptions = { year: "numeric", month: "long", day: "numeric" };
        const timeOptions = {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        };
        return {
          name: item.name,
          url,
          timeCreated: {
            date: `${timeCreated.toLocaleDateString(
              "de-DE",
              dateOptions
            )} ${timeCreated.toLocaleTimeString("de-DE", timeOptions)}`, // Convert to readable German timestamp
            raw: timeCreated, // Save the raw Date object for sorting
          },
        };
      });
      let unsortedFiles = await Promise.all(filePromises);
      // Sort the files by the time they were created, latest first
      this.files = unsortedFiles.sort(
        (a, b) => b.timeCreated.raw - a.timeCreated.raw
      );
    } catch (error) {
      console.error("Error while fetching files:", error);
    } finally {
      document.getElementById("fileList").classList.add("fade-in-bottom");
      this.isLoading = false;
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.header {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;
  .lastOrdersHeader {
    text-align: start;
    font-size: $font-size-large;
  }
}
.lastOrders {
  padding: 2rem 4rem;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 8px 37px rgba(64, 64, 64, 0.1);
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 612px;

  @media (max-width: $tablet) {
    padding: 2rem 2rem;
  }
  .fileList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .fileTimeStamp {
      display: block;
      width: 100%;
      text-align: start;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
