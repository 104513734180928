<template>
  <div class="formWrapper">
    <div class="topline">
      <div class="textInput">
        <input type="text" placeholder="Code" v-model="code" />
        <svg
          @click="generateRandomHash"
          fill="currentColor"
          viewBox="0 0 32 32"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 24q0 0.832 0.576 1.44t1.44 0.576h1.984q2.048 0 3.904-0.8t3.168-2.144 2.144-3.2 0.8-3.872q0-2.464 1.728-4.224t4.256-1.76h4v1.984q0 0.672 0.384 1.152t0.864 0.704 1.12 0.128 1.056-0.544l4-4q0.608-0.64 0.576-1.44t-0.576-1.408l-4-4q-0.48-0.448-1.088-0.544t-1.12 0.128-0.864 0.704-0.352 1.12v2.016h-4q-2.016 0-3.872 0.8t-3.2 2.112-2.144 3.2-0.768 3.872q0 2.496-1.76 4.256t-4.256 1.76h-1.984q-0.832 0-1.44 0.576t-0.576 1.408zM0 8.032q0 0.832 0.576 1.408t1.44 0.576h1.984q1.408 0 2.592 0.608t2.080 1.664q0.672-2.048 1.984-3.68-2.912-2.592-6.656-2.592h-1.984q-0.832 0-1.44 0.608t-0.576 1.408zM13.376 23.456q2.848 2.56 6.624 2.56h4v2.016q0 0.64 0.384 1.152t0.864 0.704 1.12 0.096 1.056-0.544l4-4q0.608-0.608 0.576-1.44t-0.576-1.376l-4-4q-0.48-0.48-1.088-0.576t-1.12 0.128-0.864 0.736-0.352 1.12v1.984h-4q-1.376 0-2.592-0.576t-2.048-1.664q-0.704 2.048-1.984 3.68z"
          ></path>
        </svg>
      </div>
      <div class="usageOptions">
        <label class="checkboxContainer"
          >Nur eine Anwendung
          <input type="checkbox" v-model="singleUse" />
          <span class="checkmark"></span> </label
        ><label class="checkboxContainer"
          >Nur eine Anwendung pro Nutzer
          <input type="checkbox" v-model="singleUsePerUser" />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <div class="bottomline">
      <div class="timeslot">
        <div class="headline">
          <div style="width: 50%">Start</div>
          <div style="width: 50%">Ende</div>
        </div>
        <div class="dates">
          <div class="date">
            {{ formatToGermanDateTime(startDate) }}
            <svg
              @click.stop="editStartDate = !editStartDate"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M7.127 22.562l-7.127 1.438 1.438-7.128 5.689 5.69zm1.414-1.414l11.228-11.225-5.69-5.692-11.227 11.227 5.689 5.69zm9.768-21.148l-2.816 2.817 5.691 5.691 2.816-2.819-5.691-5.689z"
              />
            </svg>
          </div>
          <div class="date">
            {{ formatToGermanDateTime(endDate)
            }}<svg
              @click.stop="editEndDate = !editEndDate"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M7.127 22.562l-7.127 1.438 1.438-7.128 5.689 5.69zm1.414-1.414l11.228-11.225-5.69-5.692-11.227 11.227 5.689 5.69zm9.768-21.148l-2.816 2.817 5.691 5.691 2.816-2.819-5.691-5.689z"
              />
            </svg>
          </div>
        </div>
        <div class="date-picker">
          <VDatePicker
            ref="startDatePicker"
            v-model="startDate"
            v-if="editStartDate && !editEndDate"
            mode="dateTime"
            is24hr
            :min-date="new Date()"
            color="red"
          />
          <VDatePicker
            ref="endDatePicker"
            v-model="endDate"
            v-if="editEndDate && !editStartDate"
            mode="dateTime"
            is24hr
            :min-date="minEndDate"
            color="red"
          />
        </div>
      </div>
      <div class="discount">
        <input
          type="number"
          v-model="discount"
          placeholder="% Rabatt"
          min="1"
          max="100"
        />
        oder
        <input
          type="number"
          v-model="absoluteDiscount"
          placeholder="€ Rabatt"
          min="1"
          max="100"
        />
      </div>
    </div>
  </div>
  <div class="addCodeWrapper">
    <IbisButton
      buttonClass="secondary"
      buttonText="Abbrechen"
      @click="onAbortClick"
    ></IbisButton>
    <IbisButton
      buttonClass="primary"
      buttonText="Hinzufügen"
      @click="onCodeSubmit"
      :buttonDisabled="!isFormValid"
      :isLoading="isCreationLoading"
    ></IbisButton>
  </div>
</template>
<script>
import IbisButton from "@/components/shared/IbisButton.vue";
import BackendHandler from "@/utility/BackendHandler";
export default {
  components: {
    IbisButton,
  },
  data() {
    return {
      code: undefined,
      singleUse: false,
      singleUsePerUser: true,
      startDate: "",
      endDate: "",
      editStartDate: false,
      editEndDate: false,
      discount: undefined,
      absoluteDiscount: undefined,
      isCreationLoading: false,
    };
  },
  computed: {
    minEndDate() {
      const minEnd = new Date();
      minEnd.setFullYear(minEnd.getFullYear() + 1);
      return minEnd;
    },
    isFormValid() {
      // Updated validation logic to ensure either discount or absoluteDiscount is set
      return (
        this.code &&
        (this.discount !== undefined || this.absoluteDiscount !== undefined) &&
        this.startDate &&
        this.endDate
      );
    },
  },
  mounted() {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate()); // Add 1 day to the current date
    this.startDate = currentDate;
  },
  watch: {
    startDate(newVal) {
      if (newVal) {
        const newEndDate = new Date(newVal);
        newEndDate.setFullYear(newEndDate.getFullYear() + 1);
        this.endDate = newEndDate;
      }
    },
    discount(newVal) {
      if (newVal !== undefined) {
        this.absoluteDiscount = undefined;
        if (newVal > 100) this.discount = 100;
        if (newVal < 1) this.discount = 1;
      }
    },
    absoluteDiscount(newVal) {
      if (newVal !== undefined) {
        this.discount = undefined;
      }
    },
    singleUse(newValue) {
      if (newValue) {
        this.singleUsePerUser = false;
      }
    },
    singleUsePerUser(newValue) {
      if (newValue) {
        this.singleUse = false;
      }
      // ... other watchers
    },
  },
  methods: {
    generateRandomHash() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let hash = "";
      for (let i = 0; i < 8; i++) {
        hash += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      this.code = hash;
    },
    formatToGermanDateTime(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    },
    async onCodeSubmit() {
      try {
        this.isCreationLoading = true;

        const payload = {
          code: this.code.trim(),
          discountPercentage: this.discount ? this.discount / 100 : undefined,
          absoluteDiscount: this.absoluteDiscount,
          validFrom: this.startDate,
          validUntil: this.endDate,
          singleUse: this.singleUse,
          singleUsePerUser: this.singleUsePerUser,
        };
        await BackendHandler.createDiscountCode(payload);

        this.$emit("buttonSubmit");
      } catch (e) {
        console.log(e);
        this.isCreationLoading = false;
      }
      this.isCreationLoading = false;
    },
    onAbortClick() {
      this.$emit("buttonSubmit");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/sass/style.scss";

.formWrapper {
  border-radius: 25px;
  width: 100%;
  border-radius: 25px;
  background-color: white;
  box-shadow: 0px 8px 37px rgba(64, 64, 64, 0.1);
  padding: 2rem;
  @media (max-width: $mobile) {
    padding: 0.5rem;
    font-size: 12px;
  }
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  gap: 2rem;
  .topline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .textInput {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 60%;

      svg {
        height: 1.5rem;
        width: 1.5rem;
        fill: $ibis-black; // default fill color
        &:hover {
          fill: $ibis-red; // change fill color on hover
          transition: fill 0.15s; // apply transition to the fill property
          cursor: pointer;
        }
      }
      input {
        max-width: none;
        width: 40%;
        text-align: start;
        padding-left: 1rem;
      }
    }
    .usageOptions {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 50px;
      background-color: $ibis-grey-middle;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }

    input:checked ~ .checkmark {
      background-color: $ibis-red;
      transition: 0.15s;
      &:after {
        display: block;
      }
    }
    .checkboxContainer {
      .checkmark:after {
        left: 6.5px;
        top: 4px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  .bottomline {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .timeslot {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 60%;
      @media (max-width: $mobile) {
        width: 75%;
      }
      svg {
        height: 1rem;
        width: 1rem;
        fill: $ibis-black; // default fill color
        &:hover {
          fill: $ibis-red; // change fill color on hover
          transition: fill 0.15s; // apply transition to the fill property
          cursor: pointer;
        }
      }
      .headline {
        display: flex;
        div {
          text-align: start;
          font-weight: bold;
        }
      }
      .dates {
        display: flex;
      }
      .date-picker {
        position: absolute;
        z-index: 200;
        left: 50%;
        transform: translate(-50%);
        top: 100%;
      }
      .date {
        width: 50%;
        text-align: start;
        display: flex;
        gap: 1rem;
        img {
          height: 1rem;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .discount {
      input {
        max-width: none;
        width: 5rem;
      }
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
    }
  }
}
.addCodeWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}
input {
  padding: 12px 10px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  text-align: center;
  @media (max-width: $mobile) {
    min-width: 3rem;
  }
}
</style>
