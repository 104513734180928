<template>
  <div class="thankYouPage" id="thankYouPage">
    <h1>Vielen Dank für Ihre Bestellung!</h1>
    <h4>Wir bearbeiten diese in Kürze.</h4>
    <div class="progress-bar-container">
      <div class="progress-bar" :style="{ width: progress + '%' }"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      progress: 0,
      start: null,
      total: 5000,
    };
  },
  computed: {
    lastCurrentSessionOrder() {
      return this.$store.getters.lastCurrentSessionOrder;
    },
  },
  mounted() {
    if (!this.lastCurrentSessionOrder) {
      this.$router.push("/");
    } else {
      document.getElementById("thankYouPage").classList.add("fade-in-bottom");

      requestAnimationFrame(this.startProgressBar);
    }
  },
  beforeUnmount() {
    cancelAnimationFrame(this.frameId);
  },
  methods: {
    easeOut(t) {
      return 1 - Math.pow(1 - t, 3);
    },
    startProgressBar(timestamp) {
      if (!this.start) this.start = timestamp;
      const elapsed = timestamp - this.start;
      const rawProgress = elapsed / this.total;

      this.progress = Math.min(this.easeOut(rawProgress) * 100, 100);

      if (rawProgress < 1) {
        this.frameId = requestAnimationFrame(this.startProgressBar);
      } else {
        setTimeout(() => {
          this.$router.push("/");
        }, 1000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/sass/style.scss";
.thankYouPage {
  text-align: center;
  margin-top: 10rem;
}

.progress-bar-container {
  width: 30%;
  max-width: 600px;
  margin: auto;
  height: 5px;
  background-color: white;
  margin-top: 2rem;
  border-radius: 50px;
}

.progress-bar {
  height: 5px;
  background-color: $ibis-red;
  border-radius: 50px;
}
</style>
