<template>
  <!--  <div v-if="!isSupportedDevice" style="padding: 1rem">
    <p>
      Diese Webplattform ist noch nicht für mobile Geräte optimiert. Bitte
      Verwenden sie die Desktop-Version.
    </p>
  </div> -->
  <div>
    <CookieConsent v-if="!cookieConsent && showCookieDialog"
      @closeDialog="showCookieDialog = false"></CookieConsent>
    <NavBar />
    <NewsBanner v-if="isNewsBannerVisible"></NewsBanner>
    <IbisToast :show="this.$store.getters.toastConfiguration.showToast"
      :toastType="this.$store.getters.toastConfiguration.toastType"
      :toastMessage="this.$store.getters.toastConfiguration.message">
    </IbisToast>
    <div style="min-height: 80vh">
      <RouterView />
    </div>
    <IbisFooter />
  </div>
</template>

<script>
import CookieConsent from "./CookieConsent.vue";
import NavBar from "./NavBar.vue";
import IbisFooter from "./IbisFooter.vue";
import IbisToast from "./components/shared/IbisToast.vue";
import AuthService from "./utility/AuthService";
import DeviceTypes from "./models/Types/DeviceTypes";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import NewsBanner from "./NewsBanner.vue";
export default {
  name: "IbiS Online Bestellung",
  components: {
    CookieConsent,
    NavBar,
    IbisFooter,
    IbisToast,
    NewsBanner,
  },
  data() {
    return {
      showCookieDialog: true,
    };
  },

  computed: {
    cookieConsent() {
      return (
        localStorage.getItem("ibisOnlinebestellungCookieConsentGiven") ===
        "true" ||
        localStorage.getItem("ibisOnlinebestellungCookieConsentGiven") ===
        "false"
      );
    },
    isNewsBannerVisible() {
      const forbiddenRoutes = ["/login", "/registrierung", "/passwort_vergessen", "/ansprechpartner", "/impressum", "/datenschutz"];
      const userObject = JSON.parse(
        localStorage.getItem("ibisOnlinebestellungUserObject")
      );
      return !forbiddenRoutes.includes(this.$route.path) && (userObject?.customer_type === "Fachhandel" || !userObject?.customer_type);
    },
  },

  async mounted() {
    /*  if (!this.cookieConsent) {
      AuthService.logout();
    } */
    this.checkDeviceSupport(); // Check device support initially
    window.addEventListener("resize", this.checkDeviceSupport); // Add event listener for window resize

    // Somewhere in your application setup code:
    const authService = await AuthService.getInstance();

    document.addEventListener("click", () => authService.resetSessionTimer());
    document.addEventListener("keydown", () => authService.resetSessionTimer());
    document.addEventListener("mousemove", () =>
      authService.resetSessionTimer()
    );
    document.addEventListener("scroll", () => authService.resetSessionTimer());

    // Call fetchAndUpdateUserData
    this.fetchAndUpdateUserData();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkDeviceSupport); // Remove event listener before component is destroyed
  },
  methods: {
    checkDeviceSupport() {
      const platform = navigator.userAgent.toLowerCase();
      if (
        /(android|webos|iphone|ipad|ipod|blackberry|windows phone)/.test(
          platform
        )
      ) {
        this.$store.commit("setDeviceType", DeviceTypes.MOBILE);
        return;
      } else if (/mac|win|linux/i.test(platform)) {
        this.$store.commit("setDeviceType", DeviceTypes.DESKTOP);
        return;
      } else if (/tablet|ipad/i.test(platform)) {
        this.$store.commit("setDeviceType", DeviceTypes.TABLET);
        return;
      }
      //this.isSupportedDevice = window.innerWidth >= 1100; // Adjust the threshold value as per your needs
    },
    async fetchAndUpdateUserData() {
      const localUserObject = localStorage.getItem(
        "ibisOnlinebestellungUserObject"
      );
      if (localUserObject) {
        const user = JSON.parse(localUserObject);
        const ibisCustomerId = user.ibis_customer_id.toString();

        try {
          const userDoc = await firebase
            .firestore()
            .collection("users")
            .doc(ibisCustomerId)
            .get();
          if (userDoc.exists) {
            const updatedUserData = userDoc.data();
            localStorage.setItem(
              "ibisOnlinebestellungUserObject",
              JSON.stringify(updatedUserData)
            );
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "./assets/sass/style.scss";

body {
  margin: 0;
}

#app {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: $ibis-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;

  @media (max-width: $tablet) {
    margin-top: 0;
  }

  input {
    //font-family: "Raleway";
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:disabled {
    opacity: 0.5;
  }

  input:focus {
    outline: none;
    border-color: $ibis-black;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  select {
    //font-family: "Raleway";
    background-color: white;
  }
}
</style>
