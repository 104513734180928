<template>
  <div>
    <label class="file-input-wrapper">
      <input
        class="grey-square"
        type="file"
        ref="fileInput"
        @change="handleFileChange"
        accept=".xlsx,.xls,.ods"
        :multiple="false"
      />
      <img class="fileInputIcon" :src="iconPath" v-if="!selectedFile" />
      <img
        v-else
        class="fileInputIcon"
        src="../../../assets/icons/admin_document.svg"
      />
      <span class="custom-text">{{
        selectedFile ? selectedFile.name : customText
      }}</span>
    </label>
  </div>
</template>
<script>
export default {
  props: ["iconPath", "customText"],
  data() {
    return {
      selectedFile: undefined,
      documentIconPath: "../../../assets/icons/admin_document.svg",
    };
  },
  methods: {
    handleFileChange() {
      const fileInput = this.$refs.fileInput;
      this.selectedFile = fileInput.files[0];
      this.$emit("file-selected", this.selectedFile);
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.file-input-wrapper {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 25vw;
  height: 25vw;
  max-width: 500px;
  max-height: 500px;
  background-color: $ibis-grey;
  border-radius: 25px;
  @media (max-width: $mobile) {
    width: 90vw;
    height: 90vw;
  }
}

.grey-square {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.custom-text {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  font-weight: bold;
}

.fileInputIcon {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 50%;
  height: 50%;
  padding: 5rem;
}
</style>
