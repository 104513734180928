<template>
  <div class="previewContainer">
    <div class="pricelistResults" v-if="results.length > 0">
      <div v-for="(product, index) in results" :key="index">
        <div class="resultItem">
          <span class="productID">{{ product.article_id }}</span>
          <span class="productName">{{ product.name }}</span>
          <span class="productType">{{ product.productType }}</span>
          <span class="productPrice">{{
            getProductPricing(product.price)
          }}</span>
        </div>
      </div>
    </div>
    <div v-else class="noResults">Keine Produkte gefunden</div>
  </div>
</template>
<script>
export default {
  props: ["results"],

  methods: {
    getProductPricing(price) {
      if (price.toString().includes(":")) {
        //TODO: impl

        const pricingDimensions = price.split(",");

        let pricingString = "";

        for (let i = 0; i < pricingDimensions.length; i++) {
          const keyValuePair = pricingDimensions[i].split(":");

          if (i === 0) {
            pricingString += `0 - ${keyValuePair[0]}cm : ${keyValuePair[1]} € `;
          } else {
            pricingString += `${pricingDimensions[i - 1].split(":")[0]}cm - ${
              keyValuePair[0]
            }cm : ${keyValuePair[1]} € `;
          }
        }
        return pricingString;
      } else {
        return price.toFixed(2).replace(".", ",") + " €";
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";
.previewContainer {
  width: 100%;
  border-radius: 25px;
  background-color: white;
  height: 40vh;
  box-shadow: 0px 8px 37px rgba(64, 64, 64, 0.1);
  overflow-y: scroll;

  .pricelistResults {
    padding: 2% 5% 5% 5%;
    .resultItem {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-bottom: 0.5rem;
      @media (max-width: $mobile) {
        flex-direction: column;
      }
      .productID {
        width: 15%;
        display: inline-block;
        text-align: start;
        margin-right: 1rem;
        @media (max-width: $mobile) {
          width: 100%;
        }
      }
      .productName {
        width: 50%;
        display: inline-block;
        text-align: start;
        @media (max-width: $mobile) {
          width: 100%;
        }
      }
      .productType {
        width: 20%;
        display: inline-block;
        text-align: start;
        margin-right: 1rem;
        word-wrap: break-word;
        @media (max-width: $mobile) {
          width: 100%;
        }
      }
      .productPrice {
        width: 10%;
        display: inline-block;
        text-align: end;
        @media (max-width: $mobile) {
          width: 100%;
        }
      }
    }
  }

  .noResults {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; // This makes the track completely transparent
    margin-top: 12px;
    margin-bottom: 12px;
    // This is essentially the space between the scrollbar and the container edge
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
</style>
