<template>
  <div class="valueInputWrapper">
    <span class="subHeader">Bildwert: </span>
    <div class="inputContainer">
      <input type="number" placeholder="Betrag" @blur="validateValue"
        v-model="workshopPictureValue" />
      <span class="subHeader">€</span>
      <div class="infoIconWrapper">
        <ToolTip tipText="Kundeneigentum ist bis zu einem nachgewiesenen
          Maximalwert von 5.000€ versichert."></ToolTip>
      </div>
    </div>
  </div>
  <div class="damageInputWrapper">
    <label class="checkboxContainer"
      style="margin-top: 2em; margin-bottom: 1em">Keine Beschädigung
      <input type="checkbox" v-model="noDamage" @input="handleDamageInput" />
      <span class="checkmark"></span>
    </label>
    <div class="textFieldWrapper" v-if="!noDamage">
      <textarea class="textField" placeholder="Schadensbeschreibung" cols="30"
        rows="5" v-model="workshopDamage" @input="syncConfiguration"></textarea>
    </div>
  </div>
</template>

<script>
import DBSyncer from "@/utility/DBSyncer";
import ToolTip from "@/components/shared/ToolTip.vue";
export default {
  components: {
    ToolTip,
  },
  data() {
    return {
      noDamage: true,
    };
  },
  mounted() {
    this.workshopDamage ? (this.noDamage = false) : null;
  },
  computed: {
    workshopPictureValue: {
      get() {
        return this.$store.getters.currentWorkshopWork.picture_value;
      },
      set(value) {
        this.$store.commit("setWorkshopPictureValue", value);
      },
    },
    workshopDamage: {
      get() {
        return this.$store.getters.currentWorkshopWork.damage;
      },
      set(value) {
        this.$store.commit("setWorkshopDamage", value);
      },
    },
  },
  methods: {
    validateValue() {
      if (this.workshopPictureValue < 0) {
        this.workshopPictureValue = 0;
      }
      if (this.workshopPictureValue > 5000) {
        this.workshopPictureValue = 5000;
      }
    },
    handleDamageInput() {
      if (this.noDamage === true) {
        this.workshopDamage = undefined;
      }
      this.syncConfiguration();
    },
    syncConfiguration() {
      DBSyncer.syncConfiguration();
      this.$store.commit("updatePrice");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../../assets/sass/style.scss";

.valueInputWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;

  .subHeader {
    font-size: $font-size-bigger;
    font-weight: bold;
  }
}

.inputContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

input {
  padding: 12px 10px;
  box-shadow: none;
  border: 2px solid $ibis-red;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  max-width: 7vw;
  text-align: center;

  @media (max-width: $mobile) {
    min-width: 3rem;
  }
}



.checkboxContainer {
  z-index: 1;
  display: flex;
  position: relative;
  padding-left: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background-color: $ibis-grey-middle;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked~.checkmark {
    background-color: $ibis-red;
    transition: 0.15s;

    &:after {
      display: block;
    }
  }

  .checkmark:after {
    left: 6.5px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.textFieldWrapper {
  margin-top: 2em;
  display: flex;
  justify-content: flex-start;

  .textField {
    border-radius: 25px;
    padding: 20px;
    width: 100%;
    resize: none;
    color: $ibis-black;
    border: 2px solid $ibis-grey-middle;
    //outline: 2px solid $ibis-black;
    outline: none;
    //font-family: "Raleway";

    &:focus {
      border: 2px solid $ibis-black;
    }
  }
}
</style>
