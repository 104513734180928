const WorkshopSurfaceFinishings_XXXL = [
  { key: "LAM101", value: "Laminat Matt" },
  //{ key: "LAM103", value: "Laminat Leinen fein" },
  { key: "LAM104", value: "Laminat Leinen grob" },
  { key: "LAM105", value: "Kristall" },
];

const SurfaceFinishingPrices_XXXL = "100x150:22.70,120x195:22.70";

const LaminationLowerMaxSize = 124;
const LaminationHigherMaxSize = 190;

const SurfaceFinishingLowerMaxSize = 120;
const SurfaceFinishingHigherMaxSize = 195;

module.exports = {
  WorkshopSurfaceFinishings_XXXL,
  SurfaceFinishingPrices_XXXL,
  LaminationLowerMaxSize,
  LaminationHigherMaxSize,
  SurfaceFinishingLowerMaxSize,
  SurfaceFinishingHigherMaxSize,
};
