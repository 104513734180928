<template>
  <div class="priceListSearchWrapper">
    <div class="topRow">
      <h2>Preislisten-Konfigurator</h2>
      <span @click="this.$router.push('/downloads/preislisten')"
        >bestehende Preislisten <span>➜</span></span
      >
    </div>
    <div class="bottomRow">
      <select v-model="manufacturer">
        <option value="" disabled selected>Hersteller</option>
        <option
          v-for="man in manufacturers"
          :key="man.value"
          :value="man.value"
          :selected="manufacturer === man.value"
        >
          {{ man.name }}
        </option>
      </select>
      <select v-model="productType">
        <option value="" disabled selected>Verkaufsart</option>
        <option
          v-for="prod in productTypes"
          :key="prod.value"
          :value="prod.value"
          :selected="productType === prod.value"
        >
          {{ prod.name }}
        </option>
      </select>
      <div class="search-bar">
        <input
          type="text"
          v-model="additionalSearch"
          placeholder="Suchbegriffe..."
          @input="buildCustomSearchString()"
        />
      </div>
    </div>
    <div class="disclaimer">
      <img src="../../assets/icons/info.svg" />
      <span
        >Der Suchvorgang kann bei unspezifischen Suchen 1-2 Minuten
        dauern.</span
      >
    </div>
    <div class="disclaimer">
      <img src="../../assets/icons/info.svg" />
      <span>Der PDF-Export ist auf 1000 Produkte limitiert.</span>
    </div>
    <div class="searchButton">
      <IbisButton
        buttonClass="primary"
        buttonText="Suchen"
        @click="buildSearchString()"
        :disabled="!manufacturer && !productType && !additionalSearch"
        :isLoading="resultsLoading"
      ></IbisButton>
    </div>
    <div
      class="previewWrapper"
      id="previewWrapper"
      v-show="!resultsLoading && !initialLoad"
    >
      <div class="previewHeadline">
        <h3>Vorschau</h3>
        <h4>{{ searchResults.length }} Produkte</h4>
      </div>
      <PriceListResultPreview :results="searchResults"></PriceListResultPreview>
    </div>
    <div
      class="exportButtons"
      id="exportButtons"
      v-show="searchResults.length > 0"
    >
      <IbisButton
        buttonClass="primary"
        buttonText="als PDF exportieren"
        :isLoading="isPDFExportLoading"
        @click="exportAsPDF()"
        :disabled="searchResults.length > 1000"
      ></IbisButton>
      <IbisButton
        buttonClass="primary"
        buttonText="als Excel exportieren"
        :isLoading="isExcelExportLoading"
        @click="exportAsExcel()"
      ></IbisButton>
    </div>
  </div>
</template>
<script>
import BackendHandler from "@/utility/BackendHandler";
import PriceListResultPreview from "./PriceListResultPreview.vue";
import IbisButton from "../shared/IbisButton.vue";
export default {
  components: {
    PriceListResultPreview,
    IbisButton,
  },
  data() {
    return {
      manufacturers: [
        { name: "Alle Hersteller", value: "" },
        { name: "Crescent", value: "Crescent" },
        { name: "e-xhibit", value: "e-xhibit" },
        { name: "Euro", value: "Euro" },
        { name: "Fabira-IbiS", value: "Fabira-IbiS" },
        //{ name: "IbiS", value: "IbiS" },
        //{ name: "IbiS Groglass artglass", value: "IbiS Groglass artglass" },
        { name: "Larson-Juhl", value: "Larson-Juhl" },
        //{ name: "Larson-Juhl Fabira-IbiS", value: "Larson-Juhl Fabira-IbiS" },
        { name: "Mittermeier", value: "Mittermeier" },
        { name: "nielsen", value: "nielsen" },
        { name: "Spagl", value: "Spagl" },
        { name: "Stas", value: "Stas" },
        //{ name: "Tru Vue", value: "Tru Vue" },
        { name: "Wohlleb", value: "Wohlleb" },
        //{ name: "ThyssenKrupp", value: "ThyssenKrupp" },

        // add other manufacturers here
      ],
      manufacturer: "",
      productTypes: [
        { name: "Alle Verkaufsarten", value: "" },
        { name: "Alu-Zuschnitt", value: "Alu-Zuschnitt" },
        //{ name: "Fertigrahmen", value: "Fertigrahmen" },
        //{ name: "Glas-Tafel", value: "Glas-Tafel" },
        //{ name: "Glas-Zuschnitt", value: "Glas-Zuschnitt" },
        // { name: "Hardware, Zubehör, Sonstiges", value: "Hardware, Zubehör, Sonstiges" },
        // { name: "Hardware/Zubehör/Sonst.", value: "Hardware/Zubehör/Sonst." },
        { name: "Holz-Rahmen", value: "Holz-Rahmen" },
        { name: "Holz-Zuschnitt", value: "Holz-Zuschnitt" },
        //{ name: "KR-Stück", value: "KR-Stück" },
        //{ name: "KR-Zuschnitt", value: "KR-Zuschnitt" },
        { name: "Passepartout-Bogen", value: "Passepartout-Bogen" },
        { name: "Passepartout-Zuschnitt", value: "Passepartout-Zuschnitt" },
        { name: "Rückwand-Bogen", value: "Rückwand-Bogen" },
        { name: "Rückwand-Zuschnitt", value: "Rückwand-Zuschnitt" },
        { name: "Stange", value: "Stange" },
        { name: "Wechselrahmen", value: "Wechselrahmen" },
        //{ name: "Fertigrahmen", value: "Fertigrahmen" },
        {
          name: "Hardware, Zubehör, Sonstiges",
          value: "Hardware, Zubehör, Sonstiges",
        },
      ],

      productType: "",
      additionalSearch: "",
      searchString: "",
      resultsLoading: false,
      searchResults: [],
      isPDFExportLoading: false,
      isExcelExportLoading: false,
      initialLoad: true,
      timeoutId: undefined,
    };
  },

  methods: {
    async buildSearchString() {
      if (!this.resultsLoading) {
        this.searchResults = [];
        this.searchString =
          this.manufacturer +
          " " +
          this.productType +
          " " +
          this.additionalSearch;

        this.resultsLoading = true;

        document
          .getElementById("previewWrapper")
          .classList.add("fade-in-bottom");

        const fetchProductSiblings = false;

        try {
          const results = await BackendHandler.searchProducts(
            this.searchString,
            fetchProductSiblings
          );
          this.extractResults(results);
        } catch (e) {
          this.resultsLoading = false;
          this.initialLoad = false;
        }
      }
    },
    buildCustomSearchString() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(async () => {
        if (this.additionalSearch.length > 2) {
          this.buildSearchString();
        }
      }, 500);
    },
    extractResults(results) {
      for (const result of results) {
        for (const product of result.products) {
          this.searchResults.push({
            article_id: product.article_id.toString(),
            name: product.name,
            productType: product.sub_category,
            price: product.price,
            price_unit: product.price_unit,
          });
        }
      }

      this.searchResults.sort((a, b) => {
        const articleIdA = a.article_id;
        const articleIdB = b.article_id;

        const isNumA = !isNaN(articleIdA);
        const isNumB = !isNaN(articleIdB);

        // If both are numbers, compare them numerically
        if (isNumA && isNumB) {
          return parseFloat(articleIdA) - parseFloat(articleIdB);
        }

        // If A is a number and B is a letter, A should come first
        if (isNumA && !isNumB) {
          return -1;
        }

        // If B is a number and A is a letter, B should come first
        if (!isNumA && isNumB) {
          return 1;
        }

        // If both are letters, compare them alphabetically
        return articleIdA.localeCompare(articleIdB);
      });

      this.resultsLoading = false;
      document.getElementById("exportButtons").classList.add("fade-in-bottom");
      this.initialLoad = false;
    },

    async exportAsPDF() {
      try {
        this.isPDFExportLoading = true;

        const response = await BackendHandler.exportPricelistAsPDF({
          data: this.searchResults,
        });

        if (response && response.data) {
          const binaryData = atob(response.data.base64); // Decode base64
          const bytes = new Uint8Array(binaryData.length);

          for (let i = 0; i < binaryData.length; i++) {
            bytes[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([bytes.buffer], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          // Create and trigger a download link
          const link = document.createElement("a");
          link.href = url;

          const date = new Date();
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
          const year = date.getFullYear();

          const formattedDate = `${day}.${month}.${year}`;

          link.download = `Preisliste_${formattedDate}.pdf`; // Name of the file to be downloaded
          link.click();

          // Cleanup
          window.URL.revokeObjectURL(url);
        }

        this.isPDFExportLoading = false;
      } catch (e) {
        this.isPDFExportLoading = false;
        console.error(e);
      }
    },

    async exportAsExcel() {
      try {
        this.isExcelExportLoading = true;

        const response = await BackendHandler.exportPricelistAsExcel({
          data: this.searchResults,
        });

        if (response && response.data) {
          const binaryData = atob(response.data.base64); // Decode base64
          const bytes = new Uint8Array(binaryData.length);

          for (let i = 0; i < binaryData.length; i++) {
            bytes[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([bytes.buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);

          // Create and trigger a download link
          const link = document.createElement("a");
          link.href = url;

          const date = new Date();
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
          const year = date.getFullYear();

          const formattedDate = `${day}.${month}.${year}`;

          link.download = `Preisliste_${formattedDate}.xlsx`; // Name of the file to be downloaded
          link.click();

          // Cleanup
          window.URL.revokeObjectURL(url);
        }

        this.isExcelExportLoading = false;
      } catch (e) {
        this.isExcelExportLoading = false;
        console.error(e);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.priceListSearchWrapper {
  width: 60vw;
  max-width: 800px;
  padding: 5% 5% 5% 5%;
  background-color: $ibis-grey;
  border-radius: 25px;
  @media (max-width: $mobile) {
    width: 90vw;
  }
  @media (min-width: $mobile) and (max-width: $tablet) {
    width: 80vw;
  }
  .topRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin-top: 0;
      margin-bottom: 3rem;
      @media (max-width: $mobile) {
        font-size: $font-size-big;
        width: 100%;
        margin-bottom: 1.5rem;
        text-align: left;
      }
    }
    span {
      margin-bottom: 3rem;
      cursor: pointer;
      transition: 0.1s;
      @media (max-width: $mobile) {
        margin-bottom: 1.5rem;
      }
      &:hover {
        color: $ibis-red;
      }
    }
  }
  .disclaimer {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    img {
      height: 1.2rem;
    }
    @media (max-width: $mobile) {
      text-align: left;
    }
  }
  .searchButton {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 2rem;
  }
  .bottomRow {
    display: flex;
    gap: 2rem;
    @media (max-width: $mobile) {
      display: grid;
    }
    .search-bar {
      display: flex;
      border-radius: 50px;
      background-color: $ibis-grey-middle;
      padding: 4px;
      width: 100%;
      @media (max-width: $mobile) {
        width: 98%;
      }
      input {
        width: 100%;
        height: 2.5em;
        border-radius: 50px;
        border: none;
        padding-left: 1rem;
        font-size: $font-size-big;

        &:focus {
          outline: none;
        }
      }
    }
  }
  .previewWrapper {
    .previewHeadline {
      display: flex;
      justify-content: space-between;
    }

    margin-top: 2rem;
  }
  .exportButtons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
  }
}
select {
  padding: 12px 20px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("../../assets/icons/arrow_down.svg");
  background-repeat: no-repeat;
  background-position: calc(95%) center;
  padding-right: 2em;
  min-width: 25%;
}

select::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}
select:focus {
  outline: none;
  border-color: $ibis-black;
}
</style>
