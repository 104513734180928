<template>
  <div
    class="priceListConfiguratorMainWrapper"
    id="priceListConfiguratorMainWrapper"
  >
    <PriceListSearch v-if="userMultiplikator <= 1"></PriceListSearch>
  </div>
</template>
<script>
import PriceListSearch from "./PriceListSearch.vue";

export default {
  components: {
    PriceListSearch,
  },
  mounted() {
    document
      .getElementById("priceListConfiguratorMainWrapper")
      .classList.add("fade-in-bottom");
  },
  computed: {
    userMultiplikator() {
      return JSON.parse(localStorage.getItem("ibisOnlinebestellungUserObject")).multiplikator;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.priceListConfiguratorMainWrapper {
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  @media (max-width: $mobile) {
    margin-top: 1rem;
  }
}
</style>
