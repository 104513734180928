<template>
  <div v-if="numericPositionPrice > 0 &&
    currentProduct.productType !== productTypes.GLAS_BOARD
    " class="positionPrice">
    <span>{{ text ? text : "Positionspreis:" }} {{ positionPrice }} €</span>
  </div>
  <div v-else-if="numericPositionPrice > 0 &&
    currentProduct.productType === productTypes.GLAS_BOARD &&
    currentGlasOption
    " class="positionPrice">
    <span>Positionspreis: {{ positionPrice }} €</span>
  </div>
</template>

<script>
import calculatePrice from "@/utility/PriceCalculator";
import ProductTypes from "@/models/Types/ProductTypes";

export default {
  props: ["productProp", "roundToTwo", "text"],
  data() {
    return {
      productTypes: ProductTypes,
    };
  },
  computed: {
    numericPositionPrice() {
      let originalPrice = calculatePrice(this.currentProduct);
      // Check if there is a discount code percentage applied
      let discountCodePercentage = 0;
      if (this.$store.getters.currentDiscountCode) {
        discountCodePercentage = this.$store.getters.currentDiscountCode
          .discountPercentage
          ? this.$store.getters.currentDiscountCode.discountPercentage
          : 0;
      }
      let absoluteDiscount = 0;
      if (this.$store.getters.currentDiscountCode) {
        absoluteDiscount = this.$store.getters.currentDiscountCode
          .absoluteDiscount
          ? this.$store.getters.currentDiscountCode.absoluteDiscount
          : 0;
      }
      // If a discount code is applied, calculate the full price without the discount code percentage
      if (discountCodePercentage > 0) {
        originalPrice = originalPrice / (1 - discountCodePercentage);
      } else if (absoluteDiscount > 0) {
        originalPrice = originalPrice + absoluteDiscount;
      } else if (this.currentProduct.discount) {
        originalPrice = originalPrice / (1 - this.currentProduct.discount);
      }

      return this.roundToTwo(originalPrice);
    },

    positionPrice() {
      // Convert the price to a string with two decimal places
      let priceString = this.numericPositionPrice.toFixed(2);

      // Replace the dot with a comma for decimal separation
      priceString = priceString.replace(".", ",");

      // Add dots as thousands separators
      return priceString.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    configuratorMode() {
      return this.$store.getters.currentConfiguratorMode;
    },
    currentProduct() {
      return !this.productProp
        ? this.$store.getters.currentProduct
        : this.productProp;
    },
    currentGlasOption() {
      return this.$store.getters.currentGlasSizeOption;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../../assets/sass/style.scss";

.positionPrice {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;

  span {
    font-weight: bold;
  }
}
</style>
