const CanvasStretchingTypes = [
    {
        key: "DEFAULT",
        description: "Standardkaschierung",
        pricing: "80x100:10.70,100x150:10.70,150x300:10.70",
        supportsSurfaceFinishings: true,

    },
    {
        key: "CLOTH",
        description: "Tuch- bzw. Stoff-/Seidenbildkaschierung",
        pricing: "90x120:24.05,200x200:24.05",
        supportedProducts: [10011541, 10008546, 10008545, 10013756],
        supportsSurfaceFinishings: true,

    },
    {
        key: "GOBELIN",
        description: "Gobelin / Stickbild aufspannen",
        pricing: "90x120:36.55,200x200:36.55",
        supportedProducts: [10011541],
        supportsSurfaceFinishings: false,
    }
]

module.exports = CanvasStretchingTypes;