const ProductOptionsToSort = {
  ARC: "Bogen",
  WOOD_CUT: "Holz-Zuschnitt",
  ALU_CUT: "Alu-Zuschnitt",
  PASSEPARTOUT_CUT: "Passepartout-Zuschnitt",
  WOOD_FRAME: "Holz-Rahmen",
  CORNER_FRAME: "Übereckrahmen",
  CUT: "Zuschnitt",
  POLE: "Stange",
  STRETCHER_CUT: "KR-Zuschnitt",
  BACK_WALL_CUT: "Rückwand-Zuschnitt",
};

module.exports = ProductOptionsToSort;
