<template lang="">
  <div class="topGlasGroupOptions">
    <select
      v-model="currentGlasOption"
      style="min-width: 45%; max-width: 50%"
      @change="validateQuantityInput()"
    >
      <option v-if="!currentGlasOption" disabled value="">Maß auswählen</option>
      <option v-else-if="currentGlasOption" :value="currentGlasOption">
        {{
          extractWidth(currentGlasOption.name) +
          "x" +
          extractHeight(currentGlasOption.name) +
          " cm "
        }}
      </option>
      <option
        v-for="(option, index) in filteredGlasSizeOptions"
        :key="index"
        :value="option"
      >
        {{
          extractWidth(option.name) + "x" + extractHeight(option.name) + " cm "
        }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  props: ["validateQuantityInput", "extractWidth", "extractHeight"],
  computed: {
    currentGlasOption: {
      get() {
        return this.$store.getters.currentGlasSizeOption;
      },
      set(newValue) {
        this.$store.commit("switchGlasSizeOption", newValue);
      },
    },
    userInfo() {
      const rawData = localStorage.getItem("ibisOnlinebestellungUserObject");
      if (!rawData) return null; // handle case where there's no userObject in localStorage

      // eslint-disable-next-line
      const { currentConfiguration, ...rest } = JSON.parse(rawData);
      return rest;
    },
    currentProductFamily() {
      return this.$store.getters.selectedProductFamily;
    },
    filteredGlasSizeOptions() {
      return this.currentProductFamily.glas_size_options.filter((option) =>
        this.checkGlasRegionAvailabilty(option)
      );
    },
  },
  methods: {
    checkGlasRegionAvailabilty(option) {
      if (option.unavailable_regions) {
        return !option.unavailable_regions
          .toString()
          .includes(this.userInfo.ibis_customer_id.toString()[0]);
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../../assets/sass/style.scss";

.topGlasGroupOptions {
  display: flex;
  justify-content: flex-end;
  input {
    padding: 12px 20px;
    box-shadow: none;
    border-radius: 50px;
    color: $ibis-black !important;
    font-size: $font-size-big;
    text-align: center;
    max-width: 7vw !important;
  }
}
</style>
