<template>
  <div class="mobileNavContainer">
    <div class="rightSideLink" @click="navigate('/konfigurator')">
      <label
        :class="currentRoute === '/konfigurator' ? 'selectedRoute' : null">Konfigurator</label>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="128.000000pt"
        height="128.000000pt" viewBox="0 0 128.000000 128.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
          :fill="currentRoute === '/konfigurator' ? '#f22539' : 'currentColor'"
          stroke="none">
          <path d="M217 1078 c-82 -102 -217 -281 -217 -288 0 -11 629 -625 640 -625 12
0 640 614 640 625 0 4 -55 79 -123 166 l-123 159 -391 3 -391 2 -35 -42z" />
        </g>
      </svg>
    </div>
    <div class="rightSideLink" @click="openTutorial()" v-if="!isRetailer">
      <label>Tutorial</label>
    </div>
    <div class="rightSideLink" @click="navigate('/restbestand')"
      v-if="isRetailer">
      <label :class="currentRoute === '/restbestand' ? 'selectedRoute' : null"
        style="margin-right: 0.2rem">Restbestand</label>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px"
        height="50px">
        <g :fill="currentRoute === '/restbestand' ? '#f22539' : 'currentColor'">
          <path
            d="M 1 3 L 1 15 L 49 15 L 49 3 Z M 3 17 L 3 48 L 47 48 L 47 17 Z M 17.5 20 L 32.5 20 C 33.882813 20 35 21.117188 35 22.5 C 35 23.882813 33.882813 25 32.5 25 L 17.5 25 C 16.117188 25 15 23.882813 15 22.5 C 15 21.117188 16.117188 20 17.5 20 Z" />
        </g>
      </svg>
    </div>
    <div class="rightSideLink" @click="navigate('/downloads')"
      v-if="userMultiplikator <= 1 && isRetailer">
      <label
        :class="currentRoute.includes('/downloads') ? 'selectedRoute' : null">Downloads</label>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="128.000000pt"
        height="128.000000pt" viewBox="0 0 128.000000 128.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
          :fill="currentRoute.includes('/downloads') ? '#f22539' : 'currentColor'
      " stroke="none">
          <path d="M189 1251 l-29 -29 0 -582 0 -582 29 -29 29 -29 422 0 422 0 29 29
29 29 0 374 0 373 -192 -3 c-177 -2 -196 -1 -225 17 -59 37 -64 60 -61 271 l3
190 -213 0 -214 0 -29 -29z m479 -483 c8 -8 12 -54 12 -142 l0 -130 33 32 c18
18 40 32 48 32 19 0 39 -20 39 -39 0 -20 -140 -161 -160 -161 -20 0 -160 141
-160 161 0 19 20 39 39 39 8 0 30 -14 48 -32 l33 -32 0 130 c0 132 6 154 40
154 9 0 21 -5 28 -12z m206 -511 c8 -12 8 -22 0 -35 -10 -15 -34 -17 -229 -20
-224 -2 -245 1 -245 37 0 39 17 41 244 39 196 -3 220 -5 230 -21z" />
          <path d="M720 1076 c0 -136 3 -167 16 -180 13 -13 44 -16 182 -16 l167 0 -180
180 c-99 99 -181 180 -182 180 -2 0 -3 -74 -3 -164z" />
        </g>
      </svg>
    </div>
    <div class="rightSideLink" @click="navigate('/ansprechpartner')">
      <label
        :class="currentRoute === '/ansprechpartner' ? 'selectedRoute' : null">Ihre
        Ansprechpartner</label>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="128.000000pt"
        height="128.000000pt" viewBox="0 0 128.000000 128.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
          :fill="currentRoute === '/ansprechpartner' ? '#f22539' : 'currentColor'
      " stroke="none">
          <path d="M143 1125 c-89 -63 -91 -66 -98 -112 -19 -140 70 -393 212 -605 93
-139 261 -295 339 -314 26 -7 211 76 232 103 22 30 12 67 -46 163 -52 86 -60
95 -90 98 -23 2 -50 -6 -90 -29 l-56 -32 -42 46 c-77 86 -159 227 -200 346
-19 52 -19 54 0 65 137 81 139 89 62 225 -78 137 -90 140 -223 46z" />
          <path d="M480 963 l0 -48 133 -90 c72 -49 138 -91 144 -93 11 -3 413 265 413
274 0 2 -155 4 -345 4 l-345 0 0 -47z" />
          <path d="M1001 815 c-124 -85 -231 -155 -239 -155 -8 0 -83 47 -168 105 l-154
106 -40 -27 c-22 -15 -40 -32 -40 -38 0 -23 102 -214 146 -273 l47 -62 46 24
c95 51 160 27 221 -81 l31 -54 179 0 c173 0 179 1 194 22 14 19 16 65 16 305
0 156 -3 283 -7 283 -5 -1 -109 -70 -232 -155z" />
        </g>
      </svg>
    </div>
    <div class="rightSideLink" @click="navigate('/profil')">
      <label :class="currentRoute === '/profil' ? 'selectedRoute' : null">Meine
        Daten & Bestellungen</label>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="128.000000pt"
        height="128.000000pt" viewBox="0 0 128.000000 128.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
          :fill="currentRoute === '/profil' ? '#f22539' : 'currentColor'"
          stroke="none">
          <path d="M543 1266 c-199 -63 -281 -290 -167 -462 122 -184 390 -183 512 1 36
55 57 141 48 202 -26 189 -216 314 -393 259z" />
          <path d="M296 645 c-53 -19 -102 -69 -136 -140 -35 -74 -50 -153 -50 -265 0
-110 23 -161 95 -205 l48 -30 363 -3 c240 -2 377 1 404 8 51 14 108 63 131
115 48 105 5 357 -78 456 -31 37 -115 79 -158 79 -16 0 -71 -20 -121 -46 -77
-39 -99 -46 -150 -46 -64 -1 -120 18 -195 64 -51 31 -93 35 -153 13z" />
        </g>
      </svg>
    </div>
    <div class="signoutButtonWrapper">
      <IbisButton buttonClass="secondary" buttonText="Abmelden"
        @click="signOutUser()"></IbisButton>
      <div v-if="this.$store.getters.userIsAdmin" @click="navigate('/admin')">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="64.000000pt" height="64.000000pt"
          viewBox="0 0 64.000000 64.000000" preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
            :fill="currentRoute === '/admin' ? '#f22539' : 'currentColor'"
            stroke="none">
            <path d="M235 553 c-41 -47 -74 -91 -72 -97 2 -6 24 -12 50 -14 l46 -3 3 -137
3 -137 55 0 55 0 3 137 3 137 46 3 c26 2 48 8 50 14 4 12 -143 184 -157 184
-5 0 -43 -39 -85 -87z" />
            <path d="M20 112 c0 -55 4 -92 12 -100 17 -17 559 -17 576 0 8 8 12 45 12 100
l0 88 -40 0 -40 0 0 -60 0 -60 -220 0 -220 0 0 60 0 60 -40 0 -40 0 0 -88z" />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import IbisButton from "./components/shared/IbisButton.vue";
import UserTypes from "./models/Types/UserTypes";
import AuthService from "./utility/AuthService";
import UserTypeProvider from "./utility/UserTypeProvider";
IbisButton;
export default {
  props: ["showMobileNav"],
  inject: ["$firebase"],
  components: {
    IbisButton,
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    isRetailer() {
      return UserTypeProvider.getUserType() === UserTypes.RETAILER;
    },
    userMultiplikator() {
      return JSON.parse(localStorage.getItem("ibisOnlinebestellungUserObject")).multiplikator;
    },
  },
  data() {
    return {
      show: this.showMobileNav,
    };
  },
  methods: {
    navigate(route) {
      this.$router.push(route);
      this.show = false;
      this.updateMobileNavVisibilty();
    },
    signOutUser() {
      AuthService.logout();
      this.$router.push("/login");
      this.show = false;
      this.updateMobileNavVisibilty();
    },
    updateMobileNavVisibilty() {
      this.$emit("updateMobileNavVisibilty", this.show); // or any other data you wish to send
    },
    async fetchTutorialDownloadUrl() {
      const specification = UserTypeProvider.getUserType() === UserTypes.BAUHAUS ? 'Bauhaus.pdf' : 'Andere.pdf';
      try {
        const storage = this.$firebase.storage(); // Get the Firebase storage
        const storageRef = storage.ref(
          "DownloadableContent/OtherContent/Tutorial " + specification
        ); // Adjust the path to your AGB file
        return await storageRef.getDownloadURL();
      } catch (error) {
        console.error("Error fetching Tutorial download URL:", error);
      }
    },
    async openTutorial() {
      const url = await this.fetchTutorialDownloadUrl();
      window.open(url, "_blank");
    },
  },
};
</script>
<style scoped lang="scss">
@import "./assets/sass/style.scss";

.mobileNavContainer {
  height: 100vh;
  width: 100vw;
  background-color: white;
  left: 0;
  top: 0;
  z-index: 100;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  font-size: $font-size-bigger;

  .rightSideLink {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.3rem;

    label {
      font-weight: bold;
    }

    svg {
      height: 1rem;
      width: 1rem;
      fill: $ibis-red;
    }
  }

  .signoutButtonWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 3rem;

    svg {
      height: 1.5rem;
      width: 1.5rem;
      fill: $ibis-red;

      &:hover {
        color: $ibis-red;
        transition: 0.15s;
      }
    }
  }
}

.selectedRoute {
  color: $ibis-red;
}
</style>
