<template>
  <div class="searchbarWrapper">
    <div>
      <div class="searchbar">
        <!-- <div class="leftPart">
          <div class="leadingButton">
            <span>+</span>
          </div>
        </div> -->
        <div class="rightPart">
          <input placeholder="Artikel suchen..." v-model="searchTerm" @input="handleSearch()" />
        </div>
      </div>
      <img src="../../../assets/icons/sale_icon.svg" class="saleIcon" />
    </div>
    <div class="searchResults" v-show="showSearchResults" id="searchResults">
      <div class="loadedResults" v-if="!isLoading && searchResults">
        <div v-for="(result, index) in searchResults" :key="index" :class="{
            'active-selection': selectedProductIndex === index,
            disabledResult: result.products[0].discontinued,
          }">
          <div class="resultTile" @click="
            result.products[0].discontinued ? () => { } : onResultClick(result)
            ">
            <div class="resultWrapper">
              <span class="productFamilyName">{{ result.products[0].description }}
              </span>
              <span class="discontinued" v-if="result.products[0].discontinued">AUSLAUF</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="spinner"></div>
      <div class="noResults" v-if="searchResults.length === 0 && !isLoading">
        KEINE PASSENDEN SUCHERGEBNISSE GEFUNDEN
      </div>
    </div>
  </div>
</template>
<script>
import ToastTypes from "@/models/Types/ToastTypes";
import BackendHandler from "@/utility/BackendHandler.js";
import backendHandler from "@/utility/BackendHandler.js";

export default {
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    /* userInfo() {
      const rawData = localStorage.getItem("ibisOnlinebestellungUserObject");
      if (!rawData) return null; // handle case where there's no userObject in localStorage

      // eslint-disable-next-line
      const { currentConfiguration, ...rest } = JSON.parse(rawData);
      return rest;
    }, */
  },
  props: ["filter", "customOnClick"],
  data() {
    return {
      searchTerm: undefined,
      searchResults: [],
      showSearchResults: false,
      isLoading: undefined,
      timeoutId: undefined,
      selectedProductIndex: 0,
    };
  },

  methods: {
    onResultClick(result) {
      !this.customOnClick
        ? this.addProductFamily(result)
        : this.customOnClick(result);
      this.showSearchResults = false;
      this.searchTerm = undefined;
    },
    async handleSearch() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.searchResults = [];
      this.timeoutId = setTimeout(async () => {
        if (this.searchTerm.toString().length > 2) {
          this.showSearchResults = true;

          document
            .getElementById("searchResults")
            .classList.add("fade-in-bottom");

          this.isLoading = true;
          try {
            const fetchProductSiblings = true;
            this.searchResults = await backendHandler.searchProducts(
              this.searchTerm.toString(),
              fetchProductSiblings
            );
            if (this.filter) {
              this.searchResults = this.filter(this.searchResults);
            }
          } catch (error) {
            this.isLoading = false;
            this.searchResults = [];
          }
          this.isLoading = false;
        } else {
          this.showSearchResults = false;
        }
      }, 500);
      this.selectedProductIndex = 0; // set timeout to 500ms
    },
    addProductFamily(productFamiliy) {
      this.$store.commit("addSelectedProductFamily", productFamiliy);
      //this.$store.commit("addProductFamily", productFamiliy);
      /* this.$store.commit(
        "setSelectedProductFamily",
        this.$store.getters.productFamilies.length - 1
      ); */

      if (this.currentRoute === "/") {
        this.$router.push("/konfigurator");
      }
      this.$nextTick(() => {
        const inputs = document.querySelectorAll("input");
        for (let i = 0; i < inputs.length; i++) {
          if (
            !inputs[i].disabled &&
            !inputs[i].readOnly &&
            (inputs[i].id.includes("width") ||
              inputs[i].id.includes("quantity"))
          ) {
            inputs[i].focus();
            break;
          }
        }
      });
    },
    keymonitor(e) {
      // Select next product with arrow down
      if (this.showSearchResults && !this.isLoading) {
        if (
          e.keyCode === 40 &&
          this.selectedProductIndex < this.searchResults.length - 1
        ) {
          this.selectedProductIndex++;
        }

        // Select previous product with arrow up
        else if (e.keyCode === 38 && this.selectedProductIndex > 0) {
          this.selectedProductIndex--;
        }

        // Trigger addProductFamily with Enter
        else if (e.keyCode === 13 && this.searchResults.length > 0) {
          !this.searchResults[this.selectedProductIndex].products.some(product => product.discontinued) ? this.addProductFamily(this.searchResults[this.selectedProductIndex]) : null;
        }

        this.$nextTick(() => {
          const resultsElement = document.getElementById("searchResults");
          if (resultsElement) {
            const selectedElement =
              resultsElement.getElementsByClassName("active-selection")[0];
            if (selectedElement) {
              selectedElement.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
              });
            }
          }
        });
      }
    },
    async assignProductFromPublicPage(parentId) {
      const family = await BackendHandler.getProductFamilyById(parentId);
      for (let i = 0; i < family.glas_size_options.length; i++) {
        if (!this.checkGlasRegionAvailabilty(family.glas_size_options[i])) {
          family.glas_size_options.splice(i, 1);
          this.showToastMessage(
            "Dieses Maß ist in Ihrer Region nicht verfügbar.",
            ToastTypes.ERROR
          );
          i--;
        }
      }
      this.addProductFamily(family);
      if (this.$route.query.glasOptionIndex) {
        const currentFamily = this.$store.getters.selectedProductFamily;
        const matchingGlasSizeOption = currentFamily.glas_size_options[parseInt(this.$route.query.glasOptionIndex)];
        this.$store.commit("setCurrentGlasSizeOption", matchingGlasSizeOption);
      }
    },
    checkGlasRegionAvailabilty(option) {
      if (option.unavailable_regions) {
        return !option.unavailable_regions
          .toString()
          .includes(this.userInfo.ibis_customer_id.toString()[0]);
      } else {
        return true;
      }
    },
    showToastMessage(toastMessage, type) {
      let payload = {
        showToast: true,
        toastType: type,
        message: toastMessage,
      };
      this.$store.commit("configureToast", payload);
    },
  },
  created() {
    window.addEventListener("keyup", this.keymonitor);
    if (this.$route.query.publicRedirectParentId) {
      this.assignProductFromPublicPage(
        this.$route.query.publicRedirectParentId
      );
    }
  },
  unmounted() {
    window.removeEventListener("keyup", this.keymonitor);
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.searchbarWrapper {
  font-size: $font-size-big;
  background-color: $ibis-grey-middle;
  padding: 4px;
  border-radius: 50px;
  position: relative;

  .searchbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftPart {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1em;
      margin-left: 0.5vw;

      .leadingButton {
        color: $ibis-red;
        border: none;
        font-size: $font-size-large;
        border-radius: 360px;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;

        span {
          color: $ibis-red;
          font-size: larger;
          font-family: "Andale Mono";
        }
      }

      span {
        color: white;
      }
    }

    .rightPart {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      input {
        width: 100%;
        height: 2.5em;
        border: none;
        border-radius: 50px;
        padding-left: 20px;
        font-size: $font-size-big;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .saleIcon {
    position: absolute;
    top: 40%;
    right: 2%;
    bottom: 60%;
    height: 16px;
    width: 16px;
  }

  .searchResults {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 15em;
    background: white;
    border-radius: 25px;
    left: 0;
    top: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    overflow-y: scroll;
    box-shadow: 0 8px 37px rgba(64, 64, 64, 0.1);

    .loadedResults {
      width: 90%;

      .active-selection {
        background-color: $ibis-grey;
        border-radius: 25px;
      }

      .resultTile {
        border-radius: 25px;
        width: 100%;

        &:hover {
          cursor: pointer;
          background-color: $ibis-grey;
        }

        .resultWrapper {
          display: flex;
          align-items: center;
          gap: 3em;
          padding: 1em 0em 1em 2em;
          width: 100%;

          .productFamilyName {
            width: 100%;
            text-align: start;
          }
        }

        .discontinued {
          color: $ibis-red;
          margin-right: 3rem;
          font-weight: bold;
        }
      }
    }
  }

  .spinner {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 4px solid rgba(255, 255, 255, 0.1);
    border-top-color: $ibis-red;
    animation: spin 1s ease-in-out infinite;
    margin: auto;
  }

  .noResults {
    margin: auto;
  }
}

.disabledResult {
  opacity: 0.5;
  cursor: not-allowed;
}

.disabledResult:hover {
  cursor: not-allowed;
  background-color: transparent;
  /* Prevents color change on hover */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.searchResults::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.searchResults::-webkit-scrollbar-track {
  background-color: none;
  margin-top: 20px;
  margin-bottom: 10px;
}

.searchResults::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.searchResults::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0.8);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
