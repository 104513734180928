<template>
  <div class="previewWrapper" v-if="ppWidth &&
    ppHeight &&
    ppInnerWidth &&
    ppInnerHeight
    ">
    <div class="topLine">
      <input type="number" :value="topLegWidth" @input="
    handleCustomTopLegWidthChange(
      this.currentProduct.second_glas_top_leg_width,
      $event.target.value
    )
    " step="0.1" />
    </div>
    <div class="middleLine">
      <input type="number" :value="leftLegWidth" @input="
    handleCustomLeftLegWidthChange(
      this.currentProduct.second_glas_left_leg_width,
      $event.target.value
    )
    " step="0.1" />
      <div class="outerBox" :style="{
    //width: ppWidth - leftLegWidth - rightLegWidth + 'px',
    //height: ppHeight - topLegWidth - bottomLegWidth + 'px',
    paddingTop: topLegWidth * scaleOutterHeight() + 'px',
    paddingLeft: leftLegWidth * scaleOutterWidth() + 'px',
    paddingRight: rightLegWidth * scaleOutterWidth() + 'px',
    paddingBottom: bottomLegWidth * scaleOutterHeight() + 'px',
    borderRadius: outerRadius + '%',
  }">
        <div class="innerBox" :style="{
    width: ppInnerWidth * scaleInnerWidth() + 'px',
    height: ppInnerHeight * scaleInnerHeight() + 'px',
    borderRadius: innerRadius + '%', display: 'flex', justifyContent: 'center', alignItems: 'center'
  }"> BILD </div>
      </div>
      <input type="number" :value="rightLegWidth" @input="
    handleCustomRightLegWidthChange(
      this.currentProduct.second_glas_right_leg_width,
      $event.target.value
    )
    " step="0.1" />
    </div>
    <div class="bottomLine">
      <input type="number" :value="bottomLegWidth" @input="
    handleCustomBottomLegWidthChange(
      this.currentProduct.second_glas_bottom_leg_width,
      $event.target.value
    )
    " step="0.1" />
    </div>
  </div>
</template>
<script>
/* import PassepartoutCutForms from "@/models/Types/PassepartoutCutForms";
import PPSizeCalculationModes from "@/models/Types/PPSizeCalculationModes";
 */
export default {
  props: ["productProp"],
  data() {
    return {
      outerRadius: 0,
      innerRadius: 0,
      //initialShapeCheck: true,

      // ppSizeCalculationModes: PPSizeCalculationModes,
    };
  },

  /*  mounted() {
     this.checkOuterShape(this.outerCutForm);
     this.checkInnerShape(this.innerCutForm);
   },
  */
  computed: {
    currentProduct() {
      return !this.productProp
        ? this.$store.getters.currentProduct
        : this.productProp;
    },
    ppHeight() {
      return this.currentProduct.height;
    },
    ppWidth() {
      return this.currentProduct.width;
    },
    ppInnerHeight() {
      return this.currentProduct.second_glas_picture_height;
    },
    ppInnerWidth() {
      return this.currentProduct.second_glas_picture_width;
    },
    /*  ppSizeCalculationMode() {
       return this.currentProduct.passepartout_size_calculation_mode;
     }, */
    //------------------------------------------------------------
    topLegWidth() {
      return parseFloat(
        parseFloat(this.currentProduct.second_glas_top_leg_width).toFixed(2)
      );
    },
    leftLegWidth() {
      return parseFloat(
        parseFloat(this.currentProduct.second_glas_left_leg_width).toFixed(2)
      );
    },
    rightLegWidth() {
      return parseFloat(
        parseFloat(this.currentProduct.second_glas_right_leg_width).toFixed(2)
      );
    },
    bottomLegWidth() {
      return parseFloat(
        parseFloat(this.currentProduct.second_glas_bottom_leg_width).toFixed(2)
      );
    },
    //------------------------------------------------------------
    completeHeight() {
      return this.topLegWidth + this.bottomLegWidth + this.ppInnerHeight;
    },
    completeWidth() {
      return this.leftLegWidth + this.rightLegWidth + this.ppInnerWidth;
    },
    //------------------------------------------------------------
    /* outerCutForm() {
      return this.currentProduct.passepartout_outer_cut_form;
    },
    innerCutForm() {
      return this.currentProduct.passepartout_inner_cut_form;
    }, */
    //------------------------------------------------------------
    maxWidth() {
      return this.currentProduct.max_width;
    },
    maxHeight() {
      return this.currentProduct.max_height;
    },
  },

  watch: {
    ppHeight() {
      this.setTopLegWidth(
        (this.currentProduct.height -
          this.currentProduct.second_glas_picture_height) /
        2
      );
      this.setBottomLegWidth(
        (this.currentProduct.height -
          this.currentProduct.second_glas_picture_height) /
        2
      );
      //this.executeInitialShapeCheck();
    },
    ppInnerHeight() {
      this.setTopLegWidth(
        (this.currentProduct.height -
          this.currentProduct.second_glas_picture_height) /
        2
      );
      this.setBottomLegWidth(
        (this.currentProduct.height -
          this.currentProduct.second_glas_picture_height) /
        2
      );
      //this.executeInitialShapeCheck();
    },
    ppWidth() {
      this.setLeftLegWidth(
        (this.currentProduct.width -
          this.currentProduct.second_glas_picture_width) /
        2
      );
      this.setRightLegWidth(
        (this.currentProduct.width -
          this.currentProduct.second_glas_picture_width) /
        2
      );
      //this.executeInitialShapeCheck();
    },
    ppInnerWidth() {
      this.setLeftLegWidth(
        (this.currentProduct.width -
          this.currentProduct.second_glas_picture_width) /
        2
      );
      this.setRightLegWidth(
        (this.currentProduct.width -
          this.currentProduct.second_glas_picture_width) /
        2
      );
      //this.executeInitialShapeCheck();
    },

    //------------------------------------------------------------

    /*  outerCutForm(newValue) {
       this.checkOuterShape(newValue);
     },
     innerCutForm(newValue) {
       this.checkInnerShape(newValue);
     }, */
  },
  methods: {
    setTopLegWidth(value) {
      this.currentProduct.second_glas_top_leg_width = value;
    },
    setLeftLegWidth(value) {
      this.currentProduct.second_glas_left_leg_width = value;
    },
    setRightLegWidth(value) {
      this.currentProduct.second_glas_right_leg_width = value;
    },
    setBottomLegWidth(value) {
      this.currentProduct.second_glas_bottom_leg_width = value;
    },
    //---------------------------------------------------------
    handleCustomTopLegWidthChange() {
      this.setBottomLegWidth(
        this.bottomLegWidth - (event.target.value - this.topLegWidth)
      );

      if (this.bottomLegWidth > 1) {
        if (event.target.value < 1) {
          this.setTopLegWidth(1);
          this.setBottomLegWidth(this.ppHeight - this.ppInnerHeight - 1);
        } else {
          this.setTopLegWidth(event.target.value);
        }
      } else {
        this.setBottomLegWidth(1);
        this.setTopLegWidth(this.ppHeight - this.ppInnerHeight - 1);
      }

      this.$store.commit("updatePrice");
    },
    handleCustomLeftLegWidthChange() {
      this.setRightLegWidth(
        this.rightLegWidth - (event.target.value - this.leftLegWidth)
      );
      if (this.rightLegWidth > 1) {
        if (event.target.value < 1) {
          this.setLeftLegWidth(1);
          this.setRightLegWidth(this.ppWidth - this.ppInnerWidth - 1);
        } else {
          this.setLeftLegWidth(event.target.value);
        }
      } else {
        this.setRightLegWidth(1);
        this.setLeftLegWidth(this.ppWidth - this.ppInnerWidth - 1);
      }
      this.$store.commit("updatePrice");
    },
    handleCustomRightLegWidthChange() {
      this.setLeftLegWidth(
        this.leftLegWidth - (event.target.value - this.rightLegWidth)
      );
      if (this.leftLegWidth > 1) {
        if (event.target.value < 1) {
          this.setRightLegWidth(1);
          this.setLeftLegWidth(this.ppWidth - this.ppInnerWidth - 1);
        } else {
          this.setRightLegWidth(event.target.value);
        }
      } else {
        this.setLeftLegWidth(1);
        this.setRightLegWidth(this.ppWidth - this.ppInnerWidth - 1);
      }
      this.$store.commit("updatePrice");
    },
    handleCustomBottomLegWidthChange() {
      this.setTopLegWidth(
        this.topLegWidth - (event.target.value - this.bottomLegWidth)
      );
      if (this.topLegWidth > 1) {
        if (event.target.value < 1) {
          this.setBottomLegWidth(1);
          this.setTopLegWidth(this.ppHeight - this.ppInnerHeight - 1);
        } else {
          this.setBottomLegWidth(event.target.value);
        }
      } else {
        this.setTopLegWidth(1);
        this.setBottomLegWidth(this.ppHeight - this.ppInnerHeight - 1);
      }
      this.$store.commit("updatePrice");
    },
    scaleOutterWidth() {
      const scale = 9 - Math.log(this.ppWidth) * 1.8;
      return scale;
    },
    scaleOutterHeight() {
      const scale = 9 - Math.log(this.ppHeight) * 1.8;
      return scale;
    },
    scaleInnerWidth() {
      const scale = 9 - Math.log(this.ppInnerWidth) * 1.8;
      return scale;
    },
    scaleInnerHeight() {
      const scale = 9 - Math.log(this.ppInnerHeight) * 1.8;
      return scale;
    },
    /* executeInitialShapeCheck() {
      if (
        this.ppHeight &&
        this.ppWidth &&
        this.ppInnerHeight &&
        this.ppInnerWidth &&
        this.initialShapeCheck
      ) {
        this.checkOuterShape(this.outerCutForm);
        this.checkInnerShape(this.innerCutForm);
        this.initialShapeCheck = false;
      }
    },
    checkOuterShape(newValue) {
      if (newValue === PassepartoutCutForms.BOX) {
        this.outerRadius = 0;
      }
      if (newValue === PassepartoutCutForms.OVAL) {
        this.outerRadius = 50;
        if (
          this.ppWidth &&
          this.ppHeight &&
          this.ppInnerWidth &&
          this.ppInnerHeight
        ) {
          let determinedSize =
            this.maxHeight < this.maxWidth ? this.maxHeight : this.maxWidth;

          if (!this.initialShapeCheck) {
            this.currentProduct.width = parseInt(determinedSize * 0.5);

            this.currentProduct.height = determinedSize;
          }
          this.currentProduct.passepartout_inner_width = parseInt(
            determinedSize * 0.2
          );

          this.currentProduct.passepartout_inner_height = parseInt(
            determinedSize * 0.4
          );
        }
      }
      if (newValue === PassepartoutCutForms.ROUND) {
        this.outerRadius = 50;

        if (
          this.ppWidth &&
          this.ppHeight &&
          this.ppInnerWidth &&
          this.ppInnerHeight
        ) {
          let determinedSize =
            this.maxHeight < this.maxWidth ? this.maxHeight : this.maxWidth;

          if (!this.initialShapeCheck) {
            this.currentProduct.width = determinedSize;

            this.currentProduct.height = determinedSize;
          }
          this.currentProduct.passepartout_inner_width = parseInt(
            determinedSize * 0.4
          );

          this.currentProduct.passepartout_inner_height = parseInt(
            determinedSize * 0.4
          );
        }
      }
    },
    checkInnerShape(newValue) {
      if (newValue === PassepartoutCutForms.BOX) {
        this.innerRadius = 0;
      }
      if (newValue === PassepartoutCutForms.OVAL) {
        this.innerRadius = 50;
        if (
          this.ppWidth &&
          this.ppHeight &&
          this.ppInnerWidth &&
          this.ppInnerHeight
        ) {
          let determinedSize =
            this.ppHeight < this.ppWidth ? this.ppHeight : this.ppWidth;

          this.currentProduct.passepartout_inner_width = parseInt(
            determinedSize * 0.3
          );

          this.currentProduct.passepartout_inner_height = parseInt(
            determinedSize * 0.6
          );
        }
      }
      if (newValue === PassepartoutCutForms.ROUND) {
        this.innerRadius = 50;

        if (
          this.ppWidth &&
          this.ppHeight &&
          this.ppInnerWidth &&
          this.ppInnerHeight
        ) {
          let determinedSize =
            this.ppHeight < this.ppWidth ? this.ppHeight : this.ppWidth;

          this.currentProduct.passepartout_inner_width = parseInt(
            determinedSize * 0.4
          );

          this.currentProduct.passepartout_inner_height = parseInt(
            determinedSize * 0.4
          );
        }
      }
    }, */
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/sass/style.scss";

.previewWrapper {
  .topLine {
    margin-bottom: 1em;
  }

  .middleLine {
    display: flex;
    align-items: center;
    gap: 1em;

    .outerBox {
      max-width: 220px;
      max-height: 400px;
      // min-width: 80px;
      // min-height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $ibis-blue;
      border: 5px solid $ibis-black;

      .innerBox {
        background-color: $ibis-grey;
      }
    }
  }

  .bottomLine {
    margin-top: 1em;
  }
}

input {
  padding: 12px 10px;
  box-shadow: none;
  border: 2px solid $ibis-black;
  border-radius: 50px;
  color: $ibis-black !important;
  font-size: $font-size-big;
  max-width: 4vw;

  @media (max-width: $mobile) {
    max-width: 12vw;
  }

  text-align: center;
}
</style>
