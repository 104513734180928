const ibisMemojis = [
  {
    name: "Bianca Hennrich",
    phone: "06201-259013",
    email: "info-hirschberg@ibis-online.de",
    primaryMemojiUrl: "Bianca_Hennrich_1.avif",
    secondaryMemojiUrl: "Bianca_Hennrich_2.avif",
  },
  {
    name: "Daniela Freitag",
    phone: "09545-9480340",
    email: "info-hallerndorf@ibis-online.de",
    primaryMemojiUrl: "Daniela_Freitag_1.avif",
    secondaryMemojiUrl: "Daniela_Freitag_2.avif",
  },
  {
    name: "David Werner",
    phone: "06201-259013",
    email: "info-hirschberg@ibis-online.de",
    primaryMemojiUrl: "David_Werner_1.avif",
    secondaryMemojiUrl: "David_Werner_2.avif",
  },
  {
    name: "Helmut Hauck",
    phone: "06201-259013",
    email: "info-hirschberg@ibis-online.de",
    primaryMemojiUrl: "Helmut_Hauck_1.avif",
    secondaryMemojiUrl: "Helmut_Hauck_2.avif",
  },
  {
    name: "Kerstin Schmidt",
    phone: "030-7212045",
    email: "info-berlin@ibis-online.de",
    primaryMemojiUrl: "Kerstin_Schmidt_1.avif",
    secondaryMemojiUrl: "Kerstin_Schmidt_2.avif",
  },
  {
    name: "Michael Pfeifer",
    phone: "06201-259013",
    email: "info-hirschberg@ibis-online.de",
    primaryMemojiUrl: "Michael_Pfeifer_1.avif",
    secondaryMemojiUrl: "Michael_Pfeifer_2.avif",
  },
  {
    name: "Nadine Karboule",
    phone: "0201-45329540",
    email: "info-essen@ibis-online.de",
    primaryMemojiUrl: "Nadine_Karboule_1.avif",
    secondaryMemojiUrl: "Nadine_Karboule_2.avif",
  },
  {
    name: "Sonja Ruderich",
    phone: "09545-9480340",
    email: "info-hallerndorf@ibis-online.de",
    primaryMemojiUrl: "Sonja_Ruderich_1.avif",
    secondaryMemojiUrl: "Sonja_Ruderich_2.avif",
  },
  {
    name: "Thomas Polzer",
    phone: "09545-9480340",
    email: "info-hallerndorf@ibis-online.de",
    primaryMemojiUrl: "Thomas_Polzer_1.avif",
    secondaryMemojiUrl: "Thomas_Polzer_2.avif",
  },
];

ibisMemojis.sort((a, b) => {
  const lastNameA = a.name.split(" ")[1];
  const lastNameB = b.name.split(" ")[1];
  return lastNameA.localeCompare(lastNameB);
});

module.exports = ibisMemojis;
