const CanvasStretchingTypes = [
    {
        key: "DEFAULT",
        description: "Standardkaschierung",
        pricing: "80x100:6.40,100x150:7.40,150x300:10.90",
        supportsSurfaceFinishings: true,

    },
    {
        key: "CLOTH",
        description: "Tuch- bzw. Stoff-/Seidenbildkaschierung",
        pricing: "90x120:16.8,200x200:16.8",
        supportedProducts: [10011541, 10008546, 10008545, 10013756],
        supportsSurfaceFinishings: true,
    },
    {
        key: "GOBELIN",
        description: "Gobelin / Stickbild aufspannen",
        pricing: "90x120:21.5,200x200:21.5",
        supportedProducts: [10011541],
        supportsSurfaceFinishings: false,
    }
]

module.exports = CanvasStretchingTypes;