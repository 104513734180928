const pricelistFileNames = {
  "Aluminium_._Fabira_IbiS_juni_2022": "Aluminium Fabira-IbiS (Juni 2022)",
  "Holz_._Fabira_IbiS_januar_2023": "Holz Fabira-IbiS (Januar 2023)",
  "Holz_._Larson&Juhl_september_2022": "Holz Larson&Juhl (September 2022)",
  "Holz_._Mittermeier_juli_2023": "Holz Mittermeier (Juli 2023)",
  "Holz_._Spagl_februar_2023": "Holz Spagl (Februar 2023)",
  "Holz_._eurolijsten_april_2023": "Holz Eurolijsten (April 2023)",
  "aluminium_._e-Xhibit_juli_2021": "Aluminium e-Xhibit (Juli 2021)",
  "aluminium_._nielsen_IbiS_februar_2023_mit_Hinweis_September":
    "Aluminium nielsen&IbiS (Februar 2023 mit Hinweis September)",
  "galerieschienen_&_-zubehör_._nielsen_&_stas_dezember_2022_mit_Hinweis_September":
    "Galerieschienen & Zubehör nielsen&Stas (Dezember 2022 mit Hinweis September)",
  "glas_._bilderglaeser_august_2023": "Glas Bildergläser (August 2023)",
  "glas_._standardglaeser_september_2022":
    "Glas Standardgläser (September 2022)",
  "holz_._Wohlleb_januar_2023": "Holz Wohlleb (Januar 2023)",
  "holz_._nielsen_april_2022_mit_Hinweis_September":
    "Holz nielsen (April 2022 mit Hinweis September)",
  keilrahmen_juni_2021: "Keilrahmen (Juni 2021)",
  passepartouts_colourmount_august_2023:
    "Passepartouts Colourmount (August 2023)",
  "passepartouts_nielsen&larson-juhl_september_2022_mit_Hinweis_September":
    "Passepartouts nielsen&Larson-Juhl (September 2022 mit Hinweis September)",
  "passepartouts_rueckwände&zubehoer_crescent_IbiS":
    "Passepartouts, Rückwände und Zubehör Crescent IbiS (Mai 2023)",
  rueckwaende_juni_2022_mit_Hinweis_September:
    "Rückwände (Juni 2022 mit Hinweis September)",
  "servicepreise_._blockrahmen_&_topolino_januar_2022":
    "Servicepreise Blockrahmen & Topolino (Januar 2022)",
  "servicepreise_._einrahmungsarbeiten_september_2022":
    "Servicepreise Einrahmungsarbeiten (September 2022)",
  "servicepreise_._kaschieren_&_laminieren_September_2022":
    "Servicepreise Kaschieren & Laminieren (September 2022)",
  "wechselrahmen_._alu_april_2022_mit_Hinweis_September":
    "Wechselrahmen Aluminium (April 2022 mit Hinweis September)",
  "wechselrahmen_._holz_februar_2023_mit_Hinweis_September":
    "Wechselrahmen Holz (Februar 2023 mit Hinweis September)",
    zu_guter_letzt_bestellhinweise_agb_impressum_september_2023:
    "Zu guter Letzt - Bestellhinweise, AGB und Impressum (September 2023)",
  "zubehoer_._ibis_verbrauchsmaterialien_september_2022":
    "Zubehör - IbiS-Verbrauchsmaterialien (September 2022)",
};
module.exports = pricelistFileNames;
