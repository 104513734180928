<template>
  <div class="frameInputWrapper">
    <div class="mainHeadlineWrapper">
      <h2 class="headline">Rahmen</h2>
    </div>
    <div class="frameSearch" v-if="!ownFrame">
      <ProductSearchbar :filter="frameSearchFilter"
        :customOnClick="selectFrame"></ProductSearchbar>
    </div>
    <div>
      
      <label class="checkboxContainer"
        style="margin-bottom: 1rem; margin-top: 2rem">
        Kundeneigener / vorhandener Rahmen
        <input type="checkbox" v-model="ownFrame" @change="setOwnFrame" />
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="frameDetails">
      <div v-if="currentProduct" class="workshopOptionDetails">
        <div class="content">
          <NonConfigurableFreeSpaceDummy :roundToTwo="roundToTwo"
            :productProp="currentProduct">
          </NonConfigurableFreeSpaceDummy>
          <TopCutAndFrameOptions :updatePrice="updatePrice"
            :heightValidation="heightValidation"
            :widthValidation="widthValidation" :selectAll="selectAll"
            :productProp="currentProduct" :isFrameInput="true"
            @measurementChange="emitMeasurementChange"></TopCutAndFrameOptions>
          <ProductRestrictions :productProp="currentProduct">
          </ProductRestrictions>
          <!-- <PositionPrice
            :roundToTwo="roundToTwo"
            :productProp="currentProduct"
          ></PositionPrice> -->
        </div>
        <div class="sizeUnitWrapper">
          <div>cm</div>
          <div style="margin-top: 3.6rem;"
            v-if="currentProduct.selected_measurement_type === measurements.FALZMASS">
            cm</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NonConfigurableFreeSpaceDummy from "@/components/configurator/shared/Information/NonConfigurableFreeSpaceDummy.vue";
//import PositionPrice from "@/components/configurator/shared/Information/PositionPrice.vue";
import ProductRestrictions from "@/components/configurator/shared/Information/ProductRestrictions.vue";
import TopCutAndFrameOptions from "@/components/configurator/shared/Options/TopCutAndFrameOptions.vue";
import ProductSearchbar from "@/components/configurator/shared/ProductSearchbar.vue";
import FrameProduct from "@/models/FrameProduct";
import FamilyTypes from "@/models/Types/FamilyTypes";
import Measurements from "@/models/Types/Measurements";
import ProductOptionsToSort from "@/models/Types/ProductOptionsToSort";
import ProductTypes from "@/models/Types/ProductTypes";
import WorkshopModes from "@/models/Types/WorkshopModes";

export default {
  props: [
    "roundToTwo",
    "updatePrice",
    "heightValidation",
    "widthValidation",
    "selectAll",
  ],
  data() {
    return {
      productTypes: ProductTypes,
      familyTypes: FamilyTypes,
      ownFrame: false,
      workshopModes: WorkshopModes,
      measurements: Measurements,
    };
  },
  mounted() {
    if (this.currentProduct?.is_own_frame) {
      this.ownFrame = true;
    }
  },
  components: {
    ProductSearchbar,
    TopCutAndFrameOptions,
    NonConfigurableFreeSpaceDummy,
    //PositionPrice,
    ProductRestrictions,
  },
  computed: {
    currentProduct() {
      return this.$store.getters.currentWorkshopWork.frame;
    },
    currentWorkshopMode() {
      return this.$store.getters.currentWorkshopMode;
    },
  },
  methods: {
    frameSearchFilter(results) {
      const filteredFamilies = results
        .map((family) => {
          // First, filter the products within each family.
          const filteredProducts = family.products.filter(
            (e) =>
              e.sub_category === ProductOptionsToSort.ALU_CUT ||
              e.sub_category === ProductOptionsToSort.WOOD_FRAME ||
              e.sub_category === ProductOptionsToSort.CORNER_FRAME
          );

          // Return the family object with the filtered products,
          // or null if there are no products left after filtering.
          return filteredProducts.length > 0
            ? { ...family, products: filteredProducts }
            : null;
        })
        // Then, filter out any null entries (i.e., families with zero products).
        .filter((family) => family !== null);
      return filteredFamilies;
    },
    selectFrame(result) {
      if (this.currentWorkshopMode === this.workshopModes.CANVAS_FRAMING) {
        result.products[0].min_width = 22;
        result.products[0].min_height = 22;
      }
      if (result.products[0].sub_category === ProductOptionsToSort.ALU_CUT) {
        result.products[0].with_poly = true;
      }
      this.$store.commit("setWorkshopFrame", result.products[0]);
    },
    emitMeasurementChange() {
      this.$emit("measurementChange");
    },
    setOwnFrame() {
      if (this.ownFrame) {
        const newFrame = new FrameProduct();
        newFrame.sub_category = ProductOptionsToSort.WOOD_FRAME;
        newFrame.is_own_frame = true;
        newFrame.name = "kundeneigener Rahmen";
        newFrame.description = "kundeneigener Rahmen";
        newFrame.price = 0;
        this.$store.commit("setWorkshopFrame", newFrame);
      } else {
        this.$store.commit("setWorkshopFrame", null);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../../../../assets/sass/style.scss";

.frameInputWrapper {
  .mainHeadlineWrapper {
    width: 60vw;

    @media (max-width: $mobile) {
      width: 95vw;
    }

    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 80vw;
    }

    max-width: 850px;

    .headline {
      text-align: left;
    }
  }
}

.sizeUnitWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 12.8rem;
  min-width: 1.5vw;
  margin-left: 0.5rem;

  span {
    height: fit-content;
  }
}

.checkboxContainer {
  z-index: 1;
  display: flex;
  position: relative;
  padding-left: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 0;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    background-color: $ibis-grey-middle;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked~.checkmark {
    background-color: $ibis-red;
    transition: 0.15s;

    &:after {
      display: block;
    }
  }

  .checkmark:after {
    left: 6.5px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
</style>
