const WorkshopModes = require("./Types/WorkshopModes");
const HangingTypes = require("./Types/HangingTypes");
const WorkshopWork = require("./WorkshopWork");
const UserTypes = require("./Types/UserTypes");
const CanvasStretchingTypes_BAUHAUS = require("./Types/BAUHAUS/CanvasStretchingTypes_BAUHAUS");
const CanvasStretchingTypes_XXXL = require("./Types/XXXL_GROUP/CanvasStretchingTypes_XXXL");
const CanvasStretchingTypes = require("./Types/CanvasStretchingTypes");
const { default: UserTypeProvider } = require("@/utility/UserTypeProvider");

class WorkshopFraming extends WorkshopWork {
  mode = WorkshopModes.FRAMING;
  frame;
  passepartout;
  no_passepartout = true;
  double_passepartout = false;
  second_passepartout;
  glas;
  back_wall;
  stretcher_frame;
  carrier_material;
  surface_finishing;
  already_clamped = false;
  hangings = [];
  installation;
  picture_value;
  damage;
  services = [];
  quantity = 1;
  notes = "";
  stretching_type;
  picture_has_to_be_cut_notes = "";
  double_glas = false;
  second_glas;
  folding_allowance = 0.2;

  constructor(
    mode,
    frame,
    passepartout,
    no_passepartout,
    double_passepartout,
    second_passepartout,
    glas,
    back_wall,
    stretcher_frame,
    carrier_material,
    surface_finishing,
    already_clamped,
    hangings,
    installation,
    picture_value,
    damage,
    services,
    quantity,
    notes,
    stretching_type,
    picture_has_to_be_cut_notes,
    double_glas,
    second_glas,
    folding_allowance,
  ) {
    super();
    this.mode = mode ? mode : WorkshopModes.FRAMING;
    this.frame = frame;
    this.passepartout = passepartout;
    this.no_passepartout =
      no_passepartout !== undefined ? no_passepartout : true;
    this.double_passepartout = double_passepartout !== undefined ? double_passepartout : false;
    this.second_passepartout = second_passepartout;
    this.glas = glas;
    this.back_wall = back_wall;
    this.stretcher_frame = stretcher_frame;
    this.carrier_material = carrier_material;
    this.surface_finishing = surface_finishing;
    this.already_clamped =
      already_clamped !== undefined ? already_clamped : false;
    hangings ? (this.hangings = hangings) : this.assignDefaultHanging();
    this.installation = installation;
    this.picture_value = picture_value;
    this.damage = damage;
    services ? (this.services = services) : null;
    this.quantity = quantity ? quantity : 1;
    this.notes = notes ? notes : "";
    this.stretching_type = stretching_type;
    this.picture_has_to_be_cut_notes = picture_has_to_be_cut_notes
      ? picture_has_to_be_cut_notes
      : "";
    this.double_glas = double_glas !== undefined ? double_glas : false;
    this.second_glas = second_glas;
    this.folding_allowance = folding_allowance ? folding_allowance : 0.2;
  }

  assignDefaultHanging() {
    HangingTypes.forEach((e) => {
      if (e.key === "DEFAULT") {
        this.hangings.push(e);
      }
    });
  }
  assignDefaultStretchingType() {
    const userType = UserTypeProvider.getUserType();
    switch (userType) {
      case UserTypes.XXXL_GROUP:
        this.stretching_type = CanvasStretchingTypes_XXXL.find(e => e.key === "DEFAULT");
        break;
      case UserTypes.BAUHAUS:
        this.stretching_type = CanvasStretchingTypes_BAUHAUS.find(e => e.key === "DEFAULT");
        break;
      default:
        this.stretching_type = CanvasStretchingTypes.find(e => e.key === "DEFAULT");
        break;
    }
  }
}
module.exports = WorkshopFraming;
