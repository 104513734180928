const PriceAdditions = {
  PP_CUSTOM_FORM_CONSTANT: 8.15,
  CUSTOM_MEASUREMENT_TYPE_FACTOR: 1.05,
  WITH_POLY_CONSTANT: 2.8,
  FRAME_MANUFACTORING_SMALL_CONSTANT: 11.46,
  FRAME_MANUFACTORING_MEDIUM_CONSTANT: 11.46,
  FRAME_MANUFACTORING_LARGE_CONSTANT: 11.46,
  PASSEPARTOUT_ADDITIONAL_CUT_OUT: 8.15,
  FRAME_HANGERS: 0,
  POLY_GROUP_CONSTANTS: {
    51406: 0,
    52404: 0,
    "D8-1": 0,
    52001: 0,
    51412: 0,
    51005: 0,
    51004: 0,
    51016: 0,
  },
};
module.exports = PriceAdditions;
