const InstallationTypes = [
  { key: "E", value: "Einbau Staubdicht", price: 18.83 },//preis pro lfm
  { key: "EE3308", value: "Einbau Standard", price: 14.39 }, //preis pro lfm
  { key: "EKR33081", value: "Einbau Keilrahmen", price: 10.05 }, //preis pro lfm
  {
    key: "DOPPEL_GLAS",
    value: "Einbau Doppelglaseinrahmung",
    price: 38.20,
  },
];
module.exports = InstallationTypes;
