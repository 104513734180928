import { createRouter, createWebHistory } from "vue-router";
import MainPage from "../components/mainpage/MainPage.vue";
import LoginScreen from "../components/authentication/LoginScreen.vue";
import RegisterScreen from "../components/authentication/RegisterScreen.vue";
import ChangePassword from "../components/authentication/ChangePassword.vue";
import ForgotPassword from "../components/authentication/ForgotPassword.vue";
import ConfiguratorEntryPage from "../components/configurator/ProductConfigurator/ConfiguratorEntryPage.vue";
import ProfilePage from "../components/profile/ProfilePage.vue";
import ThankYou from "../components/configurator/shared/ThankYou.vue";
import DownloadsPage from "../components/DownloadsPage/DownloadsPage.vue";
import FileList from "../components/DownloadsPage/FileList.vue";
import ContactsPage from "../components/contact/ContactsPage.vue";
import IbisImpressum from "../components/legal/IbisImpressum.vue";
import DataPrivacy from "../components/legal/DataPrivacy.vue";
import AdminPanel from "../components/AdminPanel/AdminPanel.vue";
import RemainingStockSale from "../components/RemainingStockSale/RemainingStockSale.vue";
import StockItemDetails from "../components/RemainingStockSale/components/StockItemDetails.vue";
// eslint-disable-next-line
import PricelistConfigurator from "../components/PricelistConfigurator/PricelistConfigurator.vue";

import AuthService from "@/utility/AuthService";

// Somewhere in your application setup code:

import store from "../main.js";

const routes = [
  {
    path: "/",
    name: "Home",
    component: MainPage,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginScreen,
  },
  {
    path: "/profil",
    name: "Profil",
    component: ProfilePage,
  },
  {
    path: "/registrierung",
    name: "Registrierung",
    component: RegisterScreen,
  },
  {
    path: "/passwort_aendern",
    name: "Password ändern",
    component: ChangePassword,
  },
  {
    path: "/passwort_vergessen",
    name: "Password vergessen",
    component: ForgotPassword,
  },
  {
    path: "/konfigurator",
    name: "Konfigurator",
    component: ConfiguratorEntryPage,
  },
  {
    path: "/danke",
    name: "Danke",
    component: ThankYou,
  },
  {
    path: "/downloads",
    name: "Downloads",
    component: DownloadsPage,
  },
  {
    path: "/downloads/preislistenkonfigurator",
    name: "Preislistenkonfigurator",
    component: PricelistConfigurator,
  },
  {
    path: "/downloads/preislisten",
    name: "Preislisten",
    component: FileList,
  },
  {
    path: "/downloads/weitere_downloads",
    name: "Weitere Downloads",
    component: FileList,
  },
  {
    path: "/ansprechpartner",
    name: "Ansprechpartner",
    component: ContactsPage,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: IbisImpressum,
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: DataPrivacy,
  },
  {
    path: "/admin",
    name: "Admin",
    component: AdminPanel,
  },
  {
    path: "/restbestand",
    name: "Restbestand",
    component: RemainingStockSale,
  },
  {
    path: "/restbestand/:id",
    name: "RestbestandDetails",
    component: StockItemDetails,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authService = await AuthService.getInstance();
  const user = await authService.getUser();

  const publicPaths = ["/login", "/registrierung", "/passwort_vergessen"];
  const alwaysPublicPaths = ["/ansprechpartner", "/impressum", "/datenschutz"];
  const adminPath = "/admin";
  const isPublicPath = publicPaths.includes(to.path);
  const isAlwaysPublicPath = alwaysPublicPaths.includes(to.path);
  const isUserAuthenticated =
    user &&
    !user.isAnonymous &&
    localStorage.getItem("ibisOnlinebestellungUserObject");
  const isAdmin = store.getters.userIsAdmin;

  // Update navbar visibility based on authentication status
  store.commit("updateNavbarVisibility", isUserAuthenticated);

  // Redirect authenticated users trying to access public paths (excluding always public paths) to the Home Screen
  if (isUserAuthenticated && isPublicPath) {
    window.scrollTo({ top: 0 });
    next("/");
    return; // Ensure no further logic is executed after this redirect
  }

  // Redirect unauthenticated users trying to access non-public paths (excluding always public paths) to the login page
  if (!isUserAuthenticated && !isPublicPath && !isAlwaysPublicPath) {
    window.scrollTo({ top: 0 });
    next("/login");
    return; // Ensure no further logic is executed after this redirect
  }

  // For navigating to the admin path, check if the user is an admin
  if (to.path === adminPath) {
    window.scrollTo({ top: 0 });
    if (isAdmin) {
      next();
    } else {
      next("/"); // Non-admin users are redirected to the Home Screen
    }
    return; // Ensure no further logic is executed after this check
  }

  // Scroll to top and proceed
  window.scrollTo({ top: 0 });
  next();
});


export default router;
