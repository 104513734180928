const { default: ProductFamily } = require("@/models/productFamily.js");
const SingleProduct = require("@/models/SingleProduct.js");
const WorkshopFraming = require("@/models/WorkshopFraming.js");
const WorkshopLamination = require("@/models/WorkshopLamination.js");
const WorkshopStretching = require("@/models/WorkshopStretching.js");
const { cloneDeep } = require("lodash");
const store = require("../main.js");
const BackendHandler = require("./BackendHandler.js");

class DBSyncer {
  syncConfiguration() {
    let families = cloneDeep(store.default.getters.productFamilies);
    let currentFamily = cloneDeep(store.default.getters.currentProductFamily);
    let configurations = cloneDeep(
      store.default.getters.selectedConfigurations
    );
    let glasOption = cloneDeep(store.default.getters.currentGlasOption);
    // Helper function to rename property
    const renameProperty = (obj, oldProp, newProp) => {
      if (Object.prototype.hasOwnProperty.call(obj, oldProp)) {
        obj[newProp] = obj[oldProp];
        delete obj[oldProp];
      }
      return obj;
    };
    if (families.length > 0) {
      for (const family of families) {
        if (family instanceof ProductFamily) {
          family.products.forEach((product) => {
            //renameProperty(product, "productType", "sub_category");
            renameProperty(product, "description", "search_params");
            renameProperty(product, "product_id", "parent_id");
            renameProperty(product, "webshop_id", "product_uid");
          });
        } else if (family instanceof WorkshopFraming) {
          family.frame
            ? renameProperty(family.frame, "description", "search_params")
            : null;
          family.frame
            ? renameProperty(family.frame, "product_id", "parent_id")
            : null;
          family.frame
            ? renameProperty(family.frame, "webshop_id", "product_uid")
            : null;

          family.passepartout
            ? renameProperty(
              family.passepartout,
              "description",
              "search_params"
            )
            : null;
          family.passepartout
            ? renameProperty(family.passepartout, "product_id", "parent_id")
            : null;
          family.passepartout
            ? renameProperty(family.passepartout, "webshop_id", "product_uid")
            : null;

          family.glas
            ? renameProperty(family.glas, "description", "search_params")
            : null;
          family.glas
            ? renameProperty(family.glas, "product_id", "parent_id")
            : null;
          family.glas
            ? renameProperty(family.glas, "webshop_id", "product_uid")
            : null;

          family.back_wall
            ? renameProperty(family.back_wall, "description", "search_params")
            : null;
          family.back_wall
            ? renameProperty(family.back_wall, "product_id", "parent_id")
            : null;
          family.back_wall
            ? renameProperty(family.back_wall, "webshop_id", "product_uid")
            : null;

          family.stretcher_frame
            ? renameProperty(family.stretcher_frame, "description", "search_params")
            : null;
          family.stretcher_frame
            ? renameProperty(family.stretcher_frame, "product_id", "parent_id")
            : null;
          family.stretcher_frame
            ? renameProperty(family.stretcher_frame, "webshop_id", "product_uid")
            : null;

          family.carrier_material
            ? renameProperty(family.carrier_material, "description", "search_params")
            : null;
          family.carrier_material
            ? renameProperty(family.carrier_material, "product_id", "parent_id")
            : null;
          family.carrier_material
            ? renameProperty(family.carrier_material, "webshop_id", "product_uid")
            : null;

          family.second_glas
            ? renameProperty(family.second_glas, "description", "search_params")
            : null;
          family.second_glas
            ? renameProperty(family.second_glas, "product_id", "parent_id")
            : null;
          family.second_glas
            ? renameProperty(family.second_glas, "webshop_id", "product_uid")
            : null;

        } else if (family instanceof WorkshopStretching) {
          family.stretcher_frame
            ? renameProperty(
              family.stretcher_frame,
              "description",
              "search_params"
            )
            : null;
          family.stretcher_frame
            ? renameProperty(family.stretcher_frame, "product_id", "parent_id")
            : null;
          family.stretcher_frame
            ? renameProperty(
              family.stretcher_frame,
              "webshop_id",
              "product_uid"
            )
            : null;
        } else if (family instanceof WorkshopLamination) {
          family.carrier_material
            ? renameProperty(
              family.carrier_material,
              "description",
              "search_params"
            )
            : null;
          family.carrier_material
            ? renameProperty(family.carrier_material, "product_id", "parent_id")
            : null;
          family.carrier_material
            ? renameProperty(
              family.carrier_material,
              "webshop_id",
              "product_uid"
            )
            : null;
        }
      }
    }
    if (currentFamily) {
      // Rename property for currentProductFamily
      currentFamily.products.forEach((product) => {
        //renameProperty(product, "productType", "sub_category");
        renameProperty(product, "description", "search_params");
        renameProperty(product, "product_id", "parent_id");
        renameProperty(product, "webshop_id", "product_uid");
      });
    }
    if (configurations.length > 0) {
      // Rename property for each element of selectedConfigurations
      configurations.forEach((config) => {
        //renameProperty(config, "productType", "sub_category");
        if (config instanceof SingleProduct) {
          renameProperty(config, "description", "search_params");
          renameProperty(config, "product_id", "parent_id");
          renameProperty(config, "webshop_id", "product_uid");
        } else if (config instanceof WorkshopFraming) {
          config.frame
            ? renameProperty(config.frame, "description", "search_params")
            : null;
          config.frame
            ? renameProperty(config.frame, "product_id", "parent_id")
            : null;
          config.frame
            ? renameProperty(config.frame, "webshop_id", "product_uid")
            : null;

          config.passepartout
            ? renameProperty(
              config.passepartout,
              "description",
              "search_params"
            )
            : null;
          config.passepartout
            ? renameProperty(config.passepartout, "product_id", "parent_id")
            : null;
          config.passepartout
            ? renameProperty(config.passepartout, "webshop_id", "product_uid")
            : null;

          config.glas
            ? renameProperty(config.glas, "description", "search_params")
            : null;
          config.glas
            ? renameProperty(config.glas, "product_id", "parent_id")
            : null;
          config.glas
            ? renameProperty(config.glas, "webshop_id", "product_uid")
            : null;

          config.back_wall
            ? renameProperty(config.back_wall, "description", "search_params")
            : null;
          config.back_wall
            ? renameProperty(config.back_wall, "product_id", "parent_id")
            : null;
          config.back_wall
            ? renameProperty(config.back_wall, "webshop_id", "product_uid")
            : null;



          config.stretcher_frame
            ? renameProperty(config.stretcher_frame, "description", "search_params")
            : null;
          config.stretcher_frame
            ? renameProperty(config.stretcher_frame, "product_id", "parent_id")
            : null;
          config.stretcher_frame
            ? renameProperty(config.stretcher_frame, "webshop_id", "product_uid")
            : null;


          config.carrier_material
            ? renameProperty(config.carrier_material, "description", "search_params")
            : null;
          config.carrier_material
            ? renameProperty(config.carrier_material, "product_id", "parent_id")
            : null;
          config.carrier_material
            ? renameProperty(config.carrier_material, "webshop_id", "product_uid")
            : null;

          config.second_glas
            ? renameProperty(config.second_glas, "description", "search_params")
            : null;
          config.second_glas
            ? renameProperty(config.second_glas, "product_id", "parent_id")
            : null;
          config.second_glas
            ? renameProperty(config.second_glas, "webshop_id", "product_uid")
            : null;

        } else if (config instanceof WorkshopStretching) {
          config.stretcher_frame
            ? renameProperty(
              config.stretcher_frame,
              "description",
              "search_params"
            )
            : null;
          config.stretcher_frame
            ? renameProperty(config.stretcher_frame, "product_id", "parent_id")
            : null;
          config.stretcher_frame
            ? renameProperty(
              config.stretcher_frame,
              "webshop_id",
              "product_uid"
            )
            : null;
        } else if (config instanceof WorkshopLamination) {
          config.carrier_material
            ? renameProperty(
              config.carrier_material,
              "description",
              "search_params"
            )
            : null;
          config.carrier_material
            ? renameProperty(config.carrier_material, "product_id", "parent_id")
            : null;
          config.carrier_material
            ? renameProperty(
              config.carrier_material,
              "webshop_id",
              "product_uid"
            )
            : null;
        }
      });
    }

    if (glasOption) {
      // Rename property for currentGlasOption
      //renameProperty(glasOption, "productType", "sub_category");
      renameProperty(glasOption, "description", "search_params");
      renameProperty(glasOption, "product_id", "parent_id");
      renameProperty(glasOption, "webshop_id", "product_uid");
    }
    const payload = {
      userInfo: {
        ibis_customer_id: JSON.parse(
          localStorage.getItem("ibisOnlinebestellungUserObject")
        ).ibis_customer_id,
      },
      currentConfiguration: {
        productFamilies: families,
        selectedProductFamily: currentFamily,
        //currentWorkshopWorkMode: store.default.getters.currentWorkshopMode,
        //currentWorkshopWork: store.default.getters.currentWorkshopWork,
        selectedConfigurations: configurations,
        currentGlasSizeOption: glasOption,
        selectedIndex: store.default.getters.selectedIndex
          ? store.default.getters.selectedIndex
          : 0,
        totalPrice: store.default.getters.totalPrice,
      },
    };
    BackendHandler.default.saveCurrentUserConfiguration(payload);
  }
  async fetchUserConfiguration() {
    if (!store.default.getters.initialSync) {
      const ibis_customer_id = JSON.parse(
        localStorage.getItem("ibisOnlinebestellungUserObject")
      ).ibis_customer_id;

      try {
        const response =
          await BackendHandler.default.fetchCurrentUserConfiguration(
            ibis_customer_id
          );
        await store.default.dispatch(
          "initializeConfigurationAsync",
          response.data
        );
      } catch (error) {
        console.log(error);
        await store.default.dispatch("logErrorInFirebase", error);
      }
    }
  }
}
module.exports = new DBSyncer();
