const WorkshopStretchers = [
  {
    key: "KN3398",
    naming: "Keilrahmen 2cm Höhe",
  },
  { key: "KNP3", naming: "Keilrahmen 3cm Höhe" },
  { key: "KNP4", naming: "Keilrahmen 4cm Höhe" },
  { key: "NONE", naming: "Bild bereits aufgespannt" },
];

module.exports = WorkshopStretchers;
