<template>
  <div class="card" @mouseover="hovering = true" @mouseleave="hovering = false">
    <div class="memoji">
      <img class="memoji-image" v-bind:src="memojiUrl" />
    </div>
    <div class="employeeName">{{ personData.name }}</div>
    <div class="employeePhone">{{ personData.phone }}</div>
    <a class="employeeEmail" :href="'mailto:' + personData.email">
      {{ personData.email }}
      <img
        src="../../assets/icons/copy_icon_contact.svg"
        @click="copyToClipBoard()"
        v-if="!isCopied"
      />
      <img src="../../assets/icons/success_icon_contact.svg" v-else />
    </a>
  </div>
</template>

<script>
export default {
  props: ["personData"],
  computed: {
    memojiUrl() {
      if (!this.hovering) {
        return require(`../../assets/images/Memojis/${this.personData.primaryMemojiUrl}`);
      } else {
        return require(`../../assets/images/Memojis/${this.personData.secondaryMemojiUrl}`);
      }
    },
  },
  data() {
    return {
      hovering: false,
      isCopied: false,
    };
  },

  methods: {
    copyToClipBoard() {
      navigator.clipboard
        .writeText(this.personData.email)
        .then(() => {
          this.isCopied = true;
          setTimeout(() => {
            this.isCopied = false;
          }, 1500);
        })
        .catch((err) => {
          console.error("Error in copying text: ", err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.card {
  width: 90%;
  height: min-content;
  background-color: $ibis-grey;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem 1rem;

  .employeeName {
    font-weight: bold;
  }
  .memoji {
    width: 20vw;
    height: 20vw; // Makes the circle responsive
    max-height: 200px; // Can adjust to make sure the circle doesn't get too big on larger screens
    max-width: 200px; // Can adjust to make sure the circle doesn't get too big on larger screens
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .memoji-image {
      width: 80%;
      height: 80%;
      object-fit: contain;
    }
  }
  .employeeEmail {
    display: flex;
    gap: 0.7rem;
    align-items: center;

    img {
      width: 1rem;

      cursor: pointer;
    }
  }
  a{
    color: $ibis-black;
  }
}
</style>
