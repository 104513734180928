<template>
  <div class="selectedConfigurationsPreview">
    <div class="selectedProductIdsItem">
      <SelectedProductIDs
        :style="{
          width: isMobile ? '100vw' : '60vw',
          maxWidth: '800px',
          padding: '5%',
          backgroundColor: '#f5f5f5',
          borderRadius: '25px',
        }"
        v-if="!currentConfigLoading"
      ></SelectedProductIDs>
    </div>
    <div v-if="currentConfigLoading" class="fetchingSpinner">
      <IbisSpinner></IbisSpinner>
    </div>
    <div class="continueConfigurationButtonWrapper">
      <div class="continueConfigurationButton">
        <IbisButton
          buttonClass="primary"
          buttonText="Weiter bestellen"
          @click="this.$router.push('/konfigurator')"
          v-if="!currentConfigLoading"
        ></IbisButton>
      </div>
    </div>
  </div>
</template>
<script>
import DBSyncer from "@/utility/DBSyncer";
import SelectedProductIDs from "../configurator/shared/SelectedProductIDs.vue";
import IbisButton from "../shared/IbisButton.vue";
import IbisSpinner from "../shared/IbisSpinner.vue";
export default {
  computed: {
    selectedConfigurations() {
      return this.$store.getters.selectedConfigurations;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isTablet() {
      return this.$store.getters.isTablet;
    },
    isDesktop() {
      return this.$store.getters.isDesktop;
    },
  },
  components: {
    SelectedProductIDs,
    IbisButton,
    IbisSpinner,
  },
  mounted() {
    this.fetchCurrentUserConfiguration();
  },
  data() {
    return {
      currentConfigLoading: true,
    };
  },
  methods: {
    async fetchCurrentUserConfiguration() {
      this.currentConfigLoading = true;

      await DBSyncer.fetchUserConfiguration();

      this.currentConfigLoading = false;
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.selectedConfigurationsPreview {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .selectedProductIdsItem {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 14rem;
    @media (max-width: $mobile) {
      margin-top: 10rem;
    }
  }

  .continueConfigurationButtonWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    .continueConfigurationButton {
      width: 70vw;
      max-width: 950px;
      margin-top: 1rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media (max-width: $mobile) {
        width: 90vw;
      }
    }
  }
}
.fetchingSpinner {
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%);
  margin-top: 14rem;
}
</style>
