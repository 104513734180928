const CutProduct = require("./CutProduct");
const SingleProduct = require("./SingleProduct");
const ProductTypes = require("./Types/ProductTypes");

class NonConfigurableProduct extends SingleProduct {
  name;
  article_id;
  description;
  workshop_naming;
  product_id;
  webshop_id;
  familyType;
  category;
  sub_category;
  selling_unit;
  min_quantity_surcharge;
  only_full_units;
  price;
  quantity = 1;
  discount = 0;

  notes;

  productType = ProductTypes.NON_CONFIGURABLE;

  glas_size_options = [];
  glas_group;
  unavailable_regions;

  passepartout_top_leg_width;
  passepartout_left_leg_width;
  passepartout_right_leg_width;
  passepartout_bottom_leg_width;

  discontinued = false;

  constructor(
    name,
    article_id,

    description,
    workshop_naming,
    product_id,
    webshop_id,
    familyType,
    category,
    sub_category,
    selling_unit,
    min_quantity_surcharge,
    only_full_units,
    price,
    quantity,
    price_unit,
    discount,

    notes,

    productType,
    glas_size_options,
    glas_group,
    unavailable_regions,

    isRemainingStock,
    remainingStockDiscount,
    productImageUrls,
    remainingStockQuantity,
    discontinued
  ) {
    super();
    this.name = name;
    this.article_id = article_id;
    this.description = description;
    this.workshop_naming = workshop_naming;
    this.product_id = product_id;
    this.webshop_id = webshop_id;
    this.familyType = familyType;
    this.category = category;
    this.sub_category = sub_category;
    this.selling_unit = selling_unit;
    this.min_quantity_surcharge = min_quantity_surcharge;
    this.only_full_units = only_full_units === "Ja" ? true : false;
    this.price = price;
    this.price_unit = price_unit;

    this.quantity = this.only_full_units
      ? selling_unit
      : quantity
      ? quantity
      : 1;

    this.discount = discount;

    this.notes = notes;

    this.productType = productType
      ? productType
      : ProductTypes.NON_CONFIGURABLE;

    this.glas_size_options = glas_size_options ? glas_size_options : undefined;
    if (this.glas_size_options) {
      NonConfigurableProduct.sortGlasSizeOptions(this.glas_size_options);
    }
    this.glas_group = glas_group;
    this.unavailable_regions = unavailable_regions;

    this.isRemainingStock = isRemainingStock;
    this.remainingStockDiscount = remainingStockDiscount;
    this.productImageUrls = productImageUrls;
    this.remainingStockQuantity = remainingStockQuantity;
    this.discontinued = discontinued === "True" ? true : false;
  }
  /* reset() {
    this.quantity = 1;
    this.notes = undefined;
  } */
  static sortGlasSizeOptions(objects) {
    objects.sort((a, b) => {
      return (
        parseFloat(CutProduct.extractWidth(a.name)) -
        parseFloat(CutProduct.extractWidth(b.name))
      );
    });
  }
}
module.exports = NonConfigurableProduct;
