<template>
  <div v-if="isRetailer">
    <div v-if="isLoading" class="loading">
      <IbisSpinner></IbisSpinner>
    </div>
    <div v-else>
      <StockList :stock="remainingStock"></StockList>
    </div>
  </div>
</template>
<script>
import ProductFamily from "@/models/productFamily";
import BackendHandler from "@/utility/BackendHandler";
import ProductFactory from "@/utility/ProductFactory";
import IbisSpinner from "../shared/IbisSpinner.vue";
import StockList from "./components/StockList.vue";
import UserTypeProvider from "@/utility/UserTypeProvider";
import UserTypes from "@/models/Types/UserTypes";
export default {
  components: {
    IbisSpinner,
    StockList,
  },
  computed: {
    isRetailer() {
      return UserTypeProvider.getUserType() === UserTypes.RETAILER;
    },
  },
  data() {
    return {
      remainingStock: [],
      isLoading: true,
    };
  },
  async mounted() {
    await this.getRemainingStock();
  },
  methods: {
    async getRemainingStock() {
      this.isLoading = true;
      try {
        const response = await BackendHandler.getRemainingStock();
        const rawProducts = response.data;

        // Convert raw products to product families, similar to searchProducts
        const productFamilies = await this.convertToProductFamilies(
          rawProducts
        );
        this.remainingStock = productFamilies; // assuming you want to work with the families from here
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        // Handle error (e.g., show error message to user)
      }
    },
    async convertToProductFamilies(rawProducts) {
      let productFamilies = [];
      let uniqueFamilyDescriptions = [];
      let uniqueGlasOptions = [];

      try {
        // Loop through all products and organize them into families
        for (const product of rawProducts) {
          // Skip if this family is already processed
          if (productFamilies.find((e) => e.parent_id === product.parent_id)) {
            continue;
          }
          // Create a new family
          let productFamily = new ProductFamily(product.parent_id);

          // Filter products for the current family
          let familyProducts = rawProducts.filter(
            (e) => e.parent_id === product.parent_id
          );

          let uniqueProductTypes = [];
          let productPromises = familyProducts.map((e) => {
            // Conditionally add product to family
            if (!uniqueGlasOptions.includes(e.search_params) && e.glas_group) {
              uniqueGlasOptions.push(e.search_params);
              return ProductFactory.fromJson(e);
            } else if (!e.glas_group) {
              return ProductFactory.fromJson(e);
            } else {
              return Promise.resolve();
            }
          });

          // Resolve all promises and filter out undefined values
          let products = (await Promise.all(productPromises)).filter(
            (product) => product !== undefined
          );

          // Add unique products to the family
          for (const product of products) {
            if (!uniqueProductTypes.includes(product.productType)) {
              productFamily.addProduct(product);
              uniqueProductTypes.push(product.productType);
            }
          }

          // Sort and potentially add the family to the list
          productFamily.sortProducts();
          productFamily.remainingStockQuantity =
            productFamily.products[0].remainingStockQuantity;
          if (
            productFamily.products[0] &&
            !uniqueFamilyDescriptions.includes(
              productFamily.products[0].description
            )
          ) {
            productFamilies.push(productFamily);
            uniqueFamilyDescriptions.push(
              productFamily.products[0].description
            );
          }
        }

        const rawData = localStorage.getItem("ibisOnlinebestellungUserObject");
        if (rawData) {
          // eslint-disable-next-line
          const { currentConfiguration, ...rest } = JSON.parse(rawData);
          const userInfo = rest;

          // Assuming productFamilies is an array of objects and each object has an "options" property that is an array
          const availableProductFamilies = productFamilies.filter((family) => {
            // Assuming the first product option is what's needed. Modify this line if the structure is different.
            const firstProduct = family.products ? family.products[0] : null;

            if (firstProduct && firstProduct.unavailable_regions) {
              // Convert both the unavailable_regions array and the ibis_customer_id to strings and check if the latter is included in the former
              return !firstProduct.unavailable_regions.includes(
                userInfo.ibis_customer_id.toString()[0]
              ); // Get first character of ibis_customer_id, convert to string and check if it's included
            } else {
              // If there's no unavailable_regions field, assume the product is available
              return true;
            }
          });
          productFamilies = availableProductFamilies;

          return this.sortFamilies(productFamilies);
        }
      } catch (error) {
        console.error(error);
      }
    },
    sortFamilies(families) {
      return families.sort((a, b) => {
        const articleIdA = a.products[0].article_id; // assuming ProductFamily objects have an 'article_id' or similar property
        const articleIdB = b.products[0].article_id; // same assumption as above

        const isNumA = !isNaN(articleIdA);
        const isNumB = !isNaN(articleIdB);

        // If both are numbers, compare them numerically
        if (isNumA && isNumB) {
          return parseFloat(articleIdA) - parseFloat(articleIdB);
        }

        // If A is a number and B is a letter, A should come first
        if (isNumA && !isNumB) {
          return -1;
        }

        // If B is a number and A is a letter, B should come first
        if (!isNumA && isNumB) {
          return 1;
        }

        // If both are letters, compare them alphabetically
        return articleIdA.localeCompare(articleIdB);
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/sass/style.scss";

.loading {
  height: 80vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
