import WorkshopModes from "@/models/Types/WorkshopModes";
import WorkshopFraming from "@/models/WorkshopFraming";
import WorkshopLamination from "@/models/WorkshopLamination";
import WorkshopStretching from "@/models/WorkshopStretching";
import ProductFactory from "./ProductFactory";

class WorkshopFactory {
  static async fromJson(data) {
    if (
      data.mode === WorkshopModes.FRAMING ||
      data.mode === WorkshopModes.FRAME_WITH_EQUIPMENT
      || data.mode === WorkshopModes.CANVAS_FRAMING
      || data.mode === WorkshopModes.LAMINATION_FRAMING
    ) {
      return new WorkshopFraming(
        data.mode,
        data.frame ? await ProductFactory.fromJson(data.frame) : undefined,
        data.passepartout
          ? await ProductFactory.fromJson(data.passepartout)
          : undefined,
        data.no_passepartout,
        data.double_passepartout,
        data.second_passepartout
          ? await ProductFactory.fromJson(data.second_passepartout)
          : undefined,
        data.glas ? await ProductFactory.fromJson(data.glas) : undefined,
        data.back_wall
          ? await ProductFactory.fromJson(data.back_wall)
          : undefined,
        data.stretcher_frame
          ? await ProductFactory.fromJson(data.stretcher_frame)
          : undefined,
        data.carrier_material
          ? await ProductFactory.fromJson(data.carrier_material)
          : undefined,
        data.surface_finishing,
        data.already_clamped,
        data.hangings,
        data.installation,
        data.picture_value,
        data.damage,
        data.services,
        data.quantity,
        data.notes,
        data.stretching_type,
        data.picture_has_to_be_cut_notes,
        data.double_glas,
        data.second_glas
          ? await ProductFactory.fromJson(data.second_glas)
          : undefined,
        data.folding_allowance
      );
    } else if (data.mode === WorkshopModes.CANVAS_STRETCH) {
      return new WorkshopStretching(
        data.stretcher_frame
          ? await ProductFactory.fromJson(data.stretcher_frame)
          : undefined,
        data.hangings,
        data.picture_value,
        data.damage,
        data.services,
        data.quantity,
        data.notes,
        data.picture_has_to_be_cut_notes,
        data.edges_have_to_be_colored_notes
      );
    } else if (data.mode === WorkshopModes.LAMINATION_AND_SURFACE_FINISHING) {
      return new WorkshopLamination(
        data.carrier_material
          ? await ProductFactory.fromJson(data.carrier_material)
          : undefined,
        data.hangings,
        data.surface_finishing,
        data.picture_value,
        data.damage,
        data.services,
        data.quantity,
        data.notes,
        data.stretching_type,
        data.picture_has_to_be_cut_notes,
        data.edges_have_to_be_colored_notes,
      );
    }
  }
}
export default WorkshopFactory;
